import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountApiService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  getHttpHeaders(){
    return{
      headers: new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('content-type', 'application/json')
    }
  }

  getHttpOptions(){
    return{
      headers: new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('content-type', 'application/json')
      .set("Authorization", "Bearer " + this.getToken())
    }
  }
  
  setToken(token) {
    localStorage.removeItem('authorization');
    localStorage.setItem('authorization', JSON.stringify(token));
  }

  getToken(): string {
    return JSON.parse(localStorage.getItem('authorization'));
  }
  
  register(obj): Observable<any> {
    return this.http.post(this.baseUrl + 'user', obj, this.getHttpHeaders())
  }
  sendEmail(obj): Observable<any> {
    return this.http.post(this.baseUrl + 'user/verification/mail', obj, this.getHttpHeaders())
  }

  authLoginUsingEmail(obj): Observable<any> {
    // return this.http.post<any>(this.baseUrl + 'authLogin/emailId', obj);
    return this.http.post<any>(this.baseUrl + '/user/authenticate', obj);
  }
  authLoginUsingMobile(obj): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'authLogin/mobileNumber', obj);
  }
  getUserInfoByID(requestId, userId): Observable<any> {
    return this.http.get<any>(this.baseUrl + `user/id?id=${requestId}&userId=${userId}`);
  }
  verifyAccount(userId): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'user/verify/' + userId, this.getHttpHeaders());
  }

  generateOTP(obj): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'user/sendOTP', obj);
  }
  verifyOTP(obj): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'user/verifyOTP', obj)
  }
  resetPassword(emailId, password): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'user/resetPassword?mobileNumber=' + emailId +'&password='+password, this.getHttpOptions())
  }
  addToCometChat(obj): Observable<any> {
    return this.http.post<any>('https://api-us.cometchat.io/v2.0/users', obj)
  }
  getAllCities(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'user/cities');
  }
  geAllState(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'user/cities');
  }
  verifyGstNumber(gst): Observable<any> {
    return this.http.get<any>(`https://cleartax.in/f/compliance-report/27FQWPS8917K1ZI/`);
  }
  getStateListBasedOnCountry(countryId): Observable<any> {
    return this.http.get<any>(this.baseUrl + `user/state/`+ countryId);
  }
  getCityListBasedOnState(stateId): Observable<any> {
    return this.http.get<any>(this.baseUrl + `user/city/`+stateId);
  }
  getUniversityList(universityName=""): Observable<any> {    
    return this.http.get<any>(this.baseUrl + `university/getByName/${universityName}`);
  }
  getinstitution(institutionName=""): Observable<any> {    
    return this.http.get<any>(this.baseUrl + `institution/getByName/${institutionName}`);
  }
  saveUniversity(obj): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/university', obj)
  }
  saveOtherCompany(obj): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'otherCompany/create', obj)
  }
  getAllCompanyList(companyName): Observable<any> {
    return this.http.get<any>(this.baseUrl + `company/search?companyName=${companyName}`);
  }
  getAllOtherCompanyList(companyName): Observable<any> {
    return this.http.get<any>(this.baseUrl + `company/otherCompany/searchByCompanyName?companyName=${companyName}`);
  }
  getCityList(keyword): Observable<any> {
    return this.http.get<any>(this.baseUrl + `user/city/filter?cityName=${keyword}`);
  }

  getUserRoles(): Observable<any> {
    return this.http.get(this.baseUrl + 'user/role')
  }
 
}
