import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Database, getDatabase, ref, set, onValue, child  } from "firebase/database";
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';
import { Router } from "@angular/router";
import { ProfileService } from '../profile/profile.service';
import { Chat } from './chat';
import { take } from 'rxjs/operators';
import { AwsService } from 'src/app/shared/AWS-Service/aws.service';
import * as FileSaver from 'file-saver';
import { PresenceService } from './presence.service';
import * as moment from 'moment';

// global var declaration
declare var window: any;

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss']
})
export class MessagingComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  add: any = "assets/img/Add1.png";
  title = 'firechat';
  app: FirebaseApp;
  db: Database;
  form: FormGroup;
  username = '';
  message = '';
  chats: Chat[] = new Array();
  userInfo: any;
  userList: any = [];
  connectedUserList: any = [];
  selectedUser: any;
  content: any;
  chatsRef: any;
  emojiToggled: boolean = false
  emoji: any;
  selectedFile: any;
  uploadedFile: any;
  presence$: any;
  searchKey: any;
  
  constructor(private router: Router, private profileService: ProfileService,
    private formBuilder: FormBuilder, private awsService: AwsService, private presence: PresenceService) {
      this.app = initializeApp(environment.firebaseConfig);
      this.db = getDatabase(this.app);

      this.form = this.formBuilder.group({
        'content' : [],
      });
      
  }

  async ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem("user"));

    const data = await this.profileService.allConnectionsByUserId(this.userInfo.id).pipe(take(1)).toPromise();
    this.getAllConnections(data);
    this.loadChat();
    // this.scrollToBottom();
    this.presence$ = this.presence.getPresence(this.selectedUser.uid);
  }

  // ngOnChanges() {
  //   this.presence$ = this.presence.getPresence(this.selectedUser.uid);
  // } 

   getAllConnections(data){
      if(data.success){
        console.log(data)

        data.data.forEach((element)=>{
          if(element.user.id != this.userInfo.id){
            element.user['chats']=[];
            element.user['unreadMessageCnt']=0;
            this.userList.push(element.user);
          }

          if(element.connectedUser.id != this.userInfo.id){
            element.connectedUser['chats']=[];
            element.connectedUser['unreadMessageCnt']=0;
            this.userList.push(element.connectedUser);
          }
        })

        if(this.userList.length > 0){
          this.selectedUser = this.userList[0];
        }

        this.connectedUserList = this.userList;
      }
  }

  async onChatSubmit() {
    var msg = document.getElementById("newSection").innerHTML;

    if(this.selectedFile != null){
      this.uploadedFile = await this.awsService.uploadFile(this.selectedFile, "postimage");
    }
    const chat ={
      "content": msg,
      "timestamp": new Date().getTime(),
      "updatedAt": new Date().toString(),
      "senderId": this.userInfo.id,
      "receiverId": this.selectedUser.id,
      "filePath": this.uploadedFile != null || this.uploadedFile != undefined ? this.uploadedFile : "null",
      "fileName": this.selectedFile != null ? this.selectedFile.name : "null",
      "messageStatus": "New",
      "id": uuidv4(),
      "messageId": this.userInfo.id + "_" + this.selectedUser.id
    }
    
    set(ref(this.db, `chats/${chat.id}`), chat);
    this.content = "";
    document.getElementById("newSection").innerHTML = ""
    this.selectedFile = null;
    this.uploadedFile = null;
  }

  changeSelectedUser(obj){
    this.chats = [];
    this.selectedUser = obj
    // this.loadChat();
    this.presence$ = this.presence.getPresence(this.selectedUser.uid);
    this.selectedUser.unreadMessageCnt = 0;
    this.chats = this.selectedUser ?  this.selectedUser.chats : [];

    this.chats.forEach((element)=>{
      if((element.receiverId == this.userInfo.id) && (element.messageStatus == "New")){
        set(ref(this.db, 'chats/' + element.id), {
          content: element.content,
          timestamp: element.timestamp,
          updatedAt: element.updatedAt,
          senderId: element.senderId,
          receiverId: element.receiverId,
          filePath: element.filePath,
          fileName: element.fileName,
          messageStatus: "Read",
          id: element.id,
          messageId: element.messageId
        })
      }
    })
  }

  loadChat(){
    const chatsRef = ref(this.db, 'chats');
    onValue(chatsRef, (snapshot: any) => {
      const data = snapshot.val();
      console.log(data)
      // for(let id in data) {
      //   if (!this.chats.map(chat => chat.id).includes(id)) {
      //     this.chats.push(data[id])
      //   }
      // }

      this.userList.map(user => user.chats = []);
      this.userList.map(user => user.unreadMessageCnt = 0);
      this.chats = [];
      for(let id in data) {
        const obj = data[id];
        const actorIds = obj.messageId.split("_");

        this.userList.forEach((element)=>{
          if(actorIds.includes(this.userInfo.id + "") && actorIds.includes(element.id + "")){
            element.chats.push(data[id])
          }
          if(actorIds.includes(this.userInfo.id + "") && actorIds.includes(element.id + "") && this.userInfo.id == obj.receiverId && obj.messageStatus == "New" ){
            element.unreadMessageCnt = element.unreadMessageCnt + 1;
          }
        })
        if(actorIds.includes(this.userInfo.id + "") && actorIds.includes(this.selectedUser.id + "")){
          this.chats.push(data[id])
        }
      }

      this.userList.forEach((element)=>{
        element.chats.sort((x, y) => {
          return new Date(x.timestamp) < new Date(y.timestamp) ? 1 : -1
        }).reverse()
      })
      this.userList.sort((x, y) => {
        if ((x.chats.length > 0 && y.chats.length > 0) && x.chats[x.chats.length-1].timestamp < y.chats[y.chats.length-1].timestamp) {
          return -1;
        }
  
        if ((x.chats.length > 0 && y.chats.length > 0) && x.chats[x.chats.length-1].timestamp > y.chats[y.chats.length-1].timestamp) {
          return 1;
        }

        if (x.chats.length == 0 && y.chats.length > 0) {
          return -1;
        }
  
        if (y.chats.length == 0 && x.chats.length > 0) {
          return 1;
        }
        return 0;
      }).reverse()

      this.chats = [];
      this.selectedUser = this.userList[0];
      this.chats = this.selectedUser ?  this.selectedUser.chats : [];

      return (
        this.chats.sort((x, y) => {
            return new Date(x.timestamp) < new Date(y.timestamp) ? 1 : -1
        })
      ).reverse()

    });
  }

  addEmoji(event){
    this.content = document.getElementById("newSection").innerHTML
    this.emoji = event.emoji.native;
    this.content = [this.content, this.emoji].join(''); // [this.description.slice(0, this.caretPos), this.emoji, this.description.slice(this.caretPos)].join('');
    this.toggleEmoji();
  }

  toggleEmoji() {
    this.emojiToggled = !this.emojiToggled;
  }

  onFileSelect(event){
    this.selectedFile = event.target.files[0];

    if(this.selectedFile.size/1024/1024 > 10){
      alert("Please upload file less than 10MB.");
      this.selectedFile = null;
    }
  }

  // async uploadFile(){
  //   const file = this.selectedFile;
  //   var reader = new FileReader()
  //   reader.readAsDataURL(file)
  //   console.log(reader)
  //   reader.onload = async (_event) => {
  //     return await this.awsService.uploadFile(file, "postimage");
  //   }
  // }

  removeFile(){
    this.selectedFile = null;
  }

  removeTags(value){
    if ((value===null) || (value===''))
      return false;
    else
      value = value.toString();
      
    // Regular expression to identify HTML tags in 
    // the input string. Replacing the identified 
    // HTML tag with a null string.
    value = value.replace( /(<([^>]+)>)/ig, ' ');
    value = value.replace(/\&nbsp;/g, ' ')
    return value.length > 15 ? value.substr(0, 16) + "..." : value; 
  }

  downloadFile(obj){
    if(obj != null && obj != undefined){
      this.awsService.downloadFile(obj.fileName).then(res => {
        console.log("Response ", res)
        this.saveFile(res, obj.fileName)   
      })
    }
  }

  saveFile(response, fileName){
    const blob = new Blob([response.Body], { type: response.ContentType });
    // fileName = fileName || response.headers.get('content-disposition').split(';')[0];
    const file = new File([blob], fileName, { type: response.ContentType });
    console.log(file)
    FileSaver.saveAs(file);
  }

  gotoNextLine(event){
    if(event.keyCode==13 && event.shiftKey==true){ //enter && shift

      event.preventDefault(); //Prevent default browser behavior
      if (window.getSelection) {
          var selection = window.getSelection(),
              range = selection.getRangeAt(0),
              br = document.createElement("br"),
              textNode = document.createTextNode("\u00a0"); //Passing " " directly will not end up being shown correctly
          range.deleteContents();//required or not?
          range.insertNode(br);
          range.collapse(false);
          range.insertNode(textNode);
          range.selectNodeContents(textNode);
    
          selection.removeAllRanges();
          selection.addRange(range);
          return false;
      }
    
    }else if(event.keyCode==13){
      this.onChatSubmit();
    }
  }
  
  searchUser(){
    this.userList = []
    if(this.searchKey != null && this.searchKey != undefined && this.searchKey.length > 0){
      for(let i = 0; i < this.connectedUserList.length; i++) {
        if (this.connectedUserList[i].firstName.toLowerCase().includes(this.searchKey.toLowerCase()) 
        || this.connectedUserList[i].lastName.toLowerCase().includes(this.searchKey.toLowerCase())) {
          this.userList.push(this.connectedUserList[i])
        }
      }
    }else{
      this.userList = this.connectedUserList;
    }

    if(this.userList.length > 0){
      this.selectedUser = this.userList[0];
    }
  }

  compareDate(timestamp){
    var today = new Date();
    var date = new Date(timestamp);
    today.setHours(0, 0, 0, 0)
    date.setHours(0, 0, 0, 0)
    if (date < today) {
      return true;
    } else {
      return false
    }
  }

  // ngAfterViewChecked() {        
  //     this.scrollToBottom();        
  // } 

  scrollToBottom(): void {
      try {
          this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      } catch(err) { }                 
  }
}