<div class="main">
    <p style="text-align: right;">
        <!-- <i class="fa fa-times" aria-hidden="true" ></i> -->
        <i class="fa fa-times" aria-hidden="true" (click)="onNoClick()"></i>
    </p>
    <div class="content">
        <p (click)="createComapny()">Please Create your <a class="text-primary" herf='/company/createCompany'><u>Company page.</u></a> </p>
    </div>

    
</div>