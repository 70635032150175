<div class="sidebarNews position">
    <div class="card">
        <div class="card-header">
            <div class="flex w-100">
                <h6>News</h6>
                <span _ngcontent-uov-c52="" class="infoIcon">
                    <!-- <i _ngcontent-uov-c52="" aria-hidden="true"
                        class="fa fa-info-circle"></i> -->
                    
                        <i class="fa fa-info-circle" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <!-- <img src="../../../assets/icons/three-dots.svg"> -->
                    </i>
                    <div class="dropdown-menu dropdown-menu-left" style="            border-radius: 5px;
                        color: fff;
                        position: absolute;           
                        top: 0px;
                        left: 0px;
                        transform: translate3d(178px, 35px, 0px);
                        background: #293266 0% 0% no-repeat padding-box;
                        border: 1px solid #293266;
                        border-radius: 5px;
                        opacity: 1;
                        letter-spacing: 0.09px;
                        color: #F3F2F0;
                        opacity: 1;
                        padding: 12px;"
                        aria-labelledby="dropdownMenuButton">
                        <ul class="mb-0 pb-0 pl-0 pt-0">
                            <li class="mb-0 pb-0 pl-0 pt-0">"Here you can find the latest news from the startup ecosystem. Coming soon!"</li>
                           
                        </ul>
                    </div>
                    </span>
            </div>
        </div>
        <div class="p-22">
            <img src="../../../../assets/icons/comingSoon.png" />
            <p class="commingSoon">Coming Soon</p>
        </div>
    </div>