import * as CryptoJS from 'crypto-js';

import { Component, Input, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { CommentService } from './comment.service';

@Component({
  selector: 'comment-list',
  templateUrl: './comment-lisst.component.html',
  styleUrls: ['./comment-lisst.component.scss']
})
export class CommentLisstComponent implements OnInit {
  @Input() comment: any;
  @Input() commentsOnPostList: any;
  @Input() CommentPost: any;
  @Input() rootType: any;
  
  userId: any;
  childCommentList =[]
  replycomment: any = "";
  UserInfo = JSON.parse(localStorage.getItem('user'))
  constructor(private router: Router ,private commentservice : CommentService) { }

  ngOnInit(): void {    
    this.comment.reply=false;

    if(this.rootType.commentsOnPublishList){      
    this.childCommentList = this.rootType.commentsOnPublishList;
    }else{
      this.childCommentList =(this.CommentPost != null && this.CommentPost.commentsOnPostList != null)?this.CommentPost.commentsOnPostList:{}
  
    }
     
    let encrypt = localStorage.getItem('Bolstart');
    this.userId = JSON.parse(localStorage.getItem('user')).id;
  }
  hideShowReply(){    
    this.comment.reply = !this.comment.reply;
  }
  selectUser(id) {
    localStorage.setItem("visit", id);
    this.router.navigate(['../profile/timeline'], { queryParams: { visit: id } });
  }
  addcommentOnComment(){ 
    if(typeof this.replycomment === undefined ||this.replycomment == "")
     return;
      
    if (this.comment != "") {    
      let obj;
      if(this.rootType.commentsOnPublishList){
          obj = {
          description: this.replycomment,       
          parentComment: this.comment,
          user: this.UserInfo,
          publish: this.rootType
        }    
        this.commentservice.addCommentOnPublish(obj).subscribe(res => {
          if (res.success) {
             this.replycomment = ""
              this.childCommentList.unshift(res.data)    
            console.log('success')
          }
        })  
      }else{
          obj = {
          description: this.replycomment,       
          parent: this.comment,
          user: this.UserInfo,
          post: this.rootType
        }         
        this.commentservice.addCommentOnPost(obj).subscribe(res => {
          if (res.success) {
             this.replycomment = ""
              this.childCommentList.unshift(res.data)    
            console.log('success')
          }
        })
      }  
  }
}
LikeOnComment(comment){
  console.log("comment on test",this.comment )   
  console.log("comment on test", comment ) 
  console.log("root type",this.rootType)
  if(typeof this.comment.LikeCommentsOnPostUser !== 'undefined'){
    this.LikeOnPostComment(comment)
  }else{
    this.LikeOnPublishComment(comment)
  }
}


LikeOnPublishComment(comment) {
  if(this.comment.likeCommentsOnPublishUser != null && this.comment.likeCommentsOnPublishUser.includes(this.UserInfo.id)){      
    delete this.comment.likeCommentsOnPublishUser[comment.likeCommentsOnPublishUser.indexOf(this.UserInfo.id)];
    this.comment.noOfLikes =(this.comment.noOfLikes>0)?0: this.comment.noOfLikes-1;
  }else{       
    this.comment.noOfLikes = this.comment.noOfLikes+1;
    this.comment.likeCommentsOnPublishUser.push(this.UserInfo.id);
  }    
   this.comment.publish ={"id":this.rootType.id};
  this.commentservice.addCommentOnPublish(this.comment).subscribe(res => {
    if (res.success) {
       this.replycomment = ""       
    }
  })
return
}


LikeOnPostComment(comment) {

  if(this.comment.LikeCommentsOnPostUser != null && this.comment.LikeCommentsOnPostUser.includes(this.UserInfo.id)){      
    delete this.comment.LikeCommentsOnPostUser[comment.LikeCommentsOnPostUser.indexOf(this.UserInfo.id)];
    this.comment.noOfLikes =(this.comment.noOfLikes>0)?0: this.comment.noOfLikes-1;
  }else{       
    this.comment.noOfLikes = this.comment.noOfLikes+1;
    this.comment.LikeCommentsOnPostUser.push(this.UserInfo.id);
  }    
   
  this.comment.post ={"id":this.rootType.id};
  this.commentservice.addCommentOnPost(this.comment).subscribe(res => {
    if (res.success) {
       this.replycomment = ""        
      console.log('success')
    }
  })
return
}
}
