<app-header></app-header>

<div class="container">
  <div class="main-p" style="overflow: hidden">
    <div class="innter_p">
  <div class="jobPostTitle">
    <div class="title-div2">
      <p class="title-main">Job Posts</p>
    </div>
  </div>
  <div class="main_page_holder">
    <div class="mainPage">
     <div class="innter-main" (click)="postNewJob()">
        <!-- <a class=""  routerLink="/jobs/job-list-ui"> All Jobs</a> -->
        <div >
          <p class="post-internship-title"> Post a new Internship</p>
          <div class="post-internship-description">
            <span>publish your job Post to thousands of <br>students across the world</span>
          </div>
          <!-- <p>publish your job Post to thousands of</p>
          <p>students across the world</p> -->
  
        </div>
        <img src="assets/angle-double-right.svg" class="right-angle-img2" alt="">
        <!-- <i class='fas fa-angle-double-right' id="icon-w"></i> -->
      </div>
    </div>
    <div class="mainPage" *ngFor="let jobList of jobPostList">
      <div  class="active-d {{jobList.active}}">
        <div class="parantOfActive">
          <!-- <span> </span> -->
          <span></span>
          <span class="title-active" *ngIf="!jobList.active">Closed Job Post</span>
          <span class="title-active" *ngIf="jobList.active">Active Job Post</span>
          <!-- <span class="title-active2 title-view-candidates view-cand"><a  (click)="setJobDetails(jobList.id)"> View Candidates</a></span> -->
          <button class="button-i" (click)="setJobDetails(jobList.id)"><span class="button-i-text">View Candidates</span></button>
        </div>
      </div>
      <div class="paddtion-from-top">
        <div class="dFlex">
          <div class="img-title">
            <img src="{{jobList.company.logo}}" alt="" class="img-c" />
            <div class="text-seq">              
              <span class="text-c"> {{jobList.jobtitle }} </span>
              <span class="text-c2"> {{jobList.company.name }} </span>
              <span class="dayCount"> {{jobList.numberOfDays }} day ago |
                {{jobList.noOfApplication?jobList.noOfApplication:'30'}} Applicants</span>
              <span style="font-size: 14px"><span class="job">Job category 
                </span><u>{{jobList.jobCategeory?jobList.jobCategeory:'Other'}}</u>,
                </span>
            </div>
          </div>
          <div class="alignBtn">
            <!-- <button > Apply now</button> -->
            <button  style="background: red;" *ngIf="jobList.active"  type="button" class="one deactivate-btn" (click)="opendia12(jobList.id,jobList.active,jobList.hire_count,jobList.hired_count)">
              Deactivate 
            </button>
  
            <!-- <button *ngIf="!jobList.active" type="button" class="one" (click)="opendia12(jobList.id,jobList.active,jobList.hire_count,jobList.hired_count)"> -->
            <!-- <button  style="background: red;" *ngIf="jobList.active"  type="button" class="one deactivate-btn" (click)="opendia12(jobList.id,jobList.active)">
              Deactivate 
            </button> -->
  
            <button *ngIf="!jobList.active" type="button" class="one re-activate-btn" (click)="opendia12(jobList.id,jobList.active,jobList.hire_count,jobList.hired_count)">
              Re-activate 
            </button>
            <button  *ngIf="jobList.active" type="button" routerLink="/jobs/job-post-form/{{ jobList.id }}/0" class="two editactive">
              Edit
            </button>
            <button  *ngIf="!jobList.active" type="button" disabled class="two" title="Job is Deactivate" >
              Edit
            </button>
          </div>
        </div>
        <div class="title-div">
          <div>
            <span class="t-e"> Location</span>
            <span class="bold-t" *ngIf="jobList.typeofJob=='WFO'"> {{ jobList.location  }} </span>
            <span class="bold-t" *ngIf="jobList.typeofJob=='WFH'"> Remote </span>
          </div>
  
          <div>
            <span class="t-e"> Star Date</span>
            <span class="bold-t"> {{jobList.startDate | date: 'dd/MM/yyyy'}} </span>
          </div>
  
          <div>
            <span class="t-e"> Employee Type</span>
            <span class="bold-t">  {{jobList.applicant_type}}</span>
          </div>
  
          <div>
            <span class="t-e"> Stipend</span>
            <span class="bold-t" *ngIf="jobList.stipend == 0">Unpaid</span>
            <span class="bold-t" *ngIf="jobList.stipend > 0"> ₹{{ jobList.stipend}} /Month</span>
          </div>
          <div>
            <span class="t-e"> Duration</span>
            <span class="bold-t"> {{jobList.duration?jobList.duration:'6'}} Month</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
</div>
