import { Component, OnInit,Input } from '@angular/core';
import { AngleUnits } from 'aws-sdk/clients/groundstation';
import * as Chart from 'chart.js';
@Component({
  selector: 'app-graph-container',
  templateUrl: './graph-container.component.html',
  styleUrls: ['./graph-container.component.scss']
})
export class GraphContainerComponent implements OnInit {
  chartType:any =  "bar";
  configChartData:any;
  public chart: any;
  publishChart:any;
  labels:any;
  title:String ="Groth"
  @Input() chartDataList: any;
  dataValue:any;
  id;

  constructor() { }

  ngOnInit(): void {
    const d = new Date();
    this.id = "my_"+d.getTime();
    this.labels =    []
    this.dataValue =   []

    this.chartDataList.forEach(element => {
     let date = new Date(element.createdAt)      
     let d= date.getDate()+"-"+(1+date.getMonth())+"-"+date.getFullYear ();
     this.labels.push(d)
      this.title =element.title;
      this.chartType=element.type;
     this.dataValue.push(element.dataValue)         
    });
 
    this.createChart();
  }

  createChart(){ 
    console.log("hello",this.dataValue,this.labels)
    this.chart  = new Chart( "id", {
      type: this.chartType, //this denotes tha type of chart

      data: {// values on X-Axis
        labels: this.labels, 
	       datasets: [
          {
            label: this.title+"",
            data: this.dataValue,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
           // backgroundColor: 'limegreen'
          },
          
        ]
      },
      options: {
        aspectRatio:1
      }
      
    });
  }
   
}
