<!-- <header>
    <section>
        <nav class="navbar navbar-expand-lg navbar-light bg-primary nav-custom">
            <a routerLink="/home" routerLinkActive="router-link-active"><img src="assets/img/bslogo2.png"></a>
            <div class="form-inline pl-2">
                <input class="form-control search" [(ngModel)]="keyWord" (focusout)="focusOut()" (keyup)="getList()"
                    type="search" placeholder="Search" aria-label="Search"><br />

            </div>
            <img class="profile d-block d-sm-none" id="dropdownButton" data-toggle="dropdown" aria-haspopup="true"
                [src]="userInfo?.profilePicture">

            <div class="dropdown-menu pull-left" aria-labelledby="dropdownButton" style="padding: 10px;">
                <ul>
                    <li>
                        <button type="button" class="btn btn-block btn-outline-primary btn-sm"
                            (click)="GoToOwnerProfile()">View
                            Profile</button>
                    </li>

                    <hr>
                    <li *ngIf="userInfo?.company_id == null" (click)="goToNewCompany()">Create Company
                    </li>
                    <li *ngIf="userInfo?.company_id != null" (click)="goToMyCompany()">My Company
                    </li>
                    <hr>
                    <li *ngIf="userInfo?.typeOfUser == 2" (click)="goToInvestingPage()">Investor Dashboard
                    </li>
                    <hr>
                    <li (click)="goToPasswordChange()">Password Change</li>
                    <hr>
                    <li (click)="logOut()">Sign Out</li>
                </ul>
            </div>

            <div class="collapse navbar-collapse  d-none d-sm-block" id="navbarCollapse">
                <ul role="menu" class="navbar-nav ml-auto" id="navAccordion">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/home" routerLinkActive="active">
                            <i class="fa fa-home"></i><br>
                            <span>Home</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/mynetwork" routerLinkActive="active">
                            <i class="fa fa-users">
                                <span class="badge" *ngIf="requestCount > 0">{{requestCount}}</span>
                            </i><br>
                            <span>Network</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/messaging" routerLinkActive="active">
                            <i class="fa fa-commenting"></i><br>
                            <span>Messaging</span>
                        </a>
                    </li>
                    <li class=" nav-item">
                        <a class="nav-link perks" [routerLink]="['/perks']" routerLinkActive="active">
                            <i class="fa fa-suitcase"></i><br>
                            <span>Perks</span>
                        </a>
                    </li>
                    <li class=" nav-item">
                        <a class="nav-link"
                            [routerLink]="userInfo?.company_id != null ? ['/jobs/job-list'] : ['/jobs/job-list']"
                            routerLinkActive="active">
                            <i class="fa fa-handshake-o"></i><br>
                            <span>Jobs</span>
                        </a>
                    </li>
                    <li class=" nav-item">
                        <a class="nav-link"
                            [routerLink]="userInfo?.company_id != null ? ['/jobs/job-post'] : ['/jobs/job-post']"
                            routerLinkActive="active">
                            <i class="fa fa-handshake-o"></i><br>
                            <span>Post Job</span>
                        </a>
                    </li>
                    <li class=" nav-item">
                        <a class="nav-link"
                            [routerLink]="userInfo?.company_id != null ? ['/jobs/job-apply'] : ['/jobs/job-apply']"
                            routerLinkActive="active">
                            <i class="fa fa-tasks" aria-hidden="true"></i><br>
                            <span>Application</span>
                        </a>
                    </li>

                    <li class="nav-item" class="dropdown">

                        <img class="profile" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="true" [src]="userInfo?.profilePicture">

                        <div class="dropdown-menu pull-left" aria-labelledby="dropdownMenuButton"
                            style="padding: 10px;">
                            <div class="row">
                                <div class="col-12" style="display: flex;">
                                    <img id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="true" [src]="userInfo?.profilePicture">&nbsp;
                                    <div class="pt-2" style="color:black">
                                        Me
                                    </div>
                                </div>
                                <div class="col-8">
                                </div>
                                <div class="col-12" style="text-align: center;">
                                    <button type="button" class="btn btn-block btn-outline-primary btn-sm"
                                        (click)="GoToOwnerProfile()">View Profile</button>
                                </div>
                            </div>
                            <hr>
                            <ul>
                                <li *ngIf="userInfo?.company_id == null" (click)="goToNewCompany()">Create Company
                                </li>
                                <li *ngIf="userInfo?.company_id != null" (click)="goToMyCompany()">My Company
                                </li>
                                <hr>
                                <li (click)="goToPasswordChange()">Password Change</li>
                                <hr>
                                <li *ngIf="userInfo?.typeOfUser == 2" (click)="goToInvestingPage()">Investor Dashboard
                                </li>
                                <hr *ngIf="userInfo?.typeOfUser == 2">
                                <li (click)="logOut()">Sign Out</li>

                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
       
        <div class="d-block d-sm-none">
            <nav class="navbar fixed-bottom navbar-dark bg-dark text-center">

                <a class="nav-link bottom" routerLink="/home" routerLinkActive="active">
                    <i class="fa fa-home"></i><br>
                    <span>Home</span>
                </a>

                <a class="nav-link bottom" routerLink="/mynetwork" routerLinkActive="active">
                    <i class="fa fa-users">
                        <span class="badge" *ngIf="requestCount > 0">{{requestCount}}</span>
                    </i><br>
                    <span>Network</span>
                </a>
                <a class="nav-link bottom" routerLink="/messaging" routerLinkActive="active">
                    <i class="fa fa-envelope"></i><br>
                    <span>message</span>
                </a>
                <-- <a class="nav-link bottom" routerLink="/signin" routerLinkActive="active">
                <i class="fa fa-bell"></i><br>
                <span>Notification</span>
            </a> 
            </nav>
        </div>
    </section>
</header>
<div class="card position" *ngIf="isShow && userList.length > 0">
    <ul>
        <li *ngFor="let item of userList">
            <span *ngIf="item.companyId == null" style="display: flex; padding: 10px;" (click)="selectUser(item.id)">
                <img [src]="item.profile" class="profile" />&nbsp;
                <h6 class="pt-4">{{item.username}}&nbsp;&nbsp;<label>{{item.headline}}</label>
                </h6>
            </span>
            <span *ngIf="item.companyId != null" style="display: flex; padding: 10px;"
                (click)="goToCompany(item.companyId)">
                <img [src]="item.profile" class="company-profile" />&nbsp;
                <h6 class="pt-4">{{item.username}}&nbsp;&nbsp;<label>{{item.headline}}</label>
                </h6>
            </span>
            <hr>
        </li>
    </ul>
</div> -->

<header>
    <section>
        <div class="asas">
        <nav class="navbar navbar-expand-lg navbar-light navbarColor nav-custom">
            <div class="container spaceCustom">
            <a routerLink="/home" routerLinkActive="router-link-active">
                <img src="assets/img/bslogo_1.png"></a>
            
            <img class="profile d-block d-sm-none" id="dropdownButton" data-toggle="dropdown" aria-haspopup="true"
                [src]="userInfo?.profilePicture">

            <div class="dropdown-menu pull-left" aria-labelledby="dropdownButton" style="padding: 10px;">
                <ul>
                    <li>
                        <button type="button" class="btn btn-block btn-outline-primary btn-sm"
                            (click)="GoToOwnerProfile()">View
                            Profile</button>
                    </li>

                    <hr>
                    <li *ngIf="userInfo?.company_id == null" (click)="goToNewCompany()">Create Company
                    </li>
                    <li *ngIf="userInfo?.company_id != null" (click)="goToMyCompany()">My Company
                    </li>
                    <hr>
                    <li *ngIf="userInfo?.typeOfUser == 2" (click)="goToInvestingPage()">Investor Dashboard
                    </li>
                    <hr>
                    <li (click)="goToPasswordChange()">Password Change</li>
                    <hr>
                    <li (click)="logOut()">Sign Out</li>
                </ul>
            </div>
           
            <div class="collapse navbar-collapse  d-none d-sm-block pr-0" id="navbarCollapse">
                <ul role="menu" class="navbar-nav ml-auto desk-nav-list" id="navAccordion">
                    <li class="nav-item">
                        <div class="form-inline pl-2 searchBox">
                         
                              <span class="searchIocn"><img src="../../../assets/icons/searchIcon.svg" /></span>
                            <input class="form-control search" [(ngModel)]="keyWord" (focusout)="focusOut()" (keyup)="getList()"
                                type="search" placeholder="" aria-label="Search"><br />
                        </div>
                        <div class="card position" *ngIf="isShow && userList.length > 0">
                            <ul>
                                <li *ngFor="let item of userList">
                                    <span *ngIf="item.companyId == null" style="display: flex; padding: 10px;" (click)="selectUser(item.id)">
                                        <img [src]="item.profile" class="profile" />&nbsp;
                                        <h6 class="item">{{item.username}}&nbsp;&nbsp;<label>{{item.headline}}</label>
                                        </h6>
                                    </span>
                                    <span *ngIf="item.companyId != null" style="display: flex; padding: 10px;"
                                        (click)="goToCompany(item.companyId)">
                                        <img [src]="item.profile" class="company-profile" />&nbsp;
                                        <h6 class="item">{{item.username}}&nbsp;&nbsp;<label>{{item.headline}}</label>
                                        </h6>
                                    </span>
                                    <hr>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link new-nav-menu" routerLink="/home" routerLinkActive="active">
                            <!-- <i class="fa fa-home"></i> -->
                            <!-- <img class="new-nav-icon" src="assets/icons/home-nav.svg" alt="Home"> -->
                            <div class="icon new-nav-icon" [ngStyle]="{'mask': 'url(assets/icons/home-nav.svg) no-repeat center', '-webkit-mask': 'url(assets/icons/home-nav.svg) no-repeat center'}"></div>
                            <span class="icon-text new-nav-menu-txt">Home</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link new-nav-menu" routerLink="/mynetwork" routerLinkActive="active">
                            <!-- <i class="fa fa-users">
                                <span class="badge" *ngIf="requestCount > 0">{{requestCount}}</span>
                            </i> -->
                            <!-- <img class="new-nav-icon" src="assets/icons/users-nav.svg" alt="Network"> -->
                            <div class="icon new-nav-icon" [ngStyle]="{'mask': 'url(assets/icons/users-nav.svg) no-repeat center', '-webkit-mask': 'url(assets/icons/users-nav.svg) no-repeat center'}">
                            </div>
                            <span class="badge" *ngIf="requestCount > 0">{{requestCount}}</span>
                            <span class="icon-text new-nav-menu-txt">Network</span>
                        </a>
                    </li>
                    <li class=" nav-item" *ngIf="userInfo?.typeOfUser != null && userInfo?.typeOfUser > 2">
                        <a class="nav-link new-nav-menu" routerLink="/jobs/job-list-ui"
                            routerLinkActive="active">
                            <!-- <i class="fa fa-search"></i> -->
                            <!-- <img class="new-nav-icon" src="assets/icons/search-nav.svg" alt="Jobs"> -->
                            <div class="icon new-nav-icon" [ngStyle]="{'mask': 'url(assets/icons/search-nav.svg) no-repeat center', '-webkit-mask': 'url(assets/icons/search-nav.svg) no-repeat center'}"></div>
                            <span class="icon-text new-nav-menu-txt">Jobs</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link new-nav-menu" routerLink="/messaging" routerLinkActive="active">
                            <!-- <i class="fa fa-commenting"></i> -->
                            <!-- <img class="new-nav-icon" src="assets/icons/message-nav.svg" alt="Message"> -->
                            <div class="icon new-nav-icon" [ngStyle]="{'mask': 'url(assets/icons/message-nav.svg) no-repeat center', '-webkit-mask': 'url(assets/icons/message-nav.svg) no-repeat center'}"></div>
                            <span class="badge" *ngIf="messageCnt > 0">{{messageCnt}}</span>
                            <span class="icon-text new-nav-menu-txt">Message</span>
                        </a>
                    </li>
                    <!-- <li class=" nav-item">
                        <a class="nav-link perks new-nav-menu" [routerLink]="['/perks']" routerLinkActive="active">                            
                            <div class="icon new-nav-icon" [ngStyle]="{'mask': 'url(assets/icons/perks-nav.svg) no-repeat center', '-webkit-mask': 'url(assets/icons/perks-nav.svg) no-repeat center'}"></div>
                            <span class="icon-text new-nav-menu-txt">Perks</span>
                        </a>
                    </li> -->
                    <li class=" nav-item" *ngIf="isStartUpFounder">
                        <a  [routerLink]="['/explore/investor']" style="display:flex; align-items: center;text-decoration:none;" >
                            <div class="icon new-nav-icon" style="background-color: #009cc3 !important;"[ngStyle]="{'mask': 'url(assets/icons/explorerwindow.svg) no-repeat center', '-webkit-mask': 'url(assets/icons/explorerwindow.svg) no-repeat center'}"></div>
                            <span class="icon-text new-nav-menu-txt explore">Explore</span>
                        </a>
                    </li>
                    <li class=" nav-item" *ngIf="isInvestor">
                        <a  [routerLink]="['/explore/startup']" style="display:flex; align-items: center;text-decoration:none;" >
                            <div class="icon new-nav-icon" style="background-color: #009cc3 !important;"[ngStyle]="{'mask': 'url(assets/icons/explorerwindow.svg) no-repeat center', '-webkit-mask': 'url(assets/icons/explorerwindow.svg) no-repeat center'}"></div>
                            <span class="icon-text new-nav-menu-txt explore">Explore</span>
                        </a>
                    </li>
                    <!-- <li class=" nav-item" *ngIf="userInfo?.userRole != null && userInfo?.userRole == 'Startup Founder'">
                        <a class="nav-link perks new-nav-menu" [routerLink]="['/explore/']" routerLinkActive="active">
                            <div class="icon new-nav-icon" style="background-color: #009cc3 !important;"[ngStyle]="{'mask': 'url(assets/icons/explorerwindow.svg) no-repeat center', '-webkit-mask': 'url(assets/icons/explorerwindow.svg) no-repeat center'}"></div>
                            <span class="icon-text new-nav-menu-txt explore">Explore</span>
                        </a>
                    </li> -->
                    <!-- <li class=" nav-item">
                        <a class="nav-link"
                            [routerLink]="userInfo?.company_id != null ? ['/jobs/job-post'] : ['/jobs/job-post']"
                            routerLinkActive="active">
                            <i class="fa fa-handshake-o"></i>
                            <span class="icon-text">Post Job</span>
                        </a>
                    </li> -->
                    <li class=" nav-item">
                        <a class="nav-link new-nav-menu"
                            [routerLink]="['/notification']"
                            routerLinkActive="active">
                            <i class="fa fa-bell" aria-hidden="true">
                                <span class="notificationBadge" *ngIf="notificationCnt > 0">{{notificationCnt}}</span>
                            </i>
                            <!-- <span class="icon-text"></span> -->
                            <!-- <img class="new-nav-icon new-notification-icon" src="assets/icons/nav-notification.svg" alt="Perks">
                            <span class="badge" *ngIf="requestCount > 0">{{requestCount}}</span> -->
                            <!-- <span class="icon-text new-nav-menu-txt"><span class="badge" *ngIf="requestCount > 0">{{requestCount}}</span></span> -->
                            
                            
                        </a>
                    </li>
                    <span class="alg-text">
                        <span class="u-name new-user-name"><strong>Me&nbsp;&nbsp;</strong></span>
                        <!-- <span class="u-profile user-role">intern</span> -->
                    </span>
                    <li class="nav-item" class="dropdown">

                        <img class="profile" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="true" 
                            *ngIf="presence$ | async as presence" 
                            [ngClass]="{   'imgBorderGreen':  presence.status  === 'online',
                                            'imgBordergray': presence.status  === 'away' || presence.status  === 'offline'            
                                        }"
                            [src]="userInfo?.profilePicture">

                        <div class="dropdown-menu pull-left" aria-labelledby="dropdownMenuButton"
                            style="padding: 10px;">

                            <div class="row">
                                <div class="col-12" style="display: flex;">
                                    <img id="dropdownMenuButton" class="profileImg" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="true" *ngIf="presence$ | async as presence" 
                                        [ngClass]="{   'imgBorderGreen':  presence.status  === 'online',
                                                        'imgBordergray': presence.status  === 'away' || presence.status  === 'offline'            
                                                    }"
                                        [src]="userInfo?.profilePicture">&nbsp;
                                    <div class="pt-2 alg-text u-name new-user-name" style="color:black; font-weight: bold;">
                                          {{userInfo?.firstName | titlecase}} {{userInfo?.lastName | titlecase}}
                                    </div>
                                </div>
                                <div class="col-8">
                                </div>
                                <div class="col-12" style="text-align: center;">
                                    <button type="button" class="btn btn-block btn-outline-primary btn-sm"
                                        (click)="GoToOwnerProfile()">View Profile</button>
                                </div>
                            </div>
                            <hr>
                            <ul>
                                <li (click)="goTo(['/perks'])" class=" nav-item" *ngIf="companyInfo">Perks</li>
                                <hr *ngIf="companyInfo">
                                <li (click)="goTo(['/jobs/job-list-ui'])" class=" nav-item" *ngIf="userInfo?.typeOfUser != null && userInfo?.typeOfUser < 3">
                                    Jobs
                                </li>
                                <hr *ngIf="userInfo?.typeOfUser != null && userInfo?.typeOfUser < 3">
                                <li (click)="goTo(['/explore'])" class=" nav-item" *ngIf="userInfo?.company_id == null && userInfo?.typeOfUser != null && userInfo?.typeOfUser == 5">
                                    Explore
                                </li>
                                <hr *ngIf="userInfo?.typeOfUser != null && userInfo?.typeOfUser == 5">
                                
                                <li (click)="goToNewCompany()" *ngIf="isInvestor || isStartUpFounder">Create Company
                                </li>
                                <hr *ngIf="isInvestor || isStartUpFounder">
                                <li  *ngIf="userInfo?.company_id != null" (click)="goToMyCompany()">My Company
                                </li>
                                <hr *ngIf="userInfo?.company_id != null" >
                                <li routerLink="/jobs/job-post-ui"  *ngIf="userInfo?.company_id != null && userInfo?.typeOfUser != null && userInfo?.typeOfUser < 3">Manage Posted Jobs</li>
                                <hr *ngIf="userInfo?.company_id != null && userInfo?.typeOfUser != null && userInfo?.typeOfUser < 3">
                                <li (click)="goToPasswordChange()">Change Password</li>
                                <hr>
                                <li *ngIf="userInfo?.typeOfUser == 2" (click)="goToInvestingPage()">Investor Dashboard
                                </li>
                                <hr *ngIf="userInfo?.typeOfUser == 2">
                                <li (click)="logOut()">Sign Out</li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            </div>
        </nav>
        </div>
        <!-- =======================  small screen  ==================================== -->
        <div class="d-block d-sm-none">
            <nav class="navbar fixed-bottom navbar-dark bg-dark text-center">

                <a class="nav-link bottom" routerLink="/home" routerLinkActive="active">
                    <i class="fa fa-home"></i><br>
                    <span>Home</span>
                </a>

                <a class="nav-link bottom" routerLink="/mynetwork" routerLinkActive="active">
                    <i class="fa fa-users">
                        <span class="badge" *ngIf="requestCount > 0">{{requestCount}}</span>
                    </i><br>
                    <span>Network</span>
                </a>
                <a class="nav-link bottom" routerLink="/messaging" routerLinkActive="active">
                    <i class="fa fa-envelope"></i><br>
                    <span>message</span>
                </a>
                <!-- <a class="nav-link bottom" routerLink="/signin" routerLinkActive="active">
                <i class="fa fa-bell"></i><br>
                <span>Notification</span>
            </a> -->
            </nav>
        </div>
    </section>
</header>

