import { BehaviorSubject, Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';  

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  baseUrl = environment.baseUrl;
  event: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) { }
  emitEvent() {
    this.event.next(null);
  }
  
  sendRequest(obj): Observable<any> {
    return this.http.post(this.baseUrl + `connection`, obj);
  }

  acceptRequest(obj): Observable<any> {
    return this.http.put(this.baseUrl + `connection`, obj);
  }

  getFriendList(userId): Observable<any> {
    return this.http.get(this.baseUrl + `connection/userId?userId=${userId}`);
  }
  addNewPost(obj): Observable<any> {
    return this.http.post(this.baseUrl + 'post', obj);
  }
  getPostListByUserId(userID, pageNo): Observable<any> {
    //return this.http.get(this.baseUrl + `post/users?page=${pageNo}&size=10&userId=${userID}`);
    return this.http.get(this.baseUrl + `post/user/${userID}`);
    
  }

  getPostListByConnection(userID, pageNo): Observable<any> {
    return this.http.get(this.baseUrl + `post/connect/users?page=${pageNo}&size=10&userId=${userID}`);
  }
  /* ==================  Publish list ====================== */
  addNewPublish(obj): Observable<any> {
    return this.http.post(this.baseUrl + 'publish', obj);
  }
  getPublishListByUserId(userID, pageNo): Observable<any> {
    return this.http.get(this.baseUrl + `publish/publishPost/byUserId?page=${pageNo}&size=10&userId=${userID}`);
  }
  getPublishListByCompanyIdAndUserId(companyId, userId): Observable<any> {
    return this.http.get(this.baseUrl + `publish/company/user/`+companyId +`/`+ userId);
  }
  getPublishPostByConnection(userID, pageNo): Observable<any> {
    return this.http.get(this.baseUrl + `publish/connect/users?page=${pageNo}&size=10&userId=${userID}`);
  }
  createChart(obj): Observable<any> {
    return this.http.post(this.baseUrl + `chart/create`, obj);
  }
  getChartData(companyId, title): Observable<any>{
    return this.http.get(this.baseUrl + `publish/company/graph/${companyId}/${title}`);
  }
//---------------------notification----------------------
getAllNotification(userID,page): Observable<any> {
  return this.http.get(this.baseUrl + `notification/${userID}/${page}`);
}
getPostNotification(userID): Observable<any> {
  return this.http.get(this.baseUrl + `notification/post/user/${userID}`);
}
getUpdatesNotification(userID): Observable<any> {
  return this.http.get(this.baseUrl + `notification/publish/user/${userID}`);
}
getNetworkNotification(userID): Observable<any> {
  return this.http.get(this.baseUrl + `notification/network/user/${userID}`);
}
getNotification(url,userID): Observable<any> {
  return this.http.get(this.baseUrl + `notification/${url}/user/${userID}`);
}

readNotification(obj,url): Observable<any> {
  return this.http.post(this.baseUrl + `notification/${url}`, obj);
}
sendNotification(obj,url): Observable<any> {
  return this.http.post(this.baseUrl + `notification/${url}`, obj);
}

//-------------------Encript And Decript------------------------------
encrypt(content) {
  return CryptoJS.AES.encrypt(content+"", "Mohitsoni").toString();
}
decrypt(content) {  
 return CryptoJS.AES.decrypt( content+"",  "Mohitsoni" ).toString(CryptoJS.enc.Utf8); 
}


}
