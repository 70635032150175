import { Component, OnInit } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { FilterSearchComponent } from 'src/app/shared/popup/filter-search/filter-search.component';
import { JobPostCancelComponent } from 'src/app/shared/popup/job-post-cancel/job-post-cancel.component';
import { JobApplyBottomSheetComponent } from '../job-apply-bottom-sheet/job-apply-bottom-sheet.component';
import { Popup11Component } from '../popups/popup11/popup11.component';
import { Popup13Component } from '../popups/popup13/popup13.component';
import { JoblistService } from 'src/app/shared/service/joblist.service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { Options, LabelType } from "@angular-slider/ngx-slider";
import { request } from 'http';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-job-list-ui',
  templateUrl: './job-list-ui.component.html',
  styleUrls: ['./job-list-ui.component.scss']
})
export class JobListUiComponent implements OnInit {
  animal: string;
  name: string;
  appUrl = environment.appUrl;
  public joblist:any=[];
  public tempJoblist:any=[];
  public filterValues:any=[];
  showStipendFilter = false;
  arr: any = [];
  isDisplay = true;
  isDisplay2 = true;
  isDisplay3 = true;
  isDisplay4 = true;
  isDisplay5 = true;
  isDisplay6 = true;
  isDisplay7 = true;
  filterForm = new UntypedFormGroup({
    jobTitle: new UntypedFormControl(),
    // jobDomain: new FormControl(),
    jobLocation: new UntypedFormControl(),
    jobIndustry: new UntypedFormControl(),
    jobType: new UntypedFormControl(),
    jobTitleDescription : new UntypedFormControl('')
  });

   actionFlashId = null;
   public p: number = 1;

  //  jobTitleDescription = new FormControl('');
jobTitleDescriptionOptions: Observable<string[]>;
LocationOptions: Observable<string[]>;
IndustryOptions: Observable<string[]>;
DomainOptions: Observable<string[]>;
jobTypeOptions: Observable<string[]>;

   config = {
    id: 'custom',
    itemsPerPage: 15,
    currentPage: 1,
    totalItems: 0
  };


  //Stipend Range picker
  minValue: number = 0;
  maxValue: number = 0;
  options: Options = {
    floor: 0,
    ceil: 0,
    translate: (value: number, label: LabelType): string => {
      if(LabelType.TickValue)    return "₹" + value;
      // switch (label) {
      //   // case LabelType.Low:
      //   //   return "₹" + value;
      //   // case LabelType.High:
      //   //   return "₹" + value;
      //     case  LabelType.TickValue:
      //       return "₹" + value;
      //   default:
      //     return "₹" + value;
      // }
    }
  };
  showShare: boolean = false;
  jobShareUrl;
  jobText: any;
  isJobTitleFilterAppiled: boolean = false;
  isJobTypeFilterAppiled: boolean = false;
  isJobStipendFilterAppiled: boolean = false;
  isJobLocationFilterAppiled: boolean = false;
  isJobIndustryFilterAppiled: boolean = false;

  ngOnInit(): void { 
    this.getMinMaxStipend();
  }
  constructor(private _bottomSheet: MatBottomSheet,public dialog: MatDialog,private jobservice:JoblistService,
    private toastr:ToastrService, private _snackBar: MatSnackBar) { 
    // this.dialog.open(JobPostCancelComponent,{
    //   width:'600px',
    //   height:'360px'    })
    this.getFilterValues();
    // this.filterForm.controls.jobType.setValue("Part Time");
    this.getallJobsList();
  }

  getMinMaxStipend(){
    this.jobservice.getMinMaxStipend().subscribe((res) =>{
      if(res.success){
        this.maxValue = res.data.maxStipend;
        this.minValue = res.data.minStipend;
        this.options.ceil = res.data.maxStipend;
        this.options.floor = res.data.minStipend;
        this.showStipendFilter = true;
      }
    });
  }

  getFilterValues(){
    this.jobservice.filterValues().subscribe((responseBody)=>{
      if (responseBody['success']) {
        this.filterValues = responseBody['data'];
      this.jobTitleDescriptionOptions = this.filterForm.controls.jobTitleDescription.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.LocationOptions = this.filterForm.controls.jobLocation.valueChanges.pipe(
      startWith(''),
      map(value => this._filter2(value || '')),
    );

    this.IndustryOptions = this.filterForm.controls.jobIndustry.valueChanges.pipe(
      startWith(''),
      map(value => this._filter3(value || '')),
    );

    this.jobTypeOptions = this.filterForm.controls.jobType.valueChanges.pipe(
      startWith(''),
      map(value => this._filter5(value || '')),
    );

    // this.DomainOptions = this.filterForm.controls.jobDomain.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filter4(value || '')),
    // );

        console.log("filterValues:",this.filterValues)
    }
  });
    //http://localhost:9090/jobpost/job/getAllFilters
  }
  openBottomSheet(id): void {
    var jobDetails = this.joblist.data.filter(obj => obj.id == id);
    if(jobDetails.length>0){
        this.jobservice.setJobDetails(jobDetails[0])
    }
    const dialogRef = this._bottomSheet.open(JobApplyBottomSheetComponent,{
      backdropClass: 'backdropBackground',
      panelClass:"read-more-sheet2"
    });
  }
  toggle() {
    this.isDisplay = !this.isDisplay;
    this.isDisplay2 = true;
    this.isDisplay3 = true;
    // this.isDisplay4 = true;
    this.isDisplay5 = true;
    this.isDisplay6 = true;
    this.isDisplay7 = true;
  }

  toggle2() {
    this.isDisplay2 = !this.isDisplay2;
    this.isDisplay = true;
    this.isDisplay3 = true;
    // this.isDisplay4 = true;
    this.isDisplay5 = true;
    this.isDisplay6 = true;
    this.isDisplay7 = true;
  }
  toggle3() {
    this.isDisplay3 = !this.isDisplay3;
    this.isDisplay2 = true;
    // this.isDisplay3 = true;
    // this.isDisplay4 = true;
    this.isDisplay5 = true;
    this.isDisplay6 = true;
    this.isDisplay7 = true;
  }
  toggle4() {
    
    this.isDisplay4 = !this.isDisplay4;
    this.isDisplay2 = true;
    this.isDisplay3 = true;
    // this.isDisplay4 = true;
    this.isDisplay5 = true;
    this.isDisplay6 = true;
    this.isDisplay7 = true;
  }

  toggle5() {
    this.isDisplay5 = !this.isDisplay5;
    this.isDisplay2 = true;
    this.isDisplay3 = true;
    this.isDisplay4 = true;
    // this.isDisplay5 = true;
    this.isDisplay6 = true;
    this.isDisplay7 = true;
  }

  toggle6() {
    this.isDisplay6 = !this.isDisplay6;
    this.isDisplay2 = true;
    this.isDisplay3 = true;
    this.isDisplay4 = true;
    this.isDisplay5 = true;
    // this.isDisplay6 = true;
    this.isDisplay7 = true;
  }
  toggle7() {
    this.isDisplay7 = !this.isDisplay7;
    this.isDisplay2 = true;
    this.isDisplay3 = true;
    this.isDisplay4 = true;
    this.isDisplay5 = true;
    this.isDisplay6 = true;
    // this.isDisplay7 = true;
  }

  createJob(){

  }
  openDialog(id): void {
    var jobDetails = this.joblist.data.filter(obj => obj.id == id);
    if(jobDetails.length>0){
        this.jobservice.setJobDetails(jobDetails[0])
    }
    const dialogRef = this.dialog.open(Popup13Component, {
      // width: '250px',
      data: {name: this.name, animal: this.animal},
      backdropClass: 'backdropBackground'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
      this.getallJobsList();
    });
  }
  getallJobsList(){
    this.jobservice.getAllJobsList().subscribe((res) =>{
        this.joblist = res;
        for(let i=0; i<this.joblist.data.length;i++){
          this.joblist.data[i]['numberOfDays']=this.jobservice.getDataDifference(this.joblist.data[i]['created'])
          this.joblist.data[i]['jobShareUrl']=this.makeLink(this.joblist.data[i]);
        }
        this.config.totalItems = this.joblist.data.length;

        console.log('joblistjoblist:',res);

        this.tempJoblist = JSON.parse(JSON.stringify(res));
        for(let i=0; i<this.tempJoblist.data.length;i++){
          this.tempJoblist.data[i]['numberOfDays']=this.jobservice.getDataDifference(this.tempJoblist.data[i]['created'])
          
          var jobDescription = this.tempJoblist.data[i]['jobDescription'];
          if(jobDescription != null)
            this.tempJoblist.data[i]['jobDescription'] = jobDescription.length > 300 ? jobDescription.substring(0, 300) + "..." : jobDescription
        }
    });


  }//



  getDateDifference(startDate){
    if(startDate==''){
      startDate = new Date();
    }
    var d1 = new Date(startDate);   
    var d2 = new Date(); 
    var diff;  
     diff = d2.getTime() - d1.getTime();
     console.log("diff:",diff)     
  }

  addToSaveList(jobId,status,index){
      var requestBody = {
        "is_active": status,
        "job_id": jobId,
        "user_id": parseInt(localStorage.getItem('member'))
      }
      this.jobservice.addToSaveList(requestBody).subscribe((responseBody)=>{
          if (responseBody['success']) {
            // this.toastr.success(responseBody['message']);
            this._snackBar.open(responseBody['message'], 'OK', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: 'style-success'
            });
            this.joblist['data'][index]['is_job_saved']=status;
            this.getallJobsList();
          } else {
            // this.toastr.error(responseBody['message']);
            this._snackBar.open(responseBody['message'], 'OK', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: 'style-error'
            });
          }
      });
    }

    filterByJobs(){
    var requestBody = {
      // "domain": this.filterForm.controls.jobDomain.value,
      "industry": this.filterForm.controls.jobIndustry.value,
      "jobtitle": this.filterForm.controls.jobTitleDescription.value,
      "location":this.filterForm.controls.jobLocation.value,
      "stipendMin": this.minValue,
      "stipendMax": this.maxValue,
      "typeofJob": this.filterForm.controls.jobType.value,
      "type": "All",
      "user_id": parseInt(localStorage.getItem('member'))
    }  
    this.jobservice.filterByJobs(requestBody).subscribe((res) =>{
    this.joblist = res;
        for(let i=0; i<this.joblist.data.length;i++){
          this.joblist.data[i]['numberOfDays']=this.jobservice.getDataDifference(this.joblist.data[i]['created'])
          // this.tempJoblist.data[i]['numberOfDays']=this.jobservice.getDataDifference(this.tempJoblist.data[i]['created'])        
          this.joblist.data[i]['jobShareUrl']=this.makeLink(this.joblist.data[i]);
        }
        this.config.totalItems = this.joblist.data.length;

        this.tempJoblist = JSON.parse(JSON.stringify(res));
        for(let i=0; i<this.tempJoblist.data.length;i++){
          this.tempJoblist.data[i]['numberOfDays']=this.jobservice.getDataDifference(this.tempJoblist.data[i]['created'])
          
          var jobDescription = this.tempJoblist.data[i]['jobDescription'];
          if(jobDescription != null)
            this.tempJoblist.data[i]['jobDescription'] = jobDescription.length > 300 ? jobDescription.substring(0, 300) + "..." : jobDescription
        }
    });
    console.log("Filters Form:",requestBody, this.filterForm.value)
    }

    onPageChange(event){
      console.log(event);
      this.config.currentPage = event;
    }

    public setJobType(type:string) {
      this.filterForm.controls.jobType.setValue(type);
      this.isDisplay2 = true;
      $("#jobType").addClass('isFilterApplied');
    }

    private _filter(value: string): string[] {
      const filterValue = (value != null && value != "") ? value.toLowerCase() : value;
  
      return this.filterValues['AllJobTitle'].filter(option => (option.jobtitle != null && option.jobtitle != "") ? 
        option.jobtitle.toLowerCase().includes(filterValue) : option);
    }

    private _filter2(value: string): string[] {
      const filterValue = value.toLowerCase();
      console.log(this.filterValues['AllLocations']);
      return this.filterValues['AllLocations'].filter(option => option.location !== null && option.location.toLowerCase().includes(filterValue));
    }


    private _filter3(value: string): string[] {
      const filterValue = value.toLowerCase();
      return this.filterValues['AllIndustry'].filter(option => option.industry !== null && option.industry.toLowerCase().includes(filterValue));
    }


    private _filter4(value: string): string[] {
      const filterValue = value.toLowerCase();
  
      return this.filterValues['AllDomain'].filter(option => option.domain !== null && option.domain.toLowerCase().includes(filterValue));
    }

    private _filter5(value: string): string[] {
      const filterValue = value.toLowerCase();
      
      return this.filterValues['AllJobType'].filter(option => option.type !== null && option.type.toLowerCase().includes(filterValue));
    }

    sliderValueChange(range) {
      this.isJobStipendFilterAppiled = true
      $("#stipend").addClass('isFilterApplied');

      console.log(range)

  // range.highValue: 400
// range.value: 120
    }

    resetFilter(){
      this.filterForm.reset();
      $("#jobTitleDescription").removeClass('isFilterApplied');
      $("#jobType").removeClass('isFilterApplied');
      $("#stipend").removeClass('isFilterApplied');
      $("#location").removeClass('isFilterApplied');
      $("#industry").removeClass('isFilterApplied');
      $("#domain").removeClass('isFilterApplied');
      this.isJobTitleFilterAppiled = false;
      this.isJobTypeFilterAppiled = false;
      this.isJobStipendFilterAppiled = false;
      this.isJobLocationFilterAppiled = false;
      this.isJobIndustryFilterAppiled = false;
      this.getallJobsList();
      this.getMinMaxStipend();
    }

    makeLink(item){
      this.jobText = item.jobtitle;

      if(this.jobText != null){
        // let result = this.jobText.link(this.appUrl +"jobs/job-list-details/" + item.id);
        let result = this.appUrl +"jobs/job-list-details/" + item.id;
        this.jobShareUrl = result;
      }

      return this.jobShareUrl;
    }

    hideShareBtn(){
      this.showShare = false;
    }

    linkCopy(){
      // this.toastr.success("Linked copied successfully to clipboard.");
      this._snackBar.open('Linked copied successfully to clipboard.', 'OK', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'style-success'
      });
    }

    onJobTitleChange(event, state){
      var value = event.source.value;
      if(value != null && value != undefined && value != ''){
        this.isJobTitleFilterAppiled = true;
        $("#jobTitleDescription").addClass('isFilterApplied');
      }else{
        this.isJobTitleFilterAppiled = false;
        $("#jobTitleDescription").removeClass('isFilterApplied');
      }
    }

    onLocationChange(event, state){
      var value = event.source.value;
      if(value != null && value != undefined && value != ''){
        this.isJobLocationFilterAppiled = true;
        $("#location").addClass('isFilterApplied');
      }else{
        this.isJobLocationFilterAppiled = false;
        $("#location").removeClass('isFilterApplied');
      }
    }

    onIndustryChange(event, state){
      var value = event.source.value;
      if(value != null && value != undefined && value != ''){
        this.isJobIndustryFilterAppiled = true;
        $("#industry").addClass('isFilterApplied');
      }else{
        this.isJobIndustryFilterAppiled = false;
        $("#industry").removeClass('isFilterApplied');
      }
    }

    onDomainChange(event, state){
      var value = event.source.value;
      if(value != null && value != undefined && value != ''){
        $("#domain").addClass('isFilterApplied');
      }else{
        $("#domain").removeClass('isFilterApplied');
      }
    }

    onJobTypeChange(event, state) {
      var value = event.source.value;
      this.isDisplay2 = true;
      if(value != null && value != undefined && value != ''){
        this.isJobTypeFilterAppiled = true;
        $("#jobType").addClass('isFilterApplied');
      }else{
        this.isJobTypeFilterAppiled = false;
        $("#jobType").removeClass('isFilterApplied');
      }
    }
}


