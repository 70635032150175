import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup14',
  templateUrl: './popup14.component.html',
  styleUrls: ['./popup14.component.scss']
})
export class Popup14Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
