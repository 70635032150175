import { Component, Input, OnInit } from '@angular/core';
import { PresenceService } from '../presence.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @Input() user;

  presence$;

  constructor(private presence: PresenceService) { }

  ngOnInit() {
    this.presence$ = this.presence.getPresence(this.user.uid);
  }

  ngOnChanges() {
    this.presence$ = this.presence.getPresence(this.user.uid);
  }

}
