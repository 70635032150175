 
import { ProfileService } from '../profile.service';
import * as $ from 'jquery'
import { Component, Input, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-connection-list',
  templateUrl: './connection-list.component.html',
  styleUrls: ['./connection-list.component.scss']
})
export class ConnectionListComponent implements OnInit {
  myNetworkList: any = [];
  userInfo = JSON.parse(localStorage.getItem('user'));
  userDetail: any={};
   
  member: number;
  isShow: boolean = false;
  currentPage: number = 0;
  config: any;
  messageInput:String = "";
  constructor(private profileService: ProfileService,
    private router: Router, private routes: ActivatedRoute) { }

  ngOnInit(): void {
    this.config = {
      itemsPerPage: 6,
      currentPage: 0,     
    }
    this.getMyNetwork();
  }
  sendMessage(){
    alert()
  }
  getMyNetwork() {
    this.routes.queryParams.subscribe(param => {
      this.member = parseInt(localStorage.getItem('member'))
      this.profileService.getMyNetworkWithOutPagination(param.visit ).subscribe(res => {
        if (res.success) {
          this.userDetail.id =param.visit;
          this.myNetworkList = res.data 
          console.log("td "+res.data)
          if (this.member == param.visit) {
            this.isShow = true;
          } else {
            this.isShow = false
          }
        }
      })
    })
  }

  sendRequest(item, index) {    
    let obj = {
      connectedUser: item,
      connected: false,
      following:true,
      followedBack:false,
      user: this.userInfo,
    }
    this.profileService.sendRequest(obj).subscribe(res => {       
      if (res.success) {         
        this.myNetworkList[index].connection = res.data        
      }
    })    
  }

  pageChanged(event) {    
    this.config.currentPage = event;
  }
  goToProfile(userId) {
    this.router.navigate(['./profile/timeline'], { queryParams: { visit: userId } });
  }

}
