<div class="main">
    <p style="text-align: right">
        <i class="fa fa-times" aria-hidden="true"></i>
    </p>

    <div class="content">
        <h2 class="main-t">Perk Activation</h2>
        <span class="text-p" style="text-align: justify;">"Congratulations on Activating this perk. You are working in
            the right direction by saving crucial funds for your startup. Please check your email inbox for next steps
            in claiming this perk.
            <br><br>
            Please note - the perks are only offered to 'Verified' startups. That is, your
            startup must have a ' ' (verification badge) next to the name on company profile page. Contact support if
            you do not have this badge."</span>


    </div>
    <div class="makeCenter"><span class="btn-s">OK</span></div>
</div>