<div class="main">
  <p style="text-align: right;">
    <i class="fa fa-times" aria-hidden="true" (click)="onNoClick()"></i>
  </p>

  <h4 class="title"> Filter By</h4>

  <form [formGroup]="filterForm">
    <div class="filterRow">
      <div class="sort">
        <span id="sortFilter" class="sortBy" style="cursor: pointer;" (click)="toggle2()">Sort By&nbsp;</span> 
        <img *ngIf="filterForm.controls.sortType.value == ''" src="assets/chevron-down.svg" [ngClass]="{'img-css-i' : !isDisplay2}" (click)="toggle2()" alt="" style="cursor: pointer;">
        <span class="filter-value-sort" *ngIf="filterForm.controls.sortType.value != ''">{{filterForm.controls.sortType.value}}</span>
        <span [hidden]="isDisplay2" (focusout)=" isDisplay2 = !isDisplay2">
          <div class="sortSpan">
            <span style="cursor: pointer;" (click)="setSortType('Newest')">Latest</span><br>
            <span style="cursor: pointer;" (click)="setSortType('Oldest')">Oldest</span><br>
            <span style="cursor: pointer;" (click)="setSortType('A-Z')">A to Z</span><br>
            <span style="cursor: pointer;" (click)="setSortType('Z-A')">Z to A</span>
          </div>
        </span>
      </div>
      <div class="location">
        <img *ngIf="filterForm.controls.location.value == ''" src="assets/Location (1).svg" alt="" (click)="toggle5()" style="cursor: pointer;"/>
        <span id="locationFilter" class="locationTitle" (click)="toggle5()" style="cursor: pointer;">Location&nbsp;</span>
        <img *ngIf="filterForm.controls.location.value == ''" src="assets/chevron-down.svg" class="img-css" [ngClass]="{'img-css-i' : !isDisplay5}" (click)="toggle5()"
          alt="" style="cursor: pointer;">
          <span class="filter-value-location" *ngIf="filterForm.controls.location.value != ''">{{filterForm.controls.location.value}}</span>
          <span [hidden]="isDisplay5" (focusout)=" isDisplay5 = !isDisplay5">
            <div class="loc">
              <input type="text" class="locationIn"
                placeholder="Search"
                aria-label="Location"
                formControlName="location"
                (keyup)="filterLocations($event)">
                <span class="locSpan">
                  <span style="cursor: pointer;" *ngFor="let option of locationOptions" (click)="setLocation(option)"> {{ (option.length > 15)? (option  | slice:0:15)+'...':(option) }}<br></span>
                </span>
            </div>
          </span>

      </div>
      <div class="scorediv">
        <span id="scoreFilter" class="scoring" style="cursor: pointer;" (click)="toggle3()">Scoring&nbsp;</span>
        <img *ngIf="filterForm.controls.score.value == 0" src="assets/chevron-down.svg" class="img-css"
          [ngClass]="{'img-css-i' : !isDisplay3}" (click)="toggle3()" alt="" style="cursor: pointer;">

          <span *ngIf="filterForm.controls.score.value > 0">
            <div style="cursor: pointer;" class="scorecircle" >
              <ng-container *ngFor="let index of scoreCnt">
                <span style="cursor: pointer;" class="fa fa-circle checked"></span>
              </ng-container>                
              <span *ngIf="filterForm.controls.score.value < 5" class="plus">+</span>                
            </div>
          </span>
        <span class="score-cnt" [hidden]="isDisplay3" (focusout)=" isDisplay3 = !isDisplay3">
          <div class="score-cnt">
            <span>
              <div style="cursor: pointer;" class="scorecircle" >
                <ng-container *ngFor="let index of [1]">
                  <span (click)="setScoreCount(1)" style="cursor: pointer;" class="fa fa-circle checked"></span>
                </ng-container>                
                <span (click)="setScoreCount(10)" class="plus">+</span>                
              </div>
            </span>
            <span>
              <div style="cursor: pointer;" class="scorecircle" >
                <ng-container *ngFor="let index of [1,2]">
                  <span (click)="setScoreCount(2)" style="cursor: pointer;" class="fa fa-circle checked"></span>
                </ng-container>                
                <span (click)="setScoreCount(20)" class="plus">+</span>
              </div>
            </span>
            <span>
              <div style="cursor: pointer;" class="scorecircle" >
                <ng-container *ngFor="let index of [1,2,3]">
                  <span (click)="setScoreCount(3)" style="cursor: pointer;" class="fa fa-circle checked"></span>
                </ng-container>
                <span (click)="setScoreCount(30)" class="plus">+</span>
              </div>
            </span>
            <span>
              <div style="cursor: pointer;" class="scorecircle" >
                <ng-container *ngFor="let index of [1,2,3,4]">
                  <span (click)="setScoreCount(4)" style="cursor: pointer;" class="fa fa-circle checked"></span>
                </ng-container>
                <span (click)="setScoreCount(40)" class="plus">+</span>
              </div>
            </span>
            <span>
              <div style="cursor: pointer;" class="scorecircle" (click)="setScoreCount(5)">
                <ng-container *ngFor="let index of [1,2,3,4,5]">
                  <span style="cursor: pointer;" class="fa fa-circle checked"></span>
                </ng-container>
              </div>
            </span>
          </div>
        </span>
      </div>
    </div>

    <div class="filterRow">
      <div class="Iconsection">
        <!-- <div style="cursor: pointer;" (click)="recruiterAction(1)" (click)="recruiterAction(item.id,1,i)"  [ngClass]="(( item.rerecruiterAction ) == 1)?'icon listselected':'icon'"><i class="fa fa-check" aria-hidden="true"></i></div> -->
        <div style="cursor: pointer;" title="Selected Candidate" (click)="recruiterAction(1)" [ngClass]="(filterForm.controls.recruiterAction.value == 1)?'icon select':'icon'" class="icon"><i class="fa fa-check"aria-hidden="true"></i></div>
        <div style="cursor: pointer;" title="Onhold Candidate" (click)="recruiterAction(2)" [ngClass]="(filterForm.controls.recruiterAction.value == 2)?'icon hold':'icon'" class="icon"><i class="fa fa-question"></i></div>
        <div style="cursor: pointer;" title="Rejected Candidate" (click)="recruiterAction(3)" [ngClass]="(filterForm.controls.recruiterAction.value == 3)?'icon reject':'icon'" class="icon"><i class="fa fa-close"></i></div>
      </div>
      <div class="messageLayout">
        <input type="checkbox" (change)="getMessageStatus($event)" id="switch1" class="check" />
        <label class="toggleSwitch" for="switch1">Toggle</label>
        {{ filterForm.controls.isMessageSent.value }}
      </div>
    </div>

    <div class="filterRow">
      <button mat-raised-button class="apply-btn" (click)="apply()">Apply</button>
      <button mat-raised-button class="reset-btn" (click)="reset()">Reset</button>
    </div>
  </form>


</div>