<app-header></app-header>

<div class="container">
  <div class="main-p" style="overflow: hidden">
    <div class="innter_p">
      <div class="nvbar" [formGroup]="filterForm">
        <div class="nav-inn">
          <div class="tooltips">
            <div (click)="toggle()" style="display: flex; gap: 10px;font-weight: 500;">
              <mat-icon *ngIf="!isJobTitleFilterAppiled" (click)="toggle()" class="search-icon" class="searchI">search</mat-icon> 
              <span id="jobTitleDescription" class="title-name" (click)="toggle()">Job title / Description </span> &nbsp;
              <img *ngIf="!isJobTitleFilterAppiled" src="assets/chevron-down.svg" class="img-css" [ngClass]="{'img-css-i' : !isDisplay}" (click)="toggle()" alt="">
              <div class="filter-value-title">{{filterForm.controls.jobTitleDescription.value}}</div>
            </div>
            <span class="tooltiptexts" [hidden]="isDisplay" (focusout)=" isDisplay = !isDisplay">
              <div>
                <div class="example-form">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <!-- <mat-label>Number</mat-label> -->
                    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" style="display: block;fill: none;height: 16px;width: 16px;stroke: darkgray;;stroke-width: 4;overflow: visible;"><g fill="none"><path d="m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9"></path></g></svg>
                    <input type="text"
                           placeholder=""
                           aria-label="Job title"
                           matInput
                           formControlName="jobTitleDescription"
                           [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                      <mat-option *ngFor="let option of jobTitleDescriptionOptions | async" [value]="option.jobtitle"
                      (onSelectionChange)="onJobTitleChange($event, option)">
                        {{option.jobtitle}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
            </span>
          </div>
  
          <div class="tooltips2" (focusout)="toggle2()">
            <div style="display: flex;gap: 10px;font-weight: 500;" (click)="toggle2()">
              <mat-icon *ngIf="!isJobTypeFilterAppiled">card_travel</mat-icon> 
              <span id="jobType" class="title-name">Job Type &nbsp;</span>
            
              <img *ngIf="!isJobTypeFilterAppiled" src="assets/chevron-down.svg" class="img-css" [ngClass]="{'img-css-i' : !isDisplay2}" (hover)="toggle2()" alt="">
              <div class="filter-value-job-type">{{filterForm.controls.jobType.value}}</div>
            </div>
            <span class="tooltiptexts2" [hidden]="isDisplay2" (focusout)=" isDisplay2 = !isDisplay2">
              <!-- <div class="job-type">
                <span (click)="setJobType('Part Time')">Part Time</span>
                <span  (click)="setJobType('Full Time')">Full Time</span>
              </div> -->
              <div class="example-form">
                <mat-form-field class="example-full-width" appearance="fill">
                  <!-- <mat-label>Number</mat-label> -->
                  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" style="display: block;fill: none;height: 16px;width: 16px;stroke: darkgray;;stroke-width: 4;overflow: visible;"><g fill="none"><path d="m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9"></path></g></svg>
                  <input type="text"
                         placeholder=""
                         aria-label="Job Type"
                         matInput
                         formControlName="jobType"
                         [matAutocomplete]="autoJobType">
                  <mat-autocomplete autoActiveFirstOption #autoJobType="matAutocomplete">
                    <mat-option *ngFor="let option of jobTypeOptions | async" [value]="option.type"
                    (onSelectionChange)="onJobTypeChange($event, option)">
                      {{option.type}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </span>
          </div>
  
          <div class="tooltips3" *ngIf="showStipendFilter" (focusout)="toggle3()">
            <div (click)="toggle3()" style="display: flex; gap: 10px;">
              <mat-icon *ngIf="!isJobStipendFilterAppiled" fontSet="material-icons-outlined" style="margin-right: 5px;"> monetization_on </mat-icon> 
              <span id='stipend' class="title-name">Stipend  &nbsp;</span>
              <img *ngIf="!isJobStipendFilterAppiled" src="assets/chevron-down.svg" class="img-css" [ngClass]="{'img-css-i' : !isDisplay2}" (click)="toggle3()" alt="">
              <div *ngIf="isJobStipendFilterAppiled" class="filter-value-stipend">{{minValue}} - {{maxValue}}</div>
            </div>
            <span class="tooltiptexts3" [hidden]="isDisplay3">
              <!-- <p>TODO</p> -->
              <div class="slider-div"><ngx-slider [(value)]="minValue"   (userChange)="sliderValueChange($event)"
                [(highValue)]="maxValue" [options]="options"></ngx-slider></div>
              
            </span>
          </div>
          <div class="tooltips4"> 
            <div class="title-name" (click)="toggle4()" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
              <img src="assets/more_vert_black_24dp.svg" class="img-css" alt="">
              Advanced &nbsp;<img src="assets/chevron-down.svg" class="img-css"
              [ngClass]="{'img-css-i' : !isDisplay4}" (click)="toggle4()" alt="">
            </div>
          </div>
  
          <button mat-raised-button class="reset-btn" (click)="resetFilter()" >Reset <span><i class="fa fa-close"></i></span></button>
  
          <button mat-raised-button class="btn_s" (click)="filterByJobs()" >Search</button>
        </div>
        <div class="collapse nav-colaps-div w-100" id="navbarToggleExternalContent">
          <div class="collapse-nav-sub-div">
            <div class="tooltips5" (focusout)="toggle5()" >
              <div (click)="toggle5()" style="display: flex; gap: 10px;">
                <img *ngIf="!isJobLocationFilterAppiled" src="assets/Location (1).svg" alt="" /> 
                <span id="location" class="title-name">Location&nbsp;</span> 
                <img *ngIf="!isJobLocationFilterAppiled" src="assets/chevron-down.svg" class="img-css" [ngClass]="{'img-css-i' : !isDisplay5}" (click)="toggle5()" alt="">
                <div class="filter-value">{{filterForm.controls.jobLocation.value}}</div>
              </div>
              <span class="tooltiptexts5" [hidden]="isDisplay5">
                <div class="example-form">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <!-- <mat-label>Number</mat-label> -->
                    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" style="display: block;fill: none;height: 16px;width: 16px;stroke: darkgray;;stroke-width: 4;overflow: visible;"><g fill="none"><path d="m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9"></path></g></svg>
                    <input type="text"
                           placeholder=""
                           aria-label="Location"
                           matInput
                           formControlName="jobLocation"
                           [matAutocomplete]="autoLocation">
                    <mat-autocomplete autoActiveFirstOption #autoLocation="matAutocomplete">
                      <mat-option *ngFor="let option of LocationOptions | async" [value]="option.location"
                      (onSelectionChange)="onLocationChange($event, option)">
                        {{option.location}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </span>
            </div>
    
            <div class="tooltips6" (focusout)="toggle6()" >
              <div (click)="toggle6()" style="display: flex; gap: 10px;">
                <!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg *ngIf="!isJobIndustryFilterAppiled"
                  enable-background="new 0 0 91 91" height="30px" id="Layer_1" version="1.1" viewBox="0 0 91 91"
                  width="30px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g>
                    <rect fill="#293266" height="6.26" width="3.4" x="31.019" y="43.344" />
                    <rect fill="#293266" height="6.259" width="3.4" x="31.019" y="55.862" />
                    <rect fill="#293266" height="6.259" width="3.4" x="31.019" y="30.825" />
                    <rect fill="#293266" height="6.259" width="3.4" x="41.452" y="55.862" />
                    <rect fill="#293266" height="6.26" width="3.4" x="41.452" y="43.344" />
                    <rect fill="#293266" height="6.259" width="3.4" x="41.452" y="30.825" />
                    <rect fill="#293266" height="3.4" width="6.652" x="60.89" y="50.561" />
                    <rect fill="#293266" height=" 3.4" width="6.652" x="60.89" y="58.906" />
                    <path
                      d="M56.331,72.315h12.191c3.711,0,6.732-3.021,6.732-6.733V40.709l-18.924-0.003V18.757H19.544v53.559h33.387   H56.331z M71.854,44.109v21.473c0,1.838-1.496,3.333-3.332,3.333H56.331v-24.81L71.854,44.109z M22.944,68.915V22.157h29.986   v18.548v28.209H22.944z"
                      fill="#293266" />
                  </g>
                </svg> 
                <span id="industry" class="title-name">Industry&nbsp;</span> 
                <img *ngIf="!isJobIndustryFilterAppiled" src="assets/chevron-down.svg" class="img-css" [ngClass]="{'img-css-i' : !isDisplay5}" (click)="toggle6()" alt="">
                <div class="filter-value">{{filterForm.controls.jobIndustry.value}}</div>
              </div>
              <span class="tooltiptexts6" [hidden]="isDisplay6">
                <div class="example-form">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <!-- <mat-label>Number</mat-label> -->
                    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" style="display: block;fill: none;height: 16px;width: 16px;stroke: darkgray;;stroke-width: 4;overflow: visible;"><g fill="none"><path d="m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9"></path></g></svg>
                    <input type="text"
                           placeholder=""
                           aria-label="Industry"
                           matInput
                           formControlName="jobIndustry"
                           [matAutocomplete]="autoIndustryOptions">
                    <mat-autocomplete autoActiveFirstOption #autoIndustryOptions="matAutocomplete">
                      <mat-option *ngFor="let option of IndustryOptions | async" [value]="option.industry"
                      (onSelectionChange)="onIndustryChange($event, option)">
                        {{option.industry}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div> 
              </span>
            </div>
    
            <!-- <div class="tooltips7"  (focusout)="toggle7()">
              <div (click)="toggle7()">
                <!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg
                  enable-background="new 0 0 50 50" height="20px" id="Layer_1" version="1.1" viewBox="0 0 50 50"
                  width="20px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <rect fill="none" height="50" width="50" />
                  <circle cx="25" cy="25" fill="none" r="24" stroke="#293266" stroke-linecap="round" stroke-miterlimit="10"
                    stroke-width="2" />
                  <ellipse cx="25" cy="25" fill="none" rx="12" ry="24" stroke="#293266" stroke-linecap="round"
                    stroke-miterlimit="10" stroke-width="2" />
                  <path d="M6.365,40.438C10.766,37.729,17.479,36,25,36  c7.418,0,14.049,1.682,18.451,4.325" fill="none"
                    stroke="#293266" stroke-miterlimit="10" stroke-width="2" />
                  <path d="M43.635,9.563C39.234,12.271,32.521,14,25,14  c-7.417,0-14.049-1.682-18.451-4.325" fill="none"
                    stroke="#293266" stroke-miterlimit="10" stroke-width="2" />
                  <line fill="none" stroke="#293266" stroke-miterlimit="10" stroke-width="2" x1="1" x2="49" y1="25"
                    y2="25" />
                  <line fill="none" stroke="#293266" stroke-miterlimit="10" stroke-width="2" x1="25" x2="25" y1="1"
                    y2="49" />
                </svg>
                <span id="domain"  class="title-name">Domain</span> 
                &nbsp;<img src="assets/chevron-down.svg" class="img-css" [ngClass]="{'img-css-i' : !isDisplay5}" (click)="toggle7()" alt="">
                <div class="filter-value">{{filterForm.controls.jobDomain.value}}</div>
              </div>
              <span class="tooltiptexts7" [hidden]="isDisplay7">
                <div class="example-form">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input type="text"
                           placeholder="Domain"
                           aria-label="Domain"
                           matInput
                           formControlName="jobDomain"
                           [matAutocomplete]="autoDomainOptions">
                    <mat-autocomplete autoActiveFirstOption #autoDomainOptions="matAutocomplete">
                      <mat-option *ngFor="let option of DomainOptions | async" [value]="option.domain"
                      (onSelectionChange)="onDomainChange($event, option)">
                        {{option.domain}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </span>
            </div> -->
          </div>
        </div>
      </div>
      <div class="jobseq">
        <!-- <p class="title-side-card">Showing  Jobs</p> -->
        <p *ngIf="joblist?.data?.length == 0" class="title-side-card">All the jobs that you apply for will be displayed here.</p>
        <p *ngIf="joblist?.data?.length > 0" class="title-side-card">Showing  {{(config.currentPage-1)* config.itemsPerPage+1}} - {{config.currentPage * config.itemsPerPage}} of {{joblist?.data?.length}} Jobs </p>
        
        <div class="seq seq2-section-grid">
          <div class="sideseq">
            <div class="side_card">
              <p class="t_name">Quick actions</p>
              <span class="discrip">Use these option to boosts.</span>
              <div class="button-al">
                <div type="button" routerLink="/jobs/savedjobs" id="btn-t">Saved Jobs</div>
                <div type="button" routerLink="/jobs/myjobs" id="btn-t">My Jobs</div>
              </div>
              <!-- <div class="button-al">
                <div type="button" id="btn-t" routerLink="/jobs/job-post-form" >Create Job</div>
              </div> -->
            </div>
            <!-- <p class="recnet">Recent Job Searches</p>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
              <p class="form-check-label" for="flexCheckDefault"
                style="padding-left:0px ;display: flex;justify-content: space-between;">
                Angular js <span class="notify">21</span>
              </p>
            </div> 
  
  
  
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
              <p class="form-check-label" for="flexCheckChecked"
                style="padding-left:0px ;display: flex;justify-content: space-between;">
                Angular developer <span class="notify">26</span>
              </p>
            </div> -->
          </div>
  
          <div class="cardseq cards-container-sec">
            <div class="card" *ngFor="let item of tempJoblist.data;let i=index">
              <span class="tooltiptext">
                <div class="title-row">
                  <span class="jobD">Job Description</span>
                  <span>
                    <!-- <i class="fa fa-bookmark-o"></i> -->

                    <i *ngIf="!item.is_job_saved" class="fa fa-bookmark-o" (click)="addToSaveList(item.id,true,i)" style="
                    font-size: 18px;
                    margin-right: 7px;" >
                    </i>
                    <i *ngIf="item.is_job_saved" class="fa fa-bookmark" (click)="addToSaveList(item.id,false,i)" style="
                    font-size: 18px;
                    margin-right: 7px;color:#293266;" >
                    </i>

                    <span class="share">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share"
                        viewBox="0 0 16 16" (click)="linkCopy()" [cdkCopyToClipboard]="item.jobShareUrl">
                        <path
                          d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                      </svg>
                    </span>
                    <!-- <svg (click)="addToSaveList(item.id)" style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                      fill="currentColor" class="bi bi-bookmark" viewBox="0 0 16 16">
                      <path
                        d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                    </svg> -->
                    <!-- <span class="share" (mouseover)="makeLink(item)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share"
                        viewBox="0 0 16 16">
                        <path
                          d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                      </svg>

                      <span class="share-btn">
                        <share-buttons theme="circles-dark"
                        [include]="['copy', 'whatsapp', 'telegram','linkedin','email']"
                        [show]="3"
                        [size]="3"
                        [url]="jobShareUrl"
                        [image] ="'https://www.eduforbetterment.com/wp-content/uploads/2020/08/qrcode.jpg'"
                        [description]="jobText"
                        ></share-buttons>
                      </span> 
                    </span> -->
                  </span>
                </div>
  
                <div class="title-row" style="margin-top: 10px;">
                  <div>
                    <div class="nameT-1">start date</div>
                    <div class="value-c">{{ item.startDate | date: 'dd/MM/yyyy'}}</div>
                  </div>
                  <div>
                    <div class="nameT">stipend</div>
                    <span class="value-c" *ngIf="item.stipend == 0">Unpaid</span>
                    <div class="value-c" *ngIf="item.stipend > 0">&#x20B9; {{ item.stipend }} /month</div>
                  </div>

                  <div>
                    <div class="nameT">Employee type</div>
                    <div class="value-c">{{ item.applicant_type }}</div>
                  </div>
                </div>
  
                <div class="discription-seq">
                  <span [innerHTML]="item.jobDescription">
                    <!-- {{ item.jobDescription }} -->
                   
                  </span>
                </div>
              </span>
              <div class="img-and-title card-i">
                <img style="cursor: pointer" src="{{item.company.logo}}" alt="" class="hight-w" [routerLink]="['/company/companyinfo/publish']" [queryParams]="{companyId:item.company.id}"/>
                <div class="text-seq">
                  <span class="first-tag">
                   {{ item.jobtitle}}
                  </span>
                  <div style="cursor: pointer" class="secund-tag" [routerLink]="['/company/companyinfo/publish']" [queryParams]="{companyId:item.company.id}">
                    {{ item.company.name}}
                  </div>
                  <span class="dis">
                    {{ item.numberOfDays}} day ago |
                    {{ item.noOfApplication }} Applicants</span>
                </div>
              </div>
              <div class="category">
                <span *ngIf="item.applicant_type!='' || item.applicant_type!=null">{{ item.applicant_type}}</span>
                <!-- <span *ngIf="item.typeofJob!=''">{{ item.typeofJob}}</span> -->
                <span *ngIf="item.typeofJob=='WFO'">{{ item.location }}</span>
                <span *ngIf="item.typeofJob=='WFH'">Remote</span>
                <span *ngIf="item.jobCategeory!=''">{{ item.jobCategeory}}</span>
                <!-- <span>Remote new</span>
                <span>UI/UX new</span> -->
              </div>
  
              <div class="buttons">
                <!-- <p class="myDIV" (click)="openBottomSheet()">
                  Read More <span class="hide">>></span>
                </p>
                 -->
                <button class="button-i" (click)="openBottomSheet(item.id)"><span class="button-i-text">Read More</span></button>
  
  
                <span *ngIf="!item.is_job_applyed" class="Quick" (click)="openDialog(item.id,i,true)">
                    Quick Apply
                    <img src="assets/icons/ray.png" width="15px" class="hideIcon" alt="" />
                  </span>
    
                  <span *ngIf="item.is_job_applyed" class="Quick Applied" (click)="openDialog(item.id,i,item.is_job_applyed)">
                    Applied </span>

              </div>
            </div>
  
          
          </div>
        </div>
       
      </div>
    </div>
  </div>
</div>
