<app-header></app-header>

<div class="box">
    <section class="container">
        <!-- <h6 class="tour">Get started with <span class="text-primary" data-target="#tourModel"
                data-toggle="modal">Bolstart</span></h6> -->
        <div class="row">
            <div class="col-md-3 mb-2 leftPannel">
                <left-Panel></left-Panel>
                <suggestion></suggestion>
            </div>
            <div class="col-md-6 mb-2 centerPannel">
               
                    <post-Panel></post-Panel>
                
            </div>
            <div class="d-none d-md-block col-md-3 mb-2 rightPannel">
                <app-activestartups></app-activestartups>
              
                <!-- <suggestion></suggestion> -->
                <advertisement [image]="add"></advertisement>
                <!-- <app-address-book-home></app-address-book-home> -->
                <app-news></app-news>
            </div>
        </div>
    
    </section>
    
    <!-- ============== Model ============== -->
    <!---------- Sign In ------------->
    <div class="modal" id="tourModel" role="dialog" data-backdrop="static">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 text-center">
                            <button type="button" class="close" data-dismiss="modal" (click)="close()">&times;</button>
                            <h5>Welcome to Bolstart</h5>
                            <h6 class="text-primary">Bolstering the Startup society</h6>
    
                            <div class="theme-form text-center">
                                <div>
                                    <iframe id="video" style="width: 100%; height: 35rem;"
                                        src="https://www.youtube.com/embed/hvi7YSm-2fU"></iframe>
                                </div>
                            </div>
                        </div>
    
                        <div>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>