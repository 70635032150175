<div class="bioCard p-4">
    <!-- <div class="row">
        <div class="col-11">
            <h5>About</h5>
        </div>
        <div class="col-1">
            <img *ngIf="userInfo?.isOwener" class="pencil" src="assets/logo/pen.svg" data-target="#aboutModel"
                data-toggle="modal" style="width: 25px;">
        </div>
    </div> -->
    <!-- <div>
      
        <pre class="break-line">{{aboutContent}}</pre>
    </div> -->
        <div class="tabData">
            <div class="aboutTitle">
                About
            </div>
            <div class="aboutMessage" [innerHtml]="aboutContent">
                <!-- {{aboutContent}} -->
                  
            </div>
        </div>
    
</div>

<!-- ================ About Model ======================= -->
<div class="modal" id="aboutModel" role="dialog">
    <div class="modal-dialog">

        <div class="modal-content">
            <div class="modal-Custheader p-2">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h6 class="modal-title">Update About Information</h6>
            </div>
            <div class="modal-body">
                <label>About</label>
                <textarea rows="6" type="text" placeholder="eg. About Me" [(ngModel)]="aboutContent"
                    class="form-control"></textarea>
                <div class="text-right">
                    <button type="submit" class="btn btn-outline-primary" (click)="updateAbout()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>