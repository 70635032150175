import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JoblistService } from 'src/app/shared/service/joblist.service';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-popup13',
  templateUrl: './popup13.component.html',
  styleUrls: ['./popup13.component.scss']
})
export class Popup13Component {

 

  
  @ViewChild('fileInput')
  fileInput;

  file: File | null = null;
  uploadedFiles:any=[];
  fileName:string="Upload CV";
  isFileFromDevice:boolean=false;
  filePath:string="" 
  constructor(
    private jobservice:JoblistService,
    public dialogRef: MatDialogRef<Popup13Component>,private toastr:ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any, private _snackBar: MatSnackBar) {
      this.jobservice.setUploadResponse({'status':"",'message':""})
      this.getUploadResponse();
      this.getLatestThreeResume();
     
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
  getUploadResponse(){
    this.jobservice.getUploadResponse().subscribe((response)=>{
      console.log(response,"Type:",typeof(response))
        if(response['status']){
            // this.toastr.success(response['message']);
            this._snackBar.open(response['message'], 'Thank You', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: 'style-success'
            });
        }else{
          if(response['status']!=''){
            // this.toastr.error(response['message']);
            this._snackBar.open(response['message'], 'OK', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: 'style-error'
            });
          }
        }
    });
  }
  onClickFileInputButton(): void {
    this.onChangeFileInput();
  }
  getFileName(){
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    if(files){
      var file = files[0];
      if(files){
        this.fileName = file.name;
        this.isFileFromDevice=true;
      }
    }
    console.log("files::",files)
  }
  uploadIconClick(): void {
    this.fileInput.nativeElement.click();
  }
  deleteFileData(){
    this.file= null;
    this.fileName="Upload CV";
    this.isFileFromDevice=false;
  }
  onChangeFileInput(): void {
    var jobDetails =  this.jobservice.getJobDetails();
    let user_id = localStorage.getItem('member');
    if(this.isFileFromDevice){
          if(user_id){
                const files: { [key: string]: File } = this.fileInput.nativeElement.files;
                var file = files[0];
                console.log("file:",file)
                var requestBody = 
                {
                  "job_id":jobDetails['id'],
                  "user_id":parseInt(user_id)
                }
                console.log(file.type)
                if(file.type=="application/pdf" || file.type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.type=="image/png" || file.type=="image/jpeg" || file.type==".docx"){      
                  this.isFileFromDevice=true;
                  var response=this.jobservice.uploadResumeForJob(file,jobDetails['id'],user_id); 
                console.log(jobDetails['id'],user_id);
                  let me = this;
                setTimeout(function(){
                  me.onNoClick();
                },1000)
                
                }else{
                  // this.toastr.error("File type should be PDF/DOC/IMAGE");
                  this._snackBar.open('File type should be PDF/DOC/IMAGE', 'OK', {
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                    panelClass: 'style-error'
                  });
                }
          }else{
                // this.toastr.error("You are not logged in.");
                this._snackBar.open('You are not logged in.', 'OK', {
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                  panelClass: 'style-error'
                });
          }
    }else{
      this.jobservice.uploadExistingResumeForJob(this.fileName,jobDetails['id'],user_id,this.filePath).subscribe((responseBody)=>{
        if(responseBody.success){
          // this.toastr.success(responseBody.message);
          this._snackBar.open(responseBody.message, 'Thank You', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'style-success'
          });
          let me = this;
          setTimeout(function(){
            me.onNoClick();
          },1000)
        }else{
          // this.toastr.error(responseBody.message);
          this._snackBar.open(responseBody.message, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'style-error'
          });
        }
      });
    }
  }

  
  onChangeFileInput1(): void {
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    console.log(files)
    //const file = event.target.files[0];
    // this.jobservice.applyToJob(file).subscribe(responseBody =>{
    //   console.log("responseBody:",responseBody);
    // }); 
  }

  getLatestThreeResume(){
    this.uploadedFiles=[];
    let user_id = localStorage.getItem('member');
    if(user_id){
      this.jobservice.getResumes(parseInt(user_id)).subscribe((response)=>{
        if(response.success){
          this.uploadedFiles=response.data;
        }
        console.log("uploadedFiles : ",this.uploadedFiles)
    });
    }
  }

  selectFile(fileDetails){
      this.isFileFromDevice=false;
      this.fileName = fileDetails['file_name'];
      this.filePath = fileDetails['resume_path'];
  }

  }


