<div class="cardProfile">
    <div *ngIf="show && postList.length == 0" class="text-center">
        <p>Visibility is very important for your startup to be noticed by investors. And what better way to attract
            investors & founders to your profile than a post. A complete profile always looks better than an empty one;
            include a picture when you share a post! 🚀</p>
    </div>
    <div class="search-results" infinite-scroll [infiniteScrollDistance]="scrollDistance" (scrolled)="onScrollDown()"
        [scrollWindow]="false" [infiniteScrollThrottle]="throttle">
        <ul class="events">
            <li class="list" *ngFor="let item of postList">
                <time></time>
                <span>
                    <label>{{item.updatedAt|date: 'dd MMM, yyyy '}}{{item.updatedAt|date: 'shortTime'}}</label>
                    <post-container (postItemUpdate)=postItemUpdate($event) (communication)=communication($event) [post]="item" [userInfo]="userInfo"></post-container> 
                </span>
            </li>
        </ul>
    </div>
</div>

<div class="postModal" *ngIf="isVisible">
    <div class="modal show" id="postModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
            <div class="modal-content modal-content-py">
                <div class="modal-header border-0">
                    <h5 class="writeUpdareTitle text-center  w-100" id="exampleModalLongTitle" style="font-size: 20px;">Update Post</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="inlineNew">
                        
                                <img class="profile" [src]="userInfo?.profilePicture" /> &nbsp;
                                <div>
                                    <h6 style=" font-size: 12px;
                                    font-family:'Poppins';
                                    color: #48555B;
                                    margin-bottom: 0px;
                                    padding-bottom: 0px;
                                    line-height: initial;">{{userInfo.firstName}}&nbsp;{{userInfo.lastName}}</h6>
            
                                    <div class="userPostMode">{{userInfo.headline}}</div>
                                </div>
                            </div><br />
                            <div>
                                <!-- <label for="files" class="attachmentTitle"><i aria-hidden="true" class="fa fa-paperclip"></i>  Attach</label>
                                <input type="file" id="files" hidden    accept="image/*" #imagePicker (change)="changeListener($event)" />
                                 -->
                                <div class="col-md-12 pr-0">
                                    <label style="cursor: pointer;" for="postFile" class="text-right attchmentBtn">
                                        <input type="file" id="postFile"  hidden  accept="image/*" #imagePicker (change)="fileChangeEvent($event)" />
                                         
                                            <i class="fa fa-paperclip" for="postFile"   aria-hidden="true"  ></i> Attach</label>
                                </div>
                                   
                                 
                              </div>
                    <div class="editor"  rows="5"   [innerHTML]="description"   
                        placeholder="What do you want to share?" 
                        id="newSection" #mydiv contenteditable="true" (keyup)="findTag(mydiv,'newSection')"
                        style="overflow-y: auto;">
                    </div>
                        <!-- <div  style="outline:none; height:50px;"  rows="5"   [innerHTML]="description"   
                            aria-placeholder="What do you want to share?"
                            (keyup)="findTag(mydiv,'newSection')"  style="height: calc(100vh - 651px);" id="newSection" #mydiv contenteditable="true">
                        </div> -->
                    <div class="card position" *ngIf="isShow && userList.length > 0">
                        <ul>
                            <li *ngFor="let item of userList">                             
                                <span *ngIf="item.companyId == null" style="display: flex; padding: 10px;" (click)="selectUser(item)">
                                    <img [src]="item.profilePicture" class="profile" />&nbsp;
                                    <h6 class="item">{{item. firstName}} {{ item. lastName }}&nbsp;&nbsp;<label>{{item.headline}}</label>
                                    </h6>
                                </span>
                                
                                <hr>
                            </li>
                        </ul>
                    </div>
                    
                    <!-- <hr> -->
                    <!-- <div class="btn-wrap">
                        <button class="btn btn-primary btn-sm img-btn-post" (click)="onSelectionTab(1)"><i
                                class="fa fa-picture-o">
                            </i> &nbsp; Image</button>
                    </div>
                    <input type="file" hidden accept="image/*" #imagePicker (change)="changeListener($event)" /> -->
                    <div class="post-img" *ngFor = "let postImage of images; let i = index" >
                        <img class="image_wrapper" [src]="postImage" /><span><i
                            *ngIf="postImage != undefined || postImage != null" (click)="removeImage(i)"
                            class="fa fa-remove fa-1x fab"></i></span>
                      </div>                                       
                        </div>
                        <emoji-mart [style]="{position:'absolute',top:'1rem'}" (emojiSelect)="addEmoji($event)"
                            *ngIf="emojiToggled" set="google" sheetSize="32">
                        </emoji-mart>
                        <i class="fa fa-smile-o" style="color: #564e4e;
                            font-size: 20px;
                            position: absolute;
                            top: 165px;
                            left: 10px;" (click)="toggleEmoji()"></i>
                    </div>
                    
                </div>
                <div class="modal-footer text-center border-0">
                    <button  type="button" class="btn btn-primary" (click)="onNewPostAdd()">Share</button>
                </div>
    
            </div>
        </div>
    </div>
    </div>

<ngx-photo-editor [imageChanedEvent]="imageChangedEvent" (imageCropped)="imageCropped($event)" [viewMode]="1"
    [aspectRatio]="1/1" [darkTheme]="false">
</ngx-photo-editor>
