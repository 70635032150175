import * as $ from 'jquery';

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AccountApiService } from './../../../account/account-api.service';
import { ProfileService } from '../../profile.service';
import { ToastrService } from 'ngx-toastr';
import { common } from 'src/app/shared/common/validator';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {
  @Input() userInfo: any;
  public educationForm: UntypedFormGroup;
  monthList: any = common.month;
  dayList: any = common.day;
  stateList: any = common.state;
  industryList: any = common.industries;
  yearList: any = common.years();
  endYearList: any = common.years();
  educationList: any = [];
  searchList: any = [];
  showList: number = 0;
  selectUniverity = false ;
  selectedUniversity: any = null;
  index: number;
  dummyIcon = "https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/unversity.png"
  isDatavalid: boolean = false; 

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private profileService: ProfileService,
    private accountService: AccountApiService,
    private _snackBar: MatSnackBar
  ) { }
  ngOnInit(): void {
    const now = new Date().getUTCFullYear()+5;
    this.endYearList = Array(now - (now - 50)).fill('').map((v, idx) => now - idx) as Array<number>;
    //this.educationList = this.userInfo.education != null ? this.userInfo.education : [];;
    this.initializeEduForm();
    this.getEducation();

  }
  getEducation() {
    this.route.queryParams.subscribe(param => {   
      this.profileService.getEducation(param.visit).subscribe(res => {
        if (res.success) {
          this.educationList = res.data;   
        }
      })
    })
  }

  initializeEduForm() {
    this.educationForm = this.fb.group({
      // institutionId: ['', [Validators.required]],
      // institutionLogo: ['',],
      // institutionName: ['',[Validators.required]],
      university: ['', [Validators.required]],
      activities: [''],
      degree: ['', [Validators.required]],
      endDate: [''],
      grade: [''],
      societies: [''],
      startDate: [''],
      stream: ['',[Validators.required]],
      userId: [''],
      id: ['']
    })
  }

  /* ============ Selected Item ========== */
  edit(item, index) {
    this.educationForm.patchValue({
      // institutionId: item.institution.id,
      // institutionLogo: item.institutionLogo,
      // institutionName: item.institution.name,
      university: item.university.name,
      activities: item.activities,
      degree: item.degree,
      endDate: moment(item.endYear).year(),
      grade: item.grade,
      societies: item.description,
      startDate: moment(item.startYear).year(),
      stream: item.stream,
      userId: item.userId,
      id: item.id
    })
    this.index = index;
    this.selectedUniversity = item.university
    // this.educationForm.value.institutionId = item.institutionId;
    // this.educationForm.value.institutionLogo = item.institutionLogo;
    // this.educationForm.value.institutionName = item.institutionName;
      
  }

  /* =============== Inpuut Focus ============================*/
  onFocus(value) {
    switch (value) {
      case 1:
        this.showList = 1
        break;
      case 2:
        this.showList = 2
        break;
      case 3:
        this.showList = 3
        break;
    }
  }
  onBlur() {
    setTimeout(() => {
      this.showList = 0
      this.searchList = [];
    }, 500)
  }
  search(event) {
    switch (this.showList) {
      case 1:
        // this.getinstitution();
        this.getUniversityList();
        break;
      case 2:
        this.searchList = common.degree;
        break;
      case 3:
        this.searchList = common.field;
        break;
    }
  }
  
  selectItem(event, item) {
    switch (event) {
      case 1:
        this.educationForm.patchValue({
          // institutionId: item.id,
          // institutionLogo: this.dummyIcon,
          // institutionName: item.name,
          university: item.name,
          userId: this.userInfo.id
        })
        this.selectedUniversity = item
        this.searchList = []
        this.selectUniverity = true;
        break;
      case 2:
        this.educationForm.patchValue({
          degree: item
        })
        this.searchList = []
        break;
      case 3:
        this.educationForm.patchValue({
          stream: item
        })
        this.searchList = []
        break;
    }
    this.showList = 0;
   
  }
  eduSubmit() {  
    if(!this.selectedUniversity){
      this.saveUniversity();   
    }
    this.selectUniverity =  false;
    this.isDatavalid = false ;
    if(this.educationForm.value.startDate > this.educationForm.value.endDate)
    {
      this.isDatavalid = true;
      return;
    }
    this.educationForm.markAllAsTouched();     
    if (this.educationForm.valid) {
      let obj = {
        activities: this.educationForm.value.activities,
        degree: this.educationForm.value.degree,
        endYear: moment(String(this.educationForm.value.endDate)),
        grade: this.educationForm.value.grade,
        description: this.educationForm.value.societies,
        university: {id:this.selectedUniversity.id},
       // institutionId: this.educationForm.value.institutionId,
        //institutionLogo: this.educationForm.value.institutionLogo,
        //institutionName: this.educationForm.value.institutionName,
        startYear:moment(String(this.educationForm.value.startDate)),// this.educationForm.value.startDate,

        stream: this.educationForm.value.stream,
        //userId: this.educationForm.value.userId,
        user:this.userInfo,
        id: this.educationForm.value.id
      }
      this.profileService.updateEducation(obj).subscribe(res => {         
        if (res.success) {
          this.getEducation();
          // this.toastr.success(res.message);
          this._snackBar.open(res.message, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'style-success'
          });
           
          this.educationForm.reset();
          if(obj.id != null)
            this.index = null;            
          $("#eductionModel .close").click();
        } else {
          // this.toastr.error(res.message);
          this._snackBar.open(res.message, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'style-error'
          });
        }
      })
    }
  }
  deleteSchool(item, index) {
    this.profileService.deleteEducation(item.id).subscribe(res => {
      if (res.success) {
        // this.toastr.success(res.message);
        this._snackBar.open(res.message, 'OK', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: 'style-success'
        });
        this.educationList.splice(index, 1);
      }
    })
  }
  newOpen() {
    this.educationForm.reset();
    this.index = null;
    this.selectedUniversity = null;
  }
  /* ==================== University ====================== */

  getinstitution() {     
    var institutionName=this.educationForm.value.institutionName;
    this.accountService.getinstitution(institutionName).subscribe(res => {
      if (res.success) {
        this.searchList = res.data;       
      }else{
        this.searchList = [];  
      }
    })
  }

  getUniversityList() {     
    var universityName=this.educationForm.value.university;

    if(universityName != null && universityName != undefined && universityName != ""){
      this.accountService.getUniversityList(universityName).subscribe(res => {
        if (res.success) {
          this.searchList = res.data;       
        }else{
          this.searchList = [];  
        }
      })
    }else{
      this.selectedUniversity = null;
    }
  }

  saveUniversity() { 
    if(this.educationForm.value.degree == null || this.educationForm.value.degree == ""){
      return false;
    } 

    var obj = {
      name: this.educationForm.value.university,
      city: "NA"
    }
    this.accountService.saveUniversity(obj).subscribe(res => {
      if (res.success) {
        this.educationForm.patchValue({
          // institutionId: res.data.id,
          // institutionLogo: this.dummyIcon,
          // institutionName: res.data.name,
          university: res.data.name,
          userId: this.userInfo.id
        })  
        this.selectUniverity = true;
        this.selectedUniversity = res.data;
        this.eduSubmit()            
        return true;
      }     
    })
    
   
    
  }
  
}
