import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activestartups',
  templateUrl: './activestartups.component.html',
  styleUrls: ['./activestartups.component.scss']
})
export class ActivestartupsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
