import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class JoblistService{
 joburl = environment.jobPostBaseUrl;
 JobDetails:any;
 setJobStatus:any;
 base_url = environment.jobPostBaseUrl;
 uploadResponse:BehaviorSubject<object>=new BehaviorSubject<object>({})
 constructor(private http:HttpClient){}
 getAllJobsList(){
  let user_id=0;
   if (localStorage.getItem('member')=== null || localStorage.getItem('member')=="0") {
    user_id=0;
    }else{
    user_id=parseInt(localStorage.getItem('member'));
    }
    var requestUrl = this.base_url+"jobpost/job/getAll?user_id="+user_id;
    return this.http.get(requestUrl);
 }

 createNewJob(requestBody){
  const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
  var requestUrl = this.base_url;
  requestUrl = this.base_url+"api/jobpost";
  return this.http.post<any>(requestUrl, requestBody, { headers });
}

updateJob(requestBody){
  const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
  var requestUrl = this.base_url;

    requestUrl = this.base_url+"jobpost/jobpost/update";
    return this.http.put<any>(requestUrl, requestBody, { headers });
  
   
}

getJob(requestBody){
  const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
  var requestUrl = this.base_url+"jobpost/getbyjobPostId?id="+requestBody['id']
   return this.http.get<any>(requestUrl, requestBody);
}

getCompanyDetails(requestBody){
  const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
  var requestUrl = this.base_url+"api/company/getById?id="+requestBody['id']
   return this.http.get<any>(requestUrl, requestBody);
}
 setJobDetails(jobDetails){
    this.JobDetails=[]
    this.JobDetails = jobDetails
 }

 getJobDetails(){
   return this.JobDetails;
 }

 
 applyToJob(requestBody){
   const headers = { 'Authorization': 'Bearer my-token'};
   this.joburl = this.base_url;
   var requestUrl = this.base_url+"jobpost/job/applyJob";
   return this.http.post<any>(requestUrl, requestBody, { headers });
  // const headers = { 'Authorization': 'Bearer my-token', 'Content-Type': 'multipart/form-data' };
  //  return this.http.post<any>(this.joburl+'/jobpost/uploadImage?id=4', requestBody, { headers });
    var response:any=[]
    var apiUrl = this.joburl+'/jobpost/uploadImage?id=4';
    var formData:any = new FormData();

    formData.append("files", requestBody, requestBody.name);
    let me = this;
    var request = new XMLHttpRequest();
    request.open("POST", apiUrl);
    request.onload = function () {
      var uploadResponse=JSON.parse(this.responseText)
      var res = {'status':uploadResponse['success'],'message':uploadResponse['message']}
      me.setUploadResponse(res);
      return res;
    };
    request.onerror = function () {
      var uploadResponse=JSON.parse(this.responseText)
      var res = {'status':uploadResponse['success'],'message':uploadResponse['message']}
      me.setUploadResponse(res);
      return res;
    };
    request.send(formData);
    // return this.http.post<any>(this.APIConstants.API_URL+this.APIConstants.s3BucketUploadURL, formData,{
    //   headers:{'Content-Type':"multipart/form-data; boundary=multipart-form-boundary"}
    // });
}


uploadResumeForJob(requestBody:File,job_id,user_id){
  const headers = { 'Authorization': 'Bearer my-token'};
  this.joburl = this.base_url;
  var requestUrl = this.base_url+"jobpost/job/applyJob";

 

 // return this.http.post<any>(requestUrl, requestBody, { headers });
 // const headers = { 'Authorization': 'Bearer my-token', 'Content-Type': 'multipart/form-data' };
 //  return this.http.post<any>(this.joburl+'/jobpost/uploadImage?id=job_id', requestBody, { headers });
   var response:any=[]
   var apiUrl = this.joburl+'/jobpost/uploadImage?id='+job_id+"&user_id="+user_id+"&file_name="+requestBody.name;
   var formData:any = new FormData();
   formData.append("timlineImage", requestBody, Math.random()+requestBody.name);
   let me = this;
   var request = new XMLHttpRequest();
   request.open("POST", apiUrl);
   request.onload = function () {
     var uploadResponse=JSON.parse(this.responseText)
     var res = {'status':uploadResponse['success'],'message':uploadResponse['message']}
     me.setUploadResponse(res);
     return res;
   };
   request.onerror = function () {
     var uploadResponse=JSON.parse(this.responseText)
     var res = {'status':uploadResponse['success'],'message':uploadResponse['message']}
     me.setUploadResponse(res);
     return res;
   };
   request.send(formData);
   // return this.http.post<any>(this.APIConstants.API_URL+this.APIConstants.s3BucketUploadURL, formData,{
   //   headers:{'Content-Type':"multipart/form-data; boundary=multipart-form-boundary"}
   // });
}


uploadExistingResumeForJob(fileName,job_id,user_id,filePath){
  this.joburl = this.base_url;
  var requestBody = {
    file_name:fileName,
    job_id:job_id,
    user_id:user_id,
    resume_path:filePath
  }
  var apiUrl = this.base_url+'/jobpost/uploadExistingResume?id='+job_id+"&user_id="+user_id+"&file_name="+fileName+"&file_path="+filePath;
  const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
  return this.http.post<any>(apiUrl, { headers });
}

setUploadResponse(res){
   this.uploadResponse.next(res);
}

getUploadResponse(){
  return this.uploadResponse.asObservable();
}

addToSaveList(requestBody){
  const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
   return this.http.post<any>(this.base_url+'jobpost/job/savedUserJobs', requestBody, { headers });
}

filterByJobs(requestBody){
  const headers = { 'Authorization': 'Bearer my-token' };
  return this.http.post<any>(this.base_url+'jobpost/filterjobpostAPI', requestBody, { headers });
}

getAllJobApplyer(requestBody){
  const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
  var requestUrl = this.base_url+"jobpost/job/getJobApplyer"
   return this.http.post<any>(requestUrl, requestBody, { headers });
}


updateRating(requestBody){
  const headers = {};
  var requestUrl = this.base_url+"jobpost/jobpost/applyedJobStarRating"
   return this.http.put<any>(requestUrl, requestBody, { headers });
}


updateRecruiterAction(requestBody){
  const headers = {};
  var requestUrl = this.base_url+"jobpost/jobpost/updateRecruiterAction"
   return this.http.put<any>(requestUrl, requestBody, { headers });
}


updateJobStatus(requestBody){
  const headers = {};
  var requestUrl =  this.base_url+"jobpost/jobpost/deActiveJobPost"
   return this.http.put<any>(requestUrl, requestBody, { headers });
}

hireUser(requestBody){
  const headers = {};
  var requestUrl =  this.base_url+"jobpost/jobpost/hireUser"
   return this.http.put<any>(requestUrl, requestBody, { headers });
}

setJobStatusResponse(setJobStatus){
  this.setJobStatus = setJobStatus
}

getJobStatusResponse(){
 return this.setJobStatus;
}


// getAllJobTitles(){
//   const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
//   var requestUrl = this.base_url+"jobtitle/job/getAll"
//    return this.http.get<any>(requestUrl);
// }


getAllJobTitles(): Observable<any> {
  return this.http.get(this.joburl + `jobtitle/job/getAll`);
}

filterValues(): Observable<any>{
   return this.http.get(this.base_url+`jobpost/job/getAllFilters`);
}


getUserSavedJobs(requestBody){
  const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
  var requestUrl = this.base_url;
  requestUrl = this.base_url+"jobpost/job/getsavedjobs";
  return this.http.post<any>(requestUrl, requestBody, { headers });
}


getUserAplliedJobs(requestBody){
  const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
  var requestUrl = this.base_url;
  requestUrl = this.base_url+"jobpost/job/myJobs";
  return this.http.post<any>(requestUrl, requestBody, { headers });
}

getDataDifference(newDate){
  if(newDate){
    var date1 = new Date(newDate);
    var date2 = new Date();
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  
    return Math.floor(Difference_In_Days);
  }else{
    return 0;
  }
  
}

getResumes(user_id){
  const headers = { 'Authorization': 'Bearer my-token'};
   return this.http.post<any>(this.base_url+'jobpost/getUploadedResume?user_id='+user_id, { headers });
}

  getMinMaxStipend(){
    const headers = { 'Authorization': 'Bearer my-token' };
    return this.http.get<any>(this.base_url+'jobpost/job/stipend', { headers });
  }

  getJobApplyersByFilter(data){
    const headers = { 'Authorization': 'Bearer my-token' };
    return this.http.post<any>(this.base_url+'jobpost/jobapplyers/filter', data, { headers });
  }

  getUserLocations(){
    const headers = { 'Authorization': 'Bearer my-token' };
    return this.http.get<any>(this.base_url+'api/user/location', { headers });
  }

  getJobTitles(){
    const headers = { 'Authorization': 'Bearer my-token' };
    return this.http.get<any>(this.base_url+'jobpost/job/jobtitle', { headers });
  }
}