<app-header></app-header>

<div class="container">
  <div class="mainclass">
    <p class="title">Publish new Job Post</p>
    <div class="divHolder">
      <div class="stepperSidebar">
        <div class="job-post-info-title">
          <div class="border-btn-tips">
            <span (click)="tipsEnabled = !tipsEnabled">{{!tipsEnabled ? "Tips" : "Did You Know"}}</span>
            <span>
            <mat-icon *ngIf="tipsEnabled">help</mat-icon><mat-icon *ngIf="!tipsEnabled">tips_and_updates</mat-icon>
          </span>
          </div>
      </div>
        <div class="stepCont">
          <div class="slectCel" [ngClass]="selected == 1 ? 'slectCel' : 'slectCel2'" (click)="stepper(1)"> 01</div>
          <div class="slectCel" [ngClass]="selected == 2 ? 'slectCel' : 'slectCel2'" (click)="stepper(2)">02</div>
          <div class="slectCel" [ngClass]="selected == 3 ? 'slectCel' : 'slectCel2'" (click)="stepper(3)">03</div>
        </div>
        <div *ngIf="selected == 1 || selected == 2 || selected == 3"  style="height: 80%;">
          <div class="contentHolder">
            <div class="content">
              <img class="imgSeq" src="../../../../assets/img/did-you-know.png" width="50px" alt="Did you know?" />
              <p class="didO">Did you know?</p>
              <ul>
                <!-- <li class="did">showcase a random fact every time. this</li> -->
                <!-- <li class="did">increases the user engagement</li> -->
              </ul>
              <!-- <div class="text-li-seq">
    <p>	<mat-icon class="icon-b">brightness_1</mat-icon>Students learn a lot more by working at a startup than at a corporate.</p>
    <p> <mat-icon class="icon-b">brightness_1</mat-icon>Instead of Netflixing, students prefer interning to boost their career.</p>
    <p> <mat-icon class="icon-b">brightness_1</mat-icon>53 Million people in India are unemployed.</p>
    <p> <mat-icon class="icon-b">brightness_1</mat-icon>4.36 Million jobs are created / year as per economic survey 2019-20.</p>
    </div> -->
  
              <!-- <div class="widthOfText" *ngIf="!tipsEnabled">
  
                <div class="margin-btm">
                  <p><i class="fa fa-circle" aria-hidden="true" style="color: white;"></i> showcase a random fact every
                    time. this</p>
                  <p> &nbsp;&nbsp;&nbsp;&nbsp;increases the user engagement</p>
                </div>
  
                <div class="margin-btm">
                  <p><i class="fa fa-circle" aria-hidden="true" style="color: white;"></i> Instead of Netflixing, students
                    prefer</p>
                  <p> &nbsp;&nbsp;&nbsp;&nbsp; interning to boost their career.</p>
                </div>
  
                <div class="margin-btm">
                  <p><i class="fa fa-circle" aria-hidden="true" style="color: white;"></i> 53 Million people in India are
                    unemployed.</p>
                </div>
  
                <div class="margin-btm">
                  <p><i class="fa fa-circle" aria-hidden="true" style="color: white;"></i> 4.36 Million jobs are created /
                    year as per.</p>
                  <p> &nbsp;&nbsp;&nbsp;&nbsp; economic survey 2019-20.</p>
  
                </div>
              </div> -->
              <div class="widthOfText" *ngIf="!tipsEnabled">
                <div class="margin-btm tips-list" *ngFor="let item of didyouknowList">
                  <span><i class="fa fa-circle" aria-hidden="true" style="color: white;"></i></span>
                  <span class="tips-span">{{item}}</span>
                </div>
              </div>
              <div class="widthOfText" *ngIf="tipsEnabled">
                <div class="margin-btm tips-list" *ngFor="let tip of tips">
                  <span><i class="fa fa-circle" aria-hidden="true" style="color: white;"></i></span>
                  <span class="tips-span">{{tip}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footerContent">
          <p class="footer-container">Need Help? <br>Connect with our experts who would love to help you out. Reach out to us at <br> reachout@bolstart.com</p>
          <!-- <p>Connect with our experts who would love to help</p>
          <p>you out. Reach out to us at</p>
          <p>helpdesk@bolstart.com</p> -->
        </div>
  
      </div>
      <div class="stepperform">
        <div class="">
          <!-- <span>Job Post Details : Job Details</span> -->
  
          <div class="row" style="padding: 40px" *ngIf="selected == 1">
            <div ngClass="">
              <div>
                <div class="">
                  <form name="form" [formGroup]="form" (ngSubmit)="form.valid && onSubmit()">
                    <label for="firstName">Job Post Details : Job Details </label>
                    <br />
                    <br />
  
                    <div class="row field">
                      <div class="col-md-12">
                        <label for="firstName">Job Title <span>*</span></label>
                        <div class="example-form">
                          <mat-form-field class="example-full-width" appearance="fill">
                            <input type="text"
                                   placeholder="Job Title"
                                   aria-label=""
                                   matInput
                                   formControlName="titlevalue"
                                   [matAutocomplete]="auto">
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getJobTitleOptionText" (optionSelected)="tabKeyPressed()">
                              <mat-option *ngFor="let option of jobTitleOptions | async" [value]="option">
                                {{option.jobTitle}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                        </div>
                        <!-- <input type="text" class="form-control placeholderInput" (input)="inputChange()" (keydown.tab)="tabKeyPressed()" formControlName="titlevalue" required
                          placeholder="Java Software Developer" />
                          <input type="text" class="form-control autocomplete" disabled  formControlName="placeholderValue" /> -->
                        <div class="error-message" *ngIf="form.controls.jobTitle.errors?.required && form.controls.jobTitle.touched"> Job title is Required *</div>  
                      </div>
                    </div>
                    <div class="row field">
                      <div class="col-md-6">
                        <label for="lastName">Internship Start Date *</label>
                        <input type="date" [min]="minDate | date:'yyyy-MM-dd'" class="form-control" id="jobPeriod"  formControlName="jobPeriod" placeholder="Start Date" />
                        <div class="error-message" *ngIf="form.controls.jobPeriod.errors?.required && form.controls.jobPeriod.touched"> Job Period is Required *</div>
                      </div>
  
                      <div class="col-md-6">
                        <label for="lastName">Job Duration (In Months)*</label>
                        <input type="number" min="1" max="12"
                        onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'" 
                        class="form-control" id="jobDuration"  formControlName="jobDuration" required
                          placeholder="3 Months" />
                          <div class="error-message" *ngIf="form.controls.jobDuration.errors?.required && form.controls.jobDuration.touched"> Job Duration is Required *
                          </div>
                          <div class="error-message" *ngIf="form.controls.jobDuration.errors?.incorrect && form.controls.jobDuration.touched">Job Duration Should Not Be Negative and More than 12 *</div>
                      </div>
                    </div>
                    <div class="row alg field">
                      <div class="col-md-12">
                        <!-- <ckeditor [editor]="Editor" data="<p>Hello, world!</p>"></ckeditor> -->
                        <label for="email">Job Description *</label>
                        <ckeditor [editor]="Editor" tagName="textarea" type="divarea" formControlName="jobDescription" data="" (ready)="onReady($event)"></ckeditor>
                        <div class="error-message" *ngIf="form.controls.jobDescription.errors?.required && form.controls.jobDescription.touched"> Job Description is Required *</div>
                      </div>
                    </div>
                    <!-- <div class="form-group alg">
                      <label for="email">Job Description *</label>
                      <textarea class="jobDesc textarea-t2" id="jobDescription"   formControlName="jobDescription" name="w3review" rows="3" cols="30"
                        placeholder="Job Description in 3 Lines">
                      </textarea>
                    </div> -->
                    <div class="row field">
                      <div class="col-md-6">
                        <label for="stipend">Stipend *</label>
                        <input type="number" min="0" max="999999" onKeyPress="if(this.value.length==6) return false;" 
                        onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"  class="form-control sti" id="jobStipend"  formControlName="jobStipend"
                          placeholder="&#x20B9; Stipend Amount" />
                          <div class="error-message" *ngIf="form.controls.jobStipend.errors?.required && form.controls.jobStipend.touched"> Job Stipend is Required *</div>
                          <div class="error-message" *ngIf="form.controls.jobStipend.errors?.incorrect && form.controls.jobStipend.touched"> Job Stipend Should Not Be Negative *</div>
                      </div>
  
                      <div class="col-md-6">
                        <label for="lastName">Location *</label>
                        <div class="locationLayout">
                          <input type="checkbox" id="switch" (change)="workType($event)" class="check" />
                          <label class="toggleSwitch" for="switch">Toggle</label>
                          {{ form.controls.workType.value }}
                          <input type="text"  *ngIf="form.controls.workType.value=='WFO'" class="form-control stipend location2-post" id="jobLocation"  formControlName="jobLocation" required
                            placeholder="Location of the job " />
                        </div>
                        <!-- <div class="error-message" *ngIf="form.controls.jobLocation.errors?.required && form.controls.jobLocation.touched"> Job Location is Required *</div> -->
                      </div>
                    </div>
                    
                    <div class="row alg field">
                      <div class="col-md-12">
                        <label for="password">Responsibilities *</label>
                        <ckeditor [editor]="Editor" formControlName="jobResponsibilities" data="" (ready)="onReady($event)"></ckeditor>
  
                        <!-- <textarea class="textarea-t1" id="jobResponsibilities"  formControlName="jobResponsibilities" rows="3" cols="30" placeholder="">1. Building interactive consumer data from multiple systems and RESTfully abstracting to the UI through a Node.js backend. 2. Define code architecture decisions to support a high-performance and scalable product with a minimal footprint. 3. Address and improve any technical issues. Collaborate well with engineers, researchers, and data implementation specialists to design and create advanced, elegant and efficient systems.
                        </textarea> -->
                        <div class="error-message" *ngIf="form.controls.jobResponsibilities.errors?.required && form.controls.jobResponsibilities.touched"> Job Responsibilities is Required *</div>
                      </div>
                    </div>
                    <div class="row alg field">
                      <div class="col-md-12">
                        <label for="password">Qualification and Requirements *</label>
                        <ckeditor [editor]="Editor" formControlName="jobRequirements" data="" (ready)="onReady($event)"></ckeditor>
    
                        <!-- <textarea class="textarea-t1"  id="jobRequirements"  formControlName="jobRequirements"  rows="3" cols="30" placeholder="">1.A developer with some prior experience in building an app. The experience could be a personal project or a college project, but hands-on experience is needed. 2. Knowledge in creating front end applications using HTML5, Angular. 3. Hands-on with JavaScript Development on both client and server-side. 4. Understanding ReactJS, NodeJS is a must. 5. Understanding of modern frameworks and design patterns. 6. Knowledge of the following will be considered as an advantage: 7. Experience with cloud technologies is also a plus. 8. Creating secure RESTful-based web services in XML or JSON, Javascript, JQuery. Continuous integration (Jenkins) and version control (Git). Needs to be self-driven to learn and build, as we are a startup and don’t have the bandwidth to train. Guidance will be provided where needed, but constant hand-holding will not be possible. Any stream is fine (engineer, BCom, 12th pass etc) as long as he/she has some hands-on experience. Preferably final year student (although this is not a criteria).
                        </textarea> -->
                        <div class="error-message" *ngIf="form.controls.jobRequirements.errors?.required && form.controls.jobRequirements.touched"> Job Requirements is Required *</div>  
                      </div>
                    </div>
                    <div class="row field">
                      <div class="col-md-9">
                        <label for="stipend">Benifits *</label>
                        <br>
                        <ckeditor [editor]="Editor" formControlName="jobBenifits" data="" (ready)="onReady($event)"></ckeditor>
  
                        <!-- <textarea class="textarea-t3"  id="jobBenifits"  formControlName="jobBenifits"  rows="3" cols="30" placeholder="">1.A developer with some prior experience in building an app. The experience could be a personal project or a college project, but hands-on experience is needed. 2. Knowledge in creating front end applications using HTML5, Angular. 3. Hands-on with JavaScript Development on both client and server-side. 4. Understanding ReactJS, NodeJS is a must. 5. Understanding of modern frameworks and design patterns. 6. Knowledge of the following will be considered as an advantage: 7. Experience with cloud technologies is also a plus. 8. Creating secure RESTful-based web services in XML or JSON, Javascript, JQuery. Continuous integration (Jenkins) and version control (Git). Needs to be self-driven to learn and build, as we are a startup and don’t have the bandwidth to train. Guidance will be provided where needed, but constant hand-holding will not be possible. Any stream is fine (engineer, BCom, 12th pass etc) as long as he/she has some hands-on experience. Preferably final year student (although this is not a criteria).
                        </textarea> -->
                        <div class="error-message" *ngIf="form.controls.jobBenifits.errors?.required && form.controls.jobBenifits.touched"> Job Requirements is Required *</div>   
                      </div>
                      <div class="col-md-3">
                        <label for="lastName">Type *</label>
                        <div class="locationLayout">
                          <input type="checkbox" (change)="getJobType($event)" id="switch1" class="check" />
                          <label class="toggleSwitch" for="switch1">Toggle</label>
                         {{ form.controls.jobType.value }}
                        </div>
                        <div class="error-message" *ngIf="form.controls.jobType.errors?.required && form.controls.jobType.touched"> Job Location is Required *</div>
                      </div>
                    </div>
  
                    <div class="btnAlg">
                      <!-- <button class="btn btn-primary"  [disabled]="!form.valid">Register</button> -->
                      <button type="button" class="tBtn" (click)="stepper(2)">
                        Preview
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="preview" *ngIf="selected == 2">
          <!-- <span>Job Post Details : Job Details</span> -->
  
          <div class="row" style="padding: 30px">
            <div class="mainPage overFlowHandle">
              <div class="dFlex">
                <div class="img-title">
                  <img [src]="form.controls.company_logo.value" alt="" class="img-c" />
                  <div class="text-seq">
                    <span class="text-c"> {{ form.controls.titlevalue.value.jobTitle }}</span>
                    <span class="text-c2"> {{ form.controls.name.value }},{{ form.controls.city.value }}
                    </span>
                    <span style="font-size: 13px">
                      {{ form.controls.currentDate.value | date: 'dd/MM/yyyy' }}</span>
                    <span style="font-size: 13px"><span class="job">Job category </span>
                      {{ form.controls.jobCategory.value }}</span>
                  </div>
                </div>
                <div class="buttonsDiv" style="width: 50%;">
                  <img (click)="stepper(1)" src="assets/img/edit.png" alt="" class="img-c" style="width: 9%;height: 30%; cursor: pointer;" title="Edit job"/>
                </div>
              </div>
              <div class="title-div">
                <div>
                  <span class="t-e"> Location</span>
                  <span class="bold-t" *ngIf="form.controls.workType.value=='WFH'"> Remote  </span>
                  <span class="bold-t" *ngIf="form.controls.workType.value=='WFO'"> {{ form.controls.jobLocation.value }} </span>
                  <!-- <span class="bold-t">  {{ form.controls.jobLocation.value }} </span> -->
                </div>
  
                <div>
                  <span class="t-e">Internship Start Date</span>
                  <span class="bold-t"> {{ form.controls.jobPeriod.value | date: 'dd/MM/yyyy' }}</span>
                </div>
  
                <div>
                  <span class="t-e">Employee Type</span>
                  <span class="bold-t">{{ form.controls.jobType.value }}</span>
                </div>
  
                <div>
                  <span class="t-e">Stipend</span>
                  <span class="bold-t" *ngIf="form.controls.jobStipend.value == 0">Unpaid</span>
                  <span class="bold-t" *ngIf="form.controls.jobStipend.value > 0">₹ {{ form.controls.jobStipend.value }} / Month</span>
                </div>
  
                <div>
                  <span class="t-e">Duration</span>
                  <span class="bold-t">{{ form.controls.jobDuration.value }} Months</span>
                </div>
              </div>
              <hr />
              <p class="or">Overview</p>
              <p class="P-tag">{{ form.controls.overview.value }}
              </p>
  
              <p class="or">Job Description</p>
              
              <ckeditor [editor]="Editor" tagName="textarea" type="divarea"  data="{{ form.controls.jobDescription.value }}" (ready)="onReady($event)"></ckeditor>
            
               <!-- <p class="P-tag" ng-non-bindable>{{ form.controls.jobDescription.value }}  </p> -->
  
              <p class="or">Responsibilities</p>
              <ckeditor [editor]="Editor" tagName="textarea" type="divarea"  data="{{ form.controls.jobResponsibilities.value }}" (ready)="onReady($event)"></ckeditor>
              <!-- <p class="P-tag">{{ form.controls.jobResponsibilities.value }}</p> -->
  
              <p class="or">Qualification and Requirements </p>
              <ckeditor [editor]="Editor" tagName="textarea" type="divarea"  data="{{ form.controls.jobRequirements.value }}" (ready)="onReady($event)"></ckeditor>
  
              <!-- <p class="P-tag">{{ form.controls.jobRequirements.value }}</p>  -->
            
  
              <p class="or">Benifits</p>
              <ckeditor [editor]="Editor" tagName="textarea" type="divarea"  data="{{ form.controls.jobBenifits.value }}" (ready)="onReady($event)"></ckeditor>
              <!-- <p class="P-tag">{{ form.controls.jobBenifits.value }}</p> -->
  
              <div class="btnAlg">
                <!-- <button class="btn btn-primary"  [disabled]="!form.valid">Register</button> -->
                <button type="button" *ngIf="form.controls.payment_status.value" class="tBtn" (click)="stepper(4)">
                  Submit
                </button>
                <button type="button" *ngIf="!form.controls.payment_status.value" class="tBtn" (click)="stepper(3)">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
  
        <div class="" *ngIf="selected == 3 && !form.controls.payment_status.value">
          <div class="row" style="padding: 30px">
            <div class="mainPage overFlowHandle">
             <div class="backbtn" (click)="stepper(2)">Back</div>
              <div class="title-div1" style="
              justify-content: center; ">
                <div class="paymentBox">
                  <div class="paymentContent">
                  <h3>How Many Interns You Want To Hire For This Position? </h3>
                    <div class="countingbox">
                      <div class="noOfHire">
                        <div class="row">
                            <div class="col-md-9" style="
                            display: flex;
                        ">
                              <div class="chargesHeading">
                                <span>Charges Per Hire</span>
                              </div>
                              <i class="fa fa-minus-circle" aria-hidden="true" (click)="reduceNumber()"></i>
                              <span>{{ form.controls.numberOfHire.value }}</span>
                              <i class="fa fa-plus-circle" aria-hidden="true" (click)="incressNumber()"></i>
                              <span>{{ form.controls.numberOfHire.value }} * {{ form.controls.bolstartCommision.value }} =</span>
                            </div>
                            <div class="col-md-3">
                              <span>{{ form.controls.numberOfHire.value  *  form.controls.bolstartCommision.value }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="candidatedMe">
                        <div class="row">
                          <div class="col-md-9" >
                            <p><input type="checkbox" id="candidatesForMe" (change)="candidatesForMe($event)" class="checkBack" />Curate The Candidates For Me</p>
                          </div>
                          <div class="col-md-3">
                            <span>{{ form.controls.curatethecandidates.value  }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="total">
                        <div class="row">
                          <div class="col-md-9">
                            <p>Total</p>
                          </div>
                          <div class="col-md-3">
                            <span>{{ form.controls.totalAmount.value  }}</span>
                          </div>
                        </div>
                      </div>
                    </div>                  
                  </div>
                </div>
                <!-- <span class="bold-t">  {{ form.controls.jobLocation.value }} </span>
        -->
              
              <div class="btnAlg">
                <!-- <button class="btn btn-primary"  [disabled]="!form.valid">Register</button> -->
                <!-- <button type="button" *ngIf="!form.controls.payment_status.value" class="tBtn" (click)="paynow()">
                  Pay now
                </button> -->
                <button type="button" *ngIf="!form.controls.payment_status.value" class="tBtn" (click)="paynow()">
                  Post a free job
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
