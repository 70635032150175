import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { map, startWith } from 'rxjs/operators';
import { JoblistService } from 'src/app/shared/service/joblist.service';
import * as $ from 'jquery';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-filterpopup',
  templateUrl: './filterpopup.component.html',
  styleUrls: ['./filterpopup.component.scss']
})
export class FilterpopupComponent implements OnInit {

  filterForm: UntypedFormGroup;
  isDisplay2: boolean = true;
  isDisplay5: boolean = true;
  isDisplay3: boolean = true;
  locationOptions  = [];
  locationOriginalOptions  = [];

  sortTypes = ['Newest', 'Oldest', 'A-Z', 'Z-A']
  filterAppliedCnt = 0;
  scoreCnt = [];
  constructor(private fb: UntypedFormBuilder, public dialogRef: MatDialogRef<FilterpopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private jobService: JoblistService,
    private toastr:ToastrService,private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getUserLocations();
    this.filterForm.controls.isMessageSent.setValue("Message Not Sent")
  }


  initializeForm() {
    this.filterForm = this.fb.group({
      sortType: [""],
      location: [""],
      score: [0],
      recruiterAction: [0],
      isMessageSent: [""],
    })

    var filterData = JSON.parse(localStorage.getItem('viewCandidateFilter'));

    if(filterData != null && filterData != undefined){
      this.filterForm.controls.sortType.setValue(filterData.sortType);
      this.filterForm.controls.location.setValue(filterData.location);
      this.filterForm.controls.score.setValue(filterData.score);
      if(filterData.score == 1){
        this.scoreCnt = [1];
      }else if(filterData.score == 2){
        this.scoreCnt = [1, 2];
      }else if(filterData.score == 3){
        this.scoreCnt = [1, 2, 3];
      }else if(filterData.score == 4){
        this.scoreCnt = [1, 2, 3, 4];
      }else if(filterData.score == 5){
        this.scoreCnt = [1, 2, 3, 4, 5];
      }
      this.filterForm.controls.recruiterAction.setValue(filterData.recruiterAction);
      $("#sortFilter").addClass('isFilterApplied');
      $("#locationFilter").addClass('isFilterApplied');
      $("#scoreFilter").addClass('isFilterApplied');
    }
  }


  getUserLocations(){
    this.jobService.getUserLocations().subscribe((responseBody)=>{
      if (responseBody['success']) {
        this.locationOptions = responseBody['data']
        this.locationOriginalOptions = JSON.parse(JSON.stringify(responseBody['data']))
      } else {
        // this.toastr.error(responseBody['message']);
        this._snackBar.open(responseBody['message'], 'OK', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: 'style-error'
        });
      }
    })
  }
  recruiterAction(action) {
    this.filterForm.controls['recruiterAction'].setValue(action);
  }

  setSortType(type) {
    this.filterForm.controls['sortType'].setValue(type);
    this.isDisplay2 = true;
    $("#sortFilter").addClass('isFilterApplied');
  }

  setScoreCount(cnt){
    $("#scoreFilter").addClass('isFilterApplied');
    this.filterForm.controls['score'].setValue(cnt);
    if(cnt == 1){
      this.scoreCnt = [1];
    }else if(cnt == 2){
      this.scoreCnt = [1, 2];
    }else if(cnt == 3){
      this.scoreCnt = [1, 2, 3];
    }else if(cnt == 4){
      this.scoreCnt = [1, 2, 3, 4];
    }else if(cnt == 5){
      this.scoreCnt = [1, 2, 3, 4, 5];
    }
    this.isDisplay3 = true;
  }

  setLocation(location){
    this.filterForm.controls['location'].setValue(location);
    this.isDisplay5 = true;
    $("#locationFilter").addClass('isFilterApplied');
  }

  toggle2() {
    this.isDisplay2 = !this.isDisplay2;
  }

  toggle5() {
    this.isDisplay5 = !this.isDisplay5;
  }

  toggle3() {
    this.isDisplay3 = !this.isDisplay3;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getMessageStatus(x: any) {
    if (x.currentTarget.checked) {
      this.filterForm.controls.isMessageSent.setValue("Message Sent")
    } else {
      this.filterForm.controls.isMessageSent.setValue("Message Not Sent")
    }
  }

  apply(){
    var data = {
      sortType: this.filterForm.controls.sortType.value,
      location: this.filterForm.controls.location.value,
      score: this.filterForm.controls.score.value,
      recruiterAction: this.filterForm.controls.recruiterAction.value,
      isMessageSent: this.filterForm.controls.isMessageSent.value === "Message Sent" ? true : false,
      jobId : this.data.job_id,
      reset:0
    };

    localStorage.setItem('viewCandidateFilter', JSON.stringify(data));
    if(data.recruiterAction > 0)
      this.filterAppliedCnt = this.filterAppliedCnt + 1;
    
    if(data.score > 0)
      this.filterAppliedCnt = this.filterAppliedCnt + 1;

    if(data.location != "")
      this.filterAppliedCnt = this.filterAppliedCnt + 1;

    if(data.sortType != "")
      this.filterAppliedCnt = this.filterAppliedCnt + 1;

    this.dialogRef.close({ data: data, cnt: this.filterAppliedCnt });
    // this.jobService.getJobApplyersByFilter(data).subscribe((responseBody)=>{
    //   if (responseBody['success']) {
    //     this.toastr.success(responseBody['message']);
    //     responseBody['data']["filter"] = data;
    //     this.dialogRef.close({ data: responseBody['data'] });
    //   } else {
    //     this.toastr.error(responseBody['message']);
    //   }
    // })
  }

  reset(){
    // var requestBody = {
    //   job_id:this.data.job_id,
    //   rerecruiterAction: 0,
    // startRating: 0
    // }

    $("#sortFilter").removeClass('isFilterApplied');
    $("#locationFilter").removeClass('isFilterApplied');
    $("#scoreFilter").removeClass('isFilterApplied');

    localStorage.removeItem('viewCandidateFilter');

    this.filterAppliedCnt = 0
    var data = {
      sortType: "this.filterForm.controls.sortType.value",
      location: this.filterForm.controls.location.value,
      score: this.filterForm.controls.score.value,
      recruiterAction: this.filterForm.controls.recruiterAction.value,
      isMessageSent: this.filterForm.controls.isMessageSent.value === "Message Sent" ? true : false,
      jobId : this.data.job_id,
      reset:1
    };
    this.dialogRef.close({data: data, cnt: this.filterAppliedCnt});
    // this.jobService.getAllJobApplyer(requestBody).subscribe((responseBody)=>{
    //   if (responseBody['success']) {
    //     this.toastr.success(responseBody['message']);
    //     this.dialogRef.close({ data: responseBody['data'] });
    //   } else {
    //     this.toastr.error(responseBody['message']);
    //   }
    // })
  }

  filterLocations(event){
    console.log("In filter location")
    var value = event.target.value;

    if(value != null){

      if(value != ''){
        const filterValue = value.toLowerCase();
        const result = this.locationOptions.filter(function(option){
          return option !== null && option.toLowerCase().includes(filterValue)
        }).
        map(function (a) {
          return a;
        });
        this.locationOptions = result;
      }else{
        this.locationOptions = this.locationOriginalOptions;
      }
    }
  }

  filter2(value) {
    const filterValue = value.toLowerCase();
    this.locationOptions.filter(option => option !== null && option.toLowerCase().includes(filterValue));
  }
}
