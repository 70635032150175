<app-header></app-header>

<div class="container">
    <h4>Job Post</h4>
    <div class="card p-4">
        <div id="stepper1" class="bs-stepper">
            <div class="bs-stepper-header">
                <div class="step" data-target="#test-l-1">
                    <button class="step-trigger">
                        <span class="bs-stepper-circle">1</span>
                        <span class="bs-stepper-label">Step 1</span>
                    </button>
                </div>
                <div class="line"></div>
                <div class="step" data-target="#test-l-2">
                    <button class="step-trigger">
                        <span class="bs-stepper-circle">2</span>
                        <span class="bs-stepper-label">Step 2</span>
                    </button>
                </div>
            </div>
            <div class="bs-stepper-content">
                <form [formGroup]="jobPostForm">
                    <div id="test-l-1" class="content">
                        <div class="row pt-2">
                            <div class="col col-md-9">
                                <label>Job Title <span class="mandatory">*</span></label>
                                <input class="form-control" placeholder="Job Title" formControlName="jobTitle">
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('jobTitle').invalid && jobPostForm.get('jobTitle').touched">Enter
                                    Job Title.</small>
                            </div>

                            <div class="col col-md-3">
                                <label>Start Date <span class="mandatory">*</span></label>
                                <input class="form-control" type="date" placeholder="Job Start Date"
                                    formControlName="startDate">
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('startDate').invalid && jobPostForm.get('startDate').touched">Enter
                                    Start Date.</small>
                            </div>

                        </div>
                        <div class="row pt-2">
                            <div class="col col-md-6">
                                <label>Company Name <span class="mandatory">*</span> </label>
                                <input class="form-control" placeholder="Company Name" formControlName="company">
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('company').invalid && jobPostForm.get('company').touched">Enter
                                    Company Name.</small>
                            </div>
                            <div class="col col-md-6">
                                <label>Job Category <span class="mandatory">*</span></label>
                                <input class="form-control" placeholder="Job Category" formControlName="jobCategeory">
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('jobCategeory').invalid && jobPostForm.get('jobCategeory').touched">Enter
                                    Job Category.</small>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col col-md-6">
                                <label>Requirements <span class="mandatory">*</span></label>
                                <input class="form-control" placeholder="Job Requirements"
                                    formControlName="requirements">
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('requirements').invalid && jobPostForm.get('requirements').touched">Enter
                                    Requirements.</small>
                            </div>
                            <div class="col col-md-6">
                                <label>Qualification <span class="mandatory">*</span></label>
                                <input class="form-control" placeholder="Job Qualification"
                                    formControlName="qualification">
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('qualification').invalid && jobPostForm.get('qualification').touched">Enter
                                    Qualification.</small>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col col-md-6">
                                <label>Job Description <span class="mandatory">*</span></label>
                                <textarea class="form-control" rows="6" placeholder="Job description"
                                    formControlName="jobDescription"></textarea>
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('jobDescription').invalid && jobPostForm.get('jobDescription').touched">Enter
                                    Job Description</small>
                            </div>
                            <div class="col col-md-6">
                                <label>Benefit <span class="mandatory">*</span></label>
                                <textarea class="form-control" rows="6" placeholder="Job Benefit"
                                    formControlName="benifit"></textarea>
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('benifit').invalid && jobPostForm.get('benifit').touched">Enter
                                    Benefit.</small>
                            </div>
                        </div>
                        <div class="text-right">
                            <button (click)="next()" class="btn btn-primary">Next</button>
                        </div>
                    </div>
                    <div id="test-l-2" class="content">
                        <div class="row pt-2">
                            <div class="col col-md-4">
                                <label>Country <span class="mandatory">*</span></label>
                                <select class="form-control" formControlName="country" (change)="selectCountry()">
                                    <option value='' selected hidden disabled>select country
                                    </option>
                                    <option [value]='item.name' *ngFor="let item of countryList">{{item.name}}
                                    </option>
                                </select>
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('country').invalid && jobPostForm.get('country').touched">Select
                                    Country.</small>
                            </div>
                            <div class="col col-md-4">
                                <label>States/Union territories <span class="mandatory">*</span></label>
                                <select class="form-control" formControlName="state" (change)="selectState()">
                                    <option value='' selected hidden disabled>select States/Union territory
                                    </option>
                                    <option [value]='item.name' *ngFor="let item of stateList">{{item.name}}
                                    </option>
                                </select>
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('state').invalid && jobPostForm.get('state').touched">Select
                                    States/Union territories.</small>
                            </div>
                            <div class="col col-md-4">
                                <label>Location <span class="mandatory">*</span></label>
                                <select class="form-control" formControlName="location">
                                    <option value='' selected hidden disabled>select Location
                                    </option>
                                    <option [value]='item.name' *ngFor="let item of cityList">{{item.name}}
                                    </option>
                                </select>
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('location').invalid && jobPostForm.get('location').touched">Select
                                    location.</small>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col col-md-4">
                                <label>Notice Period <span class="mandatory">*</span></label>
                                <select class="form-control" formControlName="duration">
                                    <option value='' selected hidden disabled>select Notice Period
                                    </option>
                                    <option value='immediate joining'>Immediate joining
                                    </option>
                                    <option value='15 Days'>15 Days
                                    </option>
                                    <option value='1 Month'>1 Month
                                    </option>
                                    <option value='2 Month'>2 Month
                                    </option>
                                    <option value='3 Month'>3 Month
                                    </option>
                                </select>
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('duration').invalid && jobPostForm.get('duration').touched">Select
                                    Notice Period.</small>
                            </div>
                            <div class="col col-md-4">
                                <label>Minimum Salary <span class="mandatory">*</span></label>
                                <input class="form-control" placeholder="Enter Minimum Salary"
                                    formControlName="salaryMin">
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('salaryMin').invalid && jobPostForm.get('salaryMin').touched">Enter
                                    Minimum Salary.</small>
                            </div>
                            <div class="col col-md-4">
                                <label>Maximum Salary <span class="mandatory">*</span></label>
                                <input class="form-control" placeholder="Enter Maximum Salary"
                                    formControlName="salaryMax">
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('salaryMax').invalid && jobPostForm.get('salaryMax').touched">Enter
                                    Maximum Salary.</small>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col col-md-4">
                                <label>Min Experiance <span class="mandatory">*</span></label>
                                <input class="form-control" placeholder="Enter Min Experiance"
                                    formControlName="minExperiance">
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('minExperiance').invalid && jobPostForm.get('minExperiance').touched">Enter
                                    Min Experiance.</small>
                            </div>
                            <div class="col col-md-4">
                                <label>Max Experiance <span class="mandatory">*</span></label>
                                <input class="form-control" placeholder="Enter Max Experiance"
                                    formControlName="maxExperiance">
                                <small class="text-danger"
                                    *ngIf="jobPostForm.get('maxExperiance').invalid && jobPostForm.get('maxExperiance').touched">Enter
                                    Max Experiance.</small>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col col-md-4">
                                <label>Resume Required <span class="mandatory">*</span></label><br />
                                <input type="checkbox" formControlName="isResumeRequired">&nbsp;<span>Resume
                                    Required</span>
                            </div>
                            <div class="col col-md-4">
                                <label>Assessment Required<span class="mandatory">*</span></label><br />
                                <input type="checkbox" formControlName="isAssissmentRequired">&nbsp;<span>Assessment
                                    Required</span>
                            </div>
                        </div>


                        <div class="text-right">
                            <button type="submit" class="btn btn-primary mt-5" (click)="saveJob()">Save Job</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>