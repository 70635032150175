import { Component, OnInit,Input,Output,EventEmitter,ChangeDetectorRef,HostListener  } from '@angular/core';
import { JoblistService } from 'src/app/shared/service/joblist.service';
import { MatDialog } from '@angular/material/dialog';
import { Popup6Component } from '../popups/popup6/popup6.component';
import { PopupJobApplyer } from '../popups/popup_job_applyer/popup_job_applyer.component';
import { Router,ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FilterpopupComponent } from '../popups/filterpopup/filterpopup.component';


@Component({
  selector: 'app-applicant-details-list',
  templateUrl: './applicant-details-list.component.html',
  styleUrls: ['./applicant-details-list.component.scss']
})
export class ApplicantDetailsListComponent implements OnInit {
  public jobApplyers:any=[];
  public jobApplyer:any=[];
  applyfilter = false;
  public jobApplyersArray:any=[];
  
  public jobApplyersDetails:any=[];
  jobDetailDetails:any=[];
  originalsApplyer:any=[];
  public jobId:any;
  public resume_path:string=""; 
  public jobDetail:any;
  public activeIndex:number=0;
  public pageIndex:number=0;
  customeFilter:any[];
  
  public performAction:any=[];
  isCount:boolean=false;
  dayCounter:any='';
  url: string = "https://angular.io/api/router/RouterLink";
  urlSafe: SafeResourceUrl;
  jobTitle;
  filterCnt: any = 0;

  

  constructor(public sanitizer: DomSanitizer,private snapRoute : ActivatedRoute, private joblistService:JoblistService,public dialog: MatDialog,public chDRef:ChangeDetectorRef) {
   
  
    this.snapRoute.params.subscribe((paramsResponse)=>{
      console.log("paramsResponse:",paramsResponse['id']);
      this.jobId = paramsResponse['id'];
      this.getApplyersList(this.jobId);

    });
    
   }
   @Output() getMessage=new EventEmitter<any>();

  ngOnInit(): void {
  }

  ngOnDestroy(): void{
    localStorage.removeItem('viewCandidateFilter');
  }
  
  opentab(evt, tabname) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabname).style.display = "block";
    evt.currentTarget.className += " active";
  }

  getApplyersList(jobId,index:number=0){
      var requestBody = {
        job_id:jobId,
        rerecruiterAction: 0,
      startRating: 0
      }
    this.joblistService.getAllJobApplyer(requestBody).subscribe((responseBody)=>{
        if(responseBody['success']){
            this.originalsApplyer=[];
            this.jobApplyers=[];
            this.originalsApplyer = responseBody['data']['AllJobApplyer'];
            this.jobApplyersDetails['job_title']=responseBody['data']['job_details']['jobtitle'];
            this.jobTitle = responseBody['data']['job_details']['jobtitle'];
            for(let i=0; i<this.originalsApplyer.length;i++){
              if(this.originalsApplyer[i]['userDetails']){
                this.jobApplyers.push(this.originalsApplyer[i]);
              }
            }
            this.jobDetail = responseBody['data']['job_details'];
            if(this.jobDetail.active===false){
              const dialogRef = this.dialog.open(Popup6Component, {
                width: '600px',
                height: '360px',
                data: {
                  msg: "This job is already Deactivated.",
                  active:"activeStatus",
                  job_id:jobId,
                  flag:"deactiveJob"
                },
                backdropClass: 'backdropBackground'
              });
            }else{
                  if(this.jobApplyers.length==0){
                    const dialogRef = this.dialog.open(PopupJobApplyer, {
                      width: '600px',
                      height: '360px',
                      data: {
                        msg: "There are no applicants for this job yet. Please wait a couple of days.",
                        active:"activeStatus",
                        job_id:jobId,
                        flag:"norecord"
                      },
                      backdropClass: 'backdropBackground'
                    });
                    dialogRef.afterClosed().subscribe(result => {
                      console.log('The dialog was closed');
                    });
            
                }else{
                  this.isCount = true;
                }
            }
          this.jobApplyersArray = this.sliceIntoChunks(this.jobApplyers,10)
           
          this.jobApplyer = this.jobApplyersArray[0];
          console.log(this.jobDetail);
          this.getUserDetails(index);          
        }
    })
  }

  getUserDetails(index){
    console.log("index="+index);
    this.jobApplyersDetails=[];
    if(index > 9){
      if(this.jobApplyers != null && this.jobApplyers != undefined)
        this.jobApplyersDetails =  this.jobApplyers[index];
    }else{
      if(this.jobApplyer != null && this.jobApplyer != undefined)
        this.jobApplyersDetails =  this.jobApplyer[index];
    }
    
    console.log(" Applyers Details : ",this.jobApplyersDetails)

    this.jobTitle = this.jobDetail['jobtitle'];

    if(this.jobApplyers.length > 0){
      this.jobApplyersDetails['job_title']=this.jobDetail['jobtitle'];
    
      this.activeIndex=index
  
      var getdate = this.jobApplyersDetails.updated_on;
      
      var url = this.jobApplyersDetails['resume_path'];
      if(this.jobApplyersDetails['resume_path'].endsWith(".doc") || this.jobApplyersDetails['resume_path'].endsWith(".docx")){
        url = "http://view.officeapps.live.com/op/view.aspx?src="+ this.jobApplyersDetails['resume_path'];
      }

      this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(url);
      
      console.log(getdate);
      var date1 = new Date(this.jobApplyersDetails['created_on']);
      var date2 = new Date();
      var Difference_In_Time = date2.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      this.dayCounter=Math.round(Difference_In_Days);
    }else{
      this.jobApplyersDetails = null;
    }
    
    
      
  }

  updateStartRating(startRating,id,index){
   // var index=  this.activeIndex;
    var requestBody =  {
        "id":id,
        "startRating":startRating
        }
        console.log(requestBody)
    this.joblistService.updateRating(requestBody).subscribe((responseBody)=>{
      console.log("Applyer List:",responseBody)
      if(responseBody['success']){
        this.getApplyersList(this.jobId,index)
       // this.getUserDetails(index);
      }
  })
  }

  recruiterAction(id,action,index){
    //var index=  this.activeIndex;
    var requestBody =  {
        "id":id,
        "rerecruiterAction":action
        }
        console.log(requestBody)
    this.joblistService.updateRecruiterAction(requestBody).subscribe((responseBody)=>{
      console.log("Applyer List:",responseBody)
      if(responseBody['success']){
        this.getApplyersList(this.jobId,index);
        //this.getUserDetails(index);
      }
  })
  }

  filterByName(event){
    //console.log("Values of input :",event.target.value.length);
    let counter = 0;
    const filteredEmails = this.jobApplyers.filter((e) =>{
     if(e.userDetails){
          var matches = e.userDetails.firstName.toUpperCase().indexOf(event.target.value.toUpperCase()) >= 0 ? true : false;
          if (matches) {
            counter++;
              return e;
          }else{
            var matches1 = e.userDetails.lastName.toUpperCase().indexOf(event.target.value.toUpperCase()) >= 0 ? true : false;
            if(matches1){
              counter++;
              return e;
            }
          }
      }
    });
    // if(counter==0){
    //   this.jobApplyers=[];
    //    this.jobApplyersDetails=[];
    //    this.isCount=false;
    // }else{

    //   if(event.target.value.length!=0){
    //     if(filteredEmails.length!=0){
    //       this.jobApplyers = filteredEmails;
    //       this.getUserDetails(0);
    //     }else{
    //       this.jobApplyers = this.originalsApplyer;
    //       this.getUserDetails(0);this.getUserDetails(0);
    //     }
    //   }else{
    //     this.jobApplyers = this.originalsApplyer;
    //     this.getUserDetails(0);
    //   }
    //   this.isCount=true;
    // }
    if(counter==0){
    this.jobApplyer=[];
    this.jobApplyersDetails=[];
    this.isCount=false;
    }else{
      if(event.target.value.length!=0){
        if(filteredEmails.length!=0){
          this.jobApplyer = filteredEmails;
        }else{
          this.jobApplyer = this.jobApplyers;
        }
      }else{
        this.jobApplyer = this.jobApplyers;
      }

      this.isCount=true;

      this.getUserDetails(0);
    }
    
  }

  hireCandidate(id,job_id,status,user_id){
   // "6 Interns remaining to hire this job.If you want to increase number of interns "
    const dialogRef = this.dialog.open(Popup6Component, {
      width: '600px',
      height: '360px',
      data: {
        msg: "Are you sure you want to hire this candidate ?",
        id:id,
        job_id:job_id,
        active:status,
        user_id:user_id,
        flag:"hireUser"
      },
      backdropClass: 'backdropBackground'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      if(!result){
        this.getApplyersList(job_id);
      }
    });
  }

  openFilter(){
    this.getApplyersList(this.jobId);
    const dialogRef = this.dialog.open(FilterpopupComponent, {
      width: '600px',
      height: '360px',
      data: {
        job_id:this.jobId,
      },
      backdropClass: 'backdropBackground'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log('data', result)
      if(result != null && result != undefined){
        this.filterOnjobApplyer(result.data)
        this.customeFilter = result.data;
        this.filterCnt = result.cnt;
      }
      
        // if(result != null && result != undefined){
        //   this.originalsApplyer=[];
        //   this.jobApplyers=[];
        //   this.jobApplyersDetails = [];
  
        //   this.originalsApplyer = result.data['AllJobApplyer'];
        //   this.jobApplyersDetails['job_title']=result.data['job_details']['jobtitle'];
        //   this.jobTitle = result.data['job_details']['jobtitle'];
        //   for(let i=0; i<this.originalsApplyer.length;i++){
        //     if(this.originalsApplyer[i]['userDetails']){
        //       this.jobApplyers.push(this.originalsApplyer[i]);
        //     }
        //   }
        //   this.jobDetail = result.data['job_details'];
         
        //   if(this.jobApplyers.length > 0){
        //     this.isCount = true;
        //   }
          
        //   console.log(this.jobDetail);
        //   this.getUserDetails(0);
        // }
          
    });
  }
//==================Pagination operation============================
  public getServerData(event){    
    this.pageIndex=event.pageIndex
    console.log(event);    
    this.jobApplyer = this.jobApplyersArray[event.pageIndex]
    this.getUserDetails(event.pageIndex*10)
    this.activeIndex = 0    
       
  }
//==================chunk data ===================================
  public sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
} 

//==================Main filter===================================
public filterOnjobApplyer(filter) { 

if(filter.reset == 1){
  this.getApplyersList(this.jobId);
  this.applyfilter = false;
  return;
}
this.applyfilter = true;

  console.log(filter.sortType)
  if(filter.score != ""){
    this.filterByScore(filter.score)
  }
  if(filter.recruiterAction != 0){
    this.filterByrecruiterAction(filter.recruiterAction)
  }
  
  if(filter.sortType != ''){
    this.jobApplyerSort(filter.sortType);
  }

  if(filter.location != ''){
    this.filterByLocation(filter.location);
  }
  
} 
//==================short filter===================================
jobApplyerSort(sortType){
  switch(sortType){
    case "A-Z":  
      this.jobApplyers.sort((a,b) => a.userDetails.firstName.toUpperCase() > b.userDetails.firstName.toUpperCase() ? 1 : -1)         
      break;
    case "Z-A":  
     this.jobApplyers.sort((a,b) => a.userDetails.firstName.toUpperCase() < b.userDetails.firstName.toUpperCase() ? 1 : -1)         
     break;
    case "Newest":  
     this.jobApplyers.sort((a,b) => a.id < b.id ? 1 : -1)         
     break;
    case "Oldest":  
     this.jobApplyers.sort((a,b) => a.id > b.id ? 1 : -1)         
     break;
  }
  this.jobApplyersArray = this.sliceIntoChunks(this.jobApplyers,10)
  let obj={previousPageIndex: 0, pageIndex: 0, pageSize: 10, }
  this.getServerData(obj);
}
//==================Score filter===================================
filterByScore(score){
  //console.log("Values of input :",event.target.value.length);
  let counter = 0;
  console.log(this.jobApplyer)
  const filteredscore = this.jobApplyers.filter((e) =>{
   if(e.userDetails){
        
        //var matches = e.startRating == score ? true : false;
        //console.log(e.startRating+"========="+score);
        if(score >= 10){
                 
          if (e.startRating >= score/10) {
            counter++;
              return e;
          }
          
        }else{
          if (e.startRating == score) {
            counter++;
              return e;
          }
        }
         
    }
  });
  console.log("counter  ="+counter)
  if(counter > 0){
    this.jobApplyers =  filteredscore;
    this.jobApplyer =  this.jobApplyers
    this.getUserDetails(0);
  }else{
    this.jobApplyers =  [];
    this.jobApplyersDetails=[];
    this.isCount=false;
  }
  this.jobApplyer =  this.jobApplyers
  
}

//==================Recruiter Action filter===================================
filterByrecruiterAction(recruiterAction){
  console.log("recruiterAction  of input :",recruiterAction  );
  let counter = 0;
  console.log(this.jobApplyer)
  const filteredscore = this.jobApplyers.filter((e) =>{
   if(e.userDetails){        
        //var matches = e.startRating == score ? true : false;
        if (e.rerecruiterAction == recruiterAction) {
          counter++;
            return e;
        } 
    }
    this.jobApplyer =  this.jobApplyers
  });  
  
  if(counter > 0){
    this.jobApplyers =  filteredscore;
    this.jobApplyer =  this.jobApplyers
    this.getUserDetails(0);
  }else{
    this.jobApplyers =  [];
    this.jobApplyersDetails=[];
    this.isCount=false;
  }
  this.jobApplyer =  this.jobApplyers  
  
}
//==================location filter===================================
filterByLocation(location){
  console.log("Location filter= :",location);
  let counter = 0;
  console.log(this.jobApplyer)
  const filteredscore = this.jobApplyers.filter((e) =>{
   if(e.userDetails){
        console.log("itrator  ="+e.startRating)
        //var matches = e.startRating == score ? true : false;
        if (e.userDetails.location.toUpperCase() == location.toUpperCase()) {
          counter++;
            return e;
        } 
    }
    this.jobApplyer =  this.jobApplyers

  });

  if(counter > 0){
    this.jobApplyers =  filteredscore;
    this.jobApplyer =  this.jobApplyers
    this.getUserDetails(0);
  }else{
    this.jobApplyers =  [];
    this.jobApplyersDetails=[];
    this.isCount=false;
  } 
  this.jobApplyer =  this.jobApplyers 
}
}
