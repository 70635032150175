import { Component, Inject, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
  selector: 'app-popup31',
  templateUrl: './popup31.component.html',
  styleUrls: ['./popup31.scss']
})
export class Popup31 implements OnInit {
  public popupMessage:String= "No More Applyer found for this job";
  constructor(public router:Router, public dialogRef: MatDialogRef<Popup31>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
    this.router.navigate(['home'])
    
  }

  createComapny(): void {
    this.dialogRef.close();
    this.router.navigate(['/company/createCompany'])
    
  }
  ngOnChanges():void{
      // this.popupMessage=this.getMessage;
      console.log("Popup message : ",this.data)

  }
  ngOnInit(): void {
  }

}
