import { Component, OnInit } from '@angular/core';

import { NetworkApiService } from './../network-api.service';
import { ProfileService } from '../../profile/profile.service';  

@Component({
  selector: 'app-follow',
  templateUrl: './follow.component.html',
  styleUrls: ['./follow.component.scss']
})
export class FollowComponent implements OnInit {
  followingList: any = [];
  followerList: any = [];
  selectedType: number = 1;
  userId: number;
  userInfo;
  constructor(
    private profileService: ProfileService,
    private networkService: NetworkApiService
  ) { }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('user')); 
    this.userId =  this.userInfo.id;
    this.selectType(this.selectedType)
  }
  selectType(type) {
    this.selectedType = type;
    switch (this.selectedType) {
      case 1:
        this.getFollowerList();
        break;
      case 2:
        this.getFolloweingList();
        break;
    }
  }


  getFollowerList() {
    this.networkService.getFollowers(this.userId).subscribe(res => {
      if (res.success) {
        this.followerList = res.data
      }
    })
  }
  getFolloweingList() {
    this.networkService.getFollowing(this.userId).subscribe(res => {
      if (res.success) {
        this.followingList = res.data
      }
    })
  }


  /* ================ Request ================ */
   
  sendFollowBack(item, index) {
    item.connected =1 
    this.profileService.confirmRequest(item).subscribe(res => {      
      if (res.success) {   
       console.log("success")
       console.log(index)
      }else{
        item.connected =0 
      }})
  }



  // sendFollowBack(item, index) {
  //   let obj = {
  //     connectedUserId: item.userId,
  //     isFollowed: 'true',
  //     userId: this.userId
  //   }
  //   this.networkService.followRequest(obj).subscribe(res => {
  //     if (res.success) {
  //       this.followerList[index].isFollowed = 'true';
  //     }
  //   })
  // }
// delete the connection
unfollowRequest(item, index) {     
  this.profileService.deleteConnection(item.id).subscribe(res => {
    if (res.success) {        
      item.connected =1 
      this.getFolloweingList();
     }       
  })
}

getName(firstName,lastName)
{
  let fullName;
  if(firstName != null){
    fullName = firstName;
  }
  if(lastName != null){
    fullName = firstName+" "+lastName
  }
  fullName = (fullName.length > 15)?fullName.substr(0,15)+"...":fullName;
  return fullName 
}
}
