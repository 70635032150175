 
<div>
     <!-- <select title="Pick a number"  [(ngModel)]="title"   class="selectpicker border-0" style="font-family: 'poppins';
     margin-bottom: 20px;">
        
        <option selected value="Weekly Revenue">Weekly Revenue</option>
    <option value="Weekly Expenses">Weekly Expenses</option>      
    <option value="Weeks to Product Product Launch">Weeks to Product Product Launch</option>
    <option value="Number of Customers talked to">Number of Customers talked to</option>   
    </select> -->
     
    <div class="tooltips5 wd3-19"   >
        <div class="mb-3" (click)="InvestorTicket()" style="display: flex;cursor: pointer;">
            <!-- <div class="aaa"><img *ngIf="!isJobLocationFilterAppiled" class="iconText"  src="assets/icons/ticket-alt.svg" alt="" /> </div> -->
            <span id="location" class="title-name">{{title}}&nbsp;</span> 
            <img *ngIf="!isJobLocationFilterAppiled" src="assets/chevron-down.svg" class="img-css" [ngClass]="{'img-css-i' : !isInvestorTicket}" (click)="InvestorTicket()" alt="">
            <div class="filter-value"> </div>
          </div>
        
        <span class="bropBoxCustom" [hidden]="isInvestorTicket">
          <ul class="dropDwonList mb-0" style="color:#009cc3" >
            <li style="cursor: pointer;" (click)="changeGraph('Weekly Revenue')">Weekly Revenue</li>
            <li style="cursor: pointer;"(click)="changeGraph('Weekly Expenses')">Weekly Expenses</li>
            <li style="cursor: pointer;"(click)="changeGraph('Weeks to Product Product Launch')">Weeks to Product Product Launch</li>
            <li style="cursor: pointer;"(click)="changeGraph('Number of Customers talked to')">Number of Customers talked to</li>                                     
          </ul>          
        </span>
       </div>
     


    <input id="yAxis" class="form-control" style="width:40%;"   (keyup)="updateChart()"  placeholder="Write a value .. Current Week" [(ngModel)]="dataValue" /> &nbsp;
                   
    <select hidden title="Pick a number" [(ngModel)]="chartType" (ngModelChange)="updateChart()" class="selectpicker border-0">
        <option disable> Chart</option>
        <option value="bar">BAR</option>
        <option value="line">Line</option>
        <option value="pie">Pie</option>
    </select>        
    <canvas   [id]="myChart" id="myChart"></canvas>
    <button _ngcontent-uik-c131="" data-toggle="modal" (click)="saveChart()"  class="btn CreateBtn mt-0 mb-0" ng-reflect-ng-class="[object Object]">Create</button>
     <img style="display: none;" [src]="chartImg" />
     
  </div>
  