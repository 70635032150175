import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { ProfileService } from '../../profile.service';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'skill-detail',
  templateUrl: './skill-detail.component.html',
  styleUrls: ['./skill-detail.component.scss']
})
export class SkillDetailComponent implements OnInit {
  @Input() userInfo: any;
  @ViewChild('closebutton') closebutton;

  skillList: any = [];
  skill: string;
  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getSkills();
  }
  getSkills() {
    this.route.queryParams.subscribe(param => {   
      this.profileService.getSkills(param.visit).subscribe(res => {
        if (res.success) {
          this.skillList = res.data;    
          console.log(res.data)                      
        }
      })
    })
  }

addSkillbyEnter(){
    const skillInput = document.getElementById('skillInput');
    skillInput.addEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
      this.addSkill()
      this.skill = ""
    }
});
   
 
  }
  addSkill() {
    if (this.skill != "") {
      let obj = {
        
        skillName: this.skill,
        user: this.userInfo
      }
      this.profileService.addSkill(obj).subscribe(res => {
        if (res.success) {
          this._snackBar.open(res.message, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'style-success'
          });
          this.skillList.push(res.data);
          this.skill = ""; 
          this.closebutton.nativeElement.click();
        } else {
          // this.toastr.error(res.message);
          this._snackBar.open(res.message, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'style-error'
          });
        }
      })
    }
  }
  addSkills() {
    if (this.skill != "") {
      let obj = {
        skillName: this.skill,
        userId: this.userInfo.id
      }
      this.profileService.updateSkill(obj).subscribe(res => {
        if (res.success) {
          this._snackBar.open(res.message, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'style-success'
          });
          this.skillList.push(res.data)
          this.skill = ""
        } else {
          // this.toastr.error(res.message);
          this._snackBar.open(res.message, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'style-error'
          });
        }
      })
    }
  }

  deleteSkill(item, index) {
    this.profileService.deleteSkill(item.id).subscribe(res => {
      if (res.success) {
        // this.toastr.success(res.message);
        this._snackBar.open(res.message, 'OK', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: 'style-success'
        });
        this.skillList.splice(index, 1);
      }
    })
  }
}
