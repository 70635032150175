import { Component, OnInit } from '@angular/core';
import { MatBottomSheet} from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Popup13Component } from '../popups/popup13/popup13.component';
import { JoblistService } from 'src/app/shared/service/joblist.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-job-apply-bottom-sheet',
  templateUrl: './job-apply-bottom-sheet.component.html',
  styleUrls: ['./job-apply-bottom-sheet.component.scss']
})
export class JobApplyBottomSheetComponent implements OnInit {
  public jobDetails:any;
  jobText: any;
  jobShareUrl: string;
  appUrl = environment.appUrl;
  constructor(private _bottomSheet: MatBottomSheet,private router: Router, private dialog:MatDialog,private jobservice:JoblistService,
    private toastrMsg:ToastrService, private _snackBar: MatSnackBar,
    private route : ActivatedRoute) { 
   // this.toastrMsg.success("Successful...!");
  }

  ngOnInit(): void {
    // this.jobDetails = this.jobservice.getJobDetails()
    // console.log("jobDetails : ",this.jobDetails)

    var jobId = this.route.snapshot.paramMap.get('id')

    if(jobId != null) {
      this.getJobDetails();
    }else{
      this.jobDetails = this.jobservice.getJobDetails()
      this.jobDetails['jobShareUrl']=this.makeLink(this.jobDetails);
    }
  }

  getJobDetails(){
    var jobId = this.route.snapshot.paramMap.get('id')
    var requestBody ={
      id:jobId
    }
    if(jobId != null && parseInt(jobId)!=0){
      this.jobservice.getJob(requestBody).subscribe(responseBody =>{
        if(responseBody['success']){
          console.log("Data :",responseBody['data'])
          responseBody['data']['jobShareUrl']=this.makeLink(responseBody['data']);
          this.jobDetails = responseBody['data'];
        }
      });
    }else{

    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(Popup13Component, {
      // width: '250px',
      // data: {name: this.name, animal: this.animal},
      backdropClass: 'backdropBackground'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  close() {
     this._bottomSheet.dismiss();
}


   addToSaveList(jobId,status,index){
    var requestBody = {
      'job_id':jobId,
      'is_active':status,
      "user_id": parseInt(localStorage.getItem('member')).toString(),
    }
      this.jobservice.addToSaveList(requestBody).subscribe((responseBody)=>{
        if (responseBody['success']) {
          // this.toastrMsg.success(responseBody['message']);
          this._snackBar.open(responseBody['message'], 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'style-success'
          });
          this.jobDetails['is_job_saved']=status
        } else {
          // this.toastrMsg.error(responseBody['message']);
          this._snackBar.open(responseBody['message'], 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'style-error'
          });
        }
      })
    }

    makeLink(item){
      this.jobText = item.jobtitle;

      if(this.jobText != null){
        // let result = this.jobText.link(this.appUrl +"jobs/job-list-details/" + item.id);
        let result = this.appUrl +"jobs/job-list-details/" + item.id;
        this.jobShareUrl = result;
      }

      return this.jobShareUrl;
    }

    linkCopy(){
      // this.toastrMsg.success("Linked copied successfully to clipboard.");
      this._snackBar.open("Linked copied successfully to clipboard.", 'OK', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'style-success'
      });
    }
}
