import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';
import { JobsModule } from './../jobs/jobs.module';
import { LeftPanelComponent } from './left-panel/left-panel.component';
import { NgModule } from '@angular/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { PostPanelComponent } from './post-panel/post-panel.component';
import { ProfileService } from '../profile/profile.service';
import { SharedModule } from '../../shared/shared.module';
import { WritePostComponent } from './post-panel/write-post/write-post.component';
import { ActivestartupsComponent } from './activestartups/activestartups.component';
import { NewsComponent } from './news/news.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AddressBookHomeComponent } from './address-book-home/address-book-home.component';
import { ChartComponent } from './post-panel/chart/chart.component';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';

@NgModule({
  declarations: [HomeComponent, 
    LeftPanelComponent, 
    PostPanelComponent, 
    WritePostComponent, 
    ActivestartupsComponent, 
    NewsComponent, 
    AddressBookHomeComponent, ChartComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    PickerModule,
    CKEditorModule,
    NgxPhotoEditorModule
  ],
  exports:[LeftPanelComponent , NewsComponent , ActivestartupsComponent],
  providers: [ProfileService]
})
export class HomeModule { }
