import * as $ from 'jquery'

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AccountApiService } from './../../../account/account-api.service';
import { ProfileService } from '../../profile.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { common } from 'src/app/shared/common/validator';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-experiance',
  templateUrl: './experiance.component.html',
  styleUrls: ['./experiance.component.scss']
})
export class ExperianceComponent implements OnInit {
  @Input() userInfo: any;
  public experianceForm: UntypedFormGroup;
  type: number;
  monthList: any = common.month;
  dayList: any = common.day;
  stateList: any = common.state;
  industryList: any = common.industries;
  yeaList: any = common.years();
  searchList: any = [];
  showList: number = 0
  experianceList: any = [];
  selectOtherCompany: String;
  index: number;
  isWorking = true;
  dummyIcon = "https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/unversity.png"
  today = moment(new Date()).format('yyyy-MM-DD');
  constructor(
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private accountService: AccountApiService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.initializeExpForm();
    if (this.userInfo.typeOfUser == 1 || this.userInfo.typeOfUser == 2) {
      this.type = 1
    } else {
      this.type = 2
    }
    this.getExperiance();
   // this.experianceList = this.userInfo.experience;

  }

  getExperiance() {
    this.route.queryParams.subscribe(param => {   
      this.profileService.getExperiance(param.visit).subscribe(res => {
        if (res.success) {
          this.experianceList = res.data;    
          console.log(res.data)                      
        }
      })
    })
  }

  /* =============== Experiance Form ============================*/
  initializeExpForm() {
    this.experianceForm = this.fb.group({
      company: [''],
      otherCompany: [''],
      companyName: ['', [Validators.required]],
      companyLogo: ['' ],
      title: ['', [Validators.required]],
      // userId: ['', [Validators.required]],
      endDate: ['',  ],
      startDate: ['', [Validators.required]],
      jobLocation: ['', [Validators.required]],
      isWorking: [false],
      endMonth: [''],
      endYear: [''],
      description: [''],
      id: [''],
      employmentType:['',[Validators.required]]
    })
  }
  onsetValue() {
    this.experianceForm.patchValue({
      companyLogo: this.dummyIcon,
      userId: this.userInfo.id
    })
  }
  selectItem(item) {
    let company= null;
    let otherCompany= null;
    if(Object.keys(item).length != 3){
     company = item; 
    }else{
      otherCompany = item;
    }
    
    this.experianceForm.patchValue({
      companyId: item.id,
      companyName: item.name,
      companyLogo: item.logo,
      company:company,
      otherCompany:otherCompany
    
    })
   console.log(company)
   console.log(otherCompany)
    //alert( Object.keys(item).length)
   // this.selectOtherCompany = item.name;
  }

  selectedCity(item) {
    this.experianceForm.patchValue({
      jobLocation: item.name,
    })
  }

  expSubmit() {
    // if(this.experianceForm.value.companyName != this.selectOtherCompany){
    //   this.saveOtherCompany();     
    // }

    this.experianceForm.markAllAsTouched();
    // this.onsetValue()
    if (true || this.experianceForm.valid) {
      if(this.experianceForm.value.companyName != null && this.experianceForm.value.company == null && this.experianceForm.value.otherCompany == null){
        this.experianceForm.value.otherCompany ={name:this.experianceForm.value.companyName}
      }
      
      if(this.experianceForm.valid){
        let obj = {
          company: this.experianceForm.value.company,
          otherCompany:this.experianceForm.value.otherCompany,
          companyName: this.experianceForm.value.companyName,
          companyLogo: this.experianceForm.value.companyLogo,
          description: this.experianceForm.value.description,
          jobLocation: this.experianceForm.value.jobLocation,
          endMonth: this.experianceForm.value.endMonth,
          endYear: this.experianceForm.value.endYear,
          isWorking: this.experianceForm.value.isWorking,
          startMonth: this.experianceForm.value.startMonth,
          startYear: this.experianceForm.value.startYear,
          title: this.experianceForm.value.title,
          employmentType:this.experianceForm.value.employmentType,
          endDate: this.experianceForm.get('endDate').value != 'Invalid date' ? this.experianceForm.value.endDate : null,
          startDate: this.experianceForm.get('startDate').value != 'Invalid date' ? this.experianceForm.value.startDate : null,
          user: this.userInfo,
          id: this.experianceForm.value.id,
        }
        this.profileService.updateExperiance(obj).subscribe(res => {
          if (res.success) {
            this.getExperiance();
            //debugger;
            // this.toastr.success(res.message);
            this._snackBar.open(res.message, 'OK', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: 'style-success'
            });
            // if (this.index != null || this.index != undefined) {
            //   this.experianceList.splice(this.index, 1);
            //   this.experianceList.unshift(res.data)
            // } else {
            //   this.experianceList.unshift(res.data)
            // }
             this.experianceForm.reset();
            this.index = null;
            $("#experianceModel .close").click();
          } else {
            // this.toastr.error(res.message);
            this._snackBar.open(res.message, 'OK', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: 'style-error'
            });
          }
        })
      }

    }
  }
  /* ============================== selected Item ===================== */

  selecetedItem(item, index) {
    let company;
    if(item.company != null){
      company = item.company
    }
    else{
      company = item.otherCompany
    }
    
    $("#exampleCheck1").prop('checked', true);
    this.experianceForm.patchValue({      
      companyId: company.id,
      companyLogo: company.logo,
      companyName: company.name,
      company: company,
      description: item.description,
      endDate: moment(item.endDate).format('yyyy-MM-DD'),
      startDate: moment(item.startDate).format('yyyy-MM-DD'),
      id: item.id,
      isWorking: item.isWorking,
      jobLocation: item.jobLocation,
      employmentType: item.employmentType,
      startYear: item.startYear,
      title: item.title,
      userId: item.userId
    })
    this.index = index;
  }

  /* =============== Input Focus ============================ */
  onFocus(value) {
    switch (value) {
      case 4:
        this.showList = 4
        break;
      case 5:
        this.showList = 5
        break;
    }
  }

  onBlur() {
    setTimeout(() => {
      this.showList = 0
      this.searchList = [];
    }, 500)
  }
  search(event) {
    switch (this.showList) {
      case 4:
        this.getAllCompanyList(event.target.value)
        break;
      case 5:
        if (event.target.value.length > 2) {
          this.getCityList(event.target.value)
        }
        break;
    }
  }


  deleteExp(item, index) {
    this.profileService.deleteExperiance(item.id).subscribe(res => {
      if (res.success) {
        // this.toastr.success(res.message);
        this._snackBar.open(res.message, 'OK', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: 'style-success'
        });
        this.experianceList.splice(index, 1);
      }
    })
  }
  newOpen() {
    this.experianceForm.reset();
    this.index = null;
  }
  /* =========== Company List =========== */
  getAllCompanyList(keyWord) {
    this.accountService.getAllCompanyList(keyWord).subscribe(res => {
      if (res.success) {
        this.searchList = res.data;
      }
    })
    this.accountService.getAllOtherCompanyList(keyWord).subscribe(res => {
      if (res.success) {
        var i;
         for(i=0;i<res.data.length;i++){
          console.log("other data="+res.data[i])
          res.data[i].logo = this.dummyIcon;
          this.searchList.push(res.data[i])
         }
        
      }
    })
  }
  addMore(){    
    this.experianceForm.reset(); 
  }

  getCityList(keyWord) {
    this.accountService.getCityList(keyWord).subscribe(res => {
      if (res.success) {
        this.searchList = res.data;
      }
    })
  }

  saveOtherCompany() {
    var obj = {
      name: this.experianceForm.value.companyName,     
    }
    this.accountService.saveOtherCompany(obj).subscribe(res => {
      if (res.success) {
        this.experianceForm.patchValue({
          companyId: res.data.id,
          companyName: obj.name,
          companyLogo: this.dummyIcon,
          userId: this.userInfo.id,
        })  
        this.selectOtherCompany = res.data.name; 
        this.expSubmit()            
        return true;
      }     
    })  
    
  }
}
