<section class="background">

    <div class="popup">
        <div class="cross">x</div>
        <div class="content">
            Do you want to activate this perk?
        </div>
        <div class="buttons">
            <button class="yes">Yes</button>
            <button class="no">No</button>
        </div>
    </div>

</section>