<div class="skillCard p-4">
    <div class="tabDataSkill">
        <div class="row">
            <div class="col-12 pl-0 pr-0">
                <div class="experianceTitle">Skill <sup><img *ngIf="userInfo?.isOwener" data-target="#skillModel"
                    data-toggle="modal" src="assets/icons/pencil-square.svg"/></sup></div>
            </div>
            <div class="col-12 pl-0 pr-0">
            <!-- <div class="univercityName"> <img src="assets/img/Group1.svg"/> Bolstart (2017-2022)</div>
            <div class="faculty"> 
                <div class="pinIcon"><img src="assets/icons/pinTower.png"/>
                </div> 
                <div class="w-80"><span class="semoBold">Founder & CEO</span></div>
            </div> -->
            <div class="descBio" *ngFor="let item of skillList;let i = index">
                <!-- <i class="fas fa-check-square">{{item.skillName}}</i>  -->
                <span>{{item.skillName}}</span> 
                <span *ngIf="userInfo?.isOwener" class="add-trash deleteIcon"><i class="fa fa-trash"
                    (click)="deleteSkill(item,i)"></i></span>


            </div>
            <!-- <div class="descBio"><i class="fas fa-check-square"></i> Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</div>
            <div class="descBio"><i class="fas fa-check-square"></i> Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</div>
            <div class="descBio"><i class="fas fa-check-square"></i> Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</div> -->
            </div>
           
            
        </div>
        </div>
    <!-- <div class="row">
        <div class="col-11 col-xs-10">
            <h5>Skill</h5>
        </div>
        <div class="col-1 col-xs-2">
            <i *ngIf="userInfo?.isOwener" class="fa fa-plus-circle fa-2x" aria-hidden="true" data-target="#skillModel"
                data-toggle="modal"></i>
        </div>
    </div> -->
    <!-- <ul>
        <li *ngFor="let item of skillList;let i = index">{{item.skillName}}
            <span *ngIf="userInfo?.isOwener" class="add-trash deleteIcon"><i class="fa fa-trash"
                    (click)="deleteSkill(item,i)"></i></span>
            <hr>
        </li>

    </ul> -->
</div>
<!-- ================  Experience Model  ======================= -->
<div class="skillModel">
<div class="modal" id="skillModel" role="dialog">
    <div class="modal-dialog">

        <div class="modal-content p-3">
            <div class="modal-Custheader p-2">
                <button #closebutton type="button" class="close" data-dismiss="modal">&times;</button>
                <h6 class="modal-title">Add Skill</h6>
            </div>
            <div class="modal-body text-center">
                <input id="skillInput" class="form-control" (keyup)="addSkillbyEnter()" placeholder="Write a skill .. And press enter" [(ngModel)]="skill" /> &nbsp;
                <!-- <i class="fa fa-plus fab-add" (click)="addSkill()"></i> -->
                <button type="submit" class="btn modelBtn" (click)="addSkill()">Add</button>
            </div>
            <!-- <div class="text-right p-2">
                <button type="submit" class="btn btn-outline-primary" data-dismiss="modal">Submit</button>
            </div> -->
        </div>
    </div>
</div>
</div>