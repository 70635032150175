 
import { AwsService } from 'src/app/shared/AWS-Service/aws.service';

export default class Adapter {
    loader;
    reader;
    image_path:any = null
    config;
    callingObject;    
    awsService
    constructor(loader, config,callingObject,) {
      this.loader = loader;
      this.config = config;
      this.callingObject = callingObject;
      

    }
    
  
  
  public async upload(): Promise<any> {
    const value = await this.loader.file;
    console.log(value);
    
    // this.image_path = this.callingObject.ckenditorImage(value)         
    return this.callingObject.ckenditorImage(value)//this.read( value,this);
 
    }
  
    read(file,obj) {
      console.log("red function",obj.image_path);
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
  
        reader.onload = function () { 
          resolve({ default: obj.image_path});
          
        };
  
        reader.onerror = function (error) {
          reject(error);
        };
  
        reader.onabort = function () {
          reject();
        };
        reader.readAsDataURL(file);
      });
    }
  
    abort() {
      alert()
      if (this.reader) {
        this.reader.abort();
      }
    }
  }
  
  