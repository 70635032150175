import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PresenceService } from 'src/app/components/messaging/presence.service';

@Component({
  selector: 'app-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss']
})
export class ProfilePhotoComponent implements OnInit {

  @Input() user;

  presence$;

  constructor(private presence: PresenceService, private router: Router) { }

  ngOnInit() {
    this.presence$ = this.presence.getPresence(this.user.uid);
  }

  ngOnChanges() {
    this.presence$ = this.presence.getPresence(this.user.uid);
  }

  selectUser(id) {
    this.router.navigate(['../profile/timeline'], { queryParams: { visit: id } });
  }
}
