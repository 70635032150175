<div class="card p-2 min-heightBox">
    <div class="row lineBtn" >
        <div class="col-md-8 pr-0">
            <div class="lineBtn"> <div class="border-full"></div>&nbsp;&nbsp;&nbsp;<img src="../../../../../assets/icons/boltFavicon.png" style="width: 15px;height:15px;"/></div>
           
        </div>
        <div class="col-md-4">
            <button type="button" class="getStart">Get Started With Bolstart</button>
        </div>
    </div>
    <!-- <div class="text-center">
        <ul class="inline" style="border-bottom: 1px solid darkgray;">
            <li (click)="selectType(1)" [ngClass]="type==1?'selected':null"><i class="fa fa-pencil-square-o"></i>
                &nbsp;Share Post</li>
            <li (click)="selectType(2)" [ngClass]="type==2?'selected':null"><i class="fa fa-bar-chart"></i>&nbsp;Updates
            </li>
            
        </ul>
    </div> -->
    <div class="container mt-0" *ngIf="isStudent">
    <!-- <div class="inline" *ngIf="isStudent"> -->
    <div class="inline">
        <!-- <button data-toggle="modal"  data-target="#chartModel" type="button"  >Open Modal</button> -->
        <!-- <button (click)="mohit()" type="button"  >Open Modal</button>---->
        <!-- <button type="button" data-toggle="modal" data-target="#myModal">questionModel Modal</button>   -->
        <img  class="profile border-0" src="../../../../../assets/icons/writePost.svg" /> &nbsp;
        <input type="text" class="form-control" (click)="onOpenModel()" placeholder="Write a post...">
        <div class="inputImages">
       <img class="profile border-0" src="../../../../../assets/icons/jpgAttachment.png" />  
       <img class="profile border-0" src="../../../../../assets/icons/smile.png" />     
        <img class="profile border-0" src="../../../../../assets/icons/userInput.png" />        
        <img class="profile border-0" src="../../../../../assets/icons/attachment.png" />
        </div>
    </div>

    </div>
</div>


<!-- Modal -->
<div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content modal-content-py">
            <div class="modal-header border-0">
                <h5 class="modal-title" id="exampleModalLongTitle">&nbsp;</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <!-- <div class="row">
                    <div class="col-md-12 borderBottom">
                        <div class="centerBoldTitle text-center">Address Book</div> 
                        <img class="iconBorder"  src="../../../../../assets/img/Group1.svg" />
                    </div>
                    <div class="col-md-5">
                        <div class="d-flex align-items-center">
                        <div class="col-md-5 pl-0 pr-0">
                            <input type="text" placeholder="Group Name" class="form-control mb-0">
                        </div>
                        <div class="col-md-7">
                            <button type="button" class="btn createBtn mt-0 mb-0" >Create</button>
                        </div>
                        </div>
                        <div class="col-md-12">
                            
                            <div class="row">
                                <div class="col-md-12 px-2">
                                     <div class="groupListTitle">Groups</div>
                                </div>
                                <div class="col-md-4 px-2">
                                    <div class="mark1 markActive">Mark 1 <img class="btnIconwidth" src="../../../../../assets/icons/pencil-square.svg"/></div>
                                </div>
                                <div class="col-md-4 px-2">
                                    <div class="mark1">Mark 2 <img class="btnIconwidth" src="../../../../../assets/icons/pencil-square.svg"/></div>
                                </div>
                                <div class="col-md-4 px-2">
                                    <div class="mark1">Mark 3 <img class="btnIconwidth" src="../../../../../assets/icons/pencil-square.svg"/></div>
                                </div>
                                <div class="col-md-4 px-2">
                                    <div class="mark1">Mark 4 <img class="btnIconwidth" src="../../../../../assets/icons/pencil-square.svg"/></div>
                                </div>
                                <div class="col-md-4 px-2">
                                    <div class="mark1">Mark 5 <img class="btnIconwidth" src="../../../../../assets/icons/pencil-square.svg"/></div>
                                </div>
                                <div class="col-md-4 px-2">
                                    <div class="mark1">Mark 6 <img class="btnIconwidth" src="../../../../../assets/icons/pencil-square.svg"/></div>
                                </div>
                                <div class="col-md-4 px-2">
                                    <div class="mark1">Mark 7 <img class="btnIconwidth" src="../../../../../assets/icons/pencil-square.svg"/></div>
                                </div>
                                <div class="col-md-4 px-2">
                                    <div class="mark1">Mark 8 <img class="btnIconwidth" src="../../../../../assets/icons/pencil-square.svg"/></div>
                                </div>
                                <div class="col-md-4 px-2">
                                    <div class="mark1">Mark 9 <img class="btnIconwidth" src="../../../../../assets/icons/pencil-square.svg"/></div>
                                </div>
                                <div class="col-md-4 px-2">
                                    <div class="mark1">Mark 10 <img class="btnIconwidth" src="../../../../../assets/icons/pencil-square.svg"/></div>
                                </div>
                                <div class="col-md-4 px-2">
                                    <div class="mark1">Mark 11 <img class="btnIconwidth" src="../../../../../assets/icons/pencil-square.svg"/></div>
                                </div>
                                <div class="col-md-4 px-2">
                                    <div class="mark1">Mark 12 <img class="btnIconwidth" src="../../../../../assets/icons/pencil-square.svg"/></div>
                                </div>
                                <div class="col-md-4 px-2">
                                    <div class="mark1">Mark 13 <img class="btnIconwidth" src="../../../../../assets/icons/pencil-square.svg"/></div>
                                </div>
                                <div class="col-md-4 px-2">
                                    <div class="mark1">Mark 14 <img class="btnIconwidth" src="../../../../../assets/icons/pencil-square.svg"/></div>
                                </div>
                                <div class="col-md-4 px-2">
                                    <div class="mark1">Mark 15 <img class="btnIconwidth" src="../../../../../assets/icons/pencil-square.svg"/></div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div class="col-md-7">
                       
                        <div class="marklist">
                        <div class="row">                            
                            <div class="col-md-12">
                                <div class="mark2">Mark 1 </div>
                                <div class="btnStrip">
                                    <div class="col-md-3">
                                        <div class="addPeopleTitle">Add People +</div>
                                    </div>
                                    <div class="col-md-9 pl-0">
                                        <div class="d-flex align-items-center">                                            
                                                <input type="text" placeholder="First Name" class="form-control mb-0 text-center mr-6">
                                                <input type="text" placeholder="Last Name" class="form-control mb-0 text-center mr-6">
                                                <input type="text" placeholder="Email-Id" class="form-control mb-0 text-center mr-6">
                                                <button type="button" class="btn createBtn mt-0 mb-0" >Add</button>                                            
                                            </div> 
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    
                                    <div class="row">
                                        <div class="col-md-4">
                                        <a class="textRight">
                                            <img class="profile imgBorderGreen" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/profile_avater.png">&nbsp; 
                                            <div class="w-100 pl-2">
                                                <div _ngcontent-rrv-c52="" class="userName">Piyush Porwal</div>
                                                <div _ngcontent-rrv-c52="" class="userPost">UI/UX Designer</div>
                                            </div>
                                            <img src="../../../../../assets/icons/delete.svg" />
                                        </a>
                                    </div>
                                    <div class="col-md-4">
                                        <a class="textRight"><img class="profile imgBorderGreen" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/profile_avater.png">&nbsp; 
                                            <div class="w-100 pl-2">
                                                <div _ngcontent-rrv-c52="" class="userName">Piyush Porwal</div>
                                                <div _ngcontent-rrv-c52="" class="userPost">UI/UX Designer</div>
                                            </div>
                                            <img src="../../../../../assets/icons/delete.svg" />
                                        </a>
                                    </div>
                                    <div class="col-md-4">
                                        <a class="textRight"><img class="profile imgBorderGreen" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/profile_avater.png">&nbsp; 
                                            <div class="w-100 pl-2">
                                                <div _ngcontent-rrv-c52="" class="userName">Piyush Porwal</div>
                                                <div _ngcontent-rrv-c52="" class="userPost">UI/UX Designer</div>
                                            </div>
                                            <img src="../../../../../assets/icons/delete.svg" />
                                        </a>
                                    </div>
                                    <div class="col-md-4">
                                        <a class="textRight"><img class="profile imgBorderGreen" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/profile_avater.png">&nbsp; 
                                            <div class="w-100 pl-2">
                                                <div _ngcontent-rrv-c52="" class="userName">Piyush Porwal</div>
                                                <div _ngcontent-rrv-c52="" class="userPost">UI/UX Designer</div>
                                            </div>
                                            <img src="../../../../../assets/icons/delete.svg" />
                                        </a>
                                    </div>
                                    <div class="col-md-4">
                                        <a class="textRight"><img class="profile imgBorderGreen" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/profile_avater.png">&nbsp; 
                                            <div class="w-100 pl-2">
                                                <div _ngcontent-rrv-c52="" class="userName">Piyush Porwal</div>
                                                <div _ngcontent-rrv-c52="" class="userPost">UI/UX Designer</div>
                                            </div>
                                            <img src="../../../../../assets/icons/delete.svg" />
                                        </a>
                                    </div>
                                    <div class="col-md-4">
                                        <a class="textRight"><img class="profile imgBorderGreen" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/profile_avater.png">&nbsp; 
                                            <div class="w-100 pl-2">
                                                <div _ngcontent-rrv-c52="" class="userName">Piyush Porwal</div>
                                                <div _ngcontent-rrv-c52="" class="userPost">UI/UX Designer</div>
                                            </div>
                                            <img src="../../../../../assets/icons/delete.svg" />
                                        </a>
                                    </div>
                                    <div class="col-md-4">
                                        <a class="textRight"><img class="profile imgBorderGreen" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/profile_avater.png">&nbsp; 
                                            <div class="w-100 pl-2">
                                                <div _ngcontent-rrv-c52="" class="userName">Piyush Porwal</div>
                                                <div _ngcontent-rrv-c52="" class="userPost">UI/UX Designer</div>
                                            </div>
                                            <img src="../../../../../assets/icons/delete.svg" />
                                        </a>
                                    </div>
                                    <div class="col-md-4">
                                        <a class="textRight"><img class="profile imgBorderGreen" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/profile_avater.png">&nbsp; 
                                            <div class="w-100 pl-2">
                                                <div _ngcontent-rrv-c52="" class="userName">Piyush Porwal</div>
                                                <div _ngcontent-rrv-c52="" class="userPost">UI/UX Designer</div>
                                            </div>
                                            <img src="../../../../../assets/icons/delete.svg" />
                                        </a>
                                    </div>
                                    <div class="col-md-4">
                                        <a class="textRight"><img class="profile imgBorderGreen" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/profile_avater.png">&nbsp; 
                                            <div class="w-100 pl-2">
                                                <div _ngcontent-rrv-c52="" class="userName">Piyush Porwal</div>
                                                <div _ngcontent-rrv-c52="" class="userPost">UI/UX Designer</div>
                                            </div>
                                            <img src="../../../../../assets/icons/delete.svg" />
                                        </a>
                                    </div>
                                    <div class="col-md-4">
                                        <a class="textRight"><img class="profile imgBorderGreen" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/profile_avater.png">&nbsp; 
                                            <div class="w-100 pl-2">
                                                <div _ngcontent-rrv-c52="" class="userName">Piyush Porwal</div>
                                                <div _ngcontent-rrv-c52="" class="userPost">UI/UX Designer</div>
                                            </div>
                                            <img src="../../../../../assets/icons/delete.svg" />
                                        </a>
                                    </div>
                                    <div class="col-md-4">
                                        <a class="textRight"><img class="profile imgBorderGreen" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/profile_avater.png">&nbsp; 
                                            <div class="w-100 pl-2">
                                                <div _ngcontent-rrv-c52="" class="userName">Piyush Porwal</div>
                                                <div _ngcontent-rrv-c52="" class="userPost">UI/UX Designer</div>
                                            </div>
                                            <img src="../../../../../assets/icons/delete.svg" />
                                        </a>
                                    </div>
                                    <div class="col-md-4">
                                        <a class="textRight"><img class="profile imgBorderGreen" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/profile_avater.png">&nbsp; 
                                            <div class="w-100 pl-2">
                                                <div _ngcontent-rrv-c52="" class="userName">Piyush Porwal</div>
                                                <div _ngcontent-rrv-c52="" class="userPost">UI/UX Designer</div>
                                            </div>
                                            <img src="../../../../../assets/icons/delete.svg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                       
                        
                    </div>
                  
                </div> -->
                <div class="row">
                     <!-- Model code start -->
                    <!-- <div class="col-md-12">
                        <div class="writeUpdareTitle mb-4">What are Updates?</div>
                        <div class="desc"><strong>Reading time: 60 sec.</strong></div>
                        <div class="desc">Startups are hard, and raising funds is even harder. </div>
                             <div class="desc">Many times when an investor says no, they really mean not now. But how would you know when to connect with them? Investors have told us that they like to receive a small note on what is going on with your startup on a weekly basis. </div>
                             <div class="desc">Bolstart provides you with a system where you can write a weekly update for investors along with graphs that are meaningful to them. We provide you a templated approach where all you have to do is � fill in the blanks.</div>
                             <div class="desc"> Creating graphs is super easy, just share the y-axis values of this week's metrics and you will create graphs with ease. You can add up to 4 graphs in every update. These graphs are saved on Bolstart, so that next week when you add new numbers, your investors and your stakeholders can track your progress. </div>
                             <div class="desc">Don't worry, these updates are private to people you have added in your address book. That's right, you can choose whom you want to share the updates with. Once you click on "Publish", the update is not only shared on Bolstart, but also sent over emails to the people added in your address book. You can write multiple updates for different groups of people such as your stakeholders, team members or your advisory board.</div>
                             <div class="desc">There are no charges for any of this.</div>

                    </div>
                    <div class="col-md-12">
                        <div class="text-center">
                            <button type="button" class="btn okBtn mt-0 mb-0">Ok</button>
                        </div>
                    </div> -->
                    <!-- Model code end -->
                    <div class="col-md-7">
                        <div class="row d-flex align-items-center">
                            <div class="col-md-5">
                                <div class="writeUpdareTitle">Write an update<sup><i style="cursor: pointer;" data-toggle="modal"  data-dismiss="modal" data-target="#UpdatesInstructionModel" class="fa fa-info-circle" aria-hidden="true"></i></sup> </div> 
                            </div>
                            <div class="col-md-5">
                                <img class="selectImg" src="../../../../../assets/img/Company Profile-pic 2.png" /> 
                                <select title="Select a company" class="selectOption classic" (change)="changeCompany($event.target.value)">
                                    <option disabled selected> Select company</option>
                                    <option *ngFor="let item of companyList" [ngValue]="item"> {{item.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-2" style="cursor: pointer;" data-toggle="modal" data-target="#tipsModel">
                                 <div class="tipModel">Tips <img src="../../../../../assets/icons/lightbulb-outline-badged.svg" /></div>
                            </div>
                        </div>
                        <div class="row">
                             <!-- Tips code Comment start -->
                            <!-- <div class="col-md-12 pr-0">
                                <div class="previewBox">
                                    <div class="row">
                                        <div class="col-md-6">
                                            &nbsp;
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <span aria-hidden="true">&times;</span>
                                        </div>
                                        <div class="col-md-12 mt-2 boxScroll">
                                            
                                            <div class="desc1">1. Look at google calendar to see who you have met this week or had a meeting w/ to share w/ your stake holders / followers.</div>
                                            <div class="desc1"> 2. Give shout-outs to people who helped you by tagging them </div>
                                            <div class="desc1"> 3. Did you apply for any patent / trademark / copyright? </div>
                                            <div class="desc1"> 4. Did you meet any new potential customer or maybe an investor or a big client?</div>
                                            <div class="desc1">5. Did you onboard any new employee?</div>
                                            <div class="desc1">6.Any new product development? 7.Did you win any awards? achievements? </div>
                                            <div class="desc1">8. Did you meet someone special ?</div>
                                            <div class="desc1">9. Address book� a. Add Family / Friends / mentors / (potential) investors / etc. that you've met. Basically all the people invested in the success of your company. b. Update this regularly when you meet new people.</div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- Tips code Comment end -->

                            <div class="col-md-7 offset-md-4 pr-0">
                                &nbsp;
                                <!-- <label for="publisFile" class="text-right attchmentBtn">
                                <input type="file" id="publisFile" hidden accept="image/*" #imagePicker (change)="changeListener($event)" />
                                 
                                    <i class="fa fa-paperclip" for="publisFile" aria-hidden="true" (click)="onSelectionTab(1)"></i> Attach</label> -->
                            </div>
                        </div>
                        <!-- <div class="textFormatter"></div> -->
                        <div>
                            <!-- <ckeditor [editor]="Editor" tagName="textarea" type="divarea" [(ngModel)]="updateDescription" data="" (ready)="onReady($event)"></ckeditor> -->
                            <textarea (keyup)="calculateReadingTime()" placeholder="Write here" style=" border-radius: 10px;border: 1px solid; height: 217px;" tagName="textarea" type="divarea" [(ngModel)]="updateDescription" data="" ></textarea>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="selectCustom textformaterFooter">
                                    <img class="previewIocnSelect" src="../../../../../assets/icons/template.svg" />
                                    &nbsp;&nbsp;Template
                                    <!-- <select title="Pick a number" class="selectpicker border-0">
                                        <option> Template</option>
                                        <option>One</option>
                                        <option>Two</option>
                                        <option>Three</option>
                                    </select> -->
                                    
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="textformaterFooter" data-toggle="modal" style="cursor: pointer;"  data-target="#chartModel"
                                ><img class="previewIocnSelect" src="../../../../../assets/icons/graph-bar.svg"  />&nbsp;&nbsp; Insert Graph</div>
                                                          
                             </div>
                             <div class="col-md-3">
                                <div class="textformaterFooter"><img class="previewIocnSelect" src="../../../../../assets/icons/pen.svg" />&nbsp;&nbsp; Insert Signature</div>
                             </div>
                             <div class="col-md-2" style="padding: 0px;">
                                <div class="textformaterFooter">  
                                    <button type="button" *ngIf="!pollQuestion" class="btn makeInteractiveBtn mt-0 mb-0" data-toggle="modal"  style="cursor: pointer;">Make interactive</button>
                                    <button type="button" *ngIf="pollQuestion" class="btn makeInteractiveBtn mt-0 mb-0" data-toggle="modal"  style="cursor: pointer;">Update poll</button>
                                </div>
                             </div>
                            <div class="col-md-2 text-right">
                                     <label for="publisFile" class="text-right attchmentBtn">
                                <input type="file" id="publisFile" hidden accept="image/*" #imagePicker (change)="fileChangeEvent($event)" />
                                 
                                    <i class="fa fa-paperclip" for="publisFile" aria-hidden="true" (click)="onSelectionTab(1)"></i> Attach</label>
                             </div>
                             <div class="devider"></div>
                             <div class="btnSelect">
                             <div class="row">
                             <div class="col-md-3">     
                                
                                <!-- <span id="Fundraise" lass="selectOption classic" ><div class="aaa">
                                    <img class="selectImg" src="../../../../../assets/icons/group.svg" /></div>Select Group &nbsp;</span>
              
                                <span class="tooltiptexts2" [hidden]="isGroup">
                                    <ul class="dropDwonList mb-0" >
                                      <li *ngFor="let item of groupList"  (click)="selectGroup(item.id)">{{item.groupName}}</li> 
                                                                        
                                    </ul>
                                   </span>   -->
                                  



                                    <img class="selectImg" src="../../../../../assets/icons/group.svg" /> 
                                    
                                    <select title="Pick a group" class="selectOption classic" (change)="selectGroup($event.target.value)">
                                        <option disabled selected> Select Group</option>
                                        <option *ngFor="let item of groupList" [ngValue]="item"> {{item.groupName}}</option>
                                    </select>
                              </div>
                              <div class="col-md-3">
                                <!-- <div class="addMarkText" *ngIf="selectedGroup != null">
                                    Edit Address book
                                </div> -->
                                <!-- <div class="post-img" *ngIf="postImage != undefined || postImage != null">
                                    <img class="image_wrapper" [src]="postImage" /><span><i
                                            *ngIf="postImage != undefined || postImage != null" (click)="removeImage(1)"
                                            class="fa fa-remove fa-1x fab"></i></span>
                                </div> -->
                                <!-- <div *ngFor="let item of images; let i = index">   

                                    <img [src]="item" alt="">
                                    <span *ngIf="item" ><i (click)="removeImage(i)" class="fa fa-remove fa-1x fab"></i></span>

                                </div>
                                <div *ngFor="let item of chartImg; let i = index">   

                                    <img [src]="item" alt="">
                                    <span *ngIf="item" ><i (click)="removeGraph(i)" class="fa fa-remove fa-1x fab"></i></span>

                                </div> -->
 
                               
                                
                                
                            </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                <button type="button" class="btn sampleBtn mt-0 mb-0" [disabled]="!selectedGroup || !companyInfo" (click)="onNewPublishAdd()" >Publish</button>
                                </div>
                            </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-12">                                  
                                    <div class="attachmentImg" *ngFor="let item of images; let i = index">       
                                        <img [src]="item" alt="">
                                        <span *ngIf="item" ><i (click)="removeImage(i)" class="fa fa-remove fa-1x fab"></i></span>
                                        </div>
                                    <div class="attachmentImg" *ngFor="let item of chartImg; let i = index">       
                                        <img [src]="item" alt="">
                                        <span *ngIf="item" ><i (click)="removeGraph(i)" class="fa fa-remove fa-1x fab"></i></span>
                                    </div>
                                  </div>
                            </div>  
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="d-flex align-items-center">
                        <div class="col-md-5">
                            <div class="previewTitle">Preview <img src="../../../../../assets/icons/preview.svg" /></div>
                        </div>
                        <div class="col-md-7">
                            <button type="button" data-toggle="modal" data-target="#viewSampleModel"  class="btn sampleBtn mt-0 mb-0" >View Sample</button>
                        </div>
                        </div>
                        <div class="col-md-12">
                            <div class="previewBox">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="prviewBoxTitle">{{userInfo.firstName}} {{userInfo.lastName}} <span>For</span> {{ companyInfo[0] != null ? companyInfo[0].name : ""}}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="prviewBoxTitle text-right">Read Time: {{readingTime}} Min</div>
                                    </div>
                                    <div [innerHTML]="updateDescription" id="updatesPreview"></div> 
                                    <!-- <textarea id="description" style="color: gray;padding: 0px 14px;height: 170px;overflow-y: auto;" tagName="textarea" type="divarea" [value]="updateDescription" readonly="true" data="" ></textarea> -->
                                   
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <!-- <div class="inline">
                    
                    <img class="profile" [src]="userInfo?.profilePicture" /> &nbsp;
                    <div>
                        <h6 style="padding-top: 5px;">{{userInfo.firstName}}&nbsp;{{userInfo.lastName}}</h6>

                        <label>{{userInfo.headline}}</label>
                    </div>
                </div><br />
                <hr>
                <textarea *ngIf="textarea" rows="5" (click)="hide()" [(ngModel)]="description" #myTextArea
                    (keyup)="getCaretPos(myTextArea)" (click)="getCaretPos(myTextArea)"
                    placeholder=" What do you want to share?">
                </textarea>
                <div  style="outline:none; height:50px;"  rows="5"    aria-placeholder="What do you want to share?" (keyup)="findTag(mydiv,'newSection')" contenteditable="true" id="newSection" #mydiv>
                    {{mydiscription}}
                </div>
                <div class="card position" *ngIf="isShow && userList.length > 0">
                    <ul>
                        <li *ngFor="let item of userList">                             
                            <span *ngIf="item.companyId == null" style="display: flex; padding: 10px;" (click)="selectUser(item)">
                                <img [src]="item.profilePicture" class="profile" />&nbsp;
                                <h6 class="item">{{item. firstName}}{{ item. lastName }}&nbsp;&nbsp;<label>{{item.headline}}</label>
                                </h6>
                            </span>
                            
                            <hr>
                        </li>
                    </ul>
                </div>
                <emoji-mart [style]="{position:'absolute',top:'1rem',left:'4rem'}" (emojiSelect)="addEmoji($event)"
                    *ngIf="emojiToggled" set="google" sheetSize="32">
                </emoji-mart>
                <i class="fa fa-smile-o" style="color: darkgray; font-size: 20px;" (click)="toggleEmoji()"></i>
                <hr>
                <div class="btn-wrap">
                    <button class="btn btn-primary btn-sm img-btn-post" (click)="onSelectionTab(1)"><i
                            class="fa fa-picture-o">
                        </i> &nbsp; Image</button>
                </div>
                <input type="file" hidden accept="image/*" #imagePicker (change)="changeListener($event)" />
                <div class="post-img" *ngIf="postImage != undefined || postImage != null">
                    <img class="image_wrapper" [src]="postImage" /><span><i
                            *ngIf="postImage != undefined || postImage != null" (click)="removeImage(1)"
                            class="fa fa-remove fa-1x fab"></i></span>
                </div> -->
            </div>
            <!-- <div class="modal-footer">
                <button *ngIf="!isLoading" type="button" class="btn btn-primary" (click)="onNewPostAdd()">Share</button>
                <button *ngIf="isLoading" class="btn btn-primary"><i class="fa fa-spinner fa-spin"
                        aria-hidden="true"></i>
                    &nbsp;&nbsp;Loading...</button>

            </div> -->

        </div>
    </div>
</div>


<div class="chartModel">
    <div class="modal" id="chartModel" role="dialog">
        <div class="modal-dialog">
    
            <div class="modal-content modal-content-py">
                <div class="modal-Custheader p-2">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <div class="writeUpdareTitle">Add Graph</div>
                </div>
                <!-- <select title="Pick a number" class="selectpicker border-0">
                    <option> Chart</option>
                    <option>BAR</option>
                    <option>Line</option>
                    <option>Pie</option>
                </select>        -->
                <div class="modal-body text-center">
                    <!-- <input id="yAxis" class="form-control"   placeholder="Write a value .. And press enter" [(ngModel)]="chart" /> &nbsp; -->
                    <!-- <i class="fa fa-plus fab-add" (click)="addSkill()"></i> --> 
                    <!-- <button type="submit" class="btn modelBtn"  (click)="addSkill()">Add</button> -->
                </div>
                <!-- <div class="text-right p-2">
                    <button type="submit" class="btn btn-outline-primary" data-dismiss="modal">Submit</button>
                </div> -->
                <app-chart (chartData)=createChart($event)></app-chart>  
            </div>
        </div>
    </div>
</div>

<div class="pollModel">
    <div class="modal" id="pollModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Create poll</h5>
                    <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        <span>Question</span><br>
                        <input type="text" placeholder="Ask question" id="question" name="question" [(ngModel)]="pollQuestion">
                        <div>
                        <span>Options</span> &nbsp; &nbsp; <span  style="cursor: pointer;" (click)="addOption()" class="addMore"><strong style="color: #000;">+</strong></span>
                        <br>
                        <div *ngFor="let item of options">
                            <input type="text" placeholder="Add" id="option{{item}}" maxlength="30" name="option{{item}}" [(ngModel)]="optionLabelArray[item]"><br>
                        </div>
                    </div>
                    <div class="submitBtn">
                        <button type="button" class="btn sampleBtn mt-0 mb-0"  (click)="createPoll()" >Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  
    <!--view sample close here  -->
    <div class="modal" id="viewSampleModel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <!-- <h5 class="modal-title" id="exampleModalLongTitle" style="font-size:20px;">What are Updates</h5> -->
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                   <div class="row">
                   
                                                    <div class="col-md-12">
                                                        <div class="previewBox1">
                                                            <div class="row">
                                <!-- Tips code Comment start -->
                                <div class="col-md-12">
                                   <span style="    font-size: 10px;
                                   letter-spacing: 0px;
                                   color: #000000;
                                   opacity: 1;"> Hello all, hope you all had a productive week. We at Bolstart had a great week.
                                    <br/><br/>Our weekly revenue increased to Rs 17000 this week from Rs 15000 last week. <br/>We are scaling up to connect with 10 more colleges to bring verified startups internship to them. Last week we signed MoU with NMIMS and our first international school - UC San Diego! We also signed a marketing MoU with StartupSquares to increase number of startups on the platform. We hired Shubham Dandwate as our new Business Development Exec. He will be responsible for everything Sales.<br/> Last week, we all went for a team dinner outing and increased our bonding (pictures).<br/> Big thanks to Vinod Porwal for introducing us to Pratik Saboo who is helping us with UI designing and Marketing. <br/>Thank you John Doe, for letting me practice my pitch on you and for all the helpful feedback.    </span>
                                </div>
                                <!-- Tips code Comment end -->
                                </div>
                                </div>
                                </div>
                   
                </div>
                   
                </div>
            </div>
        </div>
    </div>
    <!--view sample close here  -->



<!--  instruction modal  -->
<div class="modal" id="tipsModel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h5 class="modal-title" id="exampleModalLongTitle"  style="font-size:20px;">What are Updates</h5> -->
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
               <div class="row">
<!-- Tips code Comment start -->
                            <div class="col-md-12">
                                <div class="previewBox">
                                    <div class="row">
                                        <div class="col-md-6">
                                            &nbsp;
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <span aria-hidden="true">&times;</span>
                                        </div>
                                        <div class="col-md-12 mt-2 boxScroll">
                                            
                                            <div class="desc1">1. Look at google calendar to see who you have met this week or had a meeting w/ to share w/ your stake holders / followers.</div>
                                            <div class="desc1"> 2. Give shout-outs to people who helped you by tagging them </div>
                                            <div class="desc1"> 3. Did you apply for any patent / trademark / copyright? </div>
                                            <div class="desc1"> 4. Did you meet any new potential customer or maybe an investor or a big client?</div>
                                            <div class="desc1">5. Did you onboard any new employee?</div>
                                            <div class="desc1">6.Any new product development? 7.Did you win any awards? achievements? </div>
                                            <div class="desc1">8. Did you meet someone special ?</div>
                                            <div class="desc1">9. Address book" <br/>
                                                <ul class="ml-2">
                                                a. Add Family / Friends / mentors / (potential) investors / etc. that you've met. Basically all the people invested in the success of your company. <br/>b. Update this regularly when you meet new people.
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Tips code Comment end -->
               
            </div>
               
            </div>
        </div>
    </div>
</div>


 
 <!-- Model code start -->
    <div class="modal" id="UpdatesInstructionModel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <!-- <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">What are Updates</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> 
                </div>-->
                <button type="button" class="close" data-dismiss="modal">&times;</button>

                <div class="modal-body">
                   <div class="row">
                  
                    <div class="col-md-12">
                        <div class="writeUpdateTitle mb-4">What are Updates?</div>
                        <div class="timeDesc">Reading time: 60 sec.</div>
                        <div class="desc">Startups are hard, and raising funds is even harder. </div>
                             <div class="desc">Many times when an investor says no, they really mean not now. But how would you know when to connect with them? Investors have told us that they like to receive a small note on what is going on with your startup on a weekly basis. </div>
                             <div class="desc">Bolstart provides you with a system where you can write a weekly update for investors along with graphs that are meaningful to them. We provide you a templated approach where all you have to do is fill in the blanks.</div>
                             <div class="desc"> Creating graphs is super easy, just share the y-axis values of this week's metrics and you will create graphs with ease. You can add up to 4 graphs in every update. These graphs are saved on Bolstart, so that next week when you add new numbers, your investors and your stakeholders can track your progress. </div>
                             <div class="desc">Don't worry, these updates are private to people you have added in your address book. That's right, you can choose whom you want to share the updates with. Once you click on "Publish", the update is not only shared on Bolstart, but also sent over emails to the people added in your address book. You can write multiple updates for different groups of people such as your stakeholders, team members or your advisory board.</div>
                             <div class="desc">There are no charges for any of this.</div>

                    </div>
                    <div class="col-md-12">
                        <div class="text-center">
                            <button type="button" data-target="myModal"  (click)="opneModal()"  class="btn okBtn mt-0 mb-0">Ok</button>
                        </div>
                    </div>
                </div>
                   
                </div>
            </div>
        </div>
    </div>



 <!-- Model code end -->

















<div class="postModal">
<div class="modal" id="postModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content modal-content-py">
            <div class="modal-header border-0">
                <h5 class="writeUpdareTitle text-center  w-100" id="exampleModalLongTitle" style="font-size:20px;">New Post</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                
                <div class="row">
                    <div class="col-md-12">
                        <!-- <div class="row d-flex align-items-center">
                            <div class="col-md-12">
                                <div class="writeUpdareTitle text-center">New Post
                                   <sup><i class="fa fa-info-circle" aria-hidden="true"></i></sup> 
                                 </div> 
                            </div>
                            
                        </div> -->
                        
                        <!-- START -->

                        <div class="inlineNew">
                    
                            <img class="profile" [src]="userInfo?.profilePicture" /> &nbsp;
                            <div>
                                <h6 style=" font-size: 12px;
                                font-family:'Poppins';
                                color: #48555B;
                                margin-bottom: 0px;
                                padding-bottom: 0px;
                                line-height: initial;">{{userInfo.firstName}}&nbsp;{{userInfo.lastName}}</h6>
        
                                <div class="userPostMode">{{userInfo.headline}}</div>
                            </div>
                        </div><br />
                        <div>
                            <!-- <label for="files" class="attachmentTitle"><i aria-hidden="true" class="fa fa-paperclip"></i>  Attach</label>
                            <input type="file" id="files" hidden    accept="image/*" #imagePicker (change)="changeListener($event)" />
                             -->
                            <div class="col-md-12 pr-0">
                                <label style="cursor: pointer;" for="postFile" class="text-right attchmentBtn">
                                    <input type="file" id="postFile"  hidden  accept="image/*" #imagePicker (change)="fileChangeEvent($event)" />
                                     
                                        <i class="fa fa-paperclip" for="postFile"   aria-hidden="true"  ></i> Attach</label>
                            </div>
                               
                             
                          </div>
                        <!-- <div>
                            <ckeditor [editor]="Editor" [(ngModel)]="description" tagName="textarea" type="divarea" [(ngModel)]="updateDescription" data="" (ready)="onReady($event)"></ckeditor>
                        </div>       -->
                    
                    

                         <!-- <div class="inline">
                    
                    <img class="profile" [src]="userInfo?.profilePicture" /> &nbsp;
                    <div>
                        <h6 style="padding-top: 5px;">{{userInfo.firstName}}&nbsp;{{userInfo.lastName}}</h6>

                        <label>{{userInfo.headline}}</label>
                    </div>
                </div><br /> -->
                <!-- <hr> -->
                <textarea *ngIf="textarea" rows="5" (click)="hide()" [(ngModel)]="description" #myTextArea  
                (keyup)="getCaretPos(myTextArea)" (keypress)="tagFuction($event)" (click)="getCaretPos(myTextArea)"
                    placeholder="What do you want to share?">
                </textarea>
                <div class="editor"  rows="5"   [innerHTML]="description"   
                    placeholder="What do you want to share?" 
                    id="newSection" #mydiv contenteditable="true" (keyup)="findTag(mydiv,'newSection')"
                    style="overflow-y: auto;">
                </div>
                    <!-- <div  style="outline:none; height:50px;"  rows="5"   [innerHTML]="description"   
                        aria-placeholder="What do you want to share?"
                        (keyup)="findTag(mydiv,'newSection')"  style="height: calc(100vh - 651px);" id="newSection" #mydiv contenteditable="true">
                    </div> -->
                <div class="card position" *ngIf="isShow && userList.length > 0">
                    <ul class="serchListData">
                        <li *ngFor="let item of userList">                             
                            <span *ngIf="item.companyId == null" style="display: flex; padding: 5px 10px;" (click)="selectUser(item)">
                                <img [src]="item.profilePicture" class="profile" />&nbsp;
                                <h6 class="item" style="margin-bottom: 0px;">{{item. firstName}} {{ item. lastName }}&nbsp;&nbsp;<label>{{item.headline}}</label>
                                </h6>
                            </span>
                            
                            <hr>
                        </li>
                    </ul>
                </div>
                
                <!-- <hr> -->
                <!-- <div class="btn-wrap">
                    <button class="btn btn-primary btn-sm img-btn-post" (click)="onSelectionTab(1)"><i
                            class="fa fa-picture-o">
                        </i> &nbsp; Image</button>
                </div>
                <input type="file" hidden accept="image/*" #imagePicker (change)="changeListener($event)" /> -->
                <div class="post-img" *ngFor = "let postImage of images; let i = index" >
                    <img class="image_wrapper" [src]="postImage" /><span><i
                        *ngIf="postImage != undefined || postImage != null" (click)="removeImage(i)"
                        class="fa fa-remove fa-1x fab"></i></span>
                  </div>
               
                                        
                        <!-- <div class="card position" *ngIf="isShow && userList.length > 0">
                            <ul>
                                <li *ngFor="let item of userList">                             
                                    <span *ngIf="item.companyId == null" style="display: flex; padding: 10px;" (click)="selectUser(item)">
                                        <img [src]="item.profilePicture" class="profile" />&nbsp;
                                        <h6 class="item">{{item. firstName}}{{ item. lastName }}&nbsp;&nbsp;<label>{{item.headline}}</label>
                                        </h6>
                                    </span>
                                    
                                    <hr>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <emoji-mart [style]="{position:'absolute',top:'1rem',left:'4rem'}" (emojiSelect)="addEmoji($event)"
                            *ngIf="emojiToggled" set="google" sheetSize="32">
                        </emoji-mart>
                        <i class="fa fa-smile-o" style="color: darkgray; font-size: 20px;" (click)="toggleEmoji()"></i>
                        <hr>
                        <div class="btn-wrap">
                            <button class="btn btn-primary btn-sm img-btn-post" (click)="onSelectionTab(1)"><i
                                    class="fa fa-picture-o">
                                </i> &nbsp; Image</button>
                        </div> -->

                       
                        <!-- <input type="file" class="btn btn-primary" value="Image"  accept="image/*" #imagePicker (change)="changeListener($event)" />
                         -->
                        <!-- <div class="post-img" *ngFor="let item of images">
                            <img   class="image_wrapper" [src]="item" /><span><i
                                    *ngIf="postImage != undefined || postImage != null" (click)="removeImage(1)"
                                    class="fa fa-remove fa-1x fab"></i></span>
                        </div> -->
                        
                        
                        <!-- END -->                                        
                    </div>
                    <emoji-mart [style]="{position:'absolute',top:'1rem'}" (emojiSelect)="addEmoji($event)"
                        *ngIf="emojiToggled" set="google" sheetSize="32">
                    </emoji-mart>
                    <i class="fa fa-smile-o" style="color: #564e4e;
                        font-size: 20px;
                        position: absolute;
                        top: 165px;
                        left: 10px;" (click)="toggleEmoji()"></i>
                </div>
                
            </div>
            <div class="modal-footer text-center border-0">
                <button *ngIf="!isLoading" type="button" class="btn btn-primary" (click)="onNewPostAdd()">Share</button>
                <button *ngIf="isLoading" class="btn btn-primary"><i class="fa fa-spinner fa-spin"
                        aria-hidden="true"></i>
                    &nbsp;&nbsp;Loading...</button>

            </div>

        </div>
    </div>
</div>
</div>
<!-- ====================================== -->
<div class="modal" id="poleModel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content" *ngIf="userInfo != null && userInfo.company_id != null">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">New Update</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="inline">
                    <img class="profile" [src]="userInfo?.profilePicture" /> &nbsp;
                    <div>
                        <h6 style="padding-top: 5px;">{{userInfo.firstName}}&nbsp;{{userInfo.lastName}}</h6>
                        <label>{{userInfo.headline}}</label>
                        <div *ngIf="groupList.length > 0">
                            <select class="select-control" [(ngModel)]="selectedGroupId">
                                <option value="" hidden disabled selected>Select Group</option>
                                <option *ngFor="let item of groupList" [value]="item.id">{{item.groupName}}</option>
                            </select>
                        </div>
                        <small *ngIf=" selectedGroupId == '' " class="text-danger"> Please Select Group</small>
                    </div>
                </div><br />
                <hr >
                <textarea *ngIf="textarea" rows="5" [(ngModel)]="description" (click)="hide()" #myTextArea
                    (keyup)="getCaretPos(myTextArea)" (click)="getCaretPos(myTextArea)"
                    placeholder="What do you want to share?">
                </textarea>
                <div  style="outline:none; height:50px;"  rows="5"    aria-placeholder="What do you want to share?" (keyup)="findTag(updateSection,'updateSection')" contenteditable="true" id="updateSection" #updateSection>
                    {{mydiscription}}
                </div>
                <div class="card position" *ngIf="isShow && userList.length > 0">
                    <ul>
                        <li *ngFor="let item of userList">                             
                            <span *ngIf="item.companyId == null" style="display: flex; padding: 10px;" (click)="selectUser(item)">
                                <img [src]="item.profilePicture" class="profile" />&nbsp;
                                <h6 class="item">{{item. firstName}}{{ item. lastName }}&nbsp;&nbsp;<label>{{item.headline}}</label>
                                </h6>
                            </span>
                            
                            <hr>
                        </li>
                    </ul>
                <i class="fa fa-smile-o" style="color: darkgray; font-size: 20px;" (click)="toggleEmoji()"></i>
                <emoji-mart [style]="{position:'absolute',top:'1rem',left:'4rem'}" (emojiSelect)="addEmoji($event)"
                    *ngIf="emojiToggled" set="google" sheetSize="32">
                </emoji-mart>
                <!-- <app-line-graph></app-line-graph> -->
                <hr>

                <div class="btn-wrap">
                    <button class="btn btn-primary btn-sm img-btn" (click)="onSelectionTab(1)"><i
                            class="fa fa-picture-o">
                        </i>&nbsp;Image</button>
                    <button (click)="onSelectionTab(2)" class="btn btn-primary btn-sm graph-btn"> <i
                            class="fa fa-bar-chart"></i> &nbsp; Graph</button>
                </div>
                <input type="file" hidden accept="image/*" #imagePicker (change)="changeListener($event)" />
                <div class="post-img" *ngIf="(postImage != undefined || postImage != null) && selectionTab == 1">
                    <img class="image_wrapper" [src]="postImage" /><span>
                        <i *ngIf="postImage != undefined || postImage != null" (click)="removeImage(1)"
                            class="fa fa-remove fa-1x fab"></i></span>
                </div>
                <div *ngIf="selectionTab == 2" style="text-align: center;">
                    <!-- <h5>Coming Soon...</h5> -->
                    <div style="display: inline-flex;">
                        <select class="select-control" [(ngModel)]="graphType">
                            <option value="" selected disabled hidden>Graph Type</option>
                            <option value="product launch">Week to product launch</option>
                            <option value="customers registered">No of customers registered</option>
                            <option value="Weekly Revenue">Weekly Revenue</option>
                            <option value="Weekly expenses">Weekly expenses</option>
                        </select>&nbsp;
                        <input [(ngModel)]="entity" type="number" style="text-align: right;" class="select-control"
                            placeholder="Entity">&nbsp;
                        <button (click)="onAddRecordGraph()" class="btn btn-primary add-button">Add Record</button>
                    </div>
                    <div>
                        <div *ngFor="let item of graphList">
                            <img class="image_wrapper" [src]="item.chartUrl" /><span>
                                <i *ngIf="item.chartName != undefined || item.chartName != null"
                                    (click)="removeGraph(item)" class="fa fa-remove fa-1x fab"></i>
                            </span>

                        </div>


                        <!-- For Single Graph -->
                        <!-- <div class="post-img" *ngIf="graphImage != undefined || graphImage != null">
                            <img class="image_wrapper" [src]="graphImage" /><span>
                                <i *ngIf="graphImage != undefined || graphImage != null" (click)="removeImage(2)"
                                    class="fa fa-remove fa-1x fab"></i></span>
                        </div> -->
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button *ngIf="!isLoading" type=" button" class="btn btn-primary"
                    (click)="onNewPublishAdd(companyDetail?.id)"
                    [disabled]="description == '' || selectedGroupId == '' ">Post Update</button>
                <button *ngIf="isLoading" class="btn btn-primary"><i class="fa fa-spinner fa-spin"
                        aria-hidden="true"></i>
                    &nbsp;&nbsp;Loading...</button>
            </div>

        </div>
        <div class="modal-content" *ngIf="userInfo.company_id == null">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">New Update</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <h4>You Don't Have company</h4>
            </div>

        </div>
    </div>
</div>
<!-- selection Model -->
<div class="modal" id="accountModel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-sm accountModel" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Select Account</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="inline account" (click)="onNewPublishAdd()">
                    <img class="profile" [src]="userInfo?.profilePicture" /> &nbsp;
                    <div>
                        <h6 style="padding-top: 5px;">{{userInfo?.firstName}}&nbsp;{{userInfo?.lastName}}</h6>
                    </div>
                </div><br />
                <hr>
                <div class="inline account" (click)="onNewPublishAdd(companyDetail?.id)">
                    <img class="profile" [src]="companyDetail?.logo" /> &nbsp;
                    <div>
                        <h6 style="padding-top: 5px;">{{companyDetail?.name}}</h6>
                    </div>
                </div><br />
            </div>
        </div>
    </div>
</div>

<div class="modal" id="questionModel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Ask new question</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <img style="height: 45vh;" src="assets/img/askqt.gif" />
                    <h2 class="text-primary"><i>Coming Soon....!</i></h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="dropdown-menu pull-left" aria-labelledby="dropdownButton" style="padding: 5px; width: 170px;">
    <ul>
        <li class="companyName" *ngFor="let item of companyList;let i = index;"  (click)="changeCompany(item)">{{item.name}}</li>
    </ul>
</div>

<ngx-photo-editor [imageChanedEvent]="imageChangedEvent" (imageCropped)="imageCropped($event)" [viewMode]="1"
    [aspectRatio]="1/1" [darkTheme]="false">
</ngx-photo-editor>
 