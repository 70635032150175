<div class="activeStatus">
    <div class="card">
        <div class="card-header">
            <div class="flex w-100"><h6>Active Startups</h6>
                <span class="infoIcon" id="dropdownMenuButton"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <label class="switch">
                        <input type="checkbox" checked>
                        <span class="slider round"></span>
                      </label>   
                      <div class="dropdown-menu dropdown-menu-left" style="            border-radius: 5px;
                      color: fff;
                      position: absolute;           
                      top: 0px;
                      left: 0px;
                      transform: translate3d(210px, 0px, 0px);
                      background: #293266 0% 0% no-repeat padding-box;
                      border: 1px solid #293266;
                      border-radius: 5px;
                      opacity: 1;
                      letter-spacing: 0.09px;
                      color: #F3F2F0;
                      opacity: 1;
                      padding: 12px;"
                      aria-labelledby="dropdownMenuButton">
                      <ul class="mb-0 pb-0 pl-0 pt-0">
                          <li class="mb-0 pb-0 pl-0 pt-0">"Here you can find the latest news from the startup ecosystem. Coming soon!"</li>
                         
                      </ul>
                  </div>     
            </span>
        </div>
           
        </div>
        <div class="p-22">
            <!-- <div class="grid">

                <ul>
                <li>  <a class="textRight"><img class="profile" src="../../../assets/icons/profile1.png" />&nbsp;</a></li>
                <li>  <a class="textRight"><img class="profile" src="../../../assets/icons/profile1.png" />&nbsp;</a></li>
                <li>  <a class="textRight"><img class="profile" src="../../../assets/icons/profile1.png" />&nbsp;</a></li>
                <li>  <a class="textRight"><img class="profile" src="../../../assets/icons/profile1.png" />&nbsp;</a></li>
               
                </ul>
              
                <ul>
                    <li>  <a class="textRight"><img class="profile" src="../../../assets/icons/profile1.png" />&nbsp;</a></li>
                    <li>  <a class="textRight"><img class="profile" src="../../../assets/icons/profile1.png" />&nbsp;</a></li>
                    <li>  <a class="textRight"><img class="profile" src="../../../assets/icons/profile1.png" />&nbsp;</a></li>
                    <li>  <a class="textRight"><img class="profile" src="../../../assets/icons/profile1.png" />&nbsp;</a></li>
                   
                </ul>
                
              </div> -->
        <div class="row cardProfileset">
            <!-- <div class="tooltip1">Hover over me
                <span class="tooltiptext1">Founder Name</span>
              </div> -->
            <div class="col-md-4 pl-2 pr-2">
                
                <a class="textRight tooltip1">
                    <img class="profile" src="../../../assets/icons/profile1.png" />&nbsp;
                    <span class="tooltiptext1">Founder Name</span>
                </a>
            </div>
            <div class="col-md-4 pl-2 pr-2">
                <a class="textRight tooltip1">
                    <img class="profile" src="../../../assets/icons/profile2.png" />&nbsp;
                    <span class="tooltiptext1">Founder Name</span>
                </a>
            </div>
            <div class="col-md-4 pl-2 pr-2">
                <a class="textRight tooltip1">
                    <img class="profile" src="../../../assets/icons/profile3.png" />&nbsp;
                    <span class="tooltiptext1">Founder Name</span> 
                </a>
            </div>
            <div class="col-md-4 pl-2 pr-2">
                <a class="textRight tooltip1">
                    <img class="profile" src="../../../assets/icons/profile4.png" />&nbsp;
                    <span class="tooltiptext1">Founder Name</span>   
                </a>
            </div>
            <div class="col-md-4 pl-2 pr-2">
                <a class="textRight tooltip1">
                    <img class="profile" src="../../../assets/icons/profile5.png" />&nbsp;
                    <span class="tooltiptext1">Founder Name</span>  
                </a>
            </div>
            <div class="col-md-4 pl-2 pr-2">
                <a class="textRight tooltip1">
                    <img class="profile" src="../../../assets/icons/profile6.png" />&nbsp;
                    <span class="tooltiptext1">Founder Name</span>  
                </a>
            </div>
            <div class="col-md-4 pl-2 pr-2">
                <a class="textRight tooltip1">
                    <img class="profile" src="../../../assets/icons/profile7.png" />&nbsp;
                    <span class="tooltiptext1">Founder Name</span>
                </a>
            </div>
            <div class="col-md-4 pl-2 pr-2">
                <a class="textRight tooltip1">
                    <img class="profile" src="../../../assets/icons/profile8.png" />&nbsp;
                    <span class="tooltiptext1">Founder Name</span>
                </a>
            </div>
            <div class="col-md-4 pl-2 pr-2">
                <a class="textRight tooltip1">
                    <img class="profile" src="../../../assets/icons/profile9.png" />&nbsp;
                    <span class="tooltiptext1">Founder Name</span>
                </a>
            </div>                          
    
        </div>
        </div>
        <div class="moreLink">View All +</div>
    </div>
   
    </div>