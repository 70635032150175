<div class="main">
    <p class="iconClose">
      <i class="fa fa-times" aria-hidden="true" (click)="onNoClick()"></i>
    </p>
    <div class="main-fontInputBox">
      <h4 class="fontInputBox">Upload Another version of your CV?</h4>
    </div>
    <div class="inputBorder">
      <div class="Pcontainer">
        <input
          type="file"
          (change)="getFileName()"
          class="fileUploader"
          #fileInput
          accept="image/*,.doc,.docx,.pdf"
        />
        <!-- (change)="onChangeFileInput()" -->
        <button
          mat-raised-button
          style="background-color: white"
          class="file-select-button"
          id="btn-attach"
          (click)="uploadIconClick()"
        >
           <!-- <input
        type="file"
        class="fileUploader"
        id="file"
        accept="application/pdf"
        (change)="onChangeFileInput($event)"
      /> -->
      
          <p class="Upload">
            {{ fileName }} 
             <i
              *ngIf="fileName=='Upload CV'"
              id="uploadIcon"
              class="fa fa-upload"
              aria-hidden="true"
              style="font-size: 24px"
              
            ></i>

             <i *ngIf="fileName!='Upload CV'" class="fa fa-trash" aria-hidden="true"  (click)="deleteFileData()" style="font-size: 24px"></i> 

          </p>
        </button>
      </div>
    </div>
    <hr class="main-fontInputBox color-hr" />
    <div class="main-fontInputBox">
      <p class="fontInputBox">Or choose previously uploaded CV</p>
      <div class="borderInput">
       
        <ng-container *ngIf="uploadedFiles.length>0">
          <ng-container *ngFor="let item of uploadedFiles">
            <!-- <i class="fa fa-trash" aria-hidden="true"></i> -->
            <img src="assets/file-icon.png" >&nbsp;&nbsp;<span style="cursor: pointer;" (click)="selectFile(item)" >{{ item.file_name }}  <span>( {{ item.created_on | date: 'dd/MM/yyyy' }} )</span> </span>
            <br>
        </ng-container>
        </ng-container>
        
      </div>
    </div>
    <div class="main-apply main-fontInputBox">
      <p class="apply" (click)="onClickFileInputButton()">Apply</p>
    </div>
  </div>