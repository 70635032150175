<div>
    <div>
        Filter
    </div>
    <div>
        <div class="tag">
            <p>Sort By</p>
        </div>
        <div class="tag">
            <p>Location</p>
        </div>
        <div class="tag">
            <p>Scoring</p>
        </div>

    </div>
</div>