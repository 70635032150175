<div class="main">
    <p style="text-align: right;" *ngIf="data.flag!='deactiveJob'">
        <i class="fa fa-times" aria-hidden="true" (click)="onNoClick()"></i>
    </p>


  

    <div class="content" *ngIf="data.flag=='jobUpdate'">
        <p class="text-p" *ngIf="data.id!=0">{{ popupMessage }}</p>
        <p class="text-p" *ngIf="data.id==0">{{data.msg}}</p>
        <div class="buttons-dev" *ngIf="data.id!=0">
            <button class="deactivateBtn" (click)="updateJob()">{{ data.msg }}</button>
            <button class="cancel" (click)="onNoClick()"> Cancel</button>
        </div>
    </div>

  
    <div class="content" *ngIf="data.flag=='hireUser'">

        <!-- Are you sure you want to hire this candidate? If you are hiring only one candidate this job posting will be closed. If hiring more than one, this job posting will stay open till you fill all the openings in your company.
         Do you want to proceed? -->

        <p class="text-p" *ngIf="data.id!=0">{{data.msg}}</p>
        <p style="text-align:center">If you are hiring only one candidate this job posting will be closed. If hiring more than one, this job posting will stay open till you fill all the openings in your company.</p>
        <p class="text-p"> Do you want to proceed?</p>
        <div class="buttons-dev" *ngIf="data.id!=0">
            <button class="deactivateBtn" (click)="hireUser(data.id,data.job_id,data.user_id)">Yes</button>
            <button class="deactivateBtn" (click)="onNoClick()">No</button>
        </div>
    </div>

    <div class="content" *ngIf="data.flag=='hireUserResponse'">
        <p class="text-p" *ngIf="data.id!=0">Congratulations on hiring an intern!</p>
        <div class="buttons-dev">
            <!-- <button class="deactivateBtn" (click)="onNoClick()" routerLink="jobs/job-post-form/{{data.job_id}}/1">Yes</button>
            <button *ngIf="remaining==0" class="cancel" (click)="disableJob(data.job_id)">No</button> -->
            <button *ngIf="remaining!=0" class="deactivateBtn" (click)="repayment(data.msg,remaining)">Thank you!</button>
        </div>
    </div>

    <div class="content" *ngIf="data.flag=='repayment'">
        <p class="text-p" *ngIf="data.id!=0">{{data.msg}}</p>
        <p *ngIf="data.id!=0" style="text-align: center;">Do you want to increase the number of hires? Clicking &apos;Yes&apos; will take you to the edit/payment page. &apos;No&apos; will take you back to the &apos;view candidates&apos; page.</p>
        <div class="buttons-dev">
            <button class="deactivateBtn" (click)="onNoClickRepayment()" routerLink="jobs/job-post-form/{{data.job_id}}/1">Yes</button>
            <button *ngIf="remaining==0" class="deactivateBtn" (click)="disableJob(data.job_id)">No</button>
            <button *ngIf="remaining!=0" class="deactivateBtn" (click)="onNoClick()">No</button>

        </div>
    </div>
    <div class="content" *ngIf="data.flag=='jobpost'">
        <p class="text-p">{{data.msg}}</p>
    </div>

    <p style="text-align: right;" *ngIf="data.flag=='deactiveJob'" routerLink="/jobs/job-post-ui">
        <i class="fa fa-times" aria-hidden="true" (click)="onNoClick()"></i>
    </p>
    <div class="content" *ngIf="data.flag=='deactiveJob'">
        <p class="text-p">{{data.msg}}</p>
    </div>


</div>