<div class="main"> 
    <p style="text-align: right;"><i class="fa fa-times" aria-hidden="true"></i></p>
  
<div class="content"> 
    <p class="text-p">Thank you for making the payment of $_. Your job is now live.</p>
    <div class="buttons-dev">
        <button class="deactivateBtn">Add Another</button>
         <button class="cancel"> Manage Job Page</button>
    </div>
</div>
</div>