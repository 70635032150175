import {  HttpRequest, HttpHandler,  HttpEvent,  HttpInterceptor  } from '@angular/common/http';  
import { AccountApiService } from 'src/app/components/account/account-api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
  
  @Injectable()  
  export class TokenInterceptor implements HttpInterceptor {  
    constructor(public auth: AccountApiService) {}  
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  
       
      if(this.auth.getToken() != null){
        request = request.clone({  
          setHeaders: {  
            Authorization: `Bearer ${this.auth.getToken()}`  
          } 
        });
      }
          
      return next.handle(request);  
    }  
  }