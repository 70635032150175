import * as CryptoJS from 'crypto-js';

import { Component, Input, OnInit } from '@angular/core';
import { ProfileService } from '../../profile/profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'manage-network',
  templateUrl: './manage-network.component.html',
  styleUrls: ['./manage-network.component.scss']
})
export class ManageNetworkComponent implements OnInit {
  @Input() followersCount: any;
  @Input() networkCount: any;
  userInfo
  userId
  others :any=[]
  invertor:any =[]
  founder : any =[]

  constructor(private profileService: ProfileService, private router: Router) { }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('user'));
    this.userId = this.userInfo.id;
    this.getConnectionInfo();
  }


  getConnectionInfo() {
    this.profileService.getMyNetworkWithOutPagination(this.userInfo.id).subscribe(res => {
      if (res.success) { 
        this.others = res.data.filter(s => {
          if(s.roles != null && s.roles.length > 0 && s.roles[0].roleName=="Student"){
            return true;
          }
        });
        this.invertor = res.data.filter(s => {
          if(s.roles != null && s.roles.length > 0 && s.roles[0].roleName=="Investor"){
            return true;
          }
        });
        this.founder = res.data.filter(s => {
          if(s.roles != null && s.roles.length > 0 && s.roles[0].roleName=="Startup Founder"){
            return true;
          }
        });        
        this.userInfo.connection = res.data;          
      }
    })
  }

  gotoFollowers(){
    this.router.navigate(['../mynetwork/follow']);
  }
}
