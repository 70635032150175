<app-header></app-header>

<div class="container">
  <div class="main-p" style="overflow: hidden">
    <div class="innter_p">
  <div class="applicationProfile">
    <div class="main-page-div">
      <h1 class="title">Applicant Review: {{jobTitle}} ({{ jobApplyers.length }} Applicants)</h1>
  
      <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 pl-0">
        <div class="rightBar">
          <div class="filterSec">
            <div class="search">
              <input type="text" (keyup)="filterByName($event)" placeholder="rushabh@gmail.com" name="search">
              <button type="submit"><i class="fa fa-search"></i></button>
            </div>
            <div class="filter" (click)="openFilter()">
              <span class="badge" *ngIf="filterCnt > 0">{{filterCnt}}</span>
              <a><i class="fa fa-filter" aria-hidden="true" style="margin-right: 5px;"></i>Filter</a>
            </div>
          </div>
          <div class="applicantList" *ngIf="jobApplyer != null && jobApplyer.length>0" >
            <div  [ngClass]="((activeIndex) == i)?'applicantCard active':'applicantCard'"  *ngFor="let item of jobApplyer;let i=index" (click)="getUserDetails(pageIndex*10+i)">
                  <div class="profileimg">
                      <img [src]="item.userDetails.profilePicture" width="48px" height="48px">
                  </div>
                  <div class="profileNameScore">
                    <div class="nameOfApplivcant">{{ item.userDetails.firstName }} {{ item.userDetails.lastName }}</div>
                    <div class="score">
                      <div class="scorecircle">
                        <ng-container *ngFor="let index1 of [1,2,3,4,5]">
                          <span style="cursor: pointer;" (click)="updateStartRating(index1,item.id,i)"
                          [ngClass]="((item.startRating) >= index1)?'fa fa-circle checked':'fa fa-circle-o'" 
                          ></span>
                        </ng-container>
                        <!-- <span class="fa fa-circle checked"></span>
                        <span class="fa fa-circle checked"></span>
                        <span class="fa fa-circle checked"></span>
                        <span class="fa fa-circle"></span>
                        <span class="fa fa-circle"></span> -->
                      </div>
                    </div>
                  </div>
                  <div class="Iconsection">
                    <div style="cursor: pointer;" (click)="recruiterAction(item.id,1,i)"  [ngClass]="(( item.rerecruiterAction ) == 1)?'icon listselected':'icon'" ><i class="fa fa-check" aria-hidden="true"></i></div>
                    <div style="cursor: pointer;" (click)="recruiterAction(item.id,2,i)"  [ngClass]="(( item.rerecruiterAction ) == 2)?'icon listselected':'icon'"><i class="fa fa-question"></i></div>
                    <div style="cursor: pointer;" (click)="recruiterAction(item.id,3,i)"  [ngClass]="(( item.rerecruiterAction ) == 3)?'icon listselected':'icon'"><i class="fa fa-close"></i></div>
                  </div>
            </div>
            <mat-paginator [length]=jobApplyers.length
              [pageSize]="10"              
              aria-label="Select page"
              (page)="pageEvent = getServerData($event)"
              >
            </mat-paginator>
 



            
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9 pr-0">
          <div *ngIf="!isCount"><h1>No applicants available for the filters you have selected. Please try different filters.😄</h1></div>
          <div class="jobDetails" *ngIf="isCount && jobApplyersDetails">
            <div class="upperside row" >
            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
              <div class="profileDetails">
                <div class="profileImage">
                  <img [src]="jobApplyersDetails['userDetails']['profilePicture']" style="cursor: pointer;" [routerLink]="['/profile/timeline']" [queryParams]="{visit:jobApplyersDetails['userDetails']['id']}" width="100px" height="100px">
                </div>
                <div class="profile">
                  <div class="applicantName" style="cursor: pointer;" [routerLink]="['/profile/timeline']" [queryParams]="{visit:jobApplyersDetails['userDetails']['id']}">{{ jobApplyersDetails['userDetails']["firstName"] }} {{ jobApplyersDetails['userDetails']["lastName"] }} </div>
                 <div class="profileName">{{jobApplyersDetails['job_title']}}</div>
                  <div class="location">{{jobApplyersDetails['userDetails']['location']}} | Applied {{ dayCounter }} Days ago</div>
                  <!-- <div class="studentLocation" *ngIf="jobApplyersDetails['userDetails']['education'].length>0">{{jobApplyersDetails['userDetails']['education'][0]['institutionName']}}</div> -->
                  <div class="contactDetails">
                    <div class="location">Student @ <span *ngIf="jobApplyersDetails['userDetails']['education'].length>0">{{ jobApplyersDetails['userDetails']['education'][0]['institutionName']}} </span></div>
                  </div>
                  <br>
                    <div class="contactDetails">
                    <div class="phone"><i class="fa fa-phone"></i>{{jobApplyersDetails['userDetails']['mobileNumber']}}</div>
                    <a href="mailto:{{jobApplyersDetails['userDetails']['emailId']}}"> <div class="email"><i class="fa fa-envelope"></i>{{ jobApplyersDetails['userDetails']['emailId']}}</div></a>
                  </div> 
                </div>
            </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div class="rightsection">
                <div class="hireSection">
                  <a class="hirebtn" (click)="hireCandidate( jobApplyersDetails['id'],jobApplyersDetails['job_id'],1,jobApplyersDetails['user_id'])" *ngIf="!jobApplyersDetails['is_hired']" > <i class="fa fa-handshake-o"   aria-hidden="true"></i> Hire</a>
                  <a class="hiredbtn" disabled *ngIf="jobApplyersDetails['is_hired']"> <i class="fa fa-handshake-o" aria-hidden="true"></i> Hired</a>
                  <div class="Iconsection">
                    <div (click)="recruiterAction(jobApplyersDetails['id'],1,activeIndex)"  [ngClass]="((jobApplyersDetails['rerecruiterAction']) == 1)?'icon selected':'icon'"><i class="fa fa-check" aria-hidden="true"></i></div>
                    <div (click)="recruiterAction(jobApplyersDetails['id'],2,activeIndex)"  [ngClass]="((jobApplyersDetails['rerecruiterAction']) == 2)?'icon selected':'icon'"><i class="fa fa-question"></i></div>
                    <div (click)="recruiterAction(jobApplyersDetails['id'],3,activeIndex)"  [ngClass]="((jobApplyersDetails['rerecruiterAction']) == 3)?'icon selected':'icon'"><i class="fa fa-close"></i></div>
                  </div>
                </div>
                <div class="score">
                  <div class="scorehead">Score:</div>
                  <div class="scorecircle">
                    <ng-container *ngFor="let index of [1,2,3,4,5]">
                      <span (click)="updateStartRating(index,jobApplyersDetails['id'],activeIndex)" style="cursor: pointer;"
                      [ngClass]="((jobApplyersDetails['startRating']) >= index)?'fa fa-circle checked':'fa fa-circle-o'" 
                      ></span>
                    </ng-container>
                  
                   
                    <!-- <span class="fa fa-circle checked"></span>
                    <span class="fa fa-circle checked"></span>
                    <span class="fa fa-circle"></span>
                    <span class="fa fa-circle"></span> -->
                  </div>
                </div>
                <!-- jobApplyersDetails['userDetails']['startRating'] -->
                <div class="resumeDowloadBtn">
                  <a href="{{ jobApplyersDetails['resume_path'] }}" target="_blank"> <i class="fa fa-download" aria-hidden="true"></i>Download Resume</a>
                </div>
              </div>
            </div>
          </div>
            <div class="tabsection">
            <div class="tab">
              <button class="tablinks active" (click)="opentab($event, 'tab1')">Bio</button>
              <button class="tablinks" disabled (click)="opentab($event, 'tab2')">Message</button>
              <button class="tablinks" disabled (click)="opentab($event, 'tab3')">Comments</button>
            </div>
            
            <div id="tab1" class="tabcontent">
              <div class="bioDetails">
                <!-- <p>{{ resume_path }}</p> -->
                <div class="biocontent">
                  <iframe [src]="urlSafe" style="width:100%; height:700px;" frameborder="0">
                  </iframe>
                </div>
              </div>
            </div>
            
            <div id="tab2" class="tabcontent">
              <h3>Paris</h3>
              <p>Paris is the capital of France.</p> 
            </div>
            
            <div id="tab3" class="tabcontent">
              <h3>Tokyo</h3>
              <p>Tokyo is the capital of Japan.</p>
            </div>
          </div>
          </div>
      </div>
    </div>
</div>
</div>
</div>
</div>
