<div class="sidebarAdsPlatform">
    <div class="card">
        <div class="card-header">
            <div class="flex w-100"><h6>Ads on Bolstart</h6>
                <span class="adsChange">
                    <span  class="infoIcon">
                         <span id="dropdownMenuButton" style="float:right;"
                         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Change >>  </span>  
                    
            
            
                        <!-- <i class="fa fa-info-circle" id="dropdownMenuButton" style="float:right;"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       
                    </i> -->
                    <div class="dropdown-menu dropdown-menu-left" style="            border-radius: 5px;
                        color: fff;
                        position: absolute;           
                        top: 0px;
                        left: 0px;
                        transform: translate3d(178px, 35px, 0px);
                        background: #293266 0% 0% no-repeat padding-box;
                        border: 1px solid #293266;
                        border-radius: 5px;
                        opacity: 1;
                        letter-spacing: 0.09px;
                        color: #F3F2F0;
                        opacity: 1;
                        padding: 12px;"
                        aria-labelledby="dropdownMenuButton">
                        <ul class="mb-0 pb-0 pl-0 pt-0">
                            <li class="mb-0 pb-0 pl-0 pt-0">Please email us to advertise on Bolstart</li>
                           
                        </ul>
                    </div>
                    </span>  
            </span>
        </div>
        </div>
        <div class="p-22">
            <div class="card advertisement">
                <img [src]="image">
            </div>
        </div>
        <div class="moreLink">
           
            <span  class="infoIcon">
              <span id="dropdownMenuButton"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Advertise with us </span>
              
           
           <div class="dropdown-menu dropdown-menu-left" style="            border-radius: 5px;
               color: fff;
               position: absolute;           
               top: 0px;
               left: 0px;
               transform: translate3d(178px, 35px, 0px);
               background: #293266 0% 0% no-repeat padding-box;
               border: 1px solid #293266;
               border-radius: 5px;
               opacity: 1;
               letter-spacing: 0.09px;
               color: #F3F2F0;
               opacity: 1;
               padding: 12px;"
               aria-labelledby="dropdownMenuButton">
               <ul class="mb-0 pb-0 pl-0 pt-0">
                   <li class="mb-0 pb-0 pl-0 pt-0">Please email us to advertise on Bolstart</li>
                  
               </ul>
           </div>
           </span> 

        </div>
      
    </div>
    </div>