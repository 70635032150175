import { Component, OnInit } from '@angular/core';
import * as Chart from 'chart.js';
import * as moment from 'moment';

import { AwsService } from 'src/app/shared/AWS-Service/aws.service';
import { Output, EventEmitter } from '@angular/core';
import { HomeService } from './../../service/home.service';
import { ITEM } from 'src/cometchat-pro-angular-ui-kit/CometChatWorkspace/projects/angular-chat-ui-kit/src/utils/enums';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
  @Output() chartData = new EventEmitter<any>();
myChart:any;
chartType:any =  "bar";
chartImg
userInfo = JSON.parse(localStorage.getItem('user'));
company = JSON.parse(localStorage.getItem('company'));
chartDataList:any;
graphDataList:any;
backGroundColor="#293266";
color;

currentWeek:number =this.getWeek();
labelsArray = []
 dataValueArray =[]
 currentWeekExist =false 
 isInvestorTicket=true;
 isJobLocationFilterAppiled =false
 

dataValue:number =0
title: String ="Select a graph type";
configChartData:any;
  constructor(private awsService: AwsService,private apiService: HomeService,) { }

  ngOnInit(): void {  
    this.getGraphData();  
    this.configChartData ={      
      labels: this.labelsArray,
      lineTension: 0, 
      datasets: [{
        label: this.title,
        data: this.dataValueArray,
        bezierCurve: false,
        fill: false,
        borderColor:  this.color,
        
        backgroundColor: this.color,
        pointBackgroundColor: this.color,
        pointBorderColor: this.color,
        pointHoverBackgroundColor: this.color,
        pointHoverBorderColor: this.color,
        
        // backgroundColor: [
        //   "#f38b4a",
        //   "#56d798",
        //   "#ff8397",
        //   "#f38b4a",
        //   "#56d798",
        //   "#ff8397",
        //   "#FFFFFF",
        //   "#f38b4a",
          
        //   "#FFFFFF" 
        // ],
      //  borderWidth: 2
      }]
    }
    this.createChart();
  }

  InvestorTicket() {
    this.isInvestorTicket = !this.isInvestorTicket;
     
  }
 
  createChart(){      
     
    this.myChart = new Chart("myChart", {
      type: this.chartType,
      data:this.configChartData,
      options: {       
        
        scales: {
          
          xAxes: [{
            gridLines: {
                display:false
            }
        }],
          yAxes: [{
              ticks: {
                  beginAtZero: true
              },
              gridLines: {
                display:false
            }
          }        
        ]
      }
      }
    });     
  }

  updateChart(){   
    this.myChart.data.datasets[0].data[this.myChart.data.datasets[0].data.length - 1] =this.dataValue;
    this.myChart.config.type =this.chartType   
    this.myChart.update();
  }

  /*********************
   * Save graph Data 
   */
 async saveChart(){  
 
  let obj ={
    type:this.chartType,
    dataValue:this.dataValue,
    title:this.title,
    chartImg: this.myChart.toBase64Image(),  
    userId:   this.userInfo.id,
    companyId:this.company[0].id

  }  
   this.chartData.emit(obj);
   $("#chartModel .close").click();
 }

/*******
 * Calcuate this week;
 */
 getWeek() {
  var OBJ = new Date();
  let onejan = new Date(OBJ.getFullYear(),0,1);
  console.log(onejan)
  let today = new Date();;//new Date(OBJ.getFullYear(),OBJ.getMonth(),OBJ.getDate());
  var dayOfYear = ((today.getTime() - onejan.getTime() + 86400000)/86400000);
  //return Math.ceil(dayOfYear/7)+1
  return Math.ceil(dayOfYear/7)
  
}

/**
 * change graph type
 * 
 */
changeGraph(title){
  this.isInvestorTicket =true;
  this.title =title;
  if(this.title == "Weeks to Product Product Launch"){
    this.chartType = "bar";
    this.color ="#176290"
  }else if(this.title == "Number of Customers talked to"){
    this.chartType = "bar";
    this.color ="#009cc3"
  }else if(this.title == "Weekly Revenue"){
    this.chartType = "line";
    this.color ="#29f0ae"
  }else if(this.title == "Weekly Expenses"){
    this.chartType = "line";
    this.color ="#293266"
  }
  console.log(this.backGroundColor)
  this.getGraphData()  
   
}

/**
 * graph Image
 */
graphImage(){
  let chartObject= {     
    type: this.chartType,
    data: {
      labels:this.labelsArray  ,
      datasets: [
        // {
        //   label: this.title,
        //   fill: false,
        //   data: this.dataValueArray ,
        //   borderColor:  this.color,        
        //   backgroundColor: this.color,
        //   pointBackgroundColor: this.color,
        //   pointBorderColor: this.color,
        //   pointHoverBackgroundColor: this.color,
        //   pointHoverBorderColor: this.color,
        // },
        {
          label: this.title,
          data: this.dataValueArray,
          bezierCurve: false,
          fill: false,
          borderColor:  this.color,
          
          backgroundColor: this.color,
          pointBackgroundColor: this.color,
          pointBorderColor: this.color,
          pointHoverBackgroundColor: this.color,
          pointHoverBorderColor: this.color,
          
           
        }
      ]
    }       
}

this.chartImg ="https://quickchart.io/chart?c="+JSON.stringify(chartObject);;
}

/***
 * Get graph data
 * 
 */
getGraphData(){
  this.currentWeekExist =false 
  this.labelsArray=[]
  this.dataValueArray=[]
  if(this.company != null && this.company != undefined && this.company.length > 0){
    this.apiService.getChartData(this.company[0].id,this.title).subscribe(res => {   
      if(res.success){         
        this.chartDataList  = res.data.sort(function(a, b){return a.createdAt - b.createdAt});
        console.log(this.chartDataList)
         res.data.forEach(element => {
          let date = new Date(element.createdAt)       
          let d= date.getDate()+"/"+(1+date.getMonth())+"/"+date.getFullYear ().toString().substr(-2);
          this.labelsArray.push(d)
          this.dataValueArray.push(element.dataValue)
          if(this.currentWeek == element.week){
            this.currentWeekExist =true;
          } 
         });
         console.log("cur week = ",this.currentWeek)
         if(!this.currentWeekExist){
          let date= new Date();
          let d= date.getDate()+"/"+(1+date.getMonth())+"/"+date.getFullYear ().toString().substr(-2);
          this.labelsArray.push(d)
          this.dataValueArray.push(0)
          
        } 
        // console.log("data =",this.dataValueArray) 
        // console.log("labels =",this.labelsArray) 
        // console.log("currentWeekExist =",this.currentWeekExist) 
        // console.log("currentWeek =",this.currentWeek) 
        
        this.myChart.data.labels =this.labelsArray;
        this.myChart.data.datasets[0].data =this.dataValueArray;
        this.myChart.config.type =this.chartType;
        console.log("data set=",this.myChart.data.datasets[0])   
        this.myChart.data.datasets[0].borderColor=  this.color,
          
        this.myChart.data.datasets[0].backgroundColor = this.color;
        this.myChart.data.datasets[0].pointBackgroundColor = this.color;
        this.myChart.data.datasets[0].pointBorderColor =  this.color;
        this.myChart.data.datasets[0].pointHoverBackgroundColor = this.color;
        this.myChart.data.datasets[0].pointHoverBorderColor = this.color;
        this.myChart.update();
        this.graphImage();
  
      } 
    })
  }
  

}
 
 
}