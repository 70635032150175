import { map } from 'rxjs/operators';
import { NetworkApiService } from './../network-api.service';
import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { ProfileService } from '../../profile/profile.service';  
import { Pipe, PipeTransform } from '@angular/core';


@Component({
  selector: 'know-people',
  templateUrl: './know-people.component.html',
  styleUrls: ['./know-people.component.scss']
})

export class KnowPeopleComponent implements OnInit {
  suggestList: any = [];
  LatestUserList: any = [];
  centerrSuggestList: any = [];
  
  userId: number;
  userInfo : any;
  currentpage: number = 0;
  itemsPerPage: number = 15;

  config: any;
  constructor(
    private networkService: NetworkApiService,
    private profileService: ProfileService,
  ) { }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('user'));       
    this.config = {
      itemsPerPage: 15,
      currentPage: 0,
      totalItems: 0
    }
    this.getNetworkSuggestions(this.userInfo.id, this.currentpage,this.itemsPerPage)
  }

  getName(firstName,lastName)
{
  let fullName;
  if(firstName != null){
    fullName = firstName;
  }
  if(lastName != null){
    fullName  =firstName+" "+lastName
  }
 fullName = (fullName.length > 17)?fullName.substr(0,17)+"...":fullName;
  return fullName 
}
  /* =========== Get Suugetion List ====================*/
  getSucggestedList(pageNo, UserId, size) {
     
    this.networkService.getSugestionList(UserId, pageNo,size).subscribe(res => {
      if (res.success) {
        this.suggestList = [];
        this.suggestList = res.data.content;
        this.config.totalItems = res.data.totalElements;     
      }
    })
  }

  /* =========== Get Latest User ====================*/
  getNetworkSuggestions(userId, page, size) {
    this.networkService.getNetworkSuggestions(userId, page, size).subscribe(res => {
      if (res.success) {
        this.suggestList = [];
        this.suggestList = res.data.content;
        this.config.totalItems = res.data.totalElements;          
      }
    })
  }

  
  pageChanged(event) {    
    // this.getSucggestedList(event - 1, this.userId,this.itemsPerPage);
    this.getNetworkSuggestions(this.userInfo.id,event - 1,this.itemsPerPage);
    this.config = {
      itemsPerPage: 15,
      currentPage: event,
       
    }
    this.currentpage = event;
  }

  remove(index) {
    this.suggestList.splice(index, 1)
  }


  /* ===================== Send Request ==================== */

  deleteConnection(item, index) {
    this.profileService.deleteConnection(item.connection.id).subscribe(res => {
      if (res.success) {
        this.suggestList[index].connection = null     
      }   

      
        })
  }
  

  sendRequest(item, index) {    
    let obj = {
      connectedUser: item,
      connected: false,
      following:true,
      followedBack:false,
      user: this.userInfo,
    }
    this.profileService.sendRequest(obj).subscribe(res => {
       
      if (res.success) {         
        this.suggestList[index].connection = res.data
        // this.requestType ="send";
        // this.userDetail.connection = res.data
        // console.log(this.userDetail.connection)
      }
    })


    // this.networkService.sendRequest(obj).subscribe(res => {
    //   if (res.success) {
    //     this.suggestList[index].isConnected = 'false';
    //     this.remove(index)
    //   }
    // })
  }

  sendFollowRquest(item, index) {
    console.log("follow=",item)
    let obj = {     
      connectedUser: item,
      isFollowed: 'true',
      following: true,
      user: this.userInfo,
      isSeen:true,
    }
    this.networkService.followRequest(obj).subscribe(res => {
      if (res.success) {
       // this.suggestList[index].isFollowed = 'true';
       item.isFollowed =true;
      }
    })
  }
  sendUnfollowRquest(item, index) {
    let obj = {     
      connectedUser: item,
      following: false,
      user: this.userInfo,     
    }
    this.networkService.followRequest(obj).subscribe(res => {
      if (res.success) {
        this.suggestList[index].isFollowed = null;
      }
    })
  }

}
