<div class="nteworkCard p-4">
    <div class="tabDataAccomplishment">
        <div class="tabDataSkill">

<!-- <div class="col-md-12">
    <h5>My Network</h5>
</div> -->
<div class="row" *ngIf="myNetworkList.length > 0"> 
    <div class="col-6 spaceReduce"  *ngFor="let item of myNetworkList | paginate: config  ;let i = index;">
        <a class="networkBlock" >
            <img   (click)="goToProfile(item.id)"  [src]="item.profilePicture != null ? item.profilePicture:'https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/profile_avater.png'"   class="networkprofile imgBorderGreen">&nbsp; 
            <div class="w-100 pl-2" >
                <div (click)="goToProfile(item.id)" class="networkUserName">{{item.firstName}} {{item.lastName}}</div>
                <div (click)="goToProfile(item.id)" class="NetworkuserPost">{{item.headline}}</div>
            </div>
            <!-- <button type="button" class="btn reachOutBtn mt-0 mb-0" (click)="sendRequest(item,i)" *ngIf="item.connection == null && userInfo.id!=userDetail.id" >Reach Out</button>
            <button type="button" class="btn reachOutBtn mt-0 mb-0" (click)="deleteConnection(item,i)" *ngIf="item.connection != null && userInfo.id!=userDetail.id" >Cancel Request</button> -->
             
            <button type="button"    class="btn reachOutBtn mt-0 mb-0" *ngIf="userInfo.id==userDetail.id">Message</button>  
            <!-- <img src="assets/icons/three-dots.svg"/> -->
        </a>
        
    </div>
    <div style="text-align: center;padding: 0.8rem;margin-top: 20px;">
        <pagination-controls (pageChange)="pageChanged($event)">
        </pagination-controls>
    </div>   
</div>
</div>
</div>
    <div >
        <!-- <ul>
            <li *ngFor="let item of myNetworkList| paginate: config ;let i = index;">
                <div class="inline">
                    <img class="profile"
                        [src]="item.profilePic != null ? item.profilePic:'assets/logo/profile_avater.png'">
                    &nbsp;
                    <div>
                        <a (click)="goToProfile(item.userId)">
                            <h6>{{item.user.firstName}} {{item.user.lastName}}</h6>
                            <label>{{item.user.headline}}</label>
                        </a>
                    </div>
                    <div class="menu">
                         
                        <button class="btn btn-primary btn-sm" *ngIf="!isShow && member != item.userId">Reach
                            Out</button>
                    </div>
                </div>
            </li>
            <hr>
        </ul> -->
        <!-- <div>
            <div style="text-align: center;padding: 0.8rem;">
                <pagination-controls (pageChange)="pageChanged($event)">
                </pagination-controls>
            </div>
        </div> -->
    </div>
    <div *ngIf="myNetworkList.length == 0">
        <label>You dont have any connection.</label>
    </div>
</div>
<!-- ================  Message Send Model  ======================= -->

<div class="skillModel">
    <div class="modal" id="skillModel" role="dialog">
        <div class="modal-dialog">
    
            <div class="modal-content p-3">
                <div class="modal-Custheader p-2">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h6 class="modal-title">Send Message</h6>
                </div>
                <div class="modal-body text-center">
                    <input maxlength="500" id="messageInput" [(ngModel)]="messageInput" class="form-control"  placeholder="Write a Message .. And press enter"   /> &nbsp;
                    <!-- <i class="fa fa-plus fab-add" (click)="addSkill()"></i> (keyup)="addSkillbyEnter()" -->
                    <button type="submit" class="btn modelBtn" (click)="sendMessage()">Send</button>
                   <span style="float: right;">{{messageInput.length}}</span> 
                </div>
                <!-- <div class="text-right p-2">
                    <button type="submit" class="btn btn-outline-primary" data-dismiss="modal">Submit</button>
                </div> -->
            </div>
        </div>
    </div>
    </div>