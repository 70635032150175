<div class="mainPagePopup">
<p class="t-text">
    How Many Interns You Want To Hire For This Position?
</p>
<div style="width: 90%;margin: 0 auto;">
    <div style="margin-top: 40px;display: flex;justify-content: space-between;font-size: 14px;">
        <p class="charge">Charges Per Hire</p>
        <p><i class="fa fa-minus-circle" aria-hidden="true"></i> 3
            <i class="fa fa-plus-circle" aria-hidden="true"></i> 3 X ₹ 500 = ₹ 1500

        </p>
    </div>
    <div style="margin-top: 10px;display: flex;justify-content: space-between;font-size: 14px;">
        <p class="charge"><i class="fa fa-check-square" aria-hidden="true"></i> Curate The Candidates For Me</p>
        <p>₹ 2000</p>
    </div>

    <div style="margin-top: 50px;display: flex;justify-content: space-between;font-size: 14px;">
      <p class="charge">Total</p>
    <p class="charge">₹ <span style="border: 1px solid black;padding: 5px 16px 5px 16px;">500</span></p>
    </div>
   


</div>
</div>