import { BehaviorSubject, Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NetworkApiService {
  baseUrl = environment.baseUrl;
  event: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) { }
  updateCountEvent() {
    this.event.next(null);
  }
  
  getHttpOptions(){
    return{
      headers: new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('content-type', 'application/json')
      .set("Authorization", "Bearer " + this.getToken())
    }
  }
  
  setToken(token) {
    localStorage.removeItem('authorization');
    localStorage.setItem('authorization', JSON.stringify(token));
  }

  getToken(): string {
    return JSON.parse(localStorage.getItem('authorization'));
  }
  sendRequest(obj): Observable<any> {
    return this.http.post(this.baseUrl + `connection`, obj);
  }

  acceptRequest(obj): Observable<any> {
    return this.http.put(this.baseUrl + `connection`, obj);
  }

  // getMyNetwork(userId): Observable<any> {
  //   return this.http.get(this.baseUrl + `connection/userId?userId=${userId}`);
  // }

   
  getConnectionRequest(userId): Observable<any> {
    return this.http.get(this.baseUrl + `connection/getAllConnectionRequest/${userId}`,  this.getHttpOptions());
  
    //return this.http.get(this.baseUrl + `connection/user?userId=${userId}`);
  }
  getSugestionList(userId, pageNo,size): Observable<any> {
    return this.http.get(this.baseUrl + `connection/indsustry?page=${pageNo}&size=${size}&userId=${userId}`);
  }
  getNetworkSuggestions(userId, pageNo, size): Observable<any> {
    return this.http.get(this.baseUrl + `connection/network?page=${pageNo}&size=${size}&userId=${userId}`, this.getHttpOptions());
  }
  // getPeopleYouKnow(userId): Observable<any> {
  //   return this.http.get(this.baseUrl + `connection/indsustry?page=${0}&size=15&userId=${userId}`);
  // }
  getPeopleYouKnow(userId): Observable<any> {    
    return this.http.get(this.baseUrl + `connection/network?page=${0}&size=5&userId=${userId}`,this.getHttpOptions());
  }
  followRequest(obj): Observable<any> {
    return this.http.post(this.baseUrl + `connection/follow`, obj);
  }
  /* =========== Followe============== */
  getFollowers(userId): Observable<any> {
    return this.http.get(this.baseUrl + `connection/followers?userId=${userId}`);
  }
  getFollowing(userId): Observable<any> {
    return this.http.get(this.baseUrl + `connection/following?userId=${userId}`);
  }
}
