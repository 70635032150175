
<div class="card p-22" *ngIf="post">
<div class="row centerSection">
    <div class="col-md-4 customSliderGrid" *ngIf="post.images.length">
        <div class="curveCount" >
            <img src="../../.././assets/icons/Rectangle-slider-counter.svg" />
            <div class="countPost">{{post.images.length}}</div>
        </div>
        <div id="custCarousel_{{post.id}}" *ngIf="post.images.length" class="carousel slide" data-ride="carousel" align="center">
            <!-- slides -->
            <div class="carousel-inner">
                <!-- <div   class="carousel-item  ">
                    <img src="https://i.imgur.com/weXVL8M.jpg" class="img-fluid">
                  </div> -->
              <div *ngFor="let item of post.images; let i = index" class="carousel-item {{(i==0)?'active':''}}  ">
                <img style="cursor: pointer;" (click)="open(post.images)" src="{{item}}" alt="Hills">
              </div>                
            </div>
    
            <!-- Left right -->
            <a class="carousel-control-prev" href="#custCarousel_{{post.id}}" data-slide="prev">
              <span class="carousel-control-prev-icon"></span>
            </a>
            <a class="carousel-control-next" href="#custCarousel_{{post.id}}" data-slide="next">
              <span class="carousel-control-next-icon"></span>
            </a>
    
            <!-- Thumbnails -->
            <!-- <ol class="carousel-indicators list-inline">
              <li class="list-inline-item active">
                <a id="carousel-selector-0" class="selected" data-slide-to="0" data-target="#custCarousel">
                  <img src="https://i.imgur.com/weXVL8M.jpg" class="img-fluid">
                </a>
              </li>
    
              <li class="list-inline-item">
                <a id="carousel-selector-1" data-slide-to="1" data-target="#custCarousel">
                  <img src="https://i.imgur.com/Rpxx6wU.jpg" class="img-fluid">
                </a>
              </li>
    
              <li class="list-inline-item">
                <a id="carousel-selector-2" data-slide-to="2" data-target="#custCarousel">
                  <img src="https://i.imgur.com/83fandJ.jpg"  class="img-fluid">
                </a>
              </li>
    
               <li class="list-inline-item">
                <a id="carousel-selector-2" data-slide-to="3" data-target="#custCarousel">
                  <img src="https://i.imgur.com/JiQ9Ppv.jpg"  class="img-fluid">
                </a>
              </li>
             </ol> -->
          </div>
        <!-- <div *ngIf="post.image != null" class="text-center">
            <img class="post_image" [src]="post.image" (click)="open(post.image)" />
        </div> -->
    </div>
    <div class="{{(post.images.length>0)?'customSliderRightGrid':'customSliderRightGridFullWidth'}} customSliderRightGrid">
        <div class="inline">
            <app-profile-photo [user]="post.user"></app-profile-photo>
            <div (click)="selectUser(post.user.id)" class="pointer">
                <h6>{{post.user.firstName}} {{post.user.lastName}}</h6>
                <p class="postTime" *ngIf="!post.isEdited"> {{post.createdAt|date: 'dd MMM, yyyy '}}{{post.createdAt|date: 'shortTime'}}</p>
                <p class="postTime" *ngIf="post.isEdited"> {{post.updatedAt|date: 'dd MMM, yyyy '}}{{post.updatedAt|date: 'shortTime'}} <span *ngIf="post.isEdited"> (Edited)</span></p>
                <!-- <label>{{post.title}}</label> -->
            </div>
            <div class="menu">
                <!-- <label>{{post.date | date: 'dd-MMM-yyyy' }}&nbsp;
                    {{post.time}}
                </label> &nbsp; -->

                <i *ngIf="post.user.id == userInfo?.id" class="fa" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="../../../assets/icons/three-dots.svg"></i>
                <div class="dropdown-menu dropdown-menu-left" style="border-radius:5px ;color: black;"
                    aria-labelledby="dropdownMenuButton">
                    <ul>
                        <li (click)="viewPost(post)">View</li>
                        <li (click)="editPost(post)">Edit</li>
                        <li (click)="deletePost(post.id)">Delete</li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <hr class="mt-2 mb-2"> -->
        <div class="content" (click)="hide()">
            <!-- <div class="postTitle">Weekly Update:</div> -->
            <read-more class="desc" [text]="post.description" [maxLength]="200"></read-more>
            <!-- <p></p> -->
    
            <!-- <div *ngIf="post.image != null" class="text-center">
                <img class="post_image" [src]="post.image" (click)="open(post.image)" />
            </div> -->
        </div>
        <!-- <hr> -->


<!-- starat -->
<ul class="likeShareDiv">
    <li class="inline">
         
            <div class="likeBack">
            <svg class="" (click)="likePost()" style="cursor: pointer;"> <path class="likeThumb" d="M849.415,536v-6.233h0l1.289-4.529A1.737,1.737,0,0,1,852.385,524a1.638,1.638,0,0,1,.636.126,1.592,1.592,0,0,1,.533.361,1.528,1.528,0,0,1,.442,1.156l-.09,1.79h3.6a1.03,1.03,0,0,1,.515.135,1.888,1.888,0,0,1,.982,1.618,1.839,1.839,0,0,1-.392,1.131,1.955,1.955,0,0,1,.085.54,1.831,1.831,0,0,0-.311,1.67,1.837,1.837,0,0,1-.391,1.131,1.819,1.819,0,0,1,.081.539,1.872,1.872,0,0,1-1.174,1.724,1.032,1.032,0,0,1-.4.079ZM847,536v-5.76a.47.47,0,0,1,.47-.471h.51a.47.47,0,0,1,.47.471V536Z" transform="translate(-838 -516)" /></svg>
            
            </div>
            &nbsp;<div class="count" >{{post.noOfLikes}} Likes</div>
        
    </li>
    
    <li class="inline" style="cursor: pointer;" (click)="commentHideShow(post)">
         <div class="likeBack">
            <svg> <path class="likeThumb" d="M949.028,536a5.932,5.932,0,0,1-2.359-.486.74.74,0,0,0-.383-.054l-2.555.514h-.139a.591.591,0,0,1-.216-.042.6.6,0,0,1-.361-.676l.525-2.53a.641.641,0,0,0-.053-.383A5.977,5.977,0,0,1,948.969,524c.194,0,.39.01.582.029a5.974,5.974,0,0,1,3.656,1.737l.03.012A5.978,5.978,0,0,1,949.028,536Zm2.347-6.6a.6.6,0,0,0-.553.827.6.6,0,0,0,.22.268.6.6,0,0,0,.754-.075.6.6,0,0,0-.194-.975A.583.583,0,0,0,951.375,529.4Zm-2.389,0a.6.6,0,0,0-.553.827.6.6,0,0,0,.221.268.6.6,0,0,0,.754-.075.6.6,0,0,0-.194-.975A.583.583,0,0,0,948.986,529.4Zm-2.389,0a.6.6,0,1,0,.227.046A.583.583,0,0,0,946.6,529.4Z" transform="translate(-935.001 -516)" /></svg>
             </div>

        &nbsp;<div class="count" >{{(post.commentsOnPostList)?post.commentsOnPostList.length:0}} Comments</div> &nbsp;
        
    </li>
     
</ul>



        <!-- end -->
    
        <!-- <ul class="likeShareDiv">
            <li class="inline">
                <img class="likeIcon" src="../../../assets/icons/Like.svg" (click)="likePost()" />&nbsp;<div class="count"  >{{post.noOfLikes}} Likes</div>

                 <div class="circle" [ngClass]="post.isLikedByUser == 'true'?'selected':''" (click)="likePost()"><i
                        [ngClass]="post.isLikedByUser == 'true'?'fa fa-thumbs-up':'fa fa-thumbs-o-up'"></i></div> -->
            <!-- </li> -->
            <!-- <li class="inline">
                <div class="count">{{post.noOfDislikes}}</div> &nbsp;
                <div class="circle" [ngClass]="post.isDislikedByUser == 'true'?'selected':''" (click)="dislikePost()"><i
                        [ngClass]="post.isDislikedByUser == 'true'?'fa fa-thumbs-down':'fa fa-thumbs-o-down'"></i></div>
            </li> -->
            <!-- <li class="inline" (click)="commentHideShow(post)" style="cursor: pointer;"> -->
                <!-- <img src="../../../assets/icons/Comment.svg" />&nbsp;<div class="count">{{(post.commentsOnPostList!= null)?post.commentsOnPostList.length:0}} Comments</div> &nbsp; -->
                <!-- <div class="circle" [ngClass]="post.commentsOnPostList.length > 0?'selected':''"><i
                        [ngClass]="post.commentsOnPostList.length > 0?'fa fa-comment':'fa fa-comment-o'"></i></div> -->
            <!-- </li> -->
            <!-- <li class="inline">
                <div class="circle"><i class="fa fa-share-square-o"></i> </div>
            </li> -->
            <!-- <li>
                <div class="circle"><i class="fa fa-comment"></i></div>
            </li>
           -->
        <!-- </ul> -->  
        <!-- <hr> -->
        <!-- replay & Comment Section start -->
      
    </div>
     <!-- <div class="col-md-7 offset-md-5 pl-2"> -->
    <div class="col-md-12">
       
        <!-- <hr> -->
        <!-- replay & Comment Section start -->
        <div *ngIf="post.comment"  class="inline pt-4 pb-4">
            <img class="profileComment" [src]="userInfo?.profilePicture" /> &nbsp;
            <input type="text" (keydown.enter)="addComment(post)" [(ngModel)]="comment" class="form-control"
                placeholder="Write a Comment.."> &nbsp;
    
            <i class="fa fa-smile-o cc" (click)="toggleEmoji(2)"></i>
            <emoji-mart style="position: absolute; bottom: -3rem; right: 10px; z-index:9" (emojiSelect)="addEmoji($event)"
                *ngIf="emojiToggled" set="google" sheetSize="32">
            </emoji-mart>
            <div class="circle pt-2" style="color: #293266;">
                <i class="fa fa-paper-plane" aria-hidden="true" *ngIf="this.comment != ''" (click)="addComment(post)"></i>
    
            </div>
    
        </div>
        <div  *ngIf="post.comment"  class="comment-list">
            <comment-list  *ngFor="let comment of post.commentsOnPostList" [rootType]="post"  [commentsOnPostList]="commentsOnPostList" [CommentPost]="POstData" [comment]="comment"></comment-list>
        </div>
        <div *ngIf="post.comment"  class="comment-list">
            <div class="search-results" infinite-scroll [infiniteScrollDistance]="scrollDistance"
                [infiniteScrollUpDistance]="scrollUpDistance" (scrolled)="onScrollDown()" [scrollWindow]="false"
                [infiniteScrollThrottle]="throttle">
                <comment-list *ngFor="let comment of commentList" [rootType]="post"  [CommentPost]="POstData" [comment]="comment"></comment-list>
            </div>
        </div>
    <!-- replay & Comment Section start end -->

        <!-- <button *ngIf="!isShowComment && !this.isLoader" class="comment-btn" (click)="getAllCommentsByPostId(post.id, 0)"><i
                class="fa fa-arrow-down" aria-hidden="true"></i>&nbsp;
            Show All</button>
        <button *ngIf="isShowComment && !this.isLoader" class="comment-btn" (click)="hideAllComment()"><i
                class="fa fa-arrow-up" aria-hidden="true"></i> &nbsp; Hide</button>
        <button *ngIf="this.isLoader" class="comment-btn"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
            &nbsp;&nbsp;Loading...</button> -->
    </div>
    
  
    
</div>
</div>

<ng-template #mymodal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle" style="font-size: 20px;">Update post</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="inline">
                <img class="profile" [src]="userInfo?.profilePicture" /> &nbsp;
                <div>
                    <h6 style="padding-top: 5px;">{{userInfo.firstName}}&nbsp;{{userInfo.lastName}}</h6>
                    <label>{{userInfo.headline}}</label>
                </div>
            </div><br />
            <textarea rows="5" id="description" [(ngModel)]="description" #myTextArea (keyup)="getCaretPos(myTextArea)"
                (click)="getCaretPos(myTextArea)" placeholder="What do you want to share?"></textarea>
            <emoji-mart (emojiSelect)="addEmojiOnPost($event)" *ngIf="postEmojiToggled" set="google" sheetSize="32">
            </emoji-mart>
            <i class="fa fa-smile-o" style="color: darkgray; font-size: 20px;" (click)="toggleEmoji(1)"></i>

            <hr>
            <div>
                <div class="btn-wrap">
                    <button class="btn btn-primary btn-sm img-btn-post" (click)="getImage()"><i class="fa fa-picture-o">
                        </i> &nbsp; Image</button>
                </div>


            </div>
            <div class="image" *ngIf="postImage != null && postImage != '' && postImage != undefined && postImage.length > 0">
                <div class="file_icon">
                    <img class="image_wrapper" id="image" [src]="postImage" /><span><i
                            *ngIf="postImage != null || postImage != ''" (click)="removeImage()"
                            class="fa fa-remove fa-1x fab"></i></span>
                </div>

            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="updatePostAdd()"
                [disabled]="description == null">Share</button>
        </div>
    </div>
</ng-template>
<button data-target="#editModel" hidden #openModel data-backdrop="static" data-keyboard="false"
    data-toggle="modal"></button>
<input type="file" accept="image/*" hidden #imagePicker (change)="changeListener($event)" />