import * as $ from 'jquery';

import { Component, Input, OnInit } from '@angular/core';

import { ProfileService } from '../../profile.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-about-info',
  templateUrl: './about-info.component.html',
  styleUrls: ['./about-info.component.scss']
})
export class AboutInfoComponent implements OnInit {
  @Input() userInfo: any;
  userDetail:any;
  aboutContent: string;
  constructor(private toastr: ToastrService,
    private route: ActivatedRoute,
    private profileService: ProfileService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getDetail()
    this.profileService.isUserInfoUpdated.subscribe( value => {
      if(value == true){
        this.getDetail();
      }
    });
  }
  getDetail() {
    this.route.queryParams.subscribe(param => {   
      this.profileService.getUserInfo(param.visit).subscribe(res => {
        if (res.success) {
          this.userDetail = res.data;
          console.log(res.data)
          this.aboutContent = this.userDetail.bio

                    
           
        }
      })
    })
  }


  updateAbout() {
    if (this.aboutContent != "") {
      let obj = {
        id: this.userInfo.id,
        bio: this.aboutContent
      }
      this.profileService.updateProfileInfo(obj).subscribe(res => {
        if (res.success) {
          this.aboutContent = res.data.bio;
          // this.toastr.success(res.message);
          this._snackBar.open(res.message, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'style-success'
          });
          $("#aboutModel .close").click();
        } else {
          // this.toastr.error(res.message);
          this._snackBar.open(res.message, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'style-error'
          });
        }
      })
    }
  }

}
