import * as CryptoJS from 'crypto-js';

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { HomeService } from './../../home/service/home.service';
import { ProfileService } from '../profile.service';
import { postService } from './../../../shared/post-container/post-container.service';
import { CroppedEvent } from 'ngx-photo-editor';
import { AwsService } from 'src/app/shared/AWS-Service/aws.service';

@Component({
  selector: 'app-timline-post',
  templateUrl: './timline-post.component.html',
  styleUrls: ['./timline-post.component.scss']
})
export class TimlinePostComponent implements OnInit {
  @Output() postPanelCompnent = new EventEmitter<{  }>();
  userInfo: any;
  postList: any = [];
  show: boolean = false;
  currentPage: number = 0;
  throttle = 150;
  scrollDistance = 2;
  emojiToggled: boolean = false;
  description: string;
  emoji: any;
  editpostitem: any;
  images: any = [];
  postId: any;
  postImage: any;
  isVisible: boolean = false;
  imageChangedEvent: any;
  tagkeyword: any;
  section: any;
  userList: any[];
  isShow: boolean;
  constructor(
    private profileService: ProfileService,
    private routes: ActivatedRoute,
    private awsService: AwsService,
    private homeService: HomeService,
    private postService: postService
  ) { }

  ngOnInit(): void {
    
    this.userInfo = JSON.parse(localStorage.getItem('user'));
    this.getPostList(0) 
    this.postService.deleteEvent.subscribe(data => {
      this.postList.map((post, index) => {
        if (data == post.id) {
          this.postList.splice(index, 1)
        }
      })
    })
    this.postService.updateEvent.subscribe(data => {
      this.postList.map((post) => {
        if (data.id == post.id) {
          return [post.description = data.description, post.image = data.image]
        }
      })
    })
  }

  // getUserInfo() {
  //   this.routes.queryParams.subscribe(param => {
  //     let user = JSON.parse(localStorage.getItem('user'))
  //     this.profileService.getUserInfo(param.visit).subscribe(res => {
  //       if (res.success) {
  //         this.userInfo = res.data;
  //         if (this.userInfo.id == user.id) {
  //           this.userInfo['isOwener'] = true;
  //         } else {
  //           this.userInfo['isOwener'] = false;
  //         };
  //         this.getPostList(this.currentPage)

  //       }
  //     })
  //   })
  // }
  getPostList(currentPage) {
    this.routes.queryParams.subscribe(param => {   
    
      if(this.userInfo.id == param.visit){
        this.show = true
      }
    this.homeService.getPostListByUserId(param.visit, currentPage).subscribe(res => {
      if (res.success) {
        this.postList = res.data;         
      }
    })
  })
  }

  /* ======================  infinite Scroll  =========================== */
  onScrollDown() {
    this.currentPage += 1
    this.getPostList(this.currentPage);

  }

  toggleEmoji() {
    this.emojiToggled = !this.emojiToggled;
  }
  addEmoji(event) {
    this.description = document.getElementById("newSection").innerHTML
    this.emoji = event.emoji.native;
    this.description = [this.description, this.emoji].join(''); // [this.description.slice(0, this.caretPos), this.emoji, this.description.slice(this.caretPos)].join('');
    this.toggleEmoji();
  }

  postItemUpdate(postItem){
    this.editpostitem = postItem
    console.log("updatepost=>",postItem)
    // $('#postModal').modal('show');    
    this.description =postItem.description; 
    // this.images = publish.images   
    for  (let i = 0 ; i < postItem.images.length ; i ++){
      this.images.push(postItem.images[i])
    }
    this.postId = postItem.id; 
    this.isVisible = true;  
  }
  onNewPostAdd(){
    this.description = document.getElementById("newSection").innerHTML
    document.getElementById("newSection").innerHTML = ""
    this.isVisible = false;
    if(this.editpostitem != null){
      this.editpostitem.description  = this.urlify(this.description),  
      this.editpostitem.images  = this.images
    }else{
      this.editpostitem = {
        images:this.images, 
        description: this.urlify(this.description),
        user: this.userInfo
      }
    }
      this.homeService.addNewPost(this.editpostitem).subscribe(res => {
              if (res.success) {
                this.editpostitem = null
                this.getPostList(0);
                this.homeService.emitEvent();
                this.images =[]
                this.postImage =null
                this.description =""
                this.postPanelCompnent.emit()
              }
            })
  }

  urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    let url = text.replace(urlRegex, (url) => {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    })
    return url;
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
  }

  selectUser(item){
    let userName = ""
    let link = "";
    if(item != null && item.firstName != null){
      userName =  item.firstName + " "+item.lastName;
      link = "#/profile/timeline?visit="+item.id;
    }   
    
    console.log(this.tagkeyword)
    let txt = this.section.innerHTML.replace(this.tagkeyword, "@");
    this.section.innerHTML = txt+" <a href='"+link+"' >"+userName+"</a>"
    this.description = this.section.innerHTML;
    this.userList = [];
    this.isShow = false;   
  }

//----------tag function ----------------------------
  tagFuction(keyWord) {
    this.tagkeyword = keyWord;
    if (keyWord != "") {
      keyWord = keyWord.slice(1);
     // let userId = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('Bolstart'), "Bollstart").toString(CryptoJS.enc.Utf8)).id;
      let userId = this.userInfo.id;
     
      this.profileService.searchUserOnlyFirstName(keyWord, userId).subscribe(res => {
        if (res.success) {          
          this.userList = res.data;
          this.isShow = true;
        }else {
          this.userList = [];
          this.isShow = false;
        }
      })
    } else {
      this.userList = [];
      this.isShow = false;
    }
  }   
  findTag(field,section){
    this.section = document.getElementById(section);
    // this.description = this.section.innerHTML;
    let keyWord = field.innerText;
    let arrOfStr = keyWord.split(" @");    
    let key = arrOfStr[arrOfStr.length-1];

    if(keyWord[0] == '@' ){
      console.log("function call")
      this.tagFuction(keyWord);
    } 
    if(!key.includes(" ") && keyWord.includes(" ") ){
      console.log("function aganin  call")
       key = "@"+key;       
       this.tagFuction(key);        
    } 
    if(keyWord.trim().length<1){
      this.userList = [];
      this.isShow = false;
    }    

  }

  imageCropped(event: CroppedEvent) {
    this.awsService.uploadFile(event.file, "postimage").then(res => {
      this.images.push(res);
    })
  }

  close(){
    this.isVisible = false;
  }

  communication(obj){
    switch(obj.action){
      case "POST_LIKE":
        this.ngOnInit() ;
        break;

    }
    console.log("parent call ");
  }
}
