<div class="sidebarNews">
    <div class="card position">
        <div class="card-header">
            
            <div class="flex w-100"><h6>Address Book</h6>
                <!-- <span class="infoIcon"><i _ngcontent-uov-c52="" aria-hidden="true" class="fa fa-info-circle"></i></span> -->
                <span class="adsChange"> View >></span>
            
            </div>
        </div>
        <div class="p-22">
            <!-- <img src="../../../../assets/icons/comingSoon.png" />
           <p class="commingSoon">Coming Soon</p> -->
           <button class="userProfileBtn"> Mark 1 <img class="ml-2 btnIconwidth" src="../../../../assets/icons/pencil-square.svg" /></button>
           <button class="userProfileBtn"> Mark 2 <img class="ml-2 btnIconwidth" src="../../../../assets/icons/pencil-square.svg" /></button>
           <button class="userProfileBtn"> Mark 3 <img class="ml-2 btnIconwidth" src="../../../../assets/icons/pencil-square.svg" /></button>
           <button class="userProfileBtn"> Mark 4 <img class="ml-2 btnIconwidth" src="../../../../assets/icons/pencil-square.svg" /></button>
           <button class="userProfileBtn"> Mark 5 <img class="ml-2 btnIconwidth" src="../../../../assets/icons/pencil-square.svg" /></button>
           <!-- <div class="mark">

                 Mark 1 <img class="ml-2" src="../../../../assets/icons/pencil-square.svg" />
           </div>
           <div class="mark">
                Mark 2 <img class="ml-2" src="../../../../assets/icons/pencil-square.svg" />
            </div>
            <div class="mark">
                Mark 3 <img class="ml-2" src="../../../../assets/icons/pencil-square.svg" />
            </div>
            <div class="mark">
                Mark 4 <img class="ml-2" src="../../../../assets/icons/pencil-square.svg" />
            </div>
            <div class="mark">
                Mark 5 <img class="ml-2" src="../../../../assets/icons/pencil-square.svg" />
            </div> -->
            
        </div>
        <div class="moreLink">View More</div>
        
    </div>
</div>