<div class="card p-22">     
    <div class="row centerSection">
        <div class="col-md-4 customSliderGrid" *ngIf="publish.graphAndImages.length>0">
            <div class="curveCount">
                <img src="../../.././assets/icons/Rectangle-slider-counter.svg" />
                <div class="countPost">{{publish.graphAndImages.length}}</div>
            </div>
            <!-- <ngb-carousel *ngIf="publish.graphAndImages.length>0">{{publish.graphAndImages.length}}
                <ng-template  ngbSlide *ngFor="let image of publish.graphAndImages; let i = index">
                  <img [src]="image" alt="Random slide">
                </ng-template>
              </ngb-carousel> -->

<!-- start slider -->
<div id="custCarousel{{publish.id}}" *ngIf="publish.graphAndImages.length" class="carousel slide" data-ride="carousel" align="center">
    <!-- slides -->
    <div class="carousel-inner">
         
      <div *ngFor="let item of publish.graphAndImages; let i = index" class="carousel-item {{(i==0)?'active':''}}  ">
        <img style="cursor: pointer;" (click)="open(publish.graphAndImages)" src="{{item}}" alt="Hills">
      </div>                
    </div>

    <!-- Left right -->
    <a class="carousel-control-prev" href="#custCarousel{{publish.id}}" data-slide="prev">
      <span class="carousel-control-prev-icon"></span>
    </a>
    <a class="carousel-control-next" href="#custCarousel{{publish.id}}" data-slide="next">
      <span class="carousel-control-next-icon"></span>
    </a>

    
  </div>
<!-- stop slider -->














            <!-- <div id="custCarousel" class="carousel slide" data-ride="carousel" align="center">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="../../.././assets/slide2.png" alt="Hills">
                      </div>
            
                      <div class="carousel-item">
                        <img src="../../.././assets/slide1.png" alt="Hills">
                      </div>
            
                      <div class="carousel-item">
                        <img src="../../.././assets/slide2.png" alt="Hills">
                      </div>
                    <div class="carousel-item"  *ngFor="let image of publish.images; let i = index">
                        <img [src]="image" alt="Hills">
                    </div>
        
                  
                </div>
        
                 Left right -->
                <!-- <a class="carousel-control-prev" href="#custCarousel" data-slide="prev">
                  <span class="carousel-control-prev-icon"></span>
                </a>
                <a class="carousel-control-next" href="#custCarousel" data-slide="next">
                  <span class="carousel-control-next-icon"></span>
                </a> -->
        
                <!-- Thumbnails -->
                <!-- <ol class="carousel-indicators list-inline">
                  <li class="list-inline-item active">
                    <a id="carousel-selector-0" class="selected" data-slide-to="0" data-target="#custCarousel">
                      <img src="https://i.imgur.com/weXVL8M.jpg" class="img-fluid">
                    </a>
                  </li>
        
                  <li class="list-inline-item">
                    <a id="carousel-selector-1" data-slide-to="1" data-target="#custCarousel">
                      <img src="https://i.imgur.com/Rpxx6wU.jpg" class="img-fluid">
                    </a>
                  </li>
        
                  <li class="list-inline-item">
                    <a id="carousel-selector-2" data-slide-to="2" data-target="#custCarousel">
                      <img src="https://i.imgur.com/83fandJ.jpg"  class="img-fluid">
                    </a>
                  </li>
        
                   <li class="list-inline-item">
                    <a id="carousel-selector-2" data-slide-to="3" data-target="#custCarousel">
                      <img src="https://i.imgur.com/JiQ9Ppv.jpg"  class="img-fluid">
                    </a>
                  </li>
                 </ol> -->
              <!-- </div> --> 
            <!-- <div *ngIf="post.image != null" class="text-center">
                <img class="post_image" [src]="post.image" (click)="open(post.image)" />
            </div> -->
        </div>
        <div class="{{(publish.graphAndImages.length>0)?' customSliderRightGrid':'customSliderRightGridFullWidth'}}">
            <div class="inline">
                <app-profile-photo [user]="publish.user"></app-profile-photo>
                <div >
                    <h6 (click)="selectUser(publish.user.id)" class="pointer">{{publish.user.firstName}} {{publish.user.lastName}}  </h6><h6 class="mr-1" style="font-weight:300;">for</h6><h6 (click)="selectCompany(publish.group.addressBook.company.id)" class="pointer">{{publish.group.addressBook.company.name}}</h6>
                    <p class="postTime"> {{publish.createdAt|date: 'dd MMM, yyyy '}}{{publish.createdAt|date: 'shortTime'}}</p>
                    <!-- <label>{{post.title}}</label> -->
                </div>
                <div class="menu">
                    <!-- <label>{{post.date | date: 'dd-MMM-yyyy' }}&nbsp;
                        {{post.time}}
                    </label> &nbsp; -->
    
                    <i *ngIf="publish.user.id == userInfo?.id" class="fa" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="../../../assets/icons/three-dots.svg"></i>
                    <div class="dropdown-menu dropdown-menu-left" style="border-radius:5px ;color: black;"
                        aria-labelledby="dropdownMenuButton">
                        <ul>
                            <!-- <li (click)="deletePublish(publish.id)">Delete</li> -->
                            <!-- <li (click)="getPost(publish,mymodal)">Edit</li> -->
                            <li (click)="viewPublish(publish)">View</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <hr class="mt-2 mb-2"> -->
            <div class="content" (click)="hide()">
                <div class="postTitle">Weekly Update:</div>
                <read-more class="desc" [text]="publish.description" [maxLength]="200"></read-more>
            <!-- <app-graph-container *ngIf="publish.charts.length >0" [chartDataList]="publish.charts" ></app-graph-container> -->
                <!-- <p></p> -->
        
                <!-- <div *ngIf="post.image != null" class="text-center">
                    <img class="post_image" [src]="post.image" (click)="open(post.image)" />
                </div> -->
            </div>
            <!-- <hr> -->
        
            <ul class="likeShareDiv">
                <li class="inline">
                    <!-- <img (click)="likePublish()" style="cursor: pointer;" class="likeIcon" src="../../../assets/icons/Like.svg" />&nbsp;<div class="count" >{{publish.noOfLikes}} Likes</div> -->
                    
                        <div class="likeBack">
                        <svg class="" (click)="likePublish()" style="cursor: pointer;"> <path class="likeThumb" d="M849.415,536v-6.233h0l1.289-4.529A1.737,1.737,0,0,1,852.385,524a1.638,1.638,0,0,1,.636.126,1.592,1.592,0,0,1,.533.361,1.528,1.528,0,0,1,.442,1.156l-.09,1.79h3.6a1.03,1.03,0,0,1,.515.135,1.888,1.888,0,0,1,.982,1.618,1.839,1.839,0,0,1-.392,1.131,1.955,1.955,0,0,1,.085.54,1.831,1.831,0,0,0-.311,1.67,1.837,1.837,0,0,1-.391,1.131,1.819,1.819,0,0,1,.081.539,1.872,1.872,0,0,1-1.174,1.724,1.032,1.032,0,0,1-.4.079ZM847,536v-5.76a.47.47,0,0,1,.47-.471h.51a.47.47,0,0,1,.47.471V536Z" transform="translate(-838 -516)" /></svg>
                        <!-- <img (click)="likePublish()" style="cursor: pointer;" class="likeIcon" src="../../../assets/icons/likeThumb.svg" /> -->
                        </div>
                        &nbsp;<div class="count" >{{publish.noOfLikes}} Likes</div>
                    <!-- <div class="circle" [ngClass]="post.isLikedByUser == 'true'?'selected':''" (click)="likePost()"><i
                            [ngClass]="post.isLikedByUser == 'true'?'fa fa-thumbs-up':'fa fa-thumbs-o-up'"></i></div> -->
                </li>
                <!-- <li class="inline"> 
                    <div class="count">{{post.noOfDislikes}}</div> &nbsp;
                    <div class="circle" [ngClass]="post.isDislikedByUser == 'true'?'selected':''" (click)="dislikePost()"><i
                            [ngClass]="post.isDislikedByUser == 'true'?'fa fa-thumbs-down':'fa fa-thumbs-o-down'"></i></div>
                </li> -->
                <li class="inline" style="cursor: pointer;" (click)="commentHideShow(publish)">
                    <!-- <img src="../../../assets/icons/Comment.svg" /> -->
                    <div class="likeBack">
                        <svg> <path class="likeThumb" d="M949.028,536a5.932,5.932,0,0,1-2.359-.486.74.74,0,0,0-.383-.054l-2.555.514h-.139a.591.591,0,0,1-.216-.042.6.6,0,0,1-.361-.676l.525-2.53a.641.641,0,0,0-.053-.383A5.977,5.977,0,0,1,948.969,524c.194,0,.39.01.582.029a5.974,5.974,0,0,1,3.656,1.737l.03.012A5.978,5.978,0,0,1,949.028,536Zm2.347-6.6a.6.6,0,0,0-.553.827.6.6,0,0,0,.22.268.6.6,0,0,0,.754-.075.6.6,0,0,0-.194-.975A.583.583,0,0,0,951.375,529.4Zm-2.389,0a.6.6,0,0,0-.553.827.6.6,0,0,0,.221.268.6.6,0,0,0,.754-.075.6.6,0,0,0-.194-.975A.583.583,0,0,0,948.986,529.4Zm-2.389,0a.6.6,0,1,0,.227.046A.583.583,0,0,0,946.6,529.4Z" transform="translate(-935.001 -516)" /></svg>
                        <!-- <img (click)="likePublish()" style="cursor: pointer;" class="likeIcon" src="../../../assets/icons/likeThumb.svg" /> -->
                        </div>

                    &nbsp;<div class="count" >{{(publish.commentsOnPublishList)?publish.commentsOnPublishList.length:0}} Comments</div> &nbsp;
                    <!-- <img src="../../../assets/icons/Comment.svg" />&nbsp;<div class="count" *ngIf="publish.commentsOnPublishList.length > 0">{{publishst.commentsOnPublishList.length}} Comments</div> &nbsp; -->
                    
                    <!-- <div class="circle" [ngClass]="post.commentsOnPostList.length > 0?'selected':''"><i
                            [ngClass]="post.commentsOnPostList.length > 0?'fa fa-comment':'fa fa-comment-o'"></i></div> -->
                </li>
                <!-- <li class="inline">
                    <div class="circle"><i class="fa fa-share-square-o"></i> </div>
                </li> -->
                <!-- <li>
                    <div class="circle"><i class="fa fa-comment"></i></div>
                </li>
               -->
            </ul>
            <!-- <hr> -->
            <!-- replay & Comment Section start -->
        </div>
            <div class="col-md-12">
            <div  *ngIf="publish.comment" class="inline pt-4 pl-0 pb-4">
                <img class="profileComment" [src]="userInfo?.profilePicture" /> &nbsp;
                <input type="text" (keydown.enter)="addComment(publish)" [(ngModel)]="comment" class="form-control"
                    placeholder="Write a Comment.."> &nbsp;
        
                <i class="fa fa-smile-o cc" (click)="toggleEmoji(2)"></i>
                <emoji-mart style="position: absolute; bottom: -3rem; right: 10px; z-index:9" (emojiSelect)="addEmoji($event)"
                    *ngIf="emojiToggled" set="google" sheetSize="32">
                </emoji-mart>
                <div class="circle pt-2" style="color: #293266;">
                    <i class="fa fa-paper-plane" aria-hidden="true" *ngIf="this.comment != ''" (click)="addComment(publish)"></i>
        
                </div>
        
            </div>
            <div *ngIf="publish.comment" class="comment-list pl-4">
                <comment-list *ngFor="let comment of publish.commentsOnPublishList"  [rootType]="publish" [CommentPost]="POstData" [comment]="comment"></comment-list>
            </div>
            <div *ngIf="publish.comment"   class="comment-list">
                <div class="search-results" infinite-scroll [infiniteScrollDistance]="scrollDistance"
                    [infiniteScrollUpDistance]="scrollUpDistance" (scrolled)="onScrollDown()" [scrollWindow]="false"
                    [infiniteScrollThrottle]="throttle">
                    <comment-list *ngFor="let comment of commentList" [rootType]="publish" [CommentPost]="POstData" [comment]="comment"></comment-list>
                    
           
                </div>
            </div>
        <!-- replay & Comment Section start end -->
    
            <!-- <button *ngIf="!isShowComment && !this.isLoader" class="comment-btn" (click)="getAllCommentsByPostId(post.id, 0)"><i
                    class="fa fa-arrow-down" aria-hidden="true"></i>&nbsp;
                Show All</button> -->
            <button *ngIf="isShowComment && !this.isLoader" class="comment-btn" (click)="hideAllComment()"><i
                    class="fa fa-arrow-up" aria-hidden="true"></i> &nbsp; Hide</button>
            <button *ngIf="this.isLoader" class="comment-btn"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                &nbsp;&nbsp;Loading...</button>
        </div>
        
      
        
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Update publish11</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="inline">
                <img class="profile" [src]="userInfo?.profilePicture" /> &nbsp;
                <div>
                    <h6 style="padding-top: 5px;">{{userInfo.firstName}}&nbsp;{{userInfo.lastName}}</h6>
                    <label>{{userInfo.headline}}</label>
                </div>
            </div><br />
            <textarea rows="5" id="description" [(ngModel)]="description" #myTextArea (keyup)="getCaretPos(myTextArea)"
                (click)="getCaretPos(myTextArea)" placeholder="What do you want to share?"></textarea>
            <emoji-mart (emojiSelect)="addEmojiOnPost($event)" *ngIf="postEmojiToggled" set="google" sheetSize="32">
            </emoji-mart>
            <i class="fa fa-smile-o" style="color: darkgray; font-size: 20px;" (click)="toggleEmoji(1)"></i>

            <hr>
            <div>
                <div class="btn-wrap">
                    <button class="btn btn-primary btn-sm img-btn-post" (click)="getImage()"><i class="fa fa-picture-o">
                        </i> &nbsp; Image</button>
                </div>


            </div>
            <!-- <div class="image" *ngIf="postImage != null && postImage != '' && postImage != undefined && postImage.length > 0">
                <div class="file_icon">
                    <img class="image_wrapper" id="image" [src]="postImage" /><span><i
                            *ngIf="postImage != null || postImage != ''" (click)="removeImage()"
                            class="fa fa-remove fa-1x fab"></i></span>
                </div>

            </div> -->
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="updatePostAdd()"
                [disabled]="description == null">Share</button>
        </div>
    </div>
</ng-template>
 

<!-- <div class="card p-2">
    <div class="inline">
        <img (click)="selectUser(publish.userId)" class="profile pointer" [src]="publish.profilePicture"> &nbsp;
        <div (click)="selectUser(publish.userId)" class="pointer">
            <h6>{{publish.user.firstName}}</h6>
            <label>{{publish.title}}</label>
        </div>
        <div class="menu">
            <label>{{onChangeDateFormat(publish.createdAt)}}&nbsp;
                {{publish.time}}
            </label> &nbsp;
              <i *ngIf="publish.userId == userInfo?.id" class="fa fa-ellipsis-v" id="dropdownMenuButton"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
            <div class="dropdown-menu dropdown-menu-left" style="border-radius:5px ;color: black;"
                aria-labelledby="dropdownMenuButton">
                <ul>
                    <li (click)="deletePost(publish.id)">Delete</li>
                    <li (click)="getPost(publish,mymodal)">Edit</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="content">
        <read-more [text]="publish.description" [maxLength]="200"></read-more>
        <p></p>

        <div *ngIf="publish.image != null" class="text-center">
            <img class="post_image" [src]="publish.image" (click)="open(publish.image)" />
        </div>
        <div *ngIf="publish.chartUrls != null" class="text-center">
            <div *ngFor="let item of publish.chartUrls">
                <img class="post_image" [src]="item.chartUrl" (click)="openGraph(item)" />
            </div>

        </div>
    </div>

    <ul class="inline">
        <li class="inline">
            <div class="count">{{publish.noOfLikes}}</div> &nbsp;
            <div class="circle" [ngClass]="publish.isLikedByUser == 'true'?'selected':''" (click)="likePost()"><i
                    [ngClass]="publish.isLikedByUser == 'true'?'fa fa-thumbs-up':'fa fa-thumbs-o-up'"></i></div>
        </li>
        <li class="inline">
            <div class="count">{{publish.noOfDislikes}}</div> &nbsp;
            <div class="circle" [ngClass]="publish.isDislikedByUser == 'true'?'selected':''" (click)="dislikePost()"><i
                    [ngClass]="publish.isDislikedByUser == 'true'?'fa fa-thumbs-down':'fa fa-thumbs-o-down'"></i></div>
        </li>
        <li class="inline">
            <div class="count" *ngIf="publish.commentsOnPublishtList.length >">{{publish.commentsOnPublishtList.length}}</div> &nbsp;
            <div class="circle" [ngClass]="publish.commentsOnPublishtList.length > 0?'selected':''"><i
                    [ngClass]="publish.commentsOnPublishtList.length > 0?'fa fa-comment':'fa fa-comment-o'"></i></div>
        </li>
        <li class="inline">
            <div class="circle"><i class="fa fa-share-square-o"></i> </div>
        </li>
        <li>
            <div class="circle"><i class="fa fa-comment"></i></div>
        </li>
      
    </ul>
    <div class="inline pt-1">
        <img class="profile" [src]="userInfo?.profilePicture" /> &nbsp;
        <input type="text" (keydown.enter)="addComment()" [(ngModel)]="comment" class="form-control"
            placeholder="Comment..."> &nbsp;

        <i class="fa fa-smile-o cc" (click)="toggleEmoji(2)"></i>
        <emoji-mart style="position: absolute; bottom: -3rem; right: 10px;z-index:9" (emojiSelect)="addEmoji($event)"
            *ngIf="emojiToggled" set="google" sheetSize="32">
        </emoji-mart>
        <div class="circle pt-2" style="color: #293266;">
            <i class="fa fa-paper-plane" aria-hidden="true" *ngIf="this.comment != ''" (click)="addComment()"></i>

        </div>

    </div>
    <div *ngIf="!isShowComment" class="comment-list">
        <comment-list *ngFor="let comment of publish.commentsOnPublishtList" [comment]="comment"></comment-list>
    </div>
    <div *ngIf="isShowComment" class="comment-list">
        <div class="search-results" infinite-scroll [infiniteScrollDistance]="scrollDistance"
            [infiniteScrollUpDistance]="scrollUpDistance" (scrolled)="onScrollDown()" [scrollWindow]="false"
            [infiniteScrollThrottle]="throttle">
            <comment-list *ngFor="let comment of commentList" [comment]="comment"></comment-list>
        </div>
    </div>

    <button *ngIf="!isShowComment && !this.isLoader" class="comment-btn"
        (click)="getAllCommentsByPublishId(publish.id, 0)"><i class="fa fa-arrow-down" aria-hidden="true"></i>&nbsp;
        Show All</button>
    <button *ngIf="isShowComment && !this.isLoader" class="comment-btn" (click)="hideAllComment()"><i
            class="fa fa-arrow-up" aria-hidden="true"></i> &nbsp; Hide</button>
    <button *ngIf="this.isLoader" class="comment-btn"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
        &nbsp;&nbsp;Loading...</button>
</div> -->
<!-- <div *ngIf="publish.image != null" class="text-center">
    <img class="post_image" [src]="publish.image" (click)="open(publish.image)" />
</div>
<div *ngIf="publish.chartUrls != null" class="text-center">
    <div *ngFor="let item of publish.chartUrls">
        <img class="post_image" [src]="item.chartUrl" (click)="openGraph(item)" />
    </div> -->

<!-- <ng-template #mymodal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Create new post</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="inline">
                <img class="profile" [src]="userInfo?.profilePicture" /> &nbsp;
                <div>
                    <h6 style="padding-top: 5px;">{{userInfo.firstName}}&nbsp;{{userInfo.lastName}}</h6>
                    <label>{{userInfo.headline}}</label>
                </div>
            </div><br />
            <textarea rows="5" id="description" [(ngModel)]="description"
                placeholder="What do you want to share?"></textarea>
            <emoji-mart (emojiSelect)="addEmojiOnPost($event)" *ngIf="postEmojiToggled" set="google" sheetSize="32">
            </emoji-mart>
            <hr>
            <div style="display: flex;">
                <label>Add:</label>&nbsp;
                <i class="fa fa-smile-o" style="color: darkgray; font-size: 20px;" (click)="toggleEmoji(1)"></i>
                &nbsp; &nbsp;
                <i style="flex: 0.9;" class="fa fa-picture-o fileItemStyle" aria-hidden="true" (click)="getImage()">
                </i>
                <i *ngIf="postImage != undefined || postImage != ''" (click)="removeImage()"
                    class="fa fa-remove fa-1x fab"></i>

            </div>
            <div class="image" *ngIf="postImage != undefined || postImage != null">
                <div class="file_icon">
                    <img class="image_wrapper" id="image" [src]="postImage" />
                </div>

            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="updatePostAdd()"
                [disabled]="description == null">Share</button>
        </div>
    </div>
</ng-template> -->
<button data-target="#editModel" hidden #openModel data-backdrop="static" data-keyboard="false"
    data-toggle="modal"></button>
<input type="file" accept="image/*" hidden #imagePicker (change)="changeListener($event)" />