import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyApiService {
  baseUrl = environment.baseUrl;
  public isCompanyInfoUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  constructor(private http: HttpClient) { }
  
  registerCompany(obj): Observable<any> {
    return this.http.post(this.baseUrl + 'company', obj)
  }
  getCompanyDetail(companyId): Observable<any> {
    return this.http.get(this.baseUrl + `company/` + companyId)
  }

  getAllCompanies(page, size): Observable<any> {
    return this.http.get(this.baseUrl + `company/` + page +`/`+size)
  }

  updateCompany(obj): Observable<any> {
    return this.http.put(this.baseUrl + 'company', obj)
  }

  addMember(obj): Observable<any> {
    return this.http.post(this.baseUrl + 'company/addMember', obj)
  }
  getMemberList(companyId, page, size): Observable<any> {
    return this.http.get(this.baseUrl + `company/employee/` + companyId)
  }
  removeMember(id, companyId): Observable<any> {
    return this.http.get(this.baseUrl + `company/removeEmployee?userId=${id}&companyId=${companyId}`)
  }
  
  createAddressBookGroup(obj): Observable<any> {
    return this.http.post(this.baseUrl + `company/addressbook/group`, obj)
  }

  getAddressBookGroupsByCompanyId(companyId): Observable<any> {
    return this.http.get(this.baseUrl + `company/addressbook/group/company/`+ companyId)
  }

  getAddressBookGroupById(groupId): Observable<any> {
    return this.http.get(this.baseUrl + `company/addressbook/group/`+ groupId)
  }

  getAddressBookByCompanyId(companyId): Observable<any> {
    return this.http.get(this.baseUrl + `company/addressbook/`+ companyId)
  }
  getAddressBookById(id): Observable<any> {
    return this.http.get(this.baseUrl + `company/addressBook/getById?id=${id}`)
  }
  updateAddressBook(obj): Observable<any> {
    return this.http.put(this.baseUrl + `company/updateAddressBook`, obj)
  }
  addMemberInGroup(groupMember): Observable<any> {
    return this.http.post(this.baseUrl + `company/addressbook/group/member`, groupMember)
  }
  getMembersInGroup(addressBookGroupId): Observable<any> {
    return this.http.get(this.baseUrl + `company/addressbook/group/member/group/`+addressBookGroupId)
  }
  deleteAddressBookGroupMember(groupMemberId): Observable<any> {
    return this.http.delete(this.baseUrl + `company/addressbook/group/member/`+groupMemberId)
  }
  unsubscribeMember(groupMemberId, email): Observable<any> {
    return this.http.get(this.baseUrl + `company/addressbook/group/member/unsubscribe?groupId=`+groupMemberId+`&email=`+email)
  }
  subscribeMember(groupMemberId, email): Observable<any> {
    return this.http.get(this.baseUrl + `company/addressbook/group/member/subscribe?groupId=`+groupMemberId+`&email=`+email)
  }
  deleteAddressBook(id): Observable<any> {
    return this.http.delete(this.baseUrl + `company/addressBook/delete?id=${id}`)
  }
  assignEmployeeRole(userId, role, companyId): Observable<any> {
    return this.http.put(this.baseUrl + `company/employee/role?userId=${userId}&roleName=${role}&companyId=${companyId}`, {})
  }
  getCompanyPublishPost(companyId, page, userId): Observable<any> {
    return this.http.get(this.baseUrl + `publish/company/` + companyId)
  }

  getCompanyPublishPosts(companyId, userId): Observable<any> {
    return this.http.get(this.baseUrl + `publish/company/user/` + companyId + `/` + userId)
  }
  addEmployeeByAdmin(obj): Observable<any> {
    return this.http.post(this.baseUrl + 'company/admin/addEmployee', obj)
  }
  addEmployee(obj): Observable<any> {
    return this.http.post(this.baseUrl + 'company/employee', obj)
  }
  removeEmployee(id, companyId): Observable<any> {
    return this.http.delete(this.baseUrl + `company/employee/delete/${id}/${companyId}`)
  }

  getCompanyRoles(): Observable<any> {
    return this.http.get(this.baseUrl + `company/role`)
  }

  getCompanyEmployee(companyId): Observable<any> {
    return this.http.get(this.baseUrl + 'company/employee/' + companyId)
  }
}
