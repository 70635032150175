<p class="cross">&#10006;</p>
<div class="Popup center">
    <div class="description">
        Are You Sure you want to deactivate this Job Post for Java Developer?
    </div>
    <div class="btn-class">
        <button id="deactivate-btn">
            deactivate
        </button>
        <i>cancel</i>
    </div>
</div>