import { Component, OnInit,HostListener } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { JoblistService } from 'src/app/shared/service/joblist.service';
import { routingComponents } from '../jobs-routing.module';
import { Router,ActivatedRoute } from '@angular/router';
import { Popup31 } from '../popups/popup31/popup31.component';
import { PopupJobApplyer } from '../popups/popup_job_applyer/popup_job_applyer.component';
import { Popup6Component } from '../popups/popup6/popup6.component';
import { MatDialog } from '@angular/material/dialog';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import * as moment from 'moment';
declare var Razorpay:any; 
@Component({
  selector: 'app-job-post-form',
  templateUrl: './job-post-form.component.html',
  styleUrls: ['./job-post-form.component.scss']
})
export class JobPostFormComponent implements OnInit {
  public Editor = ClassicEditor;
  minDate = new Date();
  public onReady( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
    
}
  dbJobTitle = [];
  allJobsTitles:any=[];
  selected = 1;
  message:any = "Not yet stared";
  paymentId = "";
  error = "";
  title = 'angular-razorpay-intergration';
  options = {
   // "key": "rzp_test_og48OpHQPOoNeW", // test mode
    "key":"rzp_test_z8bORlhl06oBKl",
    "amount": "200",
    "name": "Suraj Patil",
    "description": "Web Development",
    "image": "",
    "order_id": "",
    "handler": function (response: any) {
      var event = new CustomEvent("payment.success",
        {
          detail: response,
          bubbles: true,
          cancelable: true
        }
      );
      window.dispatchEvent(event);
    },
    "prefill": {
      "name": "",
      "email": "",
      "contact": ""
    },
    "notes": {
      "address": ""
    },
    "theme": {
      "color": "#3399cc"
    }
  };
  form = new UntypedFormGroup({
    jobId: new UntypedFormControl('', Validators.required),
    jobTitle: new UntypedFormControl(),
    jobPeriod: new UntypedFormControl('', Validators.required),
    jobDuration: new UntypedFormControl('', Validators.required),
    jobDescription: new UntypedFormControl('', Validators.required),
    jobStipend: new UntypedFormControl('', Validators.required),
    jobLocation : new UntypedFormControl(),
    jobResponsibilities  : new UntypedFormControl('', Validators.required),
    jobRequirements : new UntypedFormControl('', Validators.required),
    titlevalue: new UntypedFormControl(''),
    jobCategory: new UntypedFormControl(''),
    placeholderValue:  new UntypedFormControl(''),
    jobBenifits:new UntypedFormControl('', Validators.required),
    jobType:new UntypedFormControl(),
    workType:new UntypedFormControl(),
    city:new UntypedFormControl(),
    company_type:new UntypedFormControl(),
    company_logo:new UntypedFormControl(),
    contactEmail:new UntypedFormControl(),
    contactNumber:new UntypedFormControl(),
    country:new UntypedFormControl(),
    overview:new UntypedFormControl(),
    industry:new UntypedFormControl(),
    name:new UntypedFormControl(),
    profile_url:new UntypedFormControl(),
    services:new UntypedFormControl(),
    stages:new UntypedFormControl(),
    state:new UntypedFormControl(),
    websiteLink:new UntypedFormControl(),
    order_id:new UntypedFormControl(),
    payment_status:new UntypedFormControl(),
    numberOfHire:new UntypedFormControl(),
    bolstartCommision:new UntypedFormControl(),
    repayment:new UntypedFormControl(),
    existingHired:new UntypedFormControl(),
    curatethecandidates:new UntypedFormControl(),
    totalAmount:new UntypedFormControl()
   });

  public tipsEnabled: boolean = false
    tips = [
     "Don't give students large hiring tests. They might lose interest.",
     "Use meetaide / calendly for a smooth interview scheduling process.",
     "Have a task list ready before you onboard an intern."
   ]

   didyouknowList = [
     "Students learn a lot more by working at a startup than at a corporate.",
     "Instead of Netflixing, students prefer interning to boost their career.",
     "53 Million people in India are unemployed.",
     "4.36 Million jobs are created / year as per economic survey 2019-20."
   ]


   jobTitleOptions: Observable<string[]>;
  //  Observable<string[]>;

    constructor(private jobservice:JoblistService, private router:Router,private snapRoute:ActivatedRoute,public dialog: MatDialog) { 
      this.form.controls.jobType.setValue("Part Time");
      this.form.controls.workType.setValue("WFH");
      this.form.controls.jobCategory.setValue("Other");
      this.form.controls.bolstartCommision.setValue(false);
      this.form.controls.bolstartCommision.setValue(1000);
      this.form.controls.curatethecandidates.setValue(2000);
      this.form.controls.totalAmount.setValue(1000);
      this.form.controls.numberOfHire.setValue(1);
      this.form.controls.existingHired.setValue(0);
      this.form.controls.repayment.setValue(0);
      this.getCompanyDetails();
      this.getJobTitles();
      this.form.controls.jobId.setValue("0");
      this.snapRoute.params.subscribe((paramsResponse)=>{
        console.log("paramsResponse:",paramsResponse);
        if(parseInt(paramsResponse['name'])!=0){
          this.form.controls.repayment.setValue(parseInt(paramsResponse['repayment']));
          this.getJobDetails(parseInt(paramsResponse['name']),parseInt(paramsResponse['repayment']));
        }
      });
    }
  
    get firstname(){
      return this.form.get('firstName')
    }
    ngOnInit() {
      this.postNewJob()
    }
  
    stepper(value:any){
      this.form.markAllAsTouched();
      this.selected = value
    
      if(this.selected == 2){
        var jobTitle = this.form.controls.titlevalue.value.jobTitle != null ? this.form.controls.titlevalue.value.jobTitle : this.form.controls.titlevalue.value;
        var jobTitleId = this.form.controls.titlevalue.value.id != null ? this.form.controls.titlevalue.value.id : null;

        var obj = {
          'jobTitle': jobTitle,
          'id':  jobTitleId 
        }
        this.form.addControl('currentDate', new UntypedFormControl(''));
        this.form.controls['currentDate'].setValue(new Date());
        this.form.controls['titlevalue'].setValue(obj);
      }

      if(this.form.controls.titlevalue.status=="INVALID" || this.form.controls.jobPeriod.status=="INVALID" || this.form.controls.jobDuration.status=="INVALID" || this.form.controls.jobDescription.status=="INVALID"
      || this.form.controls.jobStipend.status=="INVALID" || this.form.controls.jobResponsibilities.status=="INVALID" || this.form.controls.jobRequirements.status=="INVALID" || this.form.controls.jobBenifits.status=="INVALID"
      ){
        this.selected=1;
      }else{
        if((parseInt(this.form.controls.jobStipend.value)<0)){
          this.form.controls.jobStipend.setErrors({'incorrect': true})
          this.selected=1;
        }
        if((parseInt(this.form.controls.jobDuration.value)<=0)){
          this.form.controls.jobDuration.setErrors({'incorrect': true})
           this.selected=1;
        }
        if((parseInt(this.form.controls.jobDuration.value)>=13)){
          this.form.controls.jobDuration.setErrors({'incorrect': true})
          this.selected=1;
        }
        
      }
      console.log(this.form, this.form.controls.titlevalue.value.jobTitle,this.form.controls.titlevalue.value.id,);
      if(parseInt(value) == 4){
        console.log(this.form);
       
        if(this.form.valid){
          var res = {
            "active": true,
            "applicant_type": this.form.controls.jobType.value,
            "assissmentId": "string",
            "benifit":  this.form.controls.jobBenifits.value,
            "bond": "NA",
            "city": this.form.controls.city.value,
            "companyId": parseInt(localStorage.getItem('company_id')),
            "country": this.form.controls.country.value,
            // "created": parseInt(localStorage.getItem('member')).toString(),
            "createdBy": parseInt(localStorage.getItem('member')).toString(),
            "domain": this.form.controls.websiteLink.value,
            "duration": this.form.controls.jobDuration.value,
            "image": "string",
            "industry": this.form.controls.industry.value,
            "isAssissmentRequired": true,
            "isResumeRequired": true,
            "jobCategeory": this.form.controls.jobCategory.value,
            "jobDescription": this.form.controls.jobDescription.value,
            "jobtitle": this.form.controls.titlevalue.value.jobTitle,
            "jobtitleId": this.form.controls.titlevalue.value.id,
            "location": this.form.controls.jobLocation.value,
            "maxExperiance": "1",
            "minExperiance": "3",
            "noOfApplication": "5",
            "noticePeriod": "1",
            "qualification": 'BE',
            "recruiter": parseInt(localStorage.getItem('member')),
            "requirements": this.form.controls.jobRequirements.value,
            "responsiblitie": this.form.controls.jobResponsibilities.value,
            "salaryMax": "12000",
            "salaryMin": "5000",
            "skills": "Java,Spring Boot",
            "startDate":this.form.controls.jobPeriod.value,
            "state": this.form.controls.state.value,
            "status": true,
            "stipend":  this.form.controls.jobStipend.value.toString(),
            "typeofJob": this.form.controls.workType.value,
            // "updated": "string",
            "updatedBy": "string",
            "payment_status" : this.form.controls.payment_status.value,
            "order_id":this.form.controls.order_id.value,
            "hire_count":this.form.controls.numberOfHire.value,
            "total_amount":this.form.controls.totalAmount.value 
          }
          var rs = {
            "active": true,
            "applicant_type": this.form.controls.jobType.value,
            "assissmentId": "string",
            "benifit": this.form.controls.jobBenifits.value,
            "bond": "NA",
            "city": this.form.controls.city.value,
            "companyId": parseInt(localStorage.getItem('company_id')),
            "country":this.form.controls.country.value,
            // "created": parseInt(localStorage.getItem('member')).toString(),
            "createdBy":parseInt(localStorage.getItem('member')).toString(),
            "domain": this.form.controls.websiteLink.value,
            "duration": this.form.controls.jobDuration.value,
            "image": "string",
            "industry":  this.form.controls.industry.value,
            "isAssissmentRequired": true,
            "isResumeRequired": true,
            "jobCategeory": this.form.controls.jobCategory.value,
            "jobDescription": this.form.controls.jobDescription.value,
            "jobtitle": this.form.controls.titlevalue.value.jobTitle,
            "jobtitleId": this.form.controls.titlevalue.value.id,
            "location": this.form.controls.jobLocation.value,
            "maxExperiance": "1",
            "minExperiance": "3",
            "noOfApplication": "5",
            "noticePeriod": "1",
            "qualification": "BE",
            "recruiter": parseInt(localStorage.getItem('member')).toString(),
            "requirements": this.form.controls.jobRequirements.value,
            "responsiblitie":this.form.controls.jobResponsibilities.value,
            "salaryMax": "12000",
            "salaryMin": "5000",
            "skills": "Java,Spring Boot",
            "startDate": this.form.controls.jobPeriod.value,
            "state": this.form.controls.state.value,
            "status": true,
            "stipend": this.form.controls.jobStipend.value.toString(),
            "typeofJob": this.form.controls.workType.value,
            // "updated": new Date(),
            "updatedBy": "string",
            "payment_status" : this.form.controls.payment_status.value,
            "order_id":this.form.controls.order_id.value,
            "hire_count":this.form.controls.numberOfHire.value,
            "total_amount":this.form.controls.totalAmount.value

          }
          console.log(res);
          if(parseInt(this.form.controls.jobId.value)!=0){
            res['id']=parseInt(this.form.controls.jobId.value)
            this.jobservice.updateJob(res).subscribe(responseBody =>{
              if(responseBody['success']){
                var activeMsg = responseBody['message']
                const dialogRef = this.dialog.open(Popup6Component, {
                  width: '600px',
                  height: '360px',
                  data: {
                    msg: activeMsg,
                    id:0,
                    flag:"jobpost"
                  },
                  backdropClass: 'backdropBackground'
                });
            
                dialogRef.afterClosed().subscribe(result => {
                  console.log('The dialog was closed');
                });
                this.router.navigate(['jobs/job-list-ui'])
              }
              this.selected = 1;
            }); 
          }else{
            this.selected = 1;
            this.jobservice.createNewJob(rs).subscribe(responseBody =>{
              if(responseBody['success']){
                var activeMsg = responseBody['message']
                const dialogRef = this.dialog.open(Popup6Component, {
                  width: '600px',
                  height: '360px',
                  data: {
                    msg: activeMsg,
                    id:0,
                    flag:"jobpost"
                  },
                  backdropClass: 'backdropBackground'
                });
            
                dialogRef.afterClosed().subscribe(result => {
                  console.log('The dialog was closed');
                });

                this.router.navigate(['jobs/job-list-ui'])
              }
              this.selected = 1;
            }); 
          }
        }else{
          alert("Something went wrong ...!")
        }
       
       
      }
    }
    getJobType(x:any){
      if(x.currentTarget.checked){
        this.form.controls.jobType.setValue("Full Time")
      }else{
        this.form.controls.jobType.setValue("Part Time")
      }
    }
    workType(x:any){
      if(x.currentTarget.checked){
        this.form.controls.workType.setValue("WFO")
      }else{
        this.form.controls.workType.setValue("WFH")
      }
    }

    candidatesForMe(x:any){
    if(x.currentTarget.checked){
    let getTotal = (this.form.controls.numberOfHire.value * this.form.controls.bolstartCommision.value) + this.form.controls.curatethecandidates.value;
    this.form.controls.totalAmount.setValue(getTotal)
      }else{
        let getTotal = (this.form.controls.numberOfHire.value * this.form.controls.bolstartCommision.value);
        this.form.controls.totalAmount.setValue(getTotal)
      }
    }

  inputChange() {
     this.form.controls.placeholderValue.setValue('');
    //const valueSplit = this.value.split(' ');
    // const domain = valueSplit && valueSplit.length > 1 ? valueSplit[1] : null;
    const domain = this.form.controls.titlevalue.value;
    if (domain) {
      const filteredEmails = this.dbJobTitle.filter((e) => e.toUpperCase().indexOf(domain.toUpperCase()) === 0);
      if (filteredEmails && filteredEmails.length > 0) {
         var placeholderValue = this.form.controls.titlevalue.value + filteredEmails[0].substring(domain.length);
         this.form.controls.placeholderValue.setValue(placeholderValue);
        }
    }
    console.log(this.form.controls.placeholderValue.value);
  }


  tabKeyPressed() {
    if (this.form.controls.titlevalue.value) {
      var value = this.form.controls.titlevalue.value.jobTitle.toUpperCase();
      // this.form.controls.titlevalue.setValue(value);

      if(value){
          var jobInfo = this.allJobsTitles.filter((e) =>{
           // console.log("FromArray : ",(e.job_title).toUpperCase());
            console.log("FromTitle : ",value)
            if(e.jobTitle){
              if((e.jobTitle).toUpperCase()==value){
                return e;
              }
            }
            
          });
          console.log("jobInfo:",jobInfo)
          if(jobInfo[0]){
            this.form.controls.jobBenifits.setValue(jobInfo[0]['benefits']);
            this.form.controls.jobRequirements.setValue(jobInfo[0]['requirements']);
            this.form.controls.jobDescription.setValue(jobInfo[0]['description']);
            this.form.controls.jobResponsibilities.setValue(jobInfo[0]['responsibilities']);
            this.form.controls.jobCategory.setValue(jobInfo[0]['jobCategory']);
            
            
          }
         
      }
      
    }
  }

    onSubmit(){
      console.log(this.form.value)
      alert(JSON.stringify(this.form.value));
    }

    getJobDetails(jobid,repayment){
      var requestBody ={
        id:jobid
      }
     
      this.jobservice.getJob(requestBody).subscribe(responseBody =>{
        if(responseBody['success']){
          console.log("responseBody:",responseBody['data'].benifit)
          this.form.controls.jobBenifits.setValue(responseBody['data'].benifit)
          this.form.controls.jobRequirements.setValue(responseBody['data'].requirements)
          this.form.controls.jobLocation.setValue(responseBody['data'].location)
          this.form.controls.jobRequirements.setValue(responseBody['data'].requirements)
          this.form.controls.jobDescription.setValue(responseBody['data'].jobDescription)
          this.form.controls.jobDuration.setValue(responseBody['data'].duration)
          this.form.controls.jobId.setValue(responseBody['data'].id)
          this.form.controls.jobResponsibilities.setValue(responseBody['data'].responsiblitie);
          this.form.controls.jobPeriod.setValue(responseBody['data'].startDate);
          this.form.controls.jobStipend.setValue(responseBody['data'].stipend);
          this.form.controls.titlevalue.setValue(responseBody['data'].jobtitle);
          this.form.controls.jobPeriod.setValue( moment(new Date(responseBody['data'].startDate)).format('YYYY-MM-DD'));
          //this.form.controls.typeofJob.setValue(responseBody['data'].typeofJob)
          
          this.allJobsTitles.forEach(element =>{
            if(element.jobTitle == responseBody['data'].jobtitle){
              this.form.controls.titlevalue.setValue(element);
            }
          })

          // console.log("jobTitle", this.jobTitleOptions);
          
          if(responseBody['data'].typeofJob=="Part Time"){
            document.getElementById("switch1").click();
          }
          if(responseBody['data'].payment_status){
            this.form.controls.payment_status.setValue(responseBody['data'].payment_status);
            this.form.controls.order_id.setValue(responseBody['data'].order_id)
          }
          if(repayment==1){
            this.form.controls.payment_status.setValue(false);
            this.form.controls.order_id.setValue("");
            this.form.controls.existingHired.setValue(responseBody['data'].hire_count);
          }
          // alert(responseBody['message'])
          // this.router.navigate(['jobs/job-list-ui'])
        }
        this.selected = 1;
      });
    }

  getJobTitles(){
    var jobId = this.snapRoute.snapshot.paramMap.get('name')
    if(jobId != null && parseInt(jobId)!=0){
      this.jobservice.getJobTitles().subscribe(responseBody =>{
        if(responseBody['success']){
          console.log("Data :",responseBody['data'])
          this.allJobsTitles=responseBody['data'];
          console.log(this.allJobsTitles);
          this.jobTitleOptions = this.form.controls.titlevalue.valueChanges.pipe(
            startWith(''),
            map(value => {
              // this.jobTitleFilter(value || '')
              const title = typeof value === 'string' ? value : value?.name;
              return title ? this.jobTitleFilter(title as string) : this.allJobsTitles.slice();
            }),
          );
          for(let i=0; i<responseBody['data'].length;i++){
            if((responseBody['data'][i]['jobTitle'])){
              console.log("Title:",responseBody['data'][i]['jobTitle'])
              this.dbJobTitle.push(responseBody['data'][i]['jobTitle']);
            }
          }
        }
      });
    }else{
      this.jobservice.getAllJobTitles().subscribe(responseBody =>{
        if(responseBody['success']){
          console.log("Data :",responseBody['data'])
          this.allJobsTitles=responseBody['data'];
          console.log(this.allJobsTitles);
          this.jobTitleOptions = this.form.controls.titlevalue.valueChanges.pipe(
            startWith(''),
            map(value => {
              // this.jobTitleFilter(value || '')
              const title = typeof value === 'string' ? value : value?.name;
              return title ? this.jobTitleFilter(title as string) : this.allJobsTitles.slice();
            }),
          );
          for(let i=0; i<responseBody['data'].length;i++){
            if((responseBody['data'][i]['jobTitle'])){
              console.log("Title:",responseBody['data'][i]['jobTitle'])
              this.dbJobTitle.push(responseBody['data'][i]['jobTitle']);
            }
          }
        }
      });
    }
  }

  getCompanyDetails(){
    var requestBody ={
      id:parseInt(localStorage.getItem('company_id'))
    }
    this.jobservice.getCompanyDetails(requestBody).subscribe(responseBody =>{
      if(responseBody['success']){
        console.log("responseBody:",responseBody['data']);
          this.form.controls.city.setValue(responseBody['data'].city);
          this.form.controls.company_type.setValue(responseBody['data'].company_type);
          this.form.controls.contactEmail.setValue(responseBody['data'].contactEmail);
          this.form.controls.company_logo.setValue(responseBody['data'].logo);
          this.form.controls.contactNumber.setValue(responseBody['data'].contactNumber);
          this.form.controls.country.setValue(responseBody['data'].country);
          this.form.controls.overview.setValue(responseBody['data'].description);
          this.form.controls.industry.setValue(responseBody['data'].industry);
          this.form.controls.name.setValue(responseBody['data'].name);
          this.form.controls.profile_url.setValue(responseBody['data'].profile_url);
          this.form.controls.services.setValue(responseBody['data'].services);
          this.form.controls.stages.setValue(responseBody['data'].stages);
          this.form.controls.state.setValue(responseBody['data'].state);
          this.form.controls.websiteLink.setValue(responseBody['data'].websiteLink);
         
      }
    });
  }


  
  paynow() {
    this.stepper(4);
    // this.paymentId = '';
    // this.error = '';
    // this.options.amount =  (parseInt(this.form.controls.totalAmount.value) * 100).toString(); //paise
    // this.options.prefill.name = this.form.controls.name.value;
    // this.options.name=this.form.controls.name.value;
    // this.options.prefill.email = this.form.controls.contactEmail.value;
    // this.options.prefill.contact =this.form.controls.contactNumber.value;
    // var rzp1 = new Razorpay(this.options);
    // rzp1.open();
    // rzp1.on('payment.failed', function (response: any) {
    //   //this.message = "Payment Failed";
    //   // Todo - store this information in the server
    //   console.log(response.error.code);
    //   console.log(response.error.description);
    //   console.log(response.error.source);
    //   console.log(response.error.step);
    //   console.log(response.error.reason);
    //   console.log(response.error.metadata.order_id);
    //   console.log(response.error.metadata.payment_id);
    //   //this.error = response.error.reason;
    // }
    // );
  }
  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event: any): void {
    this.message = "Success Payment";
    console.log(this.message);
    console.log(event);
    this.form.controls.payment_status.setValue("true");
    this.form.controls.order_id.setValue(event.detail.razorpay_payment_id);
    if(this.form.controls.repayment.value==1){
      let existingHired =  parseInt(this.form.controls.existingHired.value) + parseInt(this.form.controls.numberOfHire.value);
      this.form.controls.numberOfHire.setValue(existingHired);
    }
    this.stepper(4);
  }
  reduceNumber(){
    if(!(parseInt(this.form.controls.numberOfHire.value)<0)){
      let number = parseInt(this.form.controls.numberOfHire.value)-1;
      if(number==0){
        this.form.controls.numberOfHire.setValue(1);
        this.checkedValue();
      }else{
        this.form.controls.numberOfHire.setValue(number);
        this.checkedValue();
      }
    }
  }

  incressNumber(){
  if(!(parseInt(this.form.controls.numberOfHire.value)<0)){
      this.form.controls.numberOfHire.setValue(parseInt(this.form.controls.numberOfHire.value)+1);
      this.checkedValue();
    }
  }


  checkedValue(){
    const checkbox = document.getElementById(
      'candidatesForMe',
    ) as HTMLInputElement | null;
    if(checkbox?.checked){
      let getTotal = (this.form.controls.numberOfHire.value * this.form.controls.bolstartCommision.value) + this.form.controls.curatethecandidates.value;
      this.form.controls.totalAmount.setValue(getTotal)
        }else{
          let getTotal = (this.form.controls.numberOfHire.value * this.form.controls.bolstartCommision.value);
          this.form.controls.totalAmount.setValue(getTotal)
        }
  }
  getJobTitleOptionText(option:any): string {
    console.log(option);
    return option && option.jobTitle ? option.jobTitle : '';
  }

  private jobTitleFilter(value: any){
    console.log(value);
      const filterValue = value.toLowerCase();
      return this.allJobsTitles.filter(option => option.jobTitle.toLowerCase().includes(filterValue));
  }

  postNewJob(){
    var companyInfo = JSON.parse(localStorage.getItem("company"));
    console.log(localStorage.getItem("company"));
    if(companyInfo == null && companyInfo == undefined || companyInfo.length == 0 ){
    const dialogRef = this.dialog.open(Popup31, {
      width: '600px',
      height: '360px',
      data: {
        msg: "",
        active:"activeStatus",
        job_id:0,
        flag:"norecord"
      },
      backdropClass: 'backdropBackground'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
       
    });
  } 
    
  }

}
