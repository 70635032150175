<div class="profileBanner">
    <!-- <div class="bg"> -->
    <div class="bg" [ngStyle]="{'background-image': 'url(' + this.userDetail?.timelineImage + ')'  }">
        <i (click)="getImage(2)" class="fa fab right" *ngIf="userDetail?.isOwener" data-target="#VerifyModel"
            #VerifyModel data-toggle="modal"> <img src="assets/icons/camera.svg"/></i>
           
        <div class="profile-image">
            
            <!-- <img class="profile" [src]="this.userDetail?.profilePicture">
            <i (click)="getImage(1)" class="fa fa-camera fab profile-edit" *ngIf="userDetail?.isOwener"
                data-target="#VerifyModel" #VerifyModel data-toggle="modal"></i> -->
        </div>
        <div class="inner">
            <div class="whiteCard">
                <div class="row">
                    <div class="col-3">
                        <div class="profilePosition">
                            
                        <img class="profile" [src]="this.userDetail?.profilePicture"
                            *ngIf="presence$ && presence$ | async as presence" 
                            [ngClass]="{   'imgBorderGreen':  presence.status  === 'online',
                                    'imgBordergray': presence.status  === 'away' || presence.status  === 'offline'            
                        }" >
                        <i (click)="getImage(1)" class="fa profile-edit" *ngIf="userDetail?.isOwener"
                            data-target="#VerifyModel" #VerifyModel data-toggle="modal"><img src="assets/icons/cameraCircle.svg"/></i>
                        </div>
                    </div>
                    <div class="col-9">
                    <div class="leftspace">
                        <div class="userPostSpace">
                        <div class="name mr-3">{{userDetail?.firstName}} {{userDetail?.lastName}}</div>
                        <!-- <div class="name_second">({{role}})</div>  <sup><img class="ml-2" src="assets/icons/checkGreen.png"/></sup> -->
                        </div>
                        <span class="namePositon">{{userDetail?.headline}}</span>
                        <div>
                            <span class="name_second">{{userDetail?.location}}{{(userDetail?.location !=null&&userDetail?.state !=null )?',':''}} {{userDetail?.state}}</span>
                            <!-- <span>
                                <a>
                                    <h6 class="connection" [routerLink]="['./connections']"
                                        [queryParams]="{ visit: userDetail?.id}">{{userDetail?.connectionCount}} in my
                                        network</h6>
                                </a>
                            </span> -->
                        </div>
                        <!-- <div *ngIf="userDetail?.company_id != null">
                            <label style="color:#293266 ; font-size: 16px;"
                                [routerLink]="['../company/companyinfo/publish']"
                                [queryParams]="{ companyId: userDetail?.company_id}">
                                <label class="company_name">{{userDetail?.companyName}}</label> &nbsp;<img
                                    class="company_logo" [src]="userDetail?.compnayLogo">
                            </label>
                        </div> -->
                        <!-- <div>
                            <a *ngIf="!userDetail?.isOwener && userDetail?.isFollowed == 'false'"
                                (click)="followRequest(userDetail?.id)">Follow</a>
                            <a *ngIf="!userDetail?.isOwener && userDetail?.isFollowed == 'true'"
                                (click)="unfollowRequest(userDetail?.id)">Unfollow</a>
                        </div> -->
                        <div class="d-flex">
                        <button data-target="#UpdateModel" data-toggle="modal" type="button" class="btn editBtn" *ngIf="userDetail?.id == userInfo.id">Edit <img   class="pencil m-2" src="assets/icons/pencil-square.svg"
                            ></button>
                            <button type="button" (click)="sendFollowUnfollowRquest('follow')" class="btn editBtn" *ngIf="userDetail?.id != userInfo.id && userDetail != null && ((userDetail.connection == null) || (userDetail.connection != null && !userDetail.connection.following))">Follow</button>  
                            <button type="button" (click)="sendFollowUnfollowRquest('unfollow')" class="btn editBtn" *ngIf="userDetail?.id != userInfo.id &&   userDetail != null && (userDetail.connection != null && userDetail.connection.following)">Unfollow</button>                            
                            <button *ngIf="userDetail?.id != userInfo.id && requestType == 'NotSend'" type="button"  class="btn reachBtnout" (click)="sendRequest(userDetail?.id)">Reach Out</button>  
                            <button (click)="deleteConnection(userDetail?.id)"  *ngIf="requestType == 'send'" type="button reachBtnout" class="btn reachBtnout">Cancel Request</button>
                            <button (click)="deleteConnection(userDetail?.id)"  *ngIf="requestType == 'connected'" type="button reachBtnout" class="btn reachBtnout">Disconnect</button>    
                            <button (click)="confirmRequest()"  *ngIf="requestType == 'confirm'" type="button reachBtnout" class="btn reachBtnout">Confirm Request</button>                              
                            <button *ngIf="userDetail?.id != userInfo.id" type="button" class="btn editBtn">Message</button>   
                        </div>
                        
                        <a *ngIf="!userDetail?.isOwener && userDetail?.isFollowed == 'false'"
                            (click)="followRequest(userDetail?.id)">Follow</a>
                        <a *ngIf="!userDetail?.isOwener && userDetail?.isFollowed == 'true'"
                            (click)="unfollowRequest(userDetail?.id)">Unfollow</a>
                        <!-- <button class="btn btn-primary ml-3"
                            *ngIf="!userDetail?.isOwener && userDetail?.isConnected == null && userDetail?.id !=201 &&  userDetail?.id !=416"
                            (click)="sendRequest(userDetail?.id)">Reach
                            Out</button>                          -->
                        <button class="btn btn-outline-primary ml-3"
                            *ngIf="!userDetail?.isOwener && userDetail?.isConnected == 'false' "
                            (click)="cancelRequest(userDetail?.id)">Cancel
                            Request</button>
                        <button class="btn btn-primary ml-3"
                            *ngIf="!userDetail?.isOwener && userDetail?.isConnected == 'true'"
                            (click)="deleteConnection(userDetail?.id)">Disconnect</button>

                    </div>
                    </div>
                </div>
            
            <div class="mt-5" style="display: inline-flex;width:100%;background-color:#EBEBFF;">
                <div class="btn-1" routerLink="./about" [queryParams]="{ visit: userDetail?.id}"
                routerLinkActive="active-link">About</div>
                <div class="btn-1" routerLink="./timeline" [queryParams]="{ visit: userDetail?.id}"
                    routerLinkActive="active-link">Timeline</div> 
                <!--  <div class="btn-1" routerLink="./publish" [queryParams]="{ visit: userDetail?.id}"
                    routerLinkActive="active-link">
                    Publish</div> &nbsp; -->
                <div class="btn-1" routerLink="./skill" [queryParams]="{ visit: userDetail?.id}"
                    routerLinkActive="active-link">Skill</div>
                    <div routerLinkActive="active-link" class="btn-1" routerLink="./connections" [queryParams]="{ visit: userDetail?.id}" 
                    >Network <span  *ngIf="userDetail?.allConnection != null">({{(userDetail?.allConnection.length>0)?userDetail?.allConnection.length:0}})</span></div>
                <!--  <span *ngIf="userDetail?.typeOfUser == 1" style="padding-top: 5px; cursor: pointer;">
                    <h6 style="color: #F0541E;" (click)="openVerifyAccount()"
                        *ngIf="userDetail?.isMobileVerified == null ">
                        <i class="fa fa-close"></i>&nbsp;Not verified
                    </h6>
                    <h6 style="color: #63c0df;" *ngIf="userDetail?.isMobileVerified == 'false' && userDetail?.isOwener">
                        <i class="fa fa-spinner fa-spin fa-1x"></i>&nbsp;
                        Request Pending
                    </h6>
                    <h6 style="color: #F0541E;"
                        *ngIf="userDetail?.isMobileVerified == 'false' && !userDetail?.isOwener"><i
                            class="fa fa-close"></i>&nbsp;
                        Not Verified
                    </h6>
                    <h6 style="color: #009944;" *ngIf="userDetail?.isMobileVerified == 'true'"> <i
                            class="fa fa-check"></i>&nbsp;verified</h6>
                </span> -->
            </div>
        </div>
        </div>
    </div>
</div>


<!-- =================== Infomation Model =================================== -->
<div class="updateMOdelGrid">
<div class="modal" id="UpdateModel" role="dialog">
    <div class="modal-dialog">

        <div class="modal-content p-3">
            <div class="modal-Custheader">
                <button type="button" class="close" data-dismiss="modal" (click)="close()">&times;</button>
                <label class="modal-title">Update Profile</label>
            </div>
            <div class="modal-body">
                <form [formGroup]="informationForm" (submit)="updateProfile()">
                    <div class="row">
                        <div class="col col-md-4">
                            <label>First Name <span class="mandatory">*</span></label>
                            <input type="text" placeholder="First Name" formControlName="firstName"
                                class="form-control">
                            <small class="text-danger"
                                *ngIf="informationForm.get('firstName').invalid && informationForm.get('firstName').touched">Enter
                                First Name.</small>
                        </div>
                        <!-- <div class="col col-md-4 p-2">
                            <label>Middle Name</label>
                            <input type="text" placeholder="eg. Middle Name" formControlName="middleNmae"
                                class="form-control">
                            <small class="text-danger"
                                *ngIf="informationForm.get('middleNmae').invalid && informationForm.get('middleNmae').touched">Enter
                                Middle Name.</small>
                        </div> -->
                        <div class="col col-md-4">
                            <label>Last Name <span class="mandatory">*</span></label>
                            <input type="text" placeholder="Last Name" formControlName="lastName"
                                class="form-control">
                            <small class="text-danger"
                                *ngIf="informationForm.get('lastName').invalid && informationForm.get('lastName').touched">Enter
                                Last Name.</small>
                        </div>
                        <div class="col col-md-4">&nbsp;</div>
                        <div class="col col-md-4">
                            <label>Birthdate <span class="mandatory">*</span></label>
                            <input type="date" formControlName="birthDate"   placeholder="01/01/2022" class="form-control">
                            <small class="text-danger"
                                *ngIf="(informationForm.get('birthDate').invalid && informationForm.get('birthDate').touched) || 
                                (informationForm.get('birthDate').touched && informationForm.get('birthDate').value == 'Invalid date') ">Select
                                birth date.</small>
                        </div>
                        <div class="col col-md-4">
                            <label>Headline <span class="mandatory">*</span></label>
                            <input type="text" placeholder="UI/UX Designer" formControlName="headline"
                                class="form-control">
                            <small class="text-danger"
                                *ngIf="informationForm.get('headline').invalid && informationForm.get('headline').touched">Add
                                Short
                                Headline.</small>
                        </div>
                      
                        <div class="col col-md-4">&nbsp;</div>
                   
                        <div class="col col-md-4 p-2" *ngIf="type == 2">
                            <label>Birthday</label>
                            <select class="form-control" formControlName="month">
                                <option value="" selected hidden disabled>select Month</option>
                                <option [value]="item" *ngFor="let item of monthList">{{item}}</option>
                            </select>
                            <small class="text-danger"
                                *ngIf="informationForm.get('month').invalid && informationForm.get('month').touched">Select
                                Month.</small>
                        </div>
                        <div class="col col-md-4 p-3" *ngIf="type == 2">
                            <label></label>
                            <select class="form-control" formControlName="day">
                                <option value='' selected hidden disabled>select Day</option>
                                <option [value]='item' *ngFor="let item of dayList">{{item}}</option>
                            </select>
                            <small class="text-danger"
                                *ngIf="informationForm.get('day').invalid && informationForm.get('day').touched">Select
                                Day.</small>
                        </div>
                        <div class="col col-md-4">
                            <label>Country *</label>
                            <select class="form-control" formControlName="country" (change)="selectCountry()">
                                <option value='' selected hidden disabled>select country
                                </option>
                                <option [value]='item.id' *ngFor="let item of countryList">{{item.name}}
                                </option>
                            </select>
                            <small class="text-danger"
                                *ngIf="informationForm.get('country').invalid && informationForm.get('country').touched">Select
                                Country.</small>
                        </div>
                        <div class="col col-md-4">
                            <label>States/Union territories *</label>
                            <select class="form-control" formControlName="state" (change)="selectState()">
                                <option value='' selected hidden disabled>select States/Union territory
                                </option>
                                <option [value]='item.name' *ngFor="let item of stateList">{{item.name}}
                                </option>
                            </select>
                            <small class="text-danger"
                                *ngIf="informationForm.get('state').invalid && informationForm.get('state').touched">Select
                                States/Union territories.</small>
                        </div>
                        <div class="col col-md-4 ">
                            <label>City *</label>
                            <select class="form-control" formControlName="location">
                                <option value='' selected hidden disabled>select States/Union territory
                                </option>
                                <option [value]='item.name' *ngFor="let item of cityList">{{item.name}}
                                </option>
                            </select>
                            <small class="text-danger"
                                *ngIf="informationForm.get('location').invalid && informationForm.get('location').touched">Select
                                Location.</small>
                        </div>
                        <div class="col col-md-12">
                            <label>About Me </label>
                            <textarea class="form-control" formControlName="bio" placeholder="Write Something about Yourself"  id="exampleFormControlTextarea1" rows="3"></textarea>
                            
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col col-md-12 p-2">
                            <label>Headline <span class="mandatory">*</span></label>
                            <input type="text" placeholder="eg. Assistant manager" formControlName="headline"
                                class="form-control">
                            <small class="text-danger"
                                *ngIf="informationForm.get('headline').invalid && informationForm.get('headline').touched">Add
                                Short
                                Headline.</small>
                        </div>
                    </div> -->
                    <!-- <div class="row">
                        <div class="col col-md-12 p-2">
                            <label>Industry <span class="mandatory">*</span></label>
                            <select class="form-control" formControlName="industry">
                                <option value='' selected hidden disabled>select industry</option>
                                <option [value]='item' *ngFor="let item of industryList">{{item}}</option>
                            </select>
                            <small class="text-danger"
                                *ngIf="informationForm.get('industry').invalid && informationForm.get('industry').touched">Select
                                Industry.</small>
                        </div>
                    </div> -->
                    <!-- <div class="row">
                        <div class="col col-md-4 p-2">
                            <label>Country</label>
                            <select class="form-control" formControlName="country" (change)="selectCountry()">
                                <option value='' selected hidden disabled>select country
                                </option>
                                <option [value]='item.name' *ngFor="let item of countryList">{{item.name}}
                                </option>
                            </select>
                            <small class="text-danger"
                                *ngIf="informationForm.get('country').invalid && informationForm.get('country').touched">Select
                                Country.</small>
                        </div>
                        <div class="col col-md-4 p-2">
                            <label>States/Union territories</label>
                            <select class="form-control" formControlName="state" (change)="selectState()">
                                <option value='' selected hidden disabled>select States/Union territory
                                </option>
                                <option [value]='item.name' *ngFor="let item of stateList">{{item.name}}
                                </option>
                            </select>
                            <small class="text-danger"
                                *ngIf="informationForm.get('state').invalid && informationForm.get('state').touched">Select
                                States/Union territories.</small>
                        </div>
                        <div class="col col-md-4 p-2">
                            <label>City/Location/Landmark</label>
                            <select class="form-control" formControlName="location">
                                <option value='' selected hidden disabled>select States/Union territory
                                </option>
                                <option [value]='item.name' *ngFor="let item of cityList">{{item.name}}
                                </option>
                            </select>
                            <small class="text-danger"
                                *ngIf="informationForm.get('location').invalid && informationForm.get('location').touched">Select
                                Location.</small>
                        </div>

                    </div> -->
                    <div class="text-center">
                        <button type="submit" class="btn modelBtn">Save</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
</div>
<!-- <div class="modal" id="VerifyModel" role="dialog">
    <div class="modal-dialog">

        <div class="modal-content p-3">
            <div class="modal-Custheader mb-3">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h6 class="modal-title">Edit Image</h6>

            </div>
            <div style="display: inline-flex;">
                <div class="publish">
                    <i class="fa fa-picture-o" aria-hidden="true" (click)="onEditImage()"></i>
                    <div style="font-size: 20px;">Edit</div>
                </div>&nbsp;
                <div class="publish">
                    <i class="fa fa-trash" aria-hidden="true" (click)="onRemoveImage()"></i>
                    <div style="font-size: 20px;">Remove</div>
                </div>

            </div>
        </div>
    </div>
</div> -->


<!-- ===================  PhotoEditor Model  ====================================================== -->

<ngx-photo-editor [hidden]="imageEditorType == 1" [imageChanedEvent]="timelineImageChangedEvent"
    (imageCropped)="imageCropped($event)" [viewMode]="1" [aspectRatio]="21/9" [darkTheme]="false" [resizeToWidth]="1000"
    [resizeToHeight]="200">
</ngx-photo-editor>
<ngx-photo-editor [hidden]="imageEditorType == 2" [imageChanedEvent]="profileImageChangedEvent"
    (imageCropped)="imageCropped($event)" [viewMode]="1" [aspectRatio]="1/1" [darkTheme]="false" [resizeToWidth]="200"
    [resizeToHeight]="200">
</ngx-photo-editor>
<input style="display: none;" type="file" accept="image/*" #imagePicker (change)="fileChangeEvent($event)" />
<button style="display: none;" data-target="#VerifyModel" #VerifyModel data-toggle="modal"></button>