import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup12',
  templateUrl: './popup12.component.html',
  styleUrls: ['./popup12.component.scss']
})
export class Popup12Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
