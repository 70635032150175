<div class="main card">
    <p style="text-align: right;"><i class="fa fa-times" aria-hidden="true"></i></p>

    <div class="row-1">
        <p>Upload Another Version of your CV?</p>
        <div class="input-group mb-3">
            <div class="custom-file">
                <input type="file" class="custom-file-input" id="inputGroupFile01">
                <label class="custom-file-label" for="inputGroupFile01">Upload CV</label>
            </div>
        </div>
        <div>
            <h3>Or choose previously uploaded CV</h3>
            <p>CV Final 001.pdf (05/01/2021)</p>
            <p>CV Final 001.pdf (05/01/2021)</p>
            <p>CV Final 001.pdf (05/01/2021)</p>
        </div>
        <div class="card-body">
            <a href="#" class="btn btn-primary">Apply</a>
        </div>
    </div>
</div>