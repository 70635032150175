import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publish-new-job-post',
  templateUrl: './publish-new-job-post.component.html',
  styleUrls: ['./publish-new-job-post.component.scss']
})
export class PublishNewJobPostComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
