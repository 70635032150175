<div class="main">
    <h1>Publish New Job Post</h1>
    <div class="card">
        <div class="blue-box">
            <div class="stepCont">
                <div class="slectCel"> 01</div>
                <div class="slectCel">02</div>
                <div class="slectCel">03</div>
            </div>
            <span>
                <div class="contentHolder">
                    <div class="content">
                        <img class="imgSeq" src="./../../assets/Screenshot 2022-04-06 233348.png" width="50px" alt="" />
                        <p class="didO">Did you know?</p>
                        <ul>
                        </ul>

                        <div class="widthOfText">

                            <div class="margin-btm">
                                <p><i class="fa fa-circle" aria-hidden="true" style="color: white;"></i> Students learn a lot more by working at a </p>
                                <p> &nbsp;&nbsp;&nbsp;&nbsp;startup than at a corporate.</p>
                            </div>

                            <div class="margin-btm">
                                <p><i class="fa fa-circle" aria-hidden="true" style="color: white;"></i>Instead of Netflixing, students prefer</p>
                                <p> &nbsp;&nbsp;&nbsp;&nbsp; interning to boost their career.</p>
                            </div>

                            <div class="margin-btm">
                                <p><i class="fa fa-circle" aria-hidden="true" style="color: white;"></i> 53 Million
                                    people in India are unemployed.</p>
                            </div>

                            <div class="margin-btm">
                                <p><i class="fa fa-circle" aria-hidden="true" style="color: white;"></i> 4.36 Million
                                    jobs are created / year as per.</p>
                                <p> &nbsp;&nbsp;&nbsp;&nbsp; economic survey 2019-20.</p>

                            </div>
                            <!-- 4.36 Million jobs are created / year as per economic survey 2019-20. -->

                        </div>
                    </div>
                </div>
                <div class="footerContent">
                    <p>need help?</p>
                    <p>Connect with our experts who would love to help</p>
                    <p>you out. Reach out to us at</p>
                    <p>helpdesk@bolstart.com</p>
                </div>
            </span>
        </div>
        <div class="white-box">
            <label>Job Post Details: Boost Your Advertisement</label>
        </div>
    </div>
</div>