<div class="card">
    <div class="card-header">
        <h6>Activity</h6>
    </div>
    <div class="text-center">
        <img src="assets/img/activity1.gif" />
        <h4>
            <i>Coming soon...!</i>
        </h4>
    </div>

    <!-- <ul>
        <li>
            <a>Sachin Comented on your post</a><br />
            <label>3 Min Ago</label>
        </li>
        <li>
            <a>Sachin Comented on your post</a><br />
            <label>3 Min Ago</label>
        </li>
        <li>
            <a>Sachin Comented on your post</a><br />
            <label>3 Min Ago</label>
        </li>
        <li>
            <a>Sachin Comented on your post</a><br />
            <label>3 Min Ago</label>
        </li>
    </ul> -->
</div>