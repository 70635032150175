import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JobService } from '../job.service';
import { JoblistService } from 'src/app/shared/service/joblist.service';
import { Popup31 } from '../popups/popup31/popup31.component';
import { Popup6Component } from '../popups/popup6/popup6.component';
import { Router } from '@angular/router';

import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { Popup12Component } from '../popups/popup12/popup12.component';
// import { Popup13Component } from '../popups/popup13/popup13.component';

@Component({
  selector: 'app-job-post-ui',
  templateUrl: './job-post-ui.component.html',
  styleUrls: ['./job-post-ui.component.scss']
})
export class JobPostUIComponent implements OnInit {
  jobPostList: [] = [] as any
  joblist:any=[];
  companyInfo: any;
  constructor(private router:Router,private jobService: JobService, private joblistService:JoblistService,public dialog: MatDialog) {
 
  }
  arr: any = [];

  ngOnInit(): void {
    this.companyInfo = localStorage.getItem('company');
    this.getJobsByCompanyId();
    window.scroll(0,0);
  }

  getJobsByCompanyId(){
    this.jobService.getJobPostByCompanyId(this.companyInfo[0].id).subscribe((res: any) => {
      this.joblist=res;
      for(let i=0; i<res.data.length;i++){
        res.data[i]['numberOfDays']=this.joblistService.getDataDifference(res.data[i]['created']).toString();
      }
      this.jobPostList = res.data
    });
  }
  opendia12(id,activeStatus,hire_count,hired_count): void {
    console.log(id,activeStatus);
    var activeMsg = 'Activate'
    if(activeStatus){
      activeMsg = "Deactivate"
      activeStatus=false;
    }else{
      activeMsg = "Activate"
      activeStatus=true;
    }
    if(activeStatus){
      let getCount  = parseInt(hire_count) - parseInt(hired_count);
      if(hire_count<=hired_count){
        const dialogRef = this.dialog.open(Popup6Component, {
          width: '600px',
          height: '360px',
          data: {
            job_id:id,
            msg: "You have " +getCount + " more candidates remaining to hire for this job. ",
            flag:"repayment"
          },
          backdropClass: 'backdropBackground'
        });
        return;
      }
    }
    const dialogRef = this.dialog.open(Popup6Component, {
      width: '600px',
      height: '360px',
      data: {
        msg: activeMsg,
        id:id,
        active:activeStatus,
        flag:"jobUpdate"
      },
      backdropClass: 'backdropBackground'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
    let me = this;
    setTimeout(function(){
      me.getJobsByCompanyId();
    },3000);
   
  }

  setJobDetails(id){
    var jobDetails = this.joblist.data.filter(obj => obj.id == id);
    if(jobDetails.length>0){
        this.joblistService.setJobDetails(jobDetails[0]);
        this.router.navigate(['jobs/ApplicationDetails/'+id])
    }
  }

  postNewJob(){
    var companyInfo = JSON.parse(localStorage.getItem("company"));
    console.log(localStorage.getItem("company"));
    if(companyInfo == null && companyInfo == undefined || companyInfo.length == 0 ){
    const dialogRef = this.dialog.open(Popup31, {
      width: '600px',
      height: '360px',
      data: {
        msg: "Please Create a new company.",
        active:"activeStatus",
        job_id:0,
        flag:"norecord"
      },
      backdropClass: 'backdropBackground'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }else{
    this.router.navigate(["/jobs/job-post-form/0/0"])
  } 
    
  }
   
}
