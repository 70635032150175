<div class="peopleSection positionHome">
<div class="card">
    <div class="card-header">
        <div class="flex w-100"><h6>People you may know</h6><span class="infoIcon">
            <!-- <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="right" title="This is a list of people you may know as per your company and industry." aria-hidden="true"></i> -->
            <i class="fa fa-info-circle" id="dropdownMenuButton"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <!-- <img src="../../../assets/icons/three-dots.svg"> -->
        </i>
        <div class="dropdown-menu dropdown-menu-left" style="            border-radius: 5px;
            color: fff;
            position: absolute;           
            top: 0px;
            left: 0px;
            transform: translate3d(178px, 35px, 0px);
            background: #293266 0% 0% no-repeat padding-box;
            border: 1px solid #293266;
            border-radius: 5px;
            opacity: 1;
            letter-spacing: 0.09px;
            color: #F3F2F0;
            opacity: 1;
            padding: 12px;"
            aria-labelledby="dropdownMenuButton">
            <ul class="mb-0 pb-0">
                <li class="mb-0 pb-0">"This is a list of people you may know as per your company and industry."</li>
               
            </ul>
        </div>
        </span></div>
    </div>
    <div class="p-22">
    <ul>
        <li *ngFor="let item of peopleList  | slice:0:5; let i=index">
            <a (click)="selectUser(item.id)" class="textRight">
                <!-- <app-profile-photo [user]="item"></app-profile-photo> -->
                <img class="profile" [src]="item.profilePicture" />&nbsp;
                <div class="w-100">
                <div class="userName">{{getUserName(item.firstName, item.lastName)}}</div>
                <div class="userPost" *ngIf="item.headline">{{item.headline.length > 21 ? item.headline.substring(0, 21) + "..." : item.headline}}</div>
                </div>
            </a>
        </li>

    </ul>
    </div>
    <div class="moreLink" [routerLink]="['/mynetwork']"><span class="pointer">View All +</span></div>
</div>
</div>