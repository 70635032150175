<div style="float: right; margin-top: -15px;"><button (click)="close()" mat-icon-button class="close-button icon-outside" [mat-dialog-close]="true">
  <mat-icon class="close-icon material-icons color_white">close</mat-icon>
</button></div>
  

<div class="mainPage">
  <div class="dFlex">
    <div class="img-title">
      <a href="#/company/companyinfo/publish?companyId={{jobDetails['company']['id']}} ">
      <img src="{{ jobDetails['company']['logo'] }}" alt="" class="img-c" />
    </a>
      <div class="text-seq">
        <span class="text-c"> {{ jobDetails['jobtitle'] }} </span>
        <a href="#/company/companyinfo/publish?companyId={{jobDetails['company']['id']}} ">
          <span class="text-c2" *ngIf ="jobDetails['company']"> {{ jobDetails['company']['name'] }} </span>
        </a>       
        <span style="font-size: 12px"> {{ jobDetails['numberOfDays']  }} days ago | {{ jobDetails['noOfApplication'] }} Applicants</span>
        <span style="font-size: 13px"
          ><span class="job">Job category &nbsp;*&nbsp; </span> {{ jobDetails['jobCategeory'] }}</span
        >
      </div>
    </div>
    <div class="buttonsDiv">
      <!-- <button > Apply now</button> -->
      <p *ngIf="!jobDetails['is_job_applyed']" class="btn btn-primary" style="border-radius: 10px" (click)="openDialog()">Apply now</p>
      <p *ngIf="jobDetails['is_job_applyed']" class="btn btn-primary" style="border-radius: 10px">Applied</p>

      
     

      <p class="btn-t-w">Message</p>
       <div class="action-icons" *ngIf="!jobDetails['is_job_saved']" (click)="addToSaveList(jobDetails['id'],true,i)"><i class="fa fa-bookmark-o" style="
        font-size: 18px;" >
        </i></div>    
       <div class="action-icons" *ngIf="jobDetails['is_job_saved']" (click)="addToSaveList(jobDetails['id'],false,i)"> <i class="fa fa-bookmark" style="
        font-size: 18px;
        color:#293266;" >
        </i></div>    
       <div class="action-icons" (click)="linkCopy()" [cdkCopyToClipboard]="jobShareUrl">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share"
        viewBox="0 0 16 16">
        <path
          d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
      </svg>
       </div>    
      <!-- <i *ngIf="!jobDetails['is_active']" class="fa fa-bookmark-o" (click)="addToSaveList(jobDetails['id'],true,i)" style="
      font-size: 18px;
      margin-right: 7px;" > 
      </i>
      <i *ngIf="jobDetails['is_active']" class="fa fa-bookmark" (click)="addToSaveList(jobDetails['id'],false,i)" style="
      font-size: 18px;
      margin-right: 7px;color:#293266;" >
      </i>

      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share"
      viewBox="0 0 16 16">
      <path
        d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
    </svg> -->

    </div>
  </div>
  <div class="title-div">
    <div>
      <span class="t-e"> Location</span>
      <span *ngIf="jobDetails['typeofJob']=='WFO'">{{ jobDetails['location'] }}</span>
      <span *ngIf="jobDetails['typeofJob']=='WFH'">Remote</span>

      <!-- <span class="bold-t"> {{ jobDetails['location'] }} </span> -->
    </div>

    <div>
      <span class="t-e"> Start Date</span>
      <span class="bold-t"> {{ jobDetails['startDate'] | date: 'dd/MM/yyyy' }} </span>
    </div>

    <div>
      <span class="t-e"> Employee Type</span>
      <span class="bold-t"> {{ jobDetails['applicant_type'] }}  </span>
    </div>

    <div>
      <span class="t-e"> Stipend</span>
      <span class="bold-t" *ngIf="jobDetails['stipend'] == 0">Unpaid</span>
      <span class="bold-t" *ngIf="jobDetails['stipend'] > 0"> &#x20B9; {{ jobDetails['stipend'] }} / Month </span>
    </div>

    <div>
      <span class="t-e"> Duration</span>
      <span class="bold-t"> {{ jobDetails['duration'] }} Month</span>
    </div>
  </div>
  <hr style="color: rgb(192, 192, 192)" />
  <p class="or margin-top">Overview</p>
  
  <!-- <p [innerHTML]="jobDetails['jobDescription']"> -->
  <p>{{ jobDetails['company']['description'] }}</p>
  <p class="or margin-top">Job Description</p>
  <div style="padding-left: 20px">
    <div class="form-check" [innerHTML]="jobDetails['jobDescription']">
      <!-- {{ jobDetails['jobDescription'] }}  -->
    </div>
</div>
      <p class="or margin-top">Job Responsiblities</p>
      <div style="padding-left: 20px">
        <div class="form-check job-detalis" [innerHTML]="jobDetails['responsiblitie']">
          <!-- {{ jobDetails['responsiblitie'] }}  -->
        </div>
      </div>
      <p class="or margin-top">Job Requirements</p>
      <div style="padding-left: 20px">
        <div class="form-check job-detalis" [innerHTML]="jobDetails['requirements']">
        <!-- {{ jobDetails['requirements'] }}  -->
        </div>
      </div>
    
        <p class="or margin-top">Job Benefits</p>
        <div style="padding-left: 20px">
          <div class="form-check job-detalis" [innerHTML]="jobDetails['benifit']">

            <!-- {{ jobDetails['benifit'] }}  -->
          </div>
          </div>

</div>