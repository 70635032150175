import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

import { AwsService } from '../AWS-Service/aws.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PublishContainerService } from './publish-container.service';
import { Router } from '@angular/router';
import { postService } from '../post-container/post-container.service';
import { outputAst } from '@angular/compiler';
import { HomeService } from 'src/app/components/home/service/home.service';

@Component({
  selector: 'publish-container',
  templateUrl: './publish-container.component.html',
  styleUrls: ['./publish-container.component.scss']
})
export class PublishContainerComponent implements OnInit {
  @ViewChild("imagePicker", { static: false }) imagePicker: ElementRef;
  @ViewChild("openModel", { static: false }) openModel: ElementRef;

  @Input() publish: any;
  @Input() userInfo: any; 
  @Output() publishItemUpdate = new EventEmitter<{ title: string }>();
   
  color:any ="";
  comment: any = "";
  commentList: any = [];
  isShowComment: boolean = false;
  isLoader: boolean = false;
  accountHolder: any;
  currentPage: number = 0;
  throttle = 150;
  scrollDistance = 1;
  scrollUpDistance = 1.5;
  emojiToggled: boolean = false;
  postEmojiToggled: boolean = false;
  selectedFiles: FileList;
  postImage: any = null;
  description: string = null;
  companyInfo:any =""
  POstData: any;
   

  constructor(private modalService: NgbModal, private _lightboxConfig: LightboxConfig, 
    private _lightbox: Lightbox, private publishService: PublishContainerService, 
    private apiService: postService, private router: Router, private awsService: AwsService,
    private homeService: HomeService) { }

  ngOnInit(): void {    
    this.publish.graphAndImages=[];
    this.publish.graphAndImages =this.publish.graphAndImages.concat(this.publish.images)
    this.publish.commentsOnPublishList.sort(this.compare); 
    this.accountHolder = JSON.parse(localStorage.getItem('user'));
    this.companyInfo = JSON.parse(localStorage.getItem('company'));
    this.publish.comment = false
    this.updateImagesWithChart();
    this.POstData = this.publish;   
  }

   compare( a, b ) {
    if ( a.id < b.id ){
      return 1;
    }
    if ( a.id > b.id ){
      return -1;
    }
    return 0;
  }

  getPost(post, content) {
     this.publishItemUpdate.emit(post);
    // $('#editModel').removeData();
    // this.postImage = post.image;
    // this.description = post.description;
    // this.openModel.nativeElement.click();
    // this.postImage = post.image;
    // this.description = post.description
    // this.modalService
    //   .open(content, { ariaLabelledBy: "modal-basic-title" })

  }

  open(list): void {
    // open lightbox
    let albumList = [];

    list.forEach(function(element){
      const album = {
        src: element,
        caption: "",
        thumb: element
      };
      albumList.push(album)
  
    });      
    this._lightbox.open(albumList, 0, { wrapAround: true, showImageNumberLabel: true, centerVertically: true });
  }
  openGraph(image): void {
    // open lightbox
    let albumList = [];
    const album = {
      src: image.chartUrl,
      caption: "image",
      thumb: image.chartUrl
    };
    albumList.push(album)

    this._lightbox.open(albumList, 0, { wrapAround: true, showImageNumberLabel: false, centerVertically: true });
  }

  /* ==================  like and Dislike Post ====================== */
  likePublish() {
    console.log(this.publish)
    if(this.publish.likePublishUser.includes(this.userInfo.id)){      
      delete this.publish.likePublishUser[this.publish.likePublishUser.indexOf(this.userInfo.id)];
      this.publish.noOfLikes =(this.publish.noOfLikes>0)? this.publish.noOfLikes-1:0;
      
    }else{       
      this.publish.noOfLikes = (this.publish.noOfLikes)?this.publish.noOfLikes+1:1;
      this.publish.likePublishUser.push(this.userInfo.id);
    }   

    this.publishService.addLikeOrDislikeToPublish(this.publish).subscribe(res => {
      if (res.success) {
         
        // this.publish.isDislikedByUser = res.data.isDisliked;
        // this.publish.isLikedByUser = res.data.isLiked;
        // this.publish.noOfDislikes = res.data.noOfDislikes
        // this.publish.noOfLikes = res.data.noOflikes
      }
    })
  }
  dislikePost() {
    let obj = {
      isDisliked: 'true',
      publishId: this.publish.id,
      userId: this.accountHolder.id

    }
    this.publishService.addLikeOrDislikeToPublish(obj).subscribe(res => {
      if (res.success) {
        this.publish.isDislikedByUser = res.data.isDisliked;
        this.publish.isLikedByUser = res.data.isLiked;
        this.publish.noOfDislikes = res.data.noOfDislikes
        this.publish.noOfLikes = res.data.noOflikes
      }
    })
  }

  /* ========================  Comment Section   ======================================= */



  addComment(publish) {  
      
    if (this.comment != "") {
      let obj = {
        description: this.comment,         
        user:this.userInfo,
        publish: publish
      }
      this.publishService.addCommentOnPublish(obj).subscribe(res => {
        if (res.success) {
          this.comment = ""          
          console.log("list",this.publish.commentsOnPublishList)
          
          this.publish.commentsOnPublishList.unshift(res.data);       
        }
      })
    }

  }
  getAllCommentsByPublishId(postId, pageNo) {
    this.isLoader = true;
    this.publishService.getCommentListByPublish(this.userInfo.id, postId, pageNo).subscribe(res => {
      this.isLoader = false;
      if (res.success) {
        this.commentList = res.data.content;
        this.isShowComment = true;
      }
    })
  }
  hideAllComment() {
    this.isShowComment = false;
    this.commentList = [];
  }

  /* ======================  infinite Scroll  =========================== */
  onScrollDown() {
    this.currentPage += 1;
    this.getAllCommentsByPublishId(this.publish.id, this.currentPage)
  }
  /* ============================      ============================================ */

  selectUser(id) {
    localStorage.setItem("visit", id);
    this.router.navigate(['../profile/timeline'], { queryParams: { visit: id } });
  }

  selectCompany(id){
    this.router.navigate(['../company/companyinfo/publish'], { queryParams: { companyId: id } });
  }
  /* ================  Delete Post  ========================*/
  deletePublish(PublishId) {
    this.apiService.deletePublish(PublishId).subscribe(res => {
      if (res.success) {
        this.apiService.emitDeleteEvent(PublishId)
      }
    })
  }

  /* ================  update post  ========================*/

  getImage() {
    try {
      this.imagePicker.nativeElement.click();
    } catch (error) {
    }
  }
  changeListener($event) {
    this.selectedFiles = $event.target.files;
    const file = this.selectedFiles.item(0);
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (_event) => {
      this.postImage = reader.result
    }
  }

  removeImage() {
    this.selectedFiles = null;
    this.postImage = '';
  }
  updatePostAdd() {
    if (this.selectedFiles == null || this.postImage == '') {
      const obj = {    /* Request payload of add post */
        description: this.description,
        image: this.postImage,
        userId: this.userInfo.id,
        id: this.publish.id,
      }
      this.apiService.updatePost(obj).subscribe(response => {
        if (response.success) {
          this.apiService.emitUpdateEvent(response.data);
          this.modalService.dismissAll();
        }
      })
    } else {
      this.onPostImageUpload();
    }
  }

  onPostImageUpload() {
    const file = this.selectedFiles.item(0);
    this.awsService.uploadFile(file, "postimage").then(res => {
      let obj = {
        description: this.description,
        image: res,
        userId: this.userInfo.id,
        id: this.publish.id,
      }
      this.apiService.updatePost(obj).subscribe(response => {
        if (response.success) {
          this.apiService.emitUpdateEvent(response.data);
          this.modalService.dismissAll();
        }
      })
    })
  }
  close() {
    this.modalService.dismissAll();
  }


  toggleEmoji(value) {
    switch (value) {
      case 1:
        this.postEmojiToggled = !this.postEmojiToggled;
        break;

      case 2:
        this.emojiToggled = !this.emojiToggled;
        break;
    }
  }

  addEmoji(event) {
    let emoji = event.emoji.native;
    this.comment = this.comment + emoji;
    this.toggleEmoji(2)
  }

  addEmojiOnPost(event) {
    let emoji = event.emoji.native;
    this.description = this.description + emoji;
    this.toggleEmoji(1)
  }
  commentHideShow(publish){
    publish.comment = ! publish.comment   
  }
  onChangeDateFormat(value) {
    return moment(value).format('YYYY-MMM-DD HH:mm:ss');

    // let day = oldDate.substring(0, 2);
    // let month = moment.monthsShort(oldDate.substring(3, 5) - 1);
    // let year = oldDate.substring(6, 10);
    // return `${day}-${month}-${year}`
  }

  hide() {
    if (this.emojiToggled) {
      this.emojiToggled = false;
    }
  }
  updateImagesWithChart(){
//console.log("graph data=",this.publish.charts)
var result = [];
var WeekToProductLaunch = []
var WeeklyExpenses = []
var NumberofCustomerstalkedto = []
var WeeklyRevenue = []
 
this.publish.charts= this.publish.charts.sort(function(a, b){return a.createdAt - b.createdAt});
       
this.publish.charts.forEach(function(o){
  if (o.title == "Weeks to Product Product Launch") {
   
    WeekToProductLaunch.push(o);
  }
  if (o.title == "Weekly Expenses") {
    WeeklyExpenses.push(o);
  //  this.color ="#293266"
  }
  if (o.title == "Weekly Revenue") {
    WeeklyRevenue.push(o);
   // this.color ="#29f0ae"
  }
  if (o.title == "Number of Customers talked to") {
   // this.color ="#009cc3"
    NumberofCustomerstalkedto.push(o);
  }
 
   
}
   );
   result.push(WeekToProductLaunch)
   result.push(WeeklyExpenses)
   result.push(WeeklyRevenue)
   result.push(NumberofCustomerstalkedto)
result.forEach(chart=>{
  
  

    const d = new Date();
   
    let labels =    []
    let dataValue =   []
    let title;
    let chartType

    chart.forEach(element => {
     let date = new Date(element.createdAt)      
     let d= date.getDate()+"/"+(1+date.getMonth())+"/"+date.getFullYear ().toString().substr(-2);
     labels.push(d)
     title =element.title;
     chartType=element.type;
      dataValue.push(element.dataValue)         
    });

    if(chart.length>0){  
      if(title == "Weeks to Product Product Launch"){      
        this.color ="rgb(23, 98, 144)";
      }else if(title == "Number of Customers talked to"){         
        this.color =  "rgb(0, 156, 195)";
      }else if(title == "Weekly Revenue"){       
        this.color =  "rgb(41, 240, 174)";
      }else if(title == "Weekly Expenses"){         
        this.color = "rgb(41, 50, 102)";
      }

    console.log("colur",this.color)
    
    let chartObject= {     
        type: chartType,
        data: {
          labels:labels  ,
          lineTension: 0,
          datasets: [
            {
              label: title,
              fill: false,
              data: dataValue , 
              bezierCurve: false,         
              borderColor:   this.color,          
              backgroundColor: this.color,
              pointBackgroundColor: this.color,
              pointBorderColor: this.color,
              pointHoverBackgroundColor: this.color,
              pointHoverBorderColor: this.color,
                  
                            
               
            }
          ]
        },
        options: {       
        
          scales: {
            
            xAxes: [{
              gridLines: {
                  display:false
              }
          }],
            yAxes: [{
                ticks: {
                    beginAtZero: true
                },
                gridLines: {
                  display:false
              }
            }        
          ]
        }
        }
               
    }

   let url ="https://quickchart.io/chart?c="+JSON.stringify(chartObject);;
  this.publish.graphAndImages.push(url);       
  }


})
   

  }


  viewPublish(item){   
    var encryptedMessage= this.homeService.encrypt(item.id);
    this.router.navigate(['notification/view'], { queryParams: { updates:  encryptedMessage } });
  }
}
