<div class="p-2 commentBox" *ngIf="(comment.parent == null && comment.parentComment == null)">
    <div class="inline">
        <img class="profile" [src]="comment.user.profilePicture" (click)="selectUser(comment.user.id)"> &nbsp;
        <div>
            <h6 (click)="selectUser(comment.user.id)">{{comment.user.firstName}} {{comment.user.lastName}}<label>{{comment.title}}</label></h6>           
            
         <!-- <h1>total= {{CommentPost.commentsOnPostList.length}}</h1>   -->
            
        </div>
        <div>           
            <h6>  </h6>            
        </div>

    </div>
    <div style="float: right; color: darkgray;font-size: 5px;width: 13px;">
        <img src="../../../assets/icons/three-dots.svg" />
        <!-- {{comment.date | date: 'dd-MMM-yyyy'}}<br />
        {{comment.time}}<br /> -->
    </div>
    <div class="comment-align">
        <p class="commentText">{{comment.description}}</p>
        <div class="upThumb" style="cursor: pointer;">
            <!-- <i class="fa fa-thumbs-o-up" (click)="LikeOnComment(comment)">

        </i> &nbsp;&nbsp;{{comment.noOfLikes}} Likes&nbsp;&nbsp; -->
        <span style="cursor: pointer;" (click)="hideShowReply()" >Reply</span>
         <span class="ml-5">{{comment.createdAt|date: 'dd MMM, yyyy '}}{{comment.createdAt|date: 'shortTime'}}</span></div>
    </div>
     
    <div class="replySection" *ngIf="comment.reply">
    <div class="inline w-100 postionRealtive">
        <input type="text" (keydown.enter)="addcommentOnComment()"  class="form-control"
            placeholder="Reply" [(ngModel)]="replycomment"> &nbsp;
            <i class="fa fa-paper-plane paperPlane" (click)="addcommentOnComment()"></i>
       </div>
    </div>
    <div class="replySection"  *ngFor="let item of childCommentList"   >
        <div *ngIf="item.parent != null &&  item.parent.id == comment.id">
            <div class="inline"> 
                <img class="profile"  [src]="item.user.profilePicture" (click)="selectUser(item.user.id)"> &nbsp;
                <div>
                    <h6 (click)="selectUser(item.user.id)">{{item.user.firstName}} {{item.user.lastName}}<label>{{item.user.title}}</label></h6>
                </div>
            </div>
            <!-- <div style="float: right; color: darkgray;font-size: 5px;width: 13px;">
                <img src="../../../assets/icons/three-dots.svg" />{{item.createdAt|date: 'dd MMM, yyyy hh:mm'}}
               
            </div> -->
            <div class="comment-align">
                <p class="commentText">{{item.description}}</p>
                
            </div>      
        
    </div>
    <div *ngIf="item.parentComment != null &&  item.parentComment.id == comment.id">
        <div class="inline"> 
            <img class="profile"  [src]="item.user.profilePicture" (click)="selectUser(item.user.id)"> &nbsp;
            <div>
                <h6 (click)="selectUser(item.user.id)">{{item.user.firstName}} {{item.user.lastName}}<label>{{item.user.title}}</label></h6>
            </div>
            <!-- <label> {{item.createdAt|date: 'dd MMM, yyyy hh:mm'}}</label> -->
        </div>
        <div style="float: right; color: darkgray;font-size: 5px;width: 13px;">
            <img src="../../../assets/icons/three-dots.svg" />
           
        </div>
        <div class="comment-align">
            <p class="commentText">   {{item.description}}</p>
            
        </div>      
    
</div>
     </div>
</div>