<div>

</div>
<div class="row networkCard">
    <div class="col-md-12 spacing-Grid">
        <div class="inline mt-2">
            <div class="maintitle">Connect With More People</div>
        </div>
    </div>
    <div class="col-md-4 spacing-Grid" *ngFor="let item of suggestList | paginate: config ;let i = index;"
       >
        <div class="card">
        <!-- <div class="text-right">
            <i class="fa fa-times" (click)="remove(i)"></i>
        </div> -->
        <div class="row">
            
        <div class="col-md-3 pl-0 pr-0"  [routerLink]="['/profile/timeline']" [queryParams]="{ visit: item.id}">
            <!-- <app-profile-photo [user]="item"></app-profile-photo> -->
            <img class="profile" [src]="item.profilePicture!=null?item.profilePicture:'https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/User - default.png'" />
        </div>
        <div class="col-md-9 pr-0 pl-3">
        <div class="text-center" style="cursor: pointer;" [routerLink]="['/profile/timeline']" [queryParams]="{ visit: item.id}">
            <!-- <img class="p-3 profile" [src]="item.profilePicture" /> -->
            <!-- <h6>mohit{{item.firstName}} {{item.lastName}}</h6> -->
            <h6> {{getName(item.firstName,item.lastName )}}  </h6>
            <div class="postionText" *ngIf="item.roles != null && item.roles[0]!= null">{{item.roles[0].roleName}}</div>
            <div class="cardDesc" *ngIf="item.headline">{{item.headline.length > 26 ? item.headline.substring(0, 26) + "..." : item.headline}}</div>
        </div>
        <div style="display: flex;">
            <button  *ngIf="!item.isFollowed" class="btn btn-sm follow mb-0"><a href="javascript:void(0)" (click)="sendFollowRquest(item,i)"
               >Follow</a></button>
                <button *ngIf="item.isFollowed" class="btn btn-sm follow mb-0"><a href="javascript:void(0)" (click)="sendUnfollowRquest(item,i)"
                    >Unfollow</a></button>

            <!-- <a style="padding-top:12px" href="javascript:void(0)" (click)="sendUnfollowRquest(item,i)"
                *ngIf="item.isFollowed">Unfollow</a> -->
            &nbsp;
            <button  *ngIf="item.connection == null" class="btn btn-sm reachOut mb-0" (click)="sendRequest(item,i)">Reach Out</button>
            <button *ngIf="item.connection != null" class="btn btn-sm reachOut mb-0" (click)="deleteConnection(item,i)">Cancel Request</button>            
        </div>
        </div>
        </div>
    </div>
    </div>
</div>
<div style="text-align: center;padding: 0.8rem;">
    <pagination-controls (pageChange)="pageChanged($event)">
    </pagination-controls>
</div>