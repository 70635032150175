<div class="card">
    <div class="card-header text-center" (click)="GoToOwnerProfile()" style="cursor: pointer;">
        <img class="profile" *ngIf="presence$ | async as presence" 
        [ngClass]="{   'imgBorderGreen':  presence.status  === 'online',
                       'imgBordergray': presence.status  === 'away' || presence.status  === 'offline'            
                   }" 
        [src]="userInfo?.profilePicture">
        <h6>{{userInfo?.firstName}} {{userInfo?.lastName}} <img src="../../../../assets/icons/userEdit.png" (click)="GoToOwnerProfile()"></h6>
        <!-- <label>{{userInfo?.headline}}</label> -->
    </div>
    <div class="p-22">
        <div class="progressBlock" *ngIf="userInfo.profileCompletedPercentage < 100">
          <div class="progress1">
            <div class="progress-bar1" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
            [ngStyle]="{'width': userInfo.profileCompletedPercentage+'%'}">
              <span>{{userInfo.profileCompletedPercentage}}%</span>
            </div>
          </div>
          <p>Complete profile gets you maximum visibility.</p>
          <button class="btn compProfileBtn" (click)="GoToOwnerProfile()">Complete profile</button>
        </div>
        <button class="btn userProfileBtn  marginTop-0" (click)="GoToExplore()"> Explore Startups / Investors</button>
        <button class="btn userProfileBtn" *ngIf="!isStudent && isUserCreatedCompany" id="dropdownButton" data-toggle="dropdown" aria-haspopup="true"> My Companies&nbsp; 
            <img src="assets/chevron-down.svg" class="img-css" alt="">
        </button>
        <button class="btn userProfileBtn" *ngIf="!isStudent && !isUserCreatedCompany" (click)="goToCreateCompany()"> Create Company</button>

        <button class="btn userProfileBtn" (click)="goToAddressBook()" *ngIf="!isStudent && isUserCreatedCompany"> Address Book</button>
        <a  [routerLink]="['/investor']"><button class="btn userProfileBtn" *ngIf="isInvestor">Investor Dashboard</button></a>
        
        <div class="dropdown-menu pull-left" aria-labelledby="dropdownButton" style="padding: 5px; width: 170px;">
            <ul>
                <li class="companyName" *ngFor="let item of companyList;let i = index;"  (click)="changeCompany(item)">{{item.name}}</li>
            </ul>
        </div>
    </div>
    <!-- <hr> -->
   
</div>

<!-- <div class="card company-model" *ngIf="this.companyDetail?.id != null">
    <div class="p-22">
    <h6 style="padding: 5px;">My Company</h6>
    <div class="card-header text-center" (click)="GoToCompanyDetail()" style="cursor: pointer;">
        <img class="company" [src]="companyDetail?.logo">
        <h6>{{companyDetail?.name}} ({{companyDetail?.company_type}})</h6>
        <label>{{companyDetail?.industry}}</label>
    </div>
    <hr>
    <div class="p-3 job">
       
        <a [routerLink]="['/jobs/job-post-ui']"> <span style="color: red; cursor: pointer;">Post a job</span></a>
    </div>
    </div>
</div> -->