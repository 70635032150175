<div class="bioCard p-4">
    <div class="tabDataEdu">
    <div class="row" >
        <div class="col-12 pl-0 pr-0">
            <div class="educationTitle">Education <sup><img *ngIf="userInfo?.isOwener" (click)="newOpen()"
                data-target="#eductionModel" data-toggle="modal" src="assets/icons/pencil-square.svg"/></sup></div>
        </div>
        <div class="col-12" *ngFor="let item of educationList;let i = index">
            <div class="row">
                <div class="col-10">
                    <div class="univercityName"> <img src="assets/icons/univercity.png"/> {{item.university.name}} ({{item.startYear | date: 'yyyy' }} <span *ngIf="item.endYear">-{{item.endYear | date: 'yyyy'}}</span>)</div>
                    <div class="faculty"> 
                        <div class="pinIcon"><img src="assets/icons/pinTower.png"/></div> 
                         <div class="w-80"><span class="semoBold">{{item.degree}}</span><br /> {{item.stream}}</div>
                    </div>
                </div>
                <div class="col-2">
                    <span class="add-icon" style="float: left;"><i class="fa fa-edit" data-target="#eductionModel" data-toggle="modal"
                        (click)="edit(item,i)"></i></span> 
                    <span class="add-trash"><i class="fa fa-trash" (click)="deleteSchool(item,i)"></i></span>    
                </div>
            </div>
        </div>
       
        <!-- <div class="col-1 col-xs-2">
            <i *ngIf="userInfo?.isOwener" class="fa fa-plus-circle fa-2x" aria-hidden="true" (click)="newOpen()"
                data-target="#eductionModel" data-toggle="modal"></i>
        </div> -->
    </div>
    </div>
    <!-- <div *ngFor="let item of educationList;let i = index" class="mt-3">
        <div class="dsplay-flex"><img style="width:45px" [src]="item.institutionLogo" />&nbsp;<h6>
                {{item.institutionName}}<br />{{item.startDate}} - {{item.endDate}}
            </h6>

            <div *ngIf="userInfo?.isOwener" class="deleteIcon">
                <span class="add-icon"><i class="fa fa-edit" data-target="#eductionModel" data-toggle="modal"
                        (click)="selecetedItem(item,i)"></i></span> <br />
                <span class="add-trash"><i class="fa fa-trash" (click)="deleteSchool(item,i)"></i></span>

            </div>
        </div>
        <ul class="events">
            <li>
                <time></time>
                <span><strong>{{item.degree}}</strong> {{item.stream}}</span>
            </li>
        </ul>
    </div>
</div> -->

<!-- ====== Education Model ===================== -->
<div class="eductionModel">
<div class="modal" id="eductionModel" role="dialog">
    <div class="modal-dialog">

        <div class="modal-content p-3">
            <div class="modal-Custheader p-2">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h6 class="modal-title">Add Education</h6>
            </div>
            <div class="modal-body">
                <form class="p-2" [formGroup]="educationForm" (submit)="eduSubmit()">
                    <div class="row">
                        <div class="col col-md-12 p-2">
                            <label>School Name / college <span class="mandatory">*</span></label>
                            <div class="form-control">
                                <!-- <img [src]="educationForm.value.institutionLogo" style="width: 20px;"> -->
                                <img src="assets/icons/univercity.png" style="width: 20px;">
                                <input type="text" (blur)="onBlur()" (focus)="onFocus(1)" (keyup)="search($event)"
                                    class="school" placeholder="eg. shivaji university"
                                    formControlName="university">
                            </div>
                            <small class="text-danger"
                                *ngIf="educationForm.get('university').invalid && educationForm.get('university').touched">Select
                                School
                                Name / college.</small>
                            <div class="card list" *ngIf="showList == 1 && searchList.length > 0">
                                <li *ngFor="let item of searchList" (click)="selectItem(1,item)"><img
                                        src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/unversity.png"
                                        style="width: 25px;">&nbsp;{{item.name}}</li>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-md-12 p-2">
                            <label>Degree <span class="mandatory">*</span></label>
                            <input type="text" (blur)="onBlur()" (focus)="onFocus(2)" (keyup)="search($event)"
                                placeholder="eg. Bachelor" class="form-control" formControlName="degree">
                            <div class="card" *ngIf="showList == 2 && searchList.length > 0">
                                <li *ngFor="let item of searchList" (click)="selectItem(2,item)">{{item}}</li>
                            </div>
                            <small class="text-danger" *ngIf="educationForm.get('degree').invalid && educationForm.get('degree').touched">Select Degree .</small>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col col-md-12 p-2">
                            <label>Field</label>
                            <input type="text" (blur)="onBlur()" (focus)="onFocus(3)" (keyup)="search($event)"
                                placeholder="eg. Business" class="form-control" formControlName="stream">
                            <div class="card" *ngIf="showList == 3 && searchList.length > 0">
                                <li *ngFor="let item of searchList" (click)="selectItem(3,item)">{{item}}</li>
                            </div>
                            <small class="text-danger" *ngIf="educationForm.get('stream').invalid && educationForm.get('stream').touched">Select Field .</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xl-6 p-2">
                            <label>Start Year</label>
                            <select class="form-control" formControlName="startDate">
                                <option value='' selected disabled hidden>Select Start year</option>
                                <option [value]="item" *ngFor="let item of yearList">{{item}}</option>
                            </select>
                            <small class="text-danger" *ngIf="isDatavalid">Start year should not be less than end year  .</small>
                        </div>
                        <div class="col col-xl-6 p-2">
                            <label>End Year</label>
                            <select class="form-control" formControlName="endDate">
                                <option value='' selected disabled hidden>Select End year</option>
                                <option [value]="item" *ngFor="let item of endYearList">{{item}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-md-12 p-2">
                            <label>Grade</label>
                            <input type="text" formControlName="grade" placeholder="Grade" class="form-control">
                            <small class="text-danger" *ngIf="educationForm.get('grade').invalid && educationForm.get('grade').touched">Select grade .</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-md-12 p-2">
                            <label>Activities and societies</label>
                            <textarea type="text" formControlName="activities" placeholder="Activities and societies"
                                class="form-control"></textarea>
                                <small class="text-danger" *ngIf="educationForm.get('activities').invalid && educationForm.get('activities').touched">Select Activities and societies .</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-md-12 p-2">
                            <label>Description</label>
                            <textarea formControlName="societies" type="text" placeholder="Description"
                                class="form-control"></textarea>
                                <small class="text-danger" *ngIf="educationForm.get('societies').invalid && educationForm.get('societies').touched">Select Description .</small>

                        </div>
                    </div>
                    <div class="text-center">
                        <button type="submit" class="btn modelBtn">Save</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
</div>
