import { Component, Inject, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JoblistService } from 'src/app/shared/service/joblist.service';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-popup6',
  templateUrl: './popup6.component.html',
  styleUrls: ['./popup6.component.scss']
})
export class Popup6Component implements OnInit {
  public popupMessage:String= "Are You Sure you want to deactivate this job post for java Developer";
  constructor(public joblistService:JoblistService,private router:Router,private toastr:ToastrService,public dialogRef: MatDialogRef<Popup6Component>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _snackBar: MatSnackBar) { }
    @Input() getMessage:String='';
    remaining:number =0
  onNoClick(): void {
    this.dialogRef.close();
  }

  onNoClickRepayment(): void {
    this.dialogRef.close({data: 'Repayment'});
  }
  ngOnChanges():void{
      this.popupMessage=this.getMessage;
      console.log("Popup message : ",this.popupMessage)

  }
  ngOnInit(): void {
   console.log(this.data)
  }

  updateJob(redirect:boolean=false){
    var requestBody = {
      "active": this.data.active,
      "id":this.data.id
      }
      this.joblistService.setJobStatusResponse({
        "message" : "",
        "data" : "",
        "success" : false
      });
      this.joblistService.updateJobStatus(requestBody).subscribe((responseBody)=>{
        console.log("responseBody : ",responseBody);
        this.dialogRef.close();
        this.joblistService.setJobStatusResponse(responseBody);
      });
      if(redirect){
        this.router.navigate(['jobs/job-post-ui'])
      }
  }

  hireUser(id,job_id,user_id){
    var requestBody = {
      id:id,
      job_id:job_id,
      user_id:user_id
    }
    console.log("requestBody:",requestBody)
    this.joblistService.hireUser(requestBody).subscribe((responseBody)=>{
      console.log("responseBody : ",responseBody);
      if(responseBody['success']){
        this.data.msg=responseBody['message'];
        //this.data.flag="repayment";
        this.data.flag="hireUserResponse";
        this.remaining = parseInt(responseBody['data'])
      }else{
        //  this.toastr.error(responseBody['message']);
        this._snackBar.open(responseBody['message'], 'OK', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: 'style-error'
        });
        
        this.remaining = parseInt(responseBody['data'])
      }
    });
  }

  repayment(message,count){
    this.data.msg=message;
    this.data.flag="repayment";
   // this.data.flag="hireUserResponse";
    this.remaining = parseInt(count)
  }
  disableJob(job_id){
     this.data.active = false;
      this.data.id=job_id;
      var redirect = true;
      this.updateJob(redirect);
  }
}
