<div class="networkCard">
<div class="card">
    <div class="card-header">
        <div class="flex w-100">
            <h6>Manage My Netwoks</h6>
        </div>
    </div>
    <ul>
        <li (click)="gotoFollowers()" class="pointer">
            <label class="li-label">Followers</label>
            &nbsp;<span class="count">{{followersCount}}</span>
        </li>
        <li>
            <div class="investorAndCount">
                <div class="investorLeft">
                <div class="selectCustom">
                    <!-- <img class="previewIocnSelect" src="../../../../../assets/icons/template.svg" /> -->
                    <select title="Pick a number" class="selectpicker resizeselect border-0">
                        <option> Connections</option>
                        <option>Investors ({{(invertor)?invertor.length:0}})</option>
                        <option>Founders ({{(founder)?founder.length:0}})</option>                        
                        <option>Others ({{(others)?others.length:0}})</option>
                    </select>               
                </div>
                </div>
                <div class="investorVount">
                   {{(userInfo.connection)?userInfo.connection.length:0}}
                </div>
            </div>
            <!-- <div class="dropdown">
                <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                  Dropdown button
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#">Link 1</a>
                  <a class="dropdown-item" href="#">Link 2</a>
                  <a class="dropdown-item" href="#">Link 3</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Another link</a>
                </div>
              </div> -->
        </li>
        <!-- <li [routerLink]="['/profile/connections']" [queryParams]="{ visit: userId}">
            <label class="li-label">Connection</label> &nbsp;<span class="count">{{networkCount}}</span>
        </li> -->
        <!-- <hr> -->
       
        <!-- <hr> -->
        <!-- <li>
            <label class="li-label">Event</label> &nbsp;<span class="count"></span>
        </li> -->
    </ul>

</div>
</div>