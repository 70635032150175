<div class="card p-2" id="invitationCard">
    <div class="col-12 spacing-title">
    <div class="inline mt-2">
        <h6 class="header">Invitations ({{requestList.length}})</h6>

        <!-- <div class="menu">
            <a *ngIf="requestList.length > 0" [routerLink]="['./list']" [queryParams]="{ visit: userId}">View
                All</a>
        </div> -->
    </div>
    </div>
<!-- 
    <div *ngIf="requestList.length > 0"> -->
        <div *ngIf="requestList.length">
            <div class="container mt-2">
                <div class="carousel-container position-relative row">  
               
                <div id="carousel-thumbs" class="carousel slide" data-ride="carousel">
                    <!-- <li *ngFor='let in of [].constructor(requsterRows); ;let k = index'>{{k}} 
                        <hr>
                        <div *ngFor="let item of requestList|slice:((k==0)?0:k*4):4*k+4;let i = index">
                            {{item.user.firstName}}{{i}}
                            {{((i==0)?0:i*4+1)}} --- {{4*i+4}}
                        </div>                      
                        
                         </li>
                         <div *ngFor="let item of requestList;let i = index">
                            {{item.user.firstName}}{{i}}
                        </div>  -->
                    
                    
                  <div class="carousel-inner">
                    <div   *ngFor='let in of [].constructor(requsterRows); let k = index'  class="carousel-item  {{(k==0)?'active':''}}">
                      <div class="row mx-0"  >                     
                        <div  *ngFor="let item of requestList|slice:((k==0)?0:k*4):4*k+4;let i = index" id="carousel-selector-0" class="thumb col-4 col-sm-3 px-1 py-2 selected" data-target="#myCarousel" data-slide-to="0">
                            <div class="inlineCard"   *ngIf="item.connected == false">
                               
                                <div class="" (click)="selectUser(item.user.id)">
                                    <a>
                                        <div class="SliderCardTitle">{{substr(item.user.firstName+' '+item.user.lastName,14)}} </div>
                                        <label>{{substr(item.user.headline,18)}}</label>
                                    </a>
                                </div>
                                <div class="menu pl-1">
                                    <a ><img (click)="rejectRequest(item,i)" class="   checkClose"
                                        src="assets/icons/times-circle.svg" /></a>
                                        <a  > <img (click)="acceptRequest(item,k*4+i)"  class="checkClose"
                                            src="assets/icons/check-circle.svg" /></a>
                                </div>
                            </div>

                        </div>
                        
                      </div>
                    </div>                    
                  </div>
                  <a class="carousel-control-prev" href="#carousel-thumbs" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"><img src="assets/icons/ionic-md-arrow-dropleft-circle.svg"/></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#carousel-thumbs" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"><img src="assets/icons/ionic-md-arrow-dropright-circle.svg"/></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
                
                </div> 
                </div> 
        <ul>
          
            <!-- <li *ngFor="let item of requestList;let i = index">
                <div class="inline">
                    <img class="profile"
                        [src]="item.user.profilePic != null ? item.user.profilePic:'assets/logo/profile_avater.png'">
                    &nbsp;
                    <div (click)="selectUser(item.user.userId)" class="pt-3">
                        <a>
                            <h6>{{item.user.firstName}} {{item.user.lastName}}</h6>
                            <label>{{item.user.headline}}</label>
                        </a>
                    </div>
                    <div class=" menu">
                        <a href="javascript:void(0)" (click)="rejectRequest(item,i)"
                            *ngIf="item.isConnected == 'false'">Ignore</a> &nbsp;
                        <button class="btn btn-outline-primary btn-sm" *ngIf="item.isConnected == 'false'"
                            (click)="acceptRequest(item,i)">Accept</button>
                    </div>
                </div>
            </li> -->
            <!-- <hr> -->
        </ul>
    </div>
    <!-- <div *ngIf="requestList.length == 0">
        <label>You dont have any request.</label>
    </div> -->
</div>