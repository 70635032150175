import * as CryptoJS from 'crypto-js';

import { Component, ElementRef,  EventEmitter,  HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Output } from '@angular/core';

 

import { AwsService } from 'src/app/shared/AWS-Service/aws.service';
import { CompanyApiService } from 'src/app/components/company/company-api.service';
import { HomeService } from './../../service/home.service';
import { ProfileService } from 'src/app/components/profile/profile.service';
import { data, trim } from 'jquery';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Adapter from 'src/app/shared/ckeditorAdapter';
import { CroppedEvent } from 'ngx-photo-editor';
import { element } from 'protractor';
import { postService } from 'src/app/shared/post-container/post-container.service';
 

declare var $: any;

@Component({
  selector: 'write-post',
  templateUrl: './write-post.component.html',
  styleUrls: ['./write-post.component.scss']
})
export class WritePostComponent implements OnInit {
  @ViewChild("imagePicker", { static: false }) imagePicker: ElementRef;
  public Editor = ClassicEditor;
  @Output("getPostList") getPostList: EventEmitter<any> = new EventEmitter();
  @Output() postPanelCompnent = new EventEmitter<{  }>();
  @ViewChild('closebutton') closebutton;
  @Input() publishData:any; 
  selectedFiles: FileList = null;
  dataobj :any
  updatesPreview:"updatesPreview"
  postImage: any = null;
  textarea: boolean = false;
  type: number = 1;
  description = '';
  userInfo: any;
  friendList: any = [];
  selectedFriendList: any = [];
  isShow: boolean = false;
  emojiToggled: boolean = false;
  searchKeyWord: string;
  groupList: any = [];
  companyDetail: any;
  selectedGroupId;
  selectedGroup;
  entity: number;
  tagFlag: boolean = false;
  tagFlagValue = "";
  graphType: string = "";
  selectionTab: number = 1;
  graphImage: any;
  graphList: any = [];
  isLoading: boolean = false;
  caretPos: number = 0;
  userList: any = [];
  mydiscription: string ="";
  tagkeyword: string = "";
  section: any;
  isStudent: boolean = false;
  companyInfo: any;
  updateDescription = "";
  publishId= null;
  postId =null;
  editpostitem = null
  chart:number = 0;
  chartImg:any = []; 
  readingTime:any;
  isGroup=false;
  
  publishChart:any = [];
  editpublishitem  = null
  companyList: any = [];
  previewUpdateDescription: any;
  ckconfig: {
    // include any other configuration you want
    extraPlugins: ((editor: any) => void)[];
  };
  images: any = [];
  emoji: any;
  imageChangedEvent: any;
  isCompanySelected = false;
  isGroupSelected = false;
  pollQuestion: any = "";
  options = [0, 1]
  optionLabelArray = [];
  pollOptions: any = [];

  constructor(private awsService: AwsService, private companyService: CompanyApiService, 
    private apiService: HomeService, private profileService: ProfileService) { }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('user'));
    this.companyList = JSON.parse(localStorage.getItem('companyList'));
    this.companyInfo = JSON.parse(localStorage.getItem('company'));
    this.getUserInfo();
    this.getFriendList(this.userInfo.id)
    // this.getAddresssBookGroupsByCompanyId()
    this.ckconfig = {
      // include any other configuration you want
      extraPlugins: [ this.customAdapterPlugin ]
    };
    console.log("child data"+this.publishData)
  }

  customAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new Adapter(loader, editor.config,this);
    };
  }
  
  public onReady( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
    
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
      return new Adapter(loader, editor.config,this);
    };
  }
  getUserInfo() {
    this.profileService.getUserInfo(this.userInfo.id).subscribe(res => {
      if (res.success) {
        this.userInfo = res.data;

        this.userInfo.roles.forEach(element => {
          if(element.roleName == 'Student'){
            this.isStudent = true;
          }
        });
      }
    })
  }
  selectType(number) {
    alert(number)
    this.type = number;
  }

  onSelectionTab(tab) {
    if (tab == 1) {
      this.selectionTab = 1;
      if (this.postImage == null) {
        this.getImage();
      }
    } else {
      this.selectionTab = 2;
    }
  }

  getImage() {
    try {
      this.imagePicker.nativeElement.click();
    } catch (error) {
    }
  }
  onOpenModel() { 
    switch (this.type) {
      case 1:
        //this.removeImage(1,data);
        $('#postModal').modal('show');
        this.getAddresssBookGroupsByCompanyId();
        this.getCompanyDetail();
        break;
      case 2:
        
        $('#poleModel').modal('show');
        if (this.userInfo.company_id != null) {
          this.getAddresssBookGroupsByCompanyId();
          this.getCompanyDetail();
        }
        break;
      case 3:
        //this.removeImage(1,data);
        $('#questionModel').modal('show');
        break;
    }
    this.description = "";
    this.postImage = null;
    this.graphImage = null;
    this.graphList = [];
    this.entity = null;
    this.graphType = ""
  }
  changeListener($event) {
    console.log("img upload 168")
    this.selectedFiles = $event.target.files;
    const file = this.selectedFiles.item(0);
    var reader = new FileReader()
    reader.readAsDataURL(file)
    console.log(reader)
    reader.onload = (_event) => {
       this.postImage = reader.result
      this.awsService.uploadFile(file, "postimage").then(res => {
        this.images.push(res);
        // this.editor.execute( 'insertImage', { source: res } );
      })
    }
  }

  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: CroppedEvent) {
      this.awsService.uploadFile(event.file, "postimage").then(res => {
        this.images.push(res);
    })
  }

  removeImage(index) {
    // this.images.forEach( (item, index) => {
    //   if(item === data) this.images.splice(index,1);
    // });
    console.log("inde=",index)
    delete this.images[index]
    var filtered = this.images.filter(function (el) {
      return el != null;
    });
    this.images =filtered;
    console.log("images=",this.images)


    // if (type == 1) {
    //   this.selectedFiles = null;
    //   this.postImage = null;
    // } else {
    //   this.graphImage = null;
    // }
  }
  
  /* ====== get Friend List ========== */

  getFriendList(userId) {
    this.apiService.getFriendList(userId).subscribe(res => {
      if (res.success) {
        this.friendList = res.data.content;
      }
    })
  }

  /* ================== SEARCH FRIEND LIST  ======================== */
  onSearchFriend(value) {
    if (value != "") {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  onSelectFriendFromList(item) {
    this.selectedFriendList.push(item)
    if (this.selectedFriendList.length < 1) {
      this.selectedFriendList.push(item)

    } else {
      if (this.selectedFriendList.indexOf(item) == -1) {
        this.selectedFriendList.push(item)
      }
    }
    this.isShow = false;
  }
  removeFriend(index) {
    this.selectedFriendList.splice(index, 1);
  }
  /*====================== Add Post Api call For Description with Image ================*/
  // onPostImageUpload() {
    
  //   const file = this.selectedFiles.item(0);
     
  //   this.awsService.uploadFile(this.selectedFiles, "postimage").then(res => {
  //     let obj = {
  //       description: this.urlify(this.description),
  //       image: res,
  //       userId: this.userInfo.id,

  //     }
  //     this.apiService.addNewPost(obj).subscribe(response => {
  //       if (response.success) {
  //         this.apiService.emitEvent();
  //         $("#myModal .close").click();
  //         this.isLoading = false;
  //       }
  //     })
  //   })
  // }
  // onPublishImageUpload(companyId) {    
  //   // const file = this.selectedFiles.item(0);
  //   // this.awsService.uploadFile(file, "postimage").then(res => {
  //     let obj = {
  //       description: this.urlify(this.updateDescription),
  //       images: this.images,
  //       user: this.userInfo,
  //       // companyId: companyId,
  //       group: this.selectedGroup,
  //       charts: this.graphList
  //     }

  //     this.apiService.addNewPublish(obj).subscribe(response => {
  //       if (response.success) {
  //         this.apiService.emitEvent();
  //         $("#poleModel .close").click();
  //         $("#accountModel .close").click();
  //         this.isLoading = false;
  //       }
  //     })
  //   // })
  // }
  /* ================= Graph  ======================= */
  onAddRecordGraph() {
    if (this.graphType != "" && this.entity != null) {
      let obj = {
        companyId: this.companyDetail.id,
        userId: this.userInfo.id,
        datasets: [{ data: [`${this.entity}`], label: this.graphType }],
      }
      this.apiService.createChart(obj).subscribe(res => {
        if (res.success) {
          this.graphImage = res.data[0];
          this.createGraphList(this.graphImage);
        }
      })
    }

  }

  createGraphList(image) {
    if (this.graphList.length > 0) {
      let index = this.graphList.findIndex(res => res.chartName == image.chartName);
      if (index !== -1) {
        return this.graphList[index].chartUrl = image.chartUrl;
      } else {
        this.graphList.push(image)
      }
    } else {
      this.graphList.push(image);
    }

  }
  removeGraph(index) {
   // this.graphList = this.graphList.filter(res => res.chartName != item.chartName)
   delete this.chartImg[index]
   delete this.publishChart[index]
   this.publishChart= this.publishChart.filter(Boolean);
   this.chartImg= this.chartImg.filter(Boolean);
   
   
  }
  /*=================Add Post Api call only For Description ==============*/
  updatePostItem(postItem){
    this.editpostitem = postItem
    console.log("updatepost=>",postItem)
    $('#postModal').modal('show');    
    this.description =postItem.description; 
    // this.images = publish.images   
    for  (let i = 0 ; i < postItem.images.length ; i ++){
      this.images.push(postItem.images[i])
    }
    this.postId = postItem.id;   
  }
  onNewPostAdd(){
    this.isLoading = true;
    this.description = document.getElementById("newSection").innerHTML
    document.getElementById("newSection").innerHTML = ""
    // this.description = this.section.innerHTML;
    // this.section.innerHTML = "" ;
    if(this.editpostitem != null){
      this.editpostitem.description  = this.urlify(this.description),  
      this.editpostitem.images  = this.images
    }else{
      this.editpostitem = {
        images:this.images, 
        description: this.urlify(this.description),
        user: this.userInfo
      }
    }
      this.apiService.addNewPost(this.editpostitem).subscribe(res => {
              if (res.success) {
                this.editpostitem = null
                this.getPostList.emit();
                this.apiService.emitEvent();
                this.images =[]
                this.postImage =null
                this.description =""
                this.postPanelCompnent.emit()
                //this.apiService.emitEvent();
                $("#postModal .close").click();
                this.isLoading = false;
              }
            })
  }
  // onNewPostAdd() {
  //   this.description = this.section.innerHTML;
  //   this.section.innerHTML = ""

  //   if (this.selectedFiles == null && this.description.length > 0) {
  //     this.isLoading = true;
  //     const obj = {    /* Request payload of add post */
  //       description: this.urlify(this.description),
  //       userId: this.userInfo.id
  //     }
  //     this.apiService.addNewPost(obj).subscribe(res => {
  //       if (res.success) {
  //         this.apiService.emitEvent();
  //         $("#myModal .close").click();
  //         this.isLoading = false;
  //       }
  //     })
  //   } else if (this.selectedFiles != null) {
  //     this.isLoading == null
  //     this.onPostImageUpload();
  //   }
  // }
  /* =============================================== */
  reSetUpdate(){
    this.updateDescription=""
    this.postImage =""
    this.postImage = null;
    this.images = []
    
  }
  updatePublish(publish){
    this.editpublishitem = publish
    $('#myModal').modal('show'); 
    this.updateDescription =publish.description
    // this.images = publish.images 
    this.selectedGroup =  publish.group  
    this.postImage = publish.images[0];
    this.publishId = publish.id;
    console.log("inside child",publish )
  }
  
  onNewPublishAdd(companyId?) {
    if(!this.selectedGroup || !this.companyInfo){
      return;
    }
    console.log("selected group",this.selectedGroup)
   var preview =  $("#updatesPreview").html();
   $("#updatesPreview").find("figure").remove();   
   $("#updatesPreview").find("img").remove();
   console.log("test =",$("#updatesPreview").html())
   $("#updatesPreview").find("img").remove();
   console.log("update=",$("#updatesPreview").html())
     
    this.isLoading = true;  
      if(this.editpublishitem != null){
       // this.editpublishitem.description  = this.urlify(this.updateDescription),
        this.editpublishitem.description  = this.urlify($("#updatesPreview").html()),
        this.editpublishitem.images  = this.images
        this.editpublishitem.group  = this.selectedGroup
      }else{
        this.editpublishitem = {
          description: this.urlify(this.updateDescription),
          id:this.publishId,
          user: this.userInfo,
          companyId: companyId,
          group: this.selectedGroup,
          images: this.images, //this.postImage,
          charts: [],
          pollQuestion: this.pollQuestion,
          options: this.pollOptions
        }
      }
      if(this.publishChart != null){
         this.editpublishitem.charts= this.publishChart
      }

      console.log("data=",this.editpublishitem)
      this.apiService.addNewPublish(this.editpublishitem).subscribe(res => {
        if (res.success) {
          this.apiService.emitEvent();
          this.publishChart =[];
          this.chartImg = [];
          this.editpublishitem = null;
          this.reSetUpdate();  
          this.editpublishitem =null;
          this.postPanelCompnent.emit() 
          this.publishChart=[] 
          this.pollQuestion = null; 
          this.pollOptions = []; 
          this.optionLabelArray = [];  
          $("#myModal .close").click();
          this.isLoading = false;
        }
      })  
  }
  
opneModal(){
  
  $('#UpdatesInstructionModel').modal('hide'); 
  $('#myModal').modal('show'); 
}
  // onNewPublishAdd(companyId?) {
  //   this.isLoading = true;
  //   if (this.selectedFiles == null) {
  //     const obj = {    /* Request payload of add post */
  //       description: this.urlify(this.updateDescription),
  //       user: this.userInfo,
  //       // companyId: companyId,
  //       group: this.selectedGroup,
  //       images: this.images, //this.postImage,
  //       charts: this.graphList,

  //     }
  //     this.apiService.addNewPublish(obj).subscribe(res => {
  //       if (res.success) {
  //         this.apiService.emitEvent();
  //         this.reSetUpdate();
  //         this.updateDescription=""
  //         this.postImage =""
  //         $("#myModal .close").click();
          

  //         // $("#poleModel .close").click();
  //         // $("#accountModel .close").click();
  //         this.isLoading = false;
  //       }
  //     })
  //   } else {
  //     this.onPublishImageUpload(companyId);
  //   }
  // }

  /* ================= */
  hide() {
    if (this.emojiToggled) {
      this.emojiToggled = false;
    }
  }
  toggleEmoji() {
    this.emojiToggled = !this.emojiToggled;
  }
  addEmoji(event) {
    this.description = document.getElementById("newSection").innerHTML
    this.emoji = event.emoji.native;
    this.description = [this.description, this.emoji].join(''); // [this.description.slice(0, this.caretPos), this.emoji, this.description.slice(this.caretPos)].join('');
    this.toggleEmoji();
  }

  selectUser(item){
    let userName = ""
    let link = "";
    if(item != null && item.firstName != null){
      userName =  item.firstName + " "+item.lastName;
      link = "#/profile/timeline?visit="+item.id;
    }   
    
    console.log(this.tagkeyword)
    let txt = this.section.innerHTML.replace(this.tagkeyword, "@");
    this.section.innerHTML = txt+" <a href='"+link+"' >"+userName+"</a>"
    this.description = this.section.innerHTML;
    this.userList = [];
    this.isShow = false;   
  }

//----------tag function ----------------------------
  tagFuction(keyWord) {
    this.tagkeyword = keyWord;
    if (keyWord != "") {
      keyWord = keyWord.slice(1);
     // let userId = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('Bolstart'), "Bollstart").toString(CryptoJS.enc.Utf8)).id;
      let userId = this.userInfo.id;
     
      this.profileService.searchUserOnlyFirstName(keyWord, userId).subscribe(res => {
        if (res.success) {          
          this.userList = res.data;
          this.isShow = true;
        }else {
          this.userList = [];
          this.isShow = false;
        }
      })
    } else {
      this.userList = [];
      this.isShow = false;
    }
  }   
  findTag(field,section){
    this.section = document.getElementById(section);
    // this.description = this.section.innerHTML;
    let keyWord = field.innerText;
    let arrOfStr = keyWord.split(" @");    
    let key = arrOfStr[arrOfStr.length-1];

    if(keyWord[0] == '@' ){
      console.log("function call")
      this.tagFuction(keyWord);
    } 
    if(!key.includes(" ") && keyWord.includes(" ") ){
      console.log("function aganin  call")
       key = "@"+key;       
       this.tagFuction(key);        
    } 
    if(keyWord.trim().length<1){
      this.userList = [];
      this.isShow = false;
    }    

  }

  

  getCaretPos(oField) {  
    if (oField.selectionStart || oField.selectionStart == '0') {
      this.caretPos = oField.selectionStart;
    }
  }
  urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    let url = text.replace(urlRegex, (url) => {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    })
    return url;
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }
  /* ============= Get AddressBook List ==================*/
  getCompanyDetail() {
    this.profileService.getCompanyInfo(this.userInfo.id).subscribe(res => {
      if (res.success) {
        this.companyDetail = res.data[0]
      }
    })
  }

  // getAddresssBookByComId() {
  //   this.companyService.getAddressBookByCompanyId(this.userInfo.company_id).subscribe(res => {
  //     if (res.success) {
  //       this.groupList = res.data.filter(res => res.emailId.length > 0)
  //     }
  //   })
  // }

  getAddresssBookGroupsByCompanyId(){
    if(this.companyInfo != null && this.companyInfo != undefined && this.companyInfo.length > 0){
      this.companyService.getAddressBookGroupsByCompanyId(this.companyInfo[0].id).subscribe(res => {
        if (res.success) {
          this.groupList = res.data;
          // this.selectedGroup = this.groupList[0];
        }
      })
    }
  }

  selectGroup(group) {
    for(let i=0; i < this.groupList.length; i++){
      if(group == this.groupList[i].groupName){
        this.selectedGroup = this.groupList[i];
        this.isGroupSelected = true;
        break;
      }
    }
  }
  createChart(chartData){    
   this.chartImg.push(chartData.chartImg) ;   
    this.publishChart.push(chartData);      
  }
  ckenditorImage(file){
    return new Promise((resolve, reject) => {
      const reader = this.awsService.uploadFile(file, "postimage").then(res => {
        this.images.push(res);
      //  this.postImage =res;
        resolve({ default: res})      
       
      })
 
    });
  }
  calculateReadingTime(){  
    var totalWord = this.updateDescription.split(' ').length;     
    if(totalWord>200){
      this.readingTime = totalWord / 200;
      var readingTime = Math.round(this.readingTime);
    }else{
      this.readingTime =1
    }
     
  }  
  changeCompany(company){
    for(let i=0; i < this.companyList.length; i++){
      if(company == this.companyList[i].name){
        this.companyInfo = [this.companyList[i]];
        this.isCompanySelected = true;
        // localStorage.setItem("company", JSON.stringify([this.companyList[i]]));
        this.getAddresssBookGroupsByCompanyId();
        break;
      }
    }
  }

  createPoll(){
    console.log("Question: ---", this.pollQuestion);
    console.log("optionA: ---", this.optionLabelArray[0]);

    this.optionLabelArray.forEach((element) =>{
      if(element != null && element != undefined && !this.pollOptions.includes(element)){
        this.pollOptions.push(element);
      }
    })
    this.closebutton.nativeElement.click();
  }

  addOption(){
    if(this.options.length < 3){
      this.options.push(this.options[this.options.length - 1]+1);
    }
  }

  close(): void {
    document.getElementById("newSection").innerHTML = ""
  }
}