import * as CryptoJS from 'crypto-js';

import { Component, OnInit } from '@angular/core';

import { NetworkApiService } from 'src/app/components/network/network-api.service';
import { ProfileService } from './../../components/profile/profile.service';
import { Router } from '@angular/router';
import { Database, getDatabase, onValue, ref } from 'firebase/database';
import { environment } from 'src/environments/environment';
import { initializeApp } from 'firebase/app';
import { PresenceService } from 'src/app/components/messaging/presence.service';
import { HomeService } from 'src/app/components/home/service/home.service';
import { element } from 'protractor';

declare var window: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  keyWord: string;
  userList: any = [];
  isShow: boolean;
  userInfo: any;
  companyInfo: any;
  requestCount: any;
  perksLink = "http://bolstart-perks.s3-website.ap-south-1.amazonaws.com"
  db: Database;
  messageCnt: number = 0;
  app: any;
  presence$: any;
  notificationList: any = [];
  isStartUpFounder = false;
  isInvestor = false;
  notificationCnt: number = 0;
  constructor(private router: Router, private profileService: ProfileService, 
    private networkService: NetworkApiService, private presence: PresenceService,
    private homeService: HomeService) {
    this.app = initializeApp(environment.firebaseConfig);
      this.db = getDatabase(this.app);
  }

  ngOnInit(): void {
    this.profileService.event
      .subscribe(data => {
        let encrypt = localStorage.getItem('user');

        if(encrypt)
          this.userInfo = JSON.parse(encrypt);

          if(this.userInfo.roles.length > 0 && this.userInfo.roles[0].roleName == "Startup Founder"){
            this.isStartUpFounder = true
          }else if(this.userInfo.roles.length > 0 && this.userInfo.roles[0].roleName == "Investor"){
            this.isInvestor = true
          }
      });

    this.networkService.event.subscribe(data => {
      this.getRequestList()
    });
    // if(localStorage.getItem('member')=='' || localStorage.getItem('member')==null){
    //     this.logOut();
    // }

    this.presence$ = this.presence.getPresence(this.userInfo.uid);

    let companyList = JSON.parse(localStorage.getItem('company'));

    if(companyList != null && companyList != undefined && companyList.length > 0){
      this.companyInfo = companyList[0]
    }

    const chatsRef = ref(this.db, 'chats');
    onValue(chatsRef, (snapshot: any) => {
      const data = snapshot.val();

      this.messageCnt = 0;
      for(let id in data) {
        const obj = data[id];
        if(obj.receiverId == this.userInfo.id && obj.messageStatus == "New"){
          this.messageCnt = this.messageCnt + 1;
        }
      }
    });

    this.getAllNotification();
  }

  ngOnChanges() {
    this.presence$ = this.presence.getPresence(this.userInfo.uid);
  }

  getRequestList() {
    if(this.userInfo){
      this.networkService.getConnectionRequest(this.userInfo.id).subscribe(res => {
        if (res.data != null) {
          this.requestCount = res.data.length > 99 ? res.data.length + "+" : res.data.length
        } else {
          this.requestCount = 0
        }
      })
    }
  }

  logOut() {
    this.presence.signOut();
    localStorage.clear();
    sessionStorage.clear();
    // window.logOut();
    // setTimeout(() => {
    //   location.reload();
    // }, 500)
    this.router.navigate(['/account']);
  }

  GoToOwnerProfile() {
    let user = JSON.parse(localStorage.getItem('user'))
    this.router.navigate(['./profile/timeline'], { queryParams: { visit: user.id } });

  }
  goToPasswordChange() {
    // localStorage.clear();
    //sessionStorage.clear();
     //window.logOut();
    this.router.navigate(['/reset/forget/password'], { queryParams: { isPasswordChange: true } });

  }
  goToNewCompany() {
    this.router.navigate(['./company/createCompany']);
  }
  goToMyCompany() {
    this.router.navigate(['./company/companyinfo/publish'], { queryParams: { companyId: this.userInfo.company_id } });
  }
  getList() {
    if (this.keyWord != "") {
      let userId =JSON.parse(localStorage.getItem('user')).id;
      this.profileService.searchUser(this.keyWord, userId).subscribe(res => {
        if (res.success) {
          this.userList = res.data;
          this.isShow = true;
        }else {
          this.userList = [];
          this.isShow = false;
        }
      })
    } else {
      this.userList = [];
      this.isShow = false;
    }
  }
  selectUser(id) {
    localStorage.setItem("visit", id);
    this.router.navigate(['./profile/timeline'], { queryParams: { visit: id } });
    setTimeout(() => {
      this.isShow = false
      this.keyWord = null;
    }, 300)
  }

  goToCompany(id) {
    this.router.navigate(['../company/companyinfo/publish'], { queryParams: { companyId: id } });
    setTimeout(() => {
      this.isShow = false
      this.keyWord = null;
    }, 300)
  }
  focusOut() {
    setTimeout(() => {
      this.isShow = false
    }, 300)

  }
  goToInvestingPage() {
    let userId = parseInt(localStorage.getItem('member'))
    this.router.navigate(['./investor'], { queryParams: { visit: userId } });
  }
  goTo(url){
    this.router.navigate(url);
  }

  getAllNotification(){
    this.homeService.getAllNotification(this.userInfo.id, 0).subscribe(res => {
      if (res.success) {    
        Array.prototype.push.apply(this.notificationList, res.data.publishNotifications);    
        Array.prototype.push.apply(this.notificationList, res.data.postNotifications);    
        Array.prototype.push.apply(this.notificationList, res.data.networkNotifications);    
        Array.prototype.push.apply(this.notificationList, res.data.groupMemberNotifications);    

        this.notificationList.forEach((element) =>{
          if(element.status == "New"){
            this.notificationCnt = this.notificationCnt + 1;
          }
        })        
      }
    })  
  }

}
