<!-- <app-chart></app-chart> -->
<write-post (postPanelCompnent)=parentFunction() class="postBlockCenter" (getPostList)="getPostList(0)"></write-post>
<div class="postUpdateBtn container mt-0">   
   
<div class="row" *ngIf="!isStudent">
    <div class="col-md-6 pr-3">
        <button class="btn btn-primary btn-sm"
        data-toggle="modal" data-target="#myModal">Write an Update</button>         
    </div>
    <div class="col-md-6 pl-3">
        <button  data-toggle="modal" data-target="#postModal" class="btn updateBtn btn-sm inActive">Write a Post &nbsp;<img src="../../../../assets/icons/pencil-square.svg" /></button>
    </div>
</div>
</div>  


 

<div *ngIf="postPublisList.length > 0 " class="search-results" infinite-scroll
    [infiniteScrollDistance]="scrollDistance" (scrolled)="onScrollDown()" [scrollWindow]="false"
    [infiniteScrollThrottle]="throttle">
    <div  *ngFor="let listItem of postPublisList" [publish]="listItem" [userInfo]="userInfo">        
        <publish-container (communication)=communication($event)  (publishItemUpdate)=publishItemUpdate($event) *ngIf="listItem.group!=null"   [publish]="listItem" [userInfo]="userInfo"></publish-container>
        <post-container (postItemUpdate)=postItemUpdate($event) (communication)=communication($event) *ngIf="listItem.group==null"  [post]="listItem" [userInfo]="userInfo"></post-container>
       
    </div>
   
</div>
 
<!-- <div *ngIf="postList.length > 0 && selectedTab == 1" class="search-results" infinite-scroll
    [infiniteScrollDistance]="scrollDistance" (scrolled)="onScrollDown()" [scrollWindow]="false"
    [infiniteScrollThrottle]="throttle">
    <publish-container *ngFor="let item of publishList" [publish]="item" [userInfo]="userInfo"></publish-container>
    <post-container *ngFor="let item of postList " [post]="item" [userInfo]="userInfo"></post-container>
</div> -->
<!-- <div *ngIf="publishList.length > 0 && selectedTab == 2" class="search-results" infinite-scroll
    [infiniteScrollDistance]="scrollDistance" (scrolled)="onScrollDown()" [scrollWindow]="false"
    [infiniteScrollThrottle]="throttle">
    <publish-container *ngFor="let item of publishList" [publish]="item" [userInfo]="userInfo"></publish-container>

</div> -->
<div *ngIf="postList.length == 0 && selectedTab == 1" class="text-center">  
    <!-- <img class="notFound" src="assets/img/notfound.png"> -->
</div>
<div *ngIf="publishList.length == 0 && selectedTab == 2" class="text-center">
    <!-- <img class="notFound" src="assets/img/notfound.png"> -->
</div>