import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { NetworkApiService } from 'src/app/components/network/network-api.service';

@Component({
  selector: 'suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.scss']
})
export class SuggestionComponent implements OnInit {
  peopleList: any = [];
  constructor(private router: Router, private networkApiService: NetworkApiService) { }

  ngOnInit(): void {
    let userInfo = JSON.parse(localStorage.getItem('user'));
    this.getSuggestFriendList(userInfo.id)
  }

  getSuggestFriendList(userId) {
    this.networkApiService.getPeopleYouKnow(userId).subscribe(res => {
      if (res.success) {
        this.peopleList = res.data.content
      }
    })
  }

  selectUser(id) {
    localStorage.setItem("visit", id);
    this.router.navigate(['../profile/timeline'], { queryParams: { visit: id } });
  }

  getUserName(firstName, lastName){
    var userName = firstName + " " + lastName;
    return userName.length > 18 ? userName.substring(0, 18) + "..." : userName;
  }
}
