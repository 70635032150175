<div class="main"> 
    <p style="text-align: right;"><i class="fa fa-times" aria-hidden="true"></i></p>
  
<div class="content"> 
    <p class="text-p">You have availed these perks</p>
    <p>a. Corner Chat worth ₹ 201 </p>
    <p>b. Aws worth ₹700</p>
    <p>c. Razorpay worth ₹ 509</p>
    <p>d. Corner Chat worth ₹ 1000</p>
    <div class="buttons-dev">
        <button class="deactivateBtn">OK</button>
         <!-- <button class="cancel"> Cancel</button> -->
    </div>
</div>
</div>