<app-header></app-header>

<h6 class="tour">Get started with <span class="text-primary" data-target="#tourModel"
        data-toggle="modal">Bolstart</span></h6>
<div class="row">
    <div class="col-md-3 mb-2">
        <div class="card">
            <div class="card-header text-center" (click)="GoToOwnerProfile()">
                <img class="profile" [src]="userInfo?.profilePicture">
                <h6>{{userInfo?.firstName}} {{userInfo?.lastName}}</h6>
                <label>{{userInfo?.headline}}</label>
            </div>
            <hr>
            <div class="p-3" [routerLink]="['/profile/connections']" [queryParams]="{ visit: userInfo?.id}">
                <label class="li-label">My
                    Connection</label> &nbsp;<span class="count">{{userInfo?.connectionCount}}</span>
            </div>
        </div>
    </div>
    <div class="col-md-6 mb-2">
        <div class="job-wrap">
            <div class="job-inner">
                Job Post
            </div>
            <div *ngIf="jobList.length > 0" class="search-results" infinite-scroll
                [infiniteScrollDistance]="scrollDistance" (scrolled)="onScrollDown()" [scrollWindow]="false"
                [infiniteScrollThrottle]="throttle">

                <div class="card" *ngFor="let job of jobList">
                    <div class="row" (click)="uploadDocument(job)">
                        <div class="col-3 pr-0">
                            <img [src]="job.image">
                        </div>
                        <div class="col-9 pl-0">
                            <h6>{{job.jobTitle}}</h6>
                            <label class="sub-header">{{job.company}}</label><br />
                            <label>{{job.location}} <span>Status: {{job.status}}</span></label> <br />
                            <label class="sub-header">{{job.noApplicant}} Application</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-none d-md-block col-md-3 mb-2">
        <suggestion></suggestion>
        <advertisement [image]="add"></advertisement>
    </div>
</div>
<ng-template [ngIf]="getAllJobPost$ | async">
    <div>
        
    </div>
</ng-template>