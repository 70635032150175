import * as CryptoJS from 'crypto-js';

import { Component, OnInit } from '@angular/core';

import { CompanyApiService } from '../../company/company-api.service';
import { ProfileService } from './../../profile/profile.service';
import { Router } from '@angular/router';
import { PresenceService } from '../../messaging/presence.service';

@Component({
  selector: 'left-Panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss']
})
export class LeftPanelComponent implements OnInit {
  userInfo: any;
  companyDetail: any;
  isStudent: boolean;
  isInvestor:boolean;

  isUserCreatedCompany : boolean = false
  companyList: any;
  CheckUserRole: any;
  presence$: any;
  company: any;
  constructor(private profileService: ProfileService, private router: Router, 
    private companyApiService: CompanyApiService, private presence: PresenceService
  ) { }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('user'));
    this.getUserInfo();
    this.presence$ = this.presence.getPresence(this.userInfo.uid);

    this.companyList = JSON.parse(localStorage.getItem('companyList'));
    this.company = JSON.parse(localStorage.getItem('company'));
    if (this.company != null && this.company.length > 0) {
      // this.getCompanyDetail(this.companyList[0].id)
      this.companyDetail = this.company[0];
    }
    this.profileService.emitEvent()
    this.CheckCompanyInfo();
  }

  ngOnChanges() {
    this.presence$ = this.presence.getPresence(this.userInfo.uid);
  }

  getUserInfo() {
    this.profileService.getUserInfo(this.userInfo.id).subscribe(res => {
      if (res.success) {
        this.userInfo = res.data;
        this.userInfo.roles.forEach(element => {         
          if(element.roleName == 'Student'){
            this.isStudent = true;
          }
          if(element.roleName == 'Investor'){
            this.isInvestor = true;
          }
        });
      }
    })
  }

  GoToOwnerProfile() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.router.navigate(['./profile/timeline'], { queryParams: { visit: user.id, profile: 1 } });

  }

  GoToExplore() {
    
    this.CheckUserRole = this.userInfo
    for(let i=0; i<this.CheckUserRole.roles.length; i++){
      if(this.CheckUserRole.roles[i].roleType === "User" && this.CheckUserRole.roles[i].roleName === "Startup Founder"){
        this.router.navigate(['./explore/investor']);
        return
      }else{
        this.router.navigate(['./explore/startup']);
      }
    }
    
  }

  getCompanyDetail(companyId) {
    this.companyApiService.getCompanyDetail(companyId).subscribe(res => {
      if (res.success) {
        this.companyDetail = res.data;
      }
    })
  }


  CheckCompanyInfo(){
    if(this.companyList != null && this.companyList.length > 0){
      this.isUserCreatedCompany = true;
    }
    else{
      this.isUserCreatedCompany = false;
    }
  }


  goToCompany() {
    this.router.navigate(['./company/companyinfo/publish'], { queryParams: { companyId: this.companyDetail.id } });
  }
  goToAddressBook() {
    this.router.navigate(['./company/companyinfo/publish'], { queryParams: { companyId: this.companyDetail.id ,addressbook:1}  });
  }
  goToCreateCompany(){
    this.router.navigate(['./company/createCompany']);
  }

  goToNewCompany() {
    this.router.navigate(['./company/createCompany']);
  }

  changeCompany(company){
    this.companyDetail = company;
    localStorage.setItem("company", JSON.stringify([company]));
    this.goToCompany();
  }
}
