<app-header></app-header>
<section class="container" id="followers">
    <div class="row">
        <div class="col-md-9 mb-2 leftPannelFollw">
            <div class="card col-md-12">
                <div class="inline">
                    <div class="header">Followers and Following</div>
                </div>

                <div class="tabs w-100">
                    <a href="javascript:void(0)" [ngClass]="selectedType == 1 ? 'tab' : null"
                        (click)="selectType(1)">Followers</a>
                    <a href="javascript:void(0)" [ngClass]="selectedType == 2 ? 'tab' : null"
                        (click)="selectType(2)">Following</a>
                   
                </div>
                <div *ngIf="followerList.length > 0 && selectedType == 1" class="pt-3 pb-4">
                    <ul>
                        <li *ngFor="let item of followerList;let i = index" class="col-md-4">
                            <div class="inline w-100">
                                <img class="profile"
                                    [src]="item.user.profilePicture != null ? item.user.profilePicture:'assets/logo/profile_avater.png'">
                                &nbsp;
                                <div>
                                    <a [routerLink]="['/profile/timeline']" [queryParams]="{ visit: item.userId}">
                                        <h6>{{getName(item.user.firstName, item.user.lastName)}}</h6>
                                        <div class="followerPost" *ngIf="item.user.headline">{{item.user.headline.length > 18 ? item.user.headline.substring(0, 18) + "..." : item.user.headline}}</div>
                                    </a>
                                </div>
                                <div class="menu">
                                    <button class="btn btn-outline-primary btn-sm" *ngIf="!item.connected"
                                        (click)="sendFollowBack(item,i)">Follow Back</button>
                                </div>
                            </div>
                        </li>
                        
                    </ul>
                </div>
                <div *ngIf="followingList.length > 0 && selectedType == 2" class="pt-3 pb-4">
                    <ul>
                        <li *ngFor="let item of followingList;let i = index"  class="col-md-4">  
                            <div class="inline w-100" *ngIf="item.user.id != userInfo.id"> 
                                <img class="profile"
                                    [src]="item.user.profilePicture!= null ? item.user.profilePicture:'assets/logo/profile_avater.png'">
                                &nbsp;
                                <div>
                                    <a [routerLink]="['/profile/timeline']" [queryParams]="{ visit: item.userId}">
                                        <h6>{{getName(item.user.firstName, item.user.lastName)}}</h6>
                                        <div class="followerPost" *ngIf="item.user.headline">{{item.user.headline.length > 18 ? item.user.headline.substring(0, 18) + "..." : item.user.headline}}</div>
                                    </a>
                                </div>
                                <div class="menu">
                                    <button class="btn btn-outline-primary btn-sm" *ngIf="item.following"
                                        (click)="unfollowRequest(item,i)">Unfollow</button>
                                </div>
                            </div>
                            <div class="inline w-100" *ngIf="item.connectedUser.id != userInfo.id">  
                                <img class="profile"
                                    [src]="item.user.profilePicture != null ? item.user.profilePicture:'assets/logo/profile_avater.png'">
                                &nbsp;
                                <div>
                                    <a [routerLink]="['/profile/timeline']" [queryParams]="{ visit: item.userId}">
                                        <h6>{{getName(item.connectedUser.firstName, item.connectedUser.lastName)}}</h6>
                                        <div class="followerPost" *ngIf="item.connectedUser.headline">{{item.connectedUser.headline.length > 18 ? item.connectedUser.headline.substring(0, 18) + "..." : item.connectedUser.headline}}</div>

                                    </a>
                                </div>
                                <div class="menu">
                                    <button class="btn btn-outline-primary btn-sm" *ngIf="item.following"
                                        (click)="unfollowRequest(item,i)">Unfollow</button>
                                </div>
                            </div>
                        </li>
                       
                    </ul>
                </div>


            </div>
        </div>
        <div class="col-md-3 mb-2 rightPannelFollw">
            <manage-network [followersCount]="followerList.length"></manage-network>
            <suggestion></suggestion>
        </div>
    </div>
</section>