<div class="jobPostTitle">
    <p class="title-main">Perks <sup><i style="font-size: 14px;" class="fa fa-info-circle" aria-hidden="true"></i> </sup></p>
  
    <div class="main-seq-nav">
     <div class="select-seq">
         <span>No. of Perks</span>
         <span><b>500</b></span>
     </div>
     <div class="select-seq">
      <span>Perks availed</span>
      <span><b>₹500</b></span>
  </div>
  
  <div class="select-seq">
      <span>Perks available</span>
      <span><b>₹5000</b></span>
  </div>
  <div style="display: flex">
      <span class="btn-t">Contact Support</span>
    </div>
  </div>
  </div>
  <div class="main_page_holder">
    <div class="mainPage" *ngFor="let t of arr">
      <div class="active-d">
        <div class="parantOfActive">
          <span></span>
          <span class="title-active">Active Job Post</span>
          <span></span>
        </div>
      </div>
      <div class="paddtion-from-top">
        <div class="dFlex">
          <div class="img-title">
            <img
              src="assets/Screenshot 2022-03-15 204058.png"
              alt=""
              class="img-c"
            />
            <div class="text-seq">
              <div class="holder-seq">
                <div class="innterHolder-seq">
                  <span class="text-c"> Comet Chat </span>
                  <span class="text-c2"> Benefits Worth: <b>₹500</b> </span>
                </div>
                <div style="display: flex">
                  <span class="btn-t">Activate</span>
                </div>
              </div>
              <div class="dayCount">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                erat, sed diam voluptua.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  