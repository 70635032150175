// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //baseUrl: "http://ec2-13-233-64-33.ap-south-1.compute.amazonaws.com:8080/BolStart/api/",
  baseUrl: "https://api.bolstart.com/api/",
  // baseUrl: "http://localhost:9090/api/",
  jobPostBaseUrl:"https://api.bolstart.com/",
  // jobPostBaseUrl: "http://localhost:9090/",
  //baseUrl: "https://tablabs-dialindia.herokuapp.com/api/",
  appUrl: "https://bolstart.com/#/",
  defaultPic: "https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/profile_avater.png",
  defaultTimlineImage: "url(https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/timline.jpg)",
  firebaseConfig : {
    apiKey: "AIzaSyBTnLY0HlM4TCo2kyCMNhrlebYpzOkIPDw",
    authDomain: "bolstart.firebaseapp.com",
    databaseURL: "https://bolstart-default-rtdb.firebaseio.com",
    projectId: "bolstart",
    storageBucket: "bolstart.appspot.com",
    messagingSenderId: "905914777066",
    appId: "1:905914777066:web:cf9bf6cb6f9833470a4693",
    measurementId: "G-YLSX8FB42W"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impactp
 * on performance if an error is thrown.
 */
