<app-header></app-header>


<div class="box">
    <section class="container">
        <div id="chatPage">
        <div class="row">
            <div class="col-md-3 mb-2 leftPannel">
                <div id="chatLeftSide">
                    <div class="card">
                    <div class="card-headerLeft"> 
                        <div class="d-flex align-items-center w-100">
                           <div class="title"> Message </div>
                           <div class="rightIcon">
                                <div class="form-inline pl-2 searchBox">
                         
                                    <span class="searchIocn"><img src="../../../assets/icons/searchIcon.svg" /></span>
                                    <input style="padding: 0px 34px;" class="search" [(ngModel)]="searchKey" (keyup)="searchUser()"
                                        type="search" placeholder="" aria-label="Search"><br />
                                </div>
                                <!-- <img class="iconSpace" src="./assets/icons/chatThreeDot.svg"/>
                                <img class="iconSpace" src="./assets/icons/chatSearch.svg"/>
                                <img class="iconSpace" src="./assets/icons/chatAddNew.svg"/> -->
                           </div>
                        </div>
                    </div>
                    <div class="chatUserList">
                        <div class="whiteCardList" *ngFor="let item of userList"
                            [ngClass]="{
                            'borderActiveLeft grayCardList':  item.id  === selectedUser.id}">
                            <a class="textRight" (click)="changeSelectedUser(item)">
                                <!-- <img class="profile" 
                                [src]="item.profilePicture">&nbsp;  -->
                                <app-profile [user]="item"></app-profile>
                                <div class="w-100 spaceUserPhoto">
                                    <div class="dateUser">
                                        <!-- <div class="userName" [routerLink]="['/profile/timeline']" [queryParams]="{visit: item.id}">{{item.firstName}} {{item.lastName}}</div> -->
                                        <div class="userName">{{item.firstName}} {{item.lastName}}</div>
                                        <div class="dateTime" *ngIf="item.chats.length > 0">{{item.chats[item.chats.length-1].timestamp|date: 'dd/MM/yy '}}{{item.chats[item.chats.length-1].timestamp| date: 'shortTime'}}
                                        </div>
                                    </div>
                                    
                                    <!-- <div class="userPost" *ngIf="item.chats.length > 0 && item.chats[item.chats.length-1].senderId != userInfo.id"><span class="userDesc">{{item.firstName}}: </span>{{item.chats[item.chats.length-1].content}}</div> -->
                                    <!-- <div class="userPost" *ngIf="item.chats.length > 0 && item.chats[item.chats.length-1].senderId == userInfo.id"><span class="userDesc">You: </span>{{item.chats[item.chats.length-1].content}}</div> -->
                                    <div class="userPost" *ngIf="item.chats.length > 0 && item.chats[item.chats.length-1].senderId != userInfo.id"><span class="userDesc">{{item.firstName}}: </span>{{removeTags(item.chats[item.chats.length-1].content)}}<span class="badge" *ngIf="item.unreadMessageCnt > 0">{{item.unreadMessageCnt}}</span>
                                    </div>
                                    <div class="userPost" *ngIf="item.chats.length > 0 && item.chats[item.chats.length-1].senderId == userInfo.id"><span class="userDesc">You: </span>{{removeTags(item.chats[item.chats.length-1].content)}}<span class="badge" *ngIf="item.unreadMessageCnt > 0">{{item.unreadMessageCnt}}</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <!-- <div class="chatUserList">
                        <div class="whiteCardList">
                            <a class="textRight">
                                <img class="profile imgBordergray" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/User - default.png">&nbsp; 
                                <div class="w-100 spaceUserPhoto">
                                    <div class="dateUser">
                                        <div class="userName">Asmita Jaiswal</div>
                                        <div class="dateTime">Tue 28&nbsp; 4.30 pm</div>
                                    </div>
                                    
                                    <div class="userPost"><span class="userDesc">Asmita Jain:</span> dolor sit amet, consetetur sadipscing elitr, sed</div>
                                </div>
                            </a>
                        </div>
                        <div class="grayCardList borderActiveLeft">
                            <a class="textRight">
                                <img class="profile imgBorderGreen" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/User - default.png">
                                <div class="w-100 spaceUserPhoto">
                                    <div class="dateUser">
                                        <div class="userName">Rushabh Shaha</div>
                                        <div class="dateTime">Tue 28&nbsp; 4.30 pm</div>
                                    </div>
                                    <div class="userPost"><span class="userDesc">You:</span> dolor sit amet, consetetur sadipscing elitr, sed</div>
                                </div>
                           </a>
                        </div>
                        <div class="whiteCardList">
                            <a class="textRight">
                                <img class="profile imgBordergray" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/User - default.png">
                                <div class="w-100 spaceUserPhoto">
                                    <div class="dateUser">
                                        <div class="userName">Akshit Singh</div>
                                        <div class="dateTime">Tue 28&nbsp; 4.30 pm</div>
                                    </div>                                    
                                    <div class="userPost"><span class="userDesc">Asmita Jain:</span> dolor sit amet, consetetur sadipscing elitr, sed</div>
                                </div>
                            </a>
                        </div>
                        <div class="whiteCardList">
                            <a class="textRight">
                                <img class="profile imgBordergray" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/User - default.png">
                                <div class="w-100 spaceUserPhoto">
                                    <div class="dateUser">
                                        <div class="userName">Akshit Singh</div>
                                        <div class="dateTime">Tue 28&nbsp; 4.30 pm</div>
                                    </div>                                    
                                    <div class="userPost"><span class="userDesc">Asmita Jain:</span> dolor sit amet, consetetur sadipscing elitr, sed</div>
                                </div>
                            </a>
                        </div>
                        <div class="whiteCardList">
                            <a class="textRight">
                                <img class="profile imgBordergray" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/User - default.png">
                                <div class="w-100 spaceUserPhoto">
                                    <div class="dateUser">
                                        <div class="userName">Akshit Singh</div>
                                        <div class="dateTime">Tue 28&nbsp; 4.30 pm</div>
                                    </div>                                    
                                    <div class="userPost"><span class="userDesc">Asmita Jain:</span> dolor sit amet, consetetur sadipscing elitr, sed</div>
                                </div>
                            </a>
                        </div>
                        
                    </div> -->
                    </div>
                </div>
            </div>
            <div class="col-md-7 mb-2 centerPannel">  
                <div id="chatLeftSide">   
                    <div class="card">
                    <div class="card-header"> 
                        <div class="d-flex align-items-center w-100">
                            <div class="d-block w-100" *ngIf="selectedUser">
                                <div class="Activetitle" [routerLink]="['/profile/timeline']" [queryParams]="{visit: selectedUser.id}">{{selectedUser.firstName}} {{selectedUser.lastName}}</div>
                                <app-user-status [uid]="selectedUser.uid"></app-user-status>
                                <!-- <div class="activeStats"><div class="blueDot"></div> Active now</div> -->
                            </div>
                           
                           <div class="rightIcon" style="width: 12%;">
                                <img class="iconSpace" src="./assets/icons/chatThreeDot.svg"/>
                               
                           </div>
                        </div>
                    </div>
                    <a class="userProfileBox">
                        <img class="profile mb-3" *ngIf="presence$ | async as presence" [routerLink]="['/profile/timeline']" [queryParams]="{visit: selectedUser.id}"
                        [ngClass]="{
                            'imgBorderGreen':  presence.status  === 'online',
                            'imgBordergray': presence.status  === 'away' || presence.status  === 'offline'
                        }"
                        [src]="selectedUser.profilePicture">&nbsp; 
                        <div class="w-100 spaceUserPhoto">
                            <div class="dateUser mb-1" *ngIf="selectedUser">
                                <div class="userName" [routerLink]="['/profile/timeline']" [queryParams]="{visit: selectedUser.id}">{{selectedUser.firstName}} {{selectedUser.lastName}}</div>

                            </div>
                            
                            <!-- <div class="userPost">User Experience Designer - Infosys</div> -->
                            <div class="userPost" *ngIf="selectedUser">{{selectedUser.headline}}</div>
                        </div>
                    </a>
                    <div class="chatUserList chatFixScroll" #scrollMe [scrollTop]="scrollMe.scrollHeight">
                        <div class="whiteCardList border-0" *ngFor="let chat of chats">
                            <a class="textRightChat">
                                <img class="profile chatUserImg" *ngIf="chat.senderId == userInfo.id" [src]="userInfo.profilePicture">&nbsp; 
                                <img class="profile chatUserImg" *ngIf=" selectedUser && (chat.senderId != userInfo.id)" [src]="selectedUser.profilePicture">&nbsp; 
                                <div class="w-100 spaceUserPhoto">
                                    <div class="dateUser">
                                        <div class="userName" *ngIf="chat.senderId == userInfo.id">You</div>
                                        <div class="userName" *ngIf=" selectedUser && (chat.senderId != userInfo.id)">{{selectedUser.firstName}}</div>
                                        <div class="dateTime" *ngIf="compareDate(chat.timestamp) == true">{{chat.timestamp | date:"dd/MM/yy"}}   <img class="iconSpace" src="./assets/icons/three-dots-black.svg"/></div>
                                        <div class="dateTime" *ngIf="compareDate(chat.timestamp) == false">{{chat.timestamp | date:'shortTime'}}   <img class="iconSpace" src="./assets/icons/three-dots-black.svg"/></div>
                                    </div>
                                    
                                    <!-- <div class="userPost">{{chat.content}}</div> -->
                                    <div class="userPost" [innerHTML]="chat.content"></div>
                                    <!-- <div *ngIf='chat.filePath != "null"'><a [href]="chat.filePath" download>{{chat.fileName}}</a></div> -->
                                    <div *ngIf='chat.filePath != "null"'><span (click)="downloadFile(chat)">{{chat.fileName}} <i class="fa fa-download"></i></span></div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <!-- <div class="chatUserList">
                        <div class="whiteCardList border-0">
                            <a class="textRightChat">
                                <img class="profile chatUserImg" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/User - default.png">&nbsp; 
                                <div class="w-100 spaceUserPhoto">
                                    <div class="dateUser">
                                        <div class="userName">Ashlesha</div>
                                        <div class="dateTime">2 min ago   <img class="iconSpace" src="./assets/icons/three-dots-black.svg"/></div>
                                    </div>
                                    
                                    <div class="userPost">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos </div>
                                </div>
                            </a>
                        </div>
                        <div class="whiteCardList border-0">
                            <a class="textRightChat">
                                <img class="profile chatUserImg" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/User - default.png">
                                <div class="w-100 spaceUserPhoto">
                                    <div class="dateUser">
                                        <div class="userName">You</div>
                                        <div class="dateTime">2 min ago   <img class="iconSpace" src="./assets/icons/three-dots-black.svg"/></div>
                                    </div>                                    
                                    <div class="userPost">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos </div>
                                </div>
                            </a>
                        </div>
                        <div class="whiteCardList border-0">
                            <a class="textRightChat">
                                <img class="profile chatUserImg" src="https://bolstartimages.s3.ap-south-1.amazonaws.com/profile/default/User - default.png">
                                <div class="w-100 spaceUserPhoto">
                                    <div class="dateUser">
                                        <div class="userName">You</div>
                                        <div class="dateTime">2 min ago   <img class="iconSpace" src="./assets/icons/three-dots-black.svg"/></div>
                                    </div>
                                    <div class="userPost">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos </div>
                                </div>
                                
                           </a>
                           <a class="textRightChat">
                           <div class="blankDiv">&nbsp;</div>
                            <div class="w-100 spaceUserPhoto">
                                <div class="dateUser">
                                    <div class="userName">You</div>
                                    <div class="dateTime">2 min ago   <img class="iconSpace" src="./assets/icons/three-dots-black.svg"/></div>
                                </div>
                                <div class="userPost">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut  </div>
                            </div>
                            
                       </a>

                        </div>
                    
                    </div> -->
                    <div class="w-100">
                        <!-- <textarea [(ngModel)]="content" name="" class="form-control type_msg" placeholder="Write here..."></textarea> -->
                        <div class="file" *ngIf="selectedFile">{{selectedFile.name}}<span class="remove" (click)="removeFile()"><img src="assets/icons/times-circle.svg" alt="" class="img-css" style="width: 18px;"></span></div>
                        <div [innerHTML]="content"
                            placeholder="Write here.."
                            class="editor" (keypress)="gotoNextLine($event)"
                            id="newSection" #mydiv contenteditable="true">
                        </div>  
                    </div>
                    <div class="card-footer">
                        <div class="input-group mb-0">
                            <!-- <div class="input-group-append">
                                <span class="input-group-text attach_btn"><img src="./assets/icons/fileAttach.svg" /></span>
                            </div> -->
                            
                            <div class="input-group-append">
                                <span class="input-group-text send_btn" (click)="toggleEmoji()"><img src="./assets/icons/smile.svg" /></span>
                                <emoji-mart [style]="{position:'absolute',bottom:'1rem'}" (emojiSelect)="addEmoji($event)"
                                    *ngIf="emojiToggled" set="google" sheetSize="32">
                                </emoji-mart>
                            </div>
                            <div class="input-group-append">
                                <span class="input-group-text send_btn">
                                    <input type="file" id="postFile"  hidden  #uploader (change)="onFileSelect($event)" />
                                    <img src="./assets/icons/attachment.svg" (click)="uploader.click()" /></span>
                            </div>
                            <!-- <div class="input-group-append">
                                <span class="input-group-text send_btn"><img src="./assets/icons/photo.svg" /></span>
                            </div>
                            <div class="input-group-append">
                                <span class="input-group-text send_btn"><img src="./assets/icons/speaker.svg" /></span>
                            </div> -->
                        </div>
                        <div style="cursor: pointer" class="sendBtn" (click)="onChatSubmit()">Send <svg class="sendIocn"> <path class="ml-2 icon" d="M3.741,1.408,22.2,11.562a.5.5,0,0,1,0,.876L3.741,22.592A.5.5,0,0,1,3,22.154V1.846a.5.5,0,0,1,.741-.438ZM5,13v6.617L18.85,12,5,4.383V11h5v2Z" /></svg></div>
                    </div> 
                    
                    <!-- <form id="message" [formGroup]="form" (ngSubmit)="onChatSubmit(form.value)">
                        <mat-form-field appearance="outline" class="fw">
                          <mat-label>Username</mat-label>
                          <input matInput
                                          formControlName="username" >
                        </mat-form-field> 
                          <mat-form-field appearance="outline" class="fw">
                          <mat-label>Enter Chat</mat-label>
                            <input matInput
                                            formControlName="content" >
                                            <button type="submit" matSuffix mat-icon-button aria-label="Submit">
                                              <mat-icon>send</mat-icon>
                                            </button>
                          </mat-form-field>
                      </form> -->
                    </div>
                </div>
            </div>
            <div class="d-none d-md-block col-md-2 mb-2 rightPannel">
                <advertisement [image]="add"></advertisement>
               <!-- <div class="whiteBox d-flex justify-content-center align-items-center">
                Add Space
                </div> -->
                <!-- <app-activestartups></app-activestartups>
               -->
                <!-- <suggestion></suggestion> -->
                <!-- <advertisement [image]="add"></advertisement>
                <app-address-book-home></app-address-book-home>
                <app-news></app-news> -->
            </div>
        </div>
        </div>
    </section>
    

</div>