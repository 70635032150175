<app-header></app-header>

<div class="box">
    <section class="container">
        <div class="row">
            <div class="col-md-9 mb-2 leftGridContainerProfile">
                <invitation></invitation>
                <manage-network class="d-md-none d-sm-block d-md-none" [followersCount]="followrCount"> </manage-network>
                <know-people></know-people>
            </div>
            <div class="d-none d-md-block col-md-3 mb-2 RightGridContainerProfile">
                <manage-network [followersCount]="followrCount" [networkCount]="networkCount"> </manage-network>
                <suggestion></suggestion>
                <advertisement [image]="add"></advertisement>
                <app-news></app-news>
            </div>
        </div>
    </section>
</div>
