import * as bootstrap from "bootstrap";
import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
import { data, trim } from 'jquery';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AccountApiService } from './../../account/account-api.service';
import { ActivatedRoute } from '@angular/router';
import { AwsService } from 'src/app/shared/AWS-Service/aws.service';
import { CroppedEvent } from 'ngx-photo-editor';
import { ProfileService } from '../profile.service';
import { ToastrService } from 'ngx-toastr';
import { common } from 'src/app/shared/common/validator';
  
import { environment } from 'src/environments/environment';
import { PresenceService } from "../../messaging/presence.service";
import { NetworkApiService } from "../../network/network-api.service";

 
declare var $: any;



@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  @ViewChild("imagePicker", { static: false }) imagePicker: ElementRef;
  @ViewChild("VerifyModel", { static: false }) VerifyModel: ElementRef;

  public informationForm: UntypedFormGroup;
  userDetail: any;
  type: number;
  role: any;
  monthList: any = common.month;
  dayList: any = common.day;
  stateList: any = [];
  industryList: any = common.industries;
  countryList: any = common.country
  yeaList: any = common.years();
  isLoading: boolean = false;
  cityList: any = [];
  timelineImageChangedEvent: any;
  profileImageChangedEvent
  imageEditorType: number;
  requestType:String = "NotSend";
  userInfo = JSON.parse(localStorage.getItem('user'));
  presence$: any;


  constructor(
    private fb: UntypedFormBuilder,
    private networkService: NetworkApiService,
    private routes: ActivatedRoute,
    private toastr: ToastrService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private awsService: AwsService,
    private accountService: AccountApiService,
    private presence: PresenceService
  ) { }

  ngOnInit(): void {
    this.initializedInfoForm();
    this.getInfo();    
  }

  ngOnChanges() {
    this.presence$ = this.presence.getPresence(this.userDetail.uid);
  }
  openModal(){   
    this.routes.queryParams.subscribe(param => {
      if(param.profile){
        $("#UpdateModel").modal('show');      
      }
    });   
  }

  ngAfterContentInit() {     
    this.openModal();
  }   

  openVerifyAccount() {
    if (this.userDetail.isOwener) {
      let obj = {
        id: this.userDetail.id,
        isMobileVerified: "false"
      }
      this.profileService.updateProfileInfo(obj).subscribe(res => {
        if (res.success) {
          this.userDetail.isMobileVerified = "false";
          let encrypt = CryptoJS.AES.encrypt(JSON.stringify(res.data), "Bollstart").toString();
          localStorage.setItem('Bolstart', encrypt);
        }
      })
    };
  }

  getInfo() {
    this.route.queryParams.subscribe(param => {      

      this.profileService.getUserInfo(param.visit).subscribe(res => {
        if (res.success) {         
          this.userDetail = res.data; 
          if (this.userDetail.id == this.userInfo.id) {
            this.userDetail['isOwener'] = true;
          } else {
            this.userDetail['isOwener'] = false;
          }
          this.userDetail.connection = null;   
          this.presence$ = this.presence.getPresence(this.userDetail.uid);
          this.setInfoForm();
          this.isConnectionExist();   
          this.allConnectionsByUserId()
        }
      })
    })
  }

  selectRoletype(roleType) {
    switch (roleType) {
      case 1:
        this.role = "Startup";
        break;
      case 2:
        this.role = "Investor";
        break;
      case 3:
        this.role = "Mentor";
        break;
      case 4:
        this.role = "Freelancer";
        break;
      case 5:
        this.role = "Startup Enthusiast";
        break;
    }
  }
  initializedInfoForm() {
    this.informationForm = this.fb.group({
      id: ['', [Validators.required]],
      firstName: ['', [Validators.required, Validators.pattern(common.onlyAlphabetRegex)]],
      middleNmae: ['',  ],
      birthDate: ['', [Validators.required]],
      lastName: ['', [Validators.required, Validators.pattern(common.onlyAlphabetRegex)]],
      headline: ['', [Validators.required]],
      industry: ['',  ],
      // adhar: [''],
      // gst: ['', this.type == 1 || this.type == 2 ? [Validators.pattern(common.gstRegex)] : [Validators.pattern(common.gstRegex)]],
      state: [''],
      location: [''],
      month: [''],
      bio: [''],
      country: ['',[Validators.required]],
    })
  }

  setInfoForm() {         
    this.informationForm.patchValue({
      id: this.userDetail.id,
      firstName: this.userDetail.firstName,
      birthDate:moment(this.userDetail.birthDate).format('YYYY-MM-DD'),//this.userDetail.birthDate,
      lastName: this.userDetail.lastName,
      bio:this.userDetail.bio,
      headline: this.userDetail.headline,
      industry: this.userDetail.industryName,
      state: this.userDetail.state,
      location: this.userDetail.location,
      month: this.userDetail.month,
      day: this.userDetail.day,
      country: this.userDetail.country != null ? this.userDetail.country.id : null
    })
    this.selectCountry();
  }

  updateProfile() {
    this.informationForm.markAllAsTouched();
    if (this.informationForm.valid && this.informationForm.get('birthDate').value != 'Invalid date') {
     
     // this.userDetail.adhaarCard= this.informationForm.value.adhar;
      this.userDetail.country={"id":this.informationForm.value.country} ;
      this.userDetail.bio= this.informationForm.value.bio;
      this.userDetail.firstName= this.informationForm.value.firstName;
      //this.userDetail.gst= this.informationForm.value.gst;
      this.userDetail.headline= this.informationForm.value.headline;
      //this.userDetail.id= this.informationForm.value.id;
      this.userDetail.lastName= this.informationForm.value.lastName;
      this.userDetail.birthDate=this.informationForm.value.birthDate;
      //this.userDetail.industryName= this.informationForm.value.industry;
      this.userDetail.location= this.informationForm.value.location;
     // this.userDetail.month= this.informationForm.value.month;
      this.userDetail.state= this.informationForm.value.state;
     // this.userDetail.connectionCount= this.userDetail.connectionCount
   
      this.profileService.updateProfileInfo(this.userDetail).subscribe(res => {
        if (res.success) {
          if (res.success) {
            // this.toastr.success(res.message);
            this.userDetail = res.data
            this.userDetail['isOwener'] = true;
            $("#UpdateModel .close").click();
            $("#UpdateModel").removeClass('show')
            this.profileService.isUserInfoUpdated.next(true);
          } else {
            // this.toastr.error(res.message);
          }
        }
      })
    }
  }

  /* ================================  Send Request ======================== */
 
  sendRequest(requestId) {
    console.log(requestId)
    //let userId = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('Bolstart'), "Bollstart").toString(CryptoJS.enc.Utf8)).id;
    let obj = {
      connectedUser: this.userDetail,
      connected: false,
      following:true,
      followedBack:false,
      user: this.userInfo,
    }
    this.isLoading = true;
    this.profileService.sendRequest(obj).subscribe(res => {
      this.isLoading = false;
      if (res.success) {
        this.requestType ="send";
        this.userDetail.connection = res.data
        console.log(this.userDetail.connection)
      }
    })
  }
  confirmRequest(){    
   let obj= this.userDetail.connection
   obj.connected =1 
   console.log(obj)
    
    this.isLoading = true;
    this.profileService.confirmRequest(obj).subscribe(res => {
      this.isLoading = false;
      if (res.success) {
        this.requestType ="connected";
        this.userDetail.connection = res.data
        console.log(this.userDetail.connection)
      }
    })
  }
  deleteConnection() {
    this.profileService.deleteConnection(this.userDetail.connection.id).subscribe(res => {
      if (res.success) {
        this.requestType ="NotSend";   
        this.userDetail.connection = null     
      }        
      })
  }
  isConnectionExist(){    
    this.profileService.isConnectionExist(this.userInfo.id,this.userDetail.id).subscribe(res => {
      this.isLoading = false;
      if (res.success) {
        this.userDetail.connection = res.data;
        if(res.data.connected){
          this.requestType ="connected";
        }else if(res.data.user.id == this.userInfo.id){
          this.requestType ="send";
        }else if(res.data.user.id == this.userDetail.id){
          this.requestType ="confirm";
        }        
        console.log(this.userDetail.connection)
      }else{
        this.requestType ="NotSend";
      }
    })
  }
  allConnectionsByUserId(){
    this.profileService.getMyNetworkWithOutPagination(this.userDetail.id).subscribe(res => {
      if (res.success) {
        this.userDetail.allConnection = res.data;   
      }        
      })

  }


  // cancelRequest(requestId) {
          
  //   let obj = {
  //     connectedUserId: requestId,
  //     isConnected: null,
  //     userId: this.userInfo.id,
  //   }
  //   this.profileService.sendRequest(obj).subscribe(res => {
  //     if (res.success) {
  //       this.userDetail.isConnected = res.data.isConnected
  //     }
  //   })
  // }
  // deleteConnection(requestId) {
  //   let userId = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('Bolstart'), "Bollstart").toString(CryptoJS.enc.Utf8)).id;
  //   this.profileService.deleteConnection(userId, requestId).subscribe(res => {
  //     this.userDetail.isConnected = null
  //   })

  // }
  /* ================================ Follow Request ========================== */

  followRequest(requestId) {
    let userId = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('Bolstart'), "Bollstart").toString(CryptoJS.enc.Utf8)).id;

    let obj = {
      connectedUserId: requestId,
      isFollowed: 'true',
      userId: userId
    }
    this.profileService.followRequest(obj).subscribe(res => {
      if (res.success) {
        this.userDetail.isFollowed = 'true';
      }
    })
  }

  unfollowRequest(requestId) {
    let userId = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('Bolstart'), "Bollstart").toString(CryptoJS.enc.Utf8)).id;
    let obj = {
      connectedUserId: requestId,
      isFollowed: 'false',
      userId: userId
    }
    this.profileService.followRequest(obj).subscribe(res => {
      if (res.success) {
        this.userDetail.isFollowed = 'false';
      }
    })
  }


  /* ==================== Profile Image ================== */


  getImage(type) {
    try {
      this.imageEditorType = type;
      //$("#VerifyModel .open").click();
      this.imagePicker.nativeElement.click();
    } catch (error) {
    }
  }
  onEditImage() {
    this.imagePicker.nativeElement.click();
  }
  onRemoveImage() {
    if (this.imageEditorType == 1) {
      let obj = {
        id: this.userDetail.id,
        profilePicture: environment.defaultPic
      }
      this.profileService.updateProfileInfo(obj).subscribe(res => {
        this.userDetail.profilePicture = res.data.profilePicture;
        let encrypt = CryptoJS.AES.encrypt(JSON.stringify(res.data), "Bollstart").toString();
        localStorage.setItem('Bolstart', encrypt);
        this.profileService.emitEvent();
        $("#VerifyModel .close").click();
      })
    } else {
      let obj = {
        id: this.userDetail.id,
        timlineImage: environment.defaultTimlineImage
      }
      this.profileService.updateProfileInfo(obj).subscribe(res => {
        this.userDetail.timlineImage = res.data.timlineImage;
        $("#VerifyModel .close").click();
      })
    }
  }

  fileChangeEvent(event: any) {
    if (this.imageEditorType == 1) {
      this.profileImageChangedEvent = event;
    } else {
      this.timelineImageChangedEvent = event
    }
  }

  imageCropped(event: CroppedEvent) {
    // this.img = `url(${event.base64})`
    this.awsService.uploadFile(event.file, "profile").then(res => {
      if (this.imageEditorType == 1) {
        // let obj = {
        //   id: this.userDetail.id,
        //   profilePicture: res
        // }
        this.userDetail.profilePicture = res;

        this.profileService.updateProfileInfo(this.userDetail).subscribe(res => {
          this.userDetail.profilePicture = res.data.profilePicture;
          let encrypt = CryptoJS.AES.encrypt(JSON.stringify(res.data), "Bollstart").toString();
          localStorage.setItem('Bolstart', encrypt);
          localStorage.setItem('user', JSON.stringify(res.data));
          this.profileService.emitEvent();
          $("#VerifyModel .close").click();
        })
      } else {
        // let obj = {
        //   id: this.userDetail.id,
        //   timlineImage: `url(${res})`
        // }
        this.userDetail.timelineImage = res;

        this.profileService.updateProfileInfo(this.userDetail).subscribe(res => {
          this.userDetail.timelineImage = res.data.timelineImage;
          localStorage.setItem('user', JSON.stringify(res.data));
          $("#VerifyModel .close").click();
        })
      }
    })
  }

  /* =-============== */

  /* ===================== country select  =========================== */
  selectCountry() {
    let country = this.informationForm.value.country;
    console.log(country)
    if(country != null && country != undefined){
      this.getStateList(country)
    }
    // this.countryList.map(res => {
    //   if (res.name == country) {
    //     this.getStateList(res.id)
    //   }
    // })
  }

  getStateList(countryID) {
    this.accountService.getStateListBasedOnCountry(countryID).subscribe(res => {
      if (res.success) {
        this.stateList = res.data;
        this.selectState();
      }else{
        this.stateList =[] 
        this.cityList=[]
      }
    })
  }

  selectState() {
    let state = this.informationForm.value.state;
    this.stateList.map(res => {
      if (res.name == state) {
        this.getCityList(res.id);
      }
    })
  }
  actionAllow(action){
    if(this.userDetail == null || this.userDetail.id == this.userInfo.id){
      return false;
    }

    if(action == "follow"){          
      if(this.userDetail.connection != null && ((  this.userInfo.id == this.userDetail.connection.user.id  &&this.userDetail.connection.following)||
      (this.userInfo.id == this.userDetail.connection.connectedUser.id  &&this.userDetail.connection.followedBack) ) ){
        return false
      }else{
        return true;
      }      
    }else  if(action == "unfollow"){
      console.log("cc=",this.userDetail.connection)  
      if(  this.userDetail.connection == null || (this.userDetail.connection != null && this.userInfo.id == this.userDetail.connection.user.id  && !this.userDetail.connection.following)||
      (this.userDetail.connection != null && this.userInfo.id == this.userDetail.connection.connectedUser.id  && !this.userDetail.connection.followedBack)  ){
        return false
      }else{
        return true;
      }   
     
    }

  }

  getCityList(stateID) {
    this.accountService.getCityListBasedOnState(stateID).subscribe(res => {
      if (res.success) {
        this.cityList = res.data;
      }else{
        this.cityList = [];
      }
    })
  }

  close(){
    $("#UpdateModel").removeClass('show');
  }

  sendFollowUnfollowRquest(action) {
    let obj = {     
      connectedUser: this.userDetail,    
      following: true,
      user: this.userInfo,
      }
    if(action == 'unfollow')    
    {
      obj.following = false;
    } 
    this.networkService.followRequest(obj).subscribe(res => {
      if (res.success) {
       // this.suggestList[index].isFollowed = 'true';
       this.userDetail.connection =res.data;
      }
    })
  }
}
