import * as CryptoJS from 'crypto-js';

import { Component, OnInit } from '@angular/core';

import { NetworkApiService } from './../network-api.service';
import { Router } from '@angular/router';
import { ProfileService } from '../../profile/profile.service';  
@Component({
  selector: 'invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {
  requestList: any = [];
  userId: number;
  requsterRows: number=1;
  userInfo = JSON.parse(localStorage.getItem('user'));

  constructor(private networkService: NetworkApiService,private profileService: ProfileService, private router: Router) {
  }

  ngOnInit(): void {
    this.getRequestList();

  }

  getRequestList() {     
    this.userId =  this.userInfo.id;
    this.networkService.getConnectionRequest(this.userId).subscribe(res => {
      if (res.success) {
        console.log(res.data)
        this.requestList = [];
        //this.requestList = res.data.slice(0, 5)
        this.requestList = res.data 
        this.requsterRows = (this.requestList.length % 4 == 0)?this.requestList.length/4:(this.requestList.length+(4-this.requestList.length%4))/4;
 
      }
    })
  }
  rejectRequest(item, index) {          
       item.seen =true;
      
    this.profileService.confirmRequest(item).subscribe(res => {
      if (res.success) {   
        item.connected =1 
        delete this.requestList[index]
        this.requestList= this.requestList.filter(Boolean);  
       }else{
        item.seen =false;
       }       
      })
      // this.profileService.deleteConnection(item.id).subscribe(res => {
      //   if (res.success) {   
      //     item.connected =1 
      //     delete this.requestList[index]
      //    this.requestList= this.requestList.filter(Boolean);
    
         
      //    }       
      //   })
    // this.networkService.sendRequest(obj).subscribe(res => {
    //   if (res.success) {
    //     this.requestList[index].isConnected = null;
    //     this.requestList.splice(index, 1);
    //     this.networkService.updateCountEvent();
    //   }
    // })
  }

  acceptRequest(item, index) {  
    item.connected =1 
    item.followedBack = 1;
    item.seen =true;
    this.profileService.confirmRequest(item).subscribe(res => {      
      if (res.success) {   
        delete this.requestList[index]
        this.requestList= this.requestList.filter(Boolean);
      
      }else{
        item.connected =1 
        item.followedBack = 0;
        item.seen =false;
      }
    })

    // let obj = {
    //   connectedUserId: item.userId,
    //   id: item.id,
    //   isConnected: 'true',
    //   userId: item.connectedUser.userId,
    //   isFollowed: 'false'
    // }
    // this.networkService.acceptRequest(obj).subscribe(res => {
    //   if (res.success) {
    //     this.requestList[index].isConnected = 'true';
    //     this.requestList.splice(index, 1);
    //     this.networkService.updateCountEvent();
    //   }
    // })
  }

  /* ================== Get Follower list ============== */

  selectUser(id) {
    localStorage.setItem("visit", id);
    this.router.navigate(['../profile/timeline'], { queryParams: { visit: id } });
  }
  substr(string,length){
    if(string != null && string != undefined){
      if(string.length<=length){
        return string;
      }   
      return string.substr(0,length)+'...';
    }
  }

}
