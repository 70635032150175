import { BehaviorSubject, Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  baseUrl = environment.baseUrl;
  event: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public isUserInfoUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }
  emitEvent() {
    this.event.next(null);
  }
  getHttpOptions(){
    return{
      headers: new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('content-type', 'application/json')
      .set("Authorization", "Bearer " + this.getToken())
    }
  }
  
  setToken(token) {
    localStorage.removeItem('authorization');
    localStorage.setItem('authorization', JSON.stringify(token));
  }

  getToken(): string {
    return JSON.parse(localStorage.getItem('authorization'));
  }
  
  getUserData(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `user/me`, this.getHttpOptions());
  }

  getUserInfo(userId): Observable<any> {
    return this.http.get<any>(this.baseUrl + `user/`+ userId, this.getHttpOptions());
  }
  getEducation(userId): Observable<any> {
    return this.http.get<any>(this.baseUrl + `education/getEducationByUserId/`+ userId, this.getHttpOptions());
  }
  getAccomplishment(userId): Observable<any> {
    return this.http.get<any>(this.baseUrl + `accomplishment/userId/`+ userId, this.getHttpOptions());
  }
  getSkills(userId): Observable<any> {
    return this.http.get<any>(this.baseUrl + `skills/userId/`+ userId, this.getHttpOptions());
  }

  getExperiance(userId): Observable<any> {
    return this.http.get<any>(this.baseUrl + `experience/getExperienceByUserId/`+ userId, this.getHttpOptions());
  }


  
  updateProfileInfo(obj): Observable<any> {
    return this.http.put(this.baseUrl + 'user', obj, this.getHttpOptions());
  }
  updateEducation(obj): Observable<any> {
    return this.http.post(this.baseUrl + 'education', obj, this.getHttpOptions());
  }
  updateExperiance(obj): Observable<any> {
    return this.http.post(this.baseUrl + 'experience', obj, this.getHttpOptions());
  }
  updateAccomplishments(obj): Observable<any> {
    return this.http.post(this.baseUrl + 'accomplishment', obj, this.getHttpOptions());
  }
  addSkill(obj): Observable<any> {
    return this.http.post(this.baseUrl + '/skills', obj, this.getHttpOptions());
  }
  updateSkill(obj): Observable<any> {
    return this.http.put(this.baseUrl + '/skills', obj, this.getHttpOptions());
  }
  /* Delete Controllers */
  deleteEducation(id): Observable<any> {
    return this.http.delete(this.baseUrl + `education/${id}`, this.getHttpOptions());
  }
  deleteExperiance(id): Observable<any> {
    return this.http.delete(this.baseUrl + `experience/${id}`, this.getHttpOptions());
  }
  deleteSkill(id): Observable<any> {
    return this.http.delete(this.baseUrl + `skills/${id}`, this.getHttpOptions());
  }
  deleteAccomplishments(id): Observable<any> {
    return this.http.delete(this.baseUrl + `accomplishment/${id}`, this.getHttpOptions());
  }

  /* Search User */
  searchUser(key, userId): Observable<any> {
    return this.http.get(this.baseUrl + `connection/search?name=${key}&userId=${userId}`, this.getHttpOptions());
  }

  searchOnlyUser(keyword, userId): Observable<any> {
    return this.http.get(this.baseUrl + `user/search?name=${keyword}&userId=${userId}`, this.getHttpOptions())
  }

  searchUserOnlyFirstName(keyword, userId): Observable<any> {
    return this.http.get(this.baseUrl + `user/search?name=${keyword}&userId=${userId}`, this.getHttpOptions())
  }



  /* Network Sevice */
  sendRequest(obj): Observable<any> {
    return this.http.post(this.baseUrl + `connection`, obj, this.getHttpOptions());
  }
  confirmRequest(obj): Observable<any> {
    return this.http.put(this.baseUrl + `connection`, obj, this.getHttpOptions());
  }

 

  isConnectionExist(userId, connectedUserId): Observable<any> {
    return this.http.get(this.baseUrl + `connection/isConnectionExist/${userId}/${connectedUserId}`);
  }

  getMyNetwork(userId, pageNo): Observable<any> {
    return this.http.get(this.baseUrl + `connection/userId?page=${pageNo}&size=10&userId=${userId}`);
  }
  followRequest(obj): Observable<any> {
    return this.http.post(this.baseUrl + `connection/follow`, obj);
  }

  deleteConnection(connectionId): Observable<any> {
    return this.http.delete(this.baseUrl + `connection/${connectionId}`,this.getHttpOptions());
  }
  allConnectionsByUserId(userId): Observable<any> {
    return this.http.get(this.baseUrl + `connection/findByUserId/${userId}`,this.getHttpOptions());
  }
  getMyNetworkWithOutPagination(userId): Observable<any> {
    return this.http.get(this.baseUrl + `connection/getMyNetwork/${userId}`,this.getHttpOptions());
  }

  // deleteConnection(userId, requestID): Observable<any> {
  //   return this.http.delete(this.baseUrl + `connection/delete?userId=${userId}&connectedUserId=${requestID}`);
  // }
  /* Company Controller*/

  getCompanyInfo(userId): Observable<any> {
    return this.http.get(this.baseUrl + `company/user/`+ userId);
  }
}
