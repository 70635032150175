<div class="experianceCard p-4">
    <div class="tabDataExperiance">
    <div class="row">
        <div class="col-12 pl-0 pr-0">
            <div class="experianceTitle">Experience <sup><img *ngIf="userInfo?.isOwener" data-target="#experianceModel" data-toggle="modal" (click)="newOpen()" src="assets/icons/pencil-square.svg"/></sup></div>
        </div>
        <div class="col-12" *ngFor="let item of experianceList;let i = index; let last = last">
            <div class="row">
                <div class="col-10">
                    <div class="univercityName"> <img [src]= "item.company ? item.company.logo :'assets/img/CompanyProfile.png'" style="height: 15px;"/> {{(item.company)?item.company.name:(item.otherCompany)?item.otherCompany.name:"n/a"}} ({{item.startDate| date:'yyyy'}}<span *ngIf="item.endDate">-{{item.endDate | date: 'yyyy'}}</span>)</div>
                    <div class="faculty"> 
                        <div class="pinIcon"><img src= "assets/icons/pinTower.png"/>
                        </div> 
                        <div class="w-80"><span class="semoBold">{{item.title}}</span></div>
                    </div>
                </div>
                <div class="col-2">
                    <span class="add-icon" style="float: left;"><i class="fa fa-edit" (click)="selecetedItem(item,i)"
                        data-target="#experianceModel" data-toggle="modal"
                        ></i></span> 
                    <span class="add-trash"><i class="fa fa-trash" (click)="deleteExp(item,i)"></i></span>    
                </div>
            </div>
        </div>
        <!-- <div class="col-11 col-xs-10">
            <h5>Experience</h5>
        </div>
        <div class="col-1 col-xs-2">
            <i *ngIf="userInfo?.isOwener" class="fa fa-plus-circle fa-2x" aria-hidden="true"
                data-target="#experianceModel" data-toggle="modal" (click)="newOpen()"></i>
        </div> -->
    </div>
    </div>
    <!-- <div *ngFor="let item of experianceList;let i = index; let last = last" class="mt-3">
        <div class="dsplay-flex"><img style="width:45px" [src]="item.companyLogo" />&nbsp;<h6
                [routerLink]="['/company/companyinfo/publish']" [queryParams]="{companyId:item.companyId}">
                {{item.companyName}}<br />
            </h6>
            <div *ngIf="userInfo?.isOwener" class="deleteIcon">
                <span class="add-icon"><i class="fa fa-edit" (click)="selecetedItem(item,i)"
                        data-target="#experianceModel" data-toggle="modal"></i></span> <br />
                <span class="add-trash"><i class="fa fa-trash" (click)="deleteExp(item,i)"></i></span>
            </div>
        </div>
        <ul class="events">
            <li>
                <time></time>
                <span><strong>{{item.title}}</strong> {{item.startYear}} {{item.startMonth}}
                    <label *ngIf="!item.isWorking"> - {{item.endYear}}
                        {{item.endMonth}} </label> <label style="font-size: 1em;" *ngIf="item.isWorking">Currently
                        Working</label> </span>
            </li>
        </ul>
    </div> -->
</div>
<!-- ================  Experience Model  ======================= -->
<div class="experianceModel">
<div class="modal" id="experianceModel" role="dialog">
    <div class="modal-dialog">

        <div class="modal-content p-3">
            <div class="modal-Custheader">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h6 class="modal-title">Add Experience </h6>
            </div>
            <div class="modal-body">
                <form [formGroup]="experianceForm" (submit)="expSubmit()">
                    <div class="row">
                        <!-- <div class="col-md-12">
                            <table class="table borderless" *ngIf="experianceList.length > 0">
                                <thead>
                                  <tr>
                                    <th>Title</th>
                                    <th>Employment Type</th>
                                    <th>Company Name</th>
                                    <th>Industry</th>
                                    <th>&nbsp;</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="border-0" *ngFor="let item of experianceList;let i = index; let last = last">
                                    <td>{{item.title}}</td>
                                    <td>{{item.employmentType}}</td>
                                    <td>{{(item.company)?item.company.name:(item.otherCompany)?item.otherCompany.name:"n/a"}}</td>
                                    <td>{{(item.company)?item.company.companyType:"n/a"}}</td>
                                    <td>
                                        <span class="add-trash"><i class="fa fa-trash" (click)="deleteExp(item,i)"></i></span>
                                        <button (click)="selecetedItem(item,i)" type="button" class="btn modelBtnTabel"><img style="width:9px;" src="assets/icons/pencil-square-white.svg" />&nbsp;&nbsp;Edit</button>
                                    </td>
                                  </tr>
                                 
                                  
                                </tbody>
                              </table>
                        </div> -->
                        <div class="col col-md-4">
                            <label>Title</label>
                            <input type="text" class="form-control" placeholder="Add Title"
                                formControlName="title">
                            <small class="text-danger"
                                *ngIf="experianceForm.get('title').invalid && experianceForm.get('title').touched">Enter
                                Title.</small>
                        </div>
                        <div class="col col-md-4">
                            <label>Employment Type</label>
                            <span class="selectwrap">
                            <select class="form-control" formControlName="employmentType">
                                <option value="" selected disabled hidden>Full / Part</option>
                                <option value="Full-time">Full-time</option>
                                <option value="Part-Time">Part-Time</option>
                                <option value="Self-Employed">Self-Employed</option>
                                <option value="Freelance">Freelance</option>
                                <option value="Trainee">Trainee</option>
                                <option value="Internship">Internship</option>
                            </select>
                            </span>
                            <small class="text-danger"*ngIf="experianceForm.get('employmentType').invalid && experianceForm.get('employmentType').touched">Select EmploymentType.</small>
                        </div>
                        <div class="col col-md-4">
                            <label>Company Name </label>
                            <div class="form-control">
                                <img [src]="experianceForm.value.companyLogo" style="width: 20px;">
                                <input type="text" (blur)="onBlur()" (focus)="onFocus(4)" (keyup)="search($event)"
                                    placeholder="Bolstart" formControlName="companyName">
                            </div>
                            <small class="text-danger"
                                *ngIf="experianceForm.get('companyName').invalid && experianceForm.get('companyName').touched">Select
                                Company.</small>
                            <div class="card" *ngIf="showList == 4">
                                <div *ngIf="searchList.length > 0">
                                    <li *ngFor="let item of searchList" (click)="selectItem(item)"><img
                                            [src]="item.logo" style="width: 25px;">&nbsp;{{item.name}}</li>
                                </div>
                                <div *ngIf="searchList.length == 0">
                                    <label class="p-3">Company not found</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row" *ngIf="type == 2">
                        <div class="col col-md-12">
                            <label>Employment Role</label>
                            <select class="form-control">
                                <option value="" selected disabled hidden>Select Employment Role</option>
                                <option value="Full-time">Full-time</option>
                                <option value="Part-Time">Part-Time</option>
                                <option value="Self-Employed">Self-Employed</option>
                                <option value="Freelance">Freelance</option>
                                <option value="Trainee">Trainee</option>
                                <option value="Internship">Internship</option>
                            </select>
                        </div>
                    </div> -->
                    <!-- <div class="row">
                        <div class="col col-md-12">
                            <label>Company Name <span class="mandatory">*</span></label>
                            <div class="form-control">
                                <img [src]="experianceForm.value.companyLogo" style="width: 20px;">
                                <input type="text" (blur)="onBlur()" (focus)="onFocus(4)" (keyup)="search($event)"
                                    class="school" placeholder="eg. Acme Corporation" formControlName="companyName">
                            </div>
                            <small class="text-danger"
                                *ngIf="experianceForm.get('companyName').invalid && experianceForm.get('companyName').touched">Select
                                Company.</small>
                            <div class="card" *ngIf="showList == 4">
                                <div *ngIf="searchList.length > 0">
                                    <li *ngFor="let item of searchList" (click)="selectItem(item)"><img
                                            [src]="item.logo" style="width: 25px;">&nbsp;{{item.name}}</li>
                                </div>
                                <div *ngIf="searchList.length == 0">
                                    <label class="p-3">Company not found</label>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <!-- <div class="col col-xl-4">
                            <label>Industry </label>
                            <span class="selectwrap">
                            <select class="form-control">
                                <option value="" selected>E.g-Telecom</option>
                                <option value="1">Item 1</option>
                                <option value="2">Item 2</option>
                                <option value="3">Item 3</option>
                            </select>
                        </span>
                          
                        </div> -->
                        <div class="col col-md-4">
                            <label>Location </label>
                            <input type="text" placeholder="Location" formControlName="jobLocation" class="form-control"
                                (blur)="onBlur()" (focus)="onFocus(5)" (keyup)="search($event)">
                            <div class="card" *ngIf="showList == 5 && searchList.length > 0">
                                <li *ngFor="let item of searchList" (click)="selectedCity(item)"><img [src]="item.logo"
                                        style="width: 25px;">&nbsp;{{item.name}}</li>
                            </div>
                            <small class="text-danger"*ngIf="experianceForm.get('jobLocation').invalid && experianceForm.get('jobLocation').touched">Enter Location.</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xl-12">
                            <div class="form-check">
                                <input type="checkbox" formControlName="isWorking" class="form-check-input" id="exampleCheck1">
                                <label class="form-check-label" for="exampleCheck1">I am Currently Working</label>
                              </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col col-md-12">
                            <input style="width: 30px;" type="checkbox" formControlName="isWorking"><span>I am
                                currently
                                Working</span>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col col-xl-4">
                            <label>Start Date </label>
                        <span class="calenderIconFix">
                            <input type="date" placeholder="To date" class="form-control" formControlName="startDate"
                                [max]="today">
                            <!-- <select class="form-control" formControlName="startYear">
                                <option value='' selected disabled hidden>Start year</option>
                                <option [value]="item" *ngFor="let item of yeaList">{{item}}</option>
                            </select> -->
                        </span>
                            <!-- <img src="/assets/icons/calendar.svg" class="calenderInput"> -->
                        </div>
                        <!-- <div class="col col-xl-3">
                            <label></label>
                            <select class="form-control" formControlName="startMonth">
                                <option value='' selected hidden disabled>Start Month</option>
                                <option [value]="item" *ngFor="let item of monthList">{{item}}</option>
                            </select>
                        </div> -->
                        <div class="col col-xl-4" *ngIf="!this.experianceForm.value.isWorking">
                            <label>End Date </label>
                            <span class="calenderIconFix">
                                <input type="date" placeholder="To date" class="form-control" formControlName="endDate"
                                [max]="today">
                            <!-- <select class="form-control" formControlName="endYear">
                                <option value='' selected disabled hidden>Select End year</option>
                                <option [value]="item" *ngFor="let item of yeaList">{{item}}</option>
                            </select> -->
                        </span>
                            <!-- <img src="/assets/icons/calendar.svg" class="calenderInput"> -->
                        </div>
                        <!-- <div class="col col-xl-3" *ngIf="!this.experianceForm.value.isWorking">
                            <label></label>
                            <select class="form-control" formControlName="endMonth">
                                <option value='' selected hidden disabled>End Month</option>
                                <option [value]="item" *ngFor="let item of monthList">{{item}}</option>
                            </select>
                        </div> -->
                    </div>
                    <div class="row">
                        <div class="col col-md-12">
                            <label>Description</label>
                            <textarea formControlName="description" type="text" placeholder="Add Description"
                                class="form-control textAreaHeight"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-md-12">
                            <div class="text-right">
                                
                                <button type="submit" class="btn modelBtn">Save</button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-md-12">
                            <div  style="cursor: pointer;" (click)="addMore()" class="addMore"><strong style="color: #000;">+</strong> Add More</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</div>
<style>
   input .ng-invalid {
        background-color: red;
    }
</style>