<div class="main">
    <p style="text-align: right;">
        <!-- <i class="fa fa-times" aria-hidden="true" ></i> -->
        <i class="fa fa-times" aria-hidden="true" (click)="onNoClick()"></i>
    </p>
    <div class="content">
        <p>{{data.msg}}</p>
    </div>

    
</div>