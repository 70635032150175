<app-header></app-header>

<div class="box">
    <section class="container">
        <div class="row">
            <div class="col-md-9 mb-2 ProfileleftGridContainerProfile">
                <app-timeline></app-timeline>
                <router-outlet></router-outlet>
            </div>
            <div class="ProfileRightGridContainerProfile d-none d-md-block col-md-3 mb-2">
                <!-- <activity></activity> -->
                <advertisement [image]="add"></advertisement>
                <app-news></app-news>
                <suggestion></suggestion>
            </div>
        </div>
    </section>
</div>
