<div class="accomplishmentCard p-4">
    <div class="tabDataAccomplishment">
        <div class="row">
            <div class="col-12 pl-0 pr-0">
                <div class="AccomplishmenTitle">Accomplishment <sup><img *ngIf="userInfo?.isOwener" data-target="#acctModel" data-toggle="modal" src="assets/icons/pencil-square.svg"/></sup></div>
            </div>
            <div class="col-12 pl-0 pr-0" *ngFor="let item of accomplishmentList;let i = index">
             <div class="row"> 
                <div class="col-10">
                    <!-- <div class="w-20"><i class="fas fa-check-square"></i></div> -->
                    <div class="AccomplishmenTitle">{{item.name}}<span class="boldText">({{item.fromDate | date: 'dd-MMM-yyyy'}} - {{item.tillDate | date: 'dd-MMM-yyyy'}}) </span></div>
                    <div class="normalText">{{item.certificationInstitute}}</div>
                    <div class="descText">{{item.description}}</div>
                </div> 
                <div class="col-2" *ngIf="userInfo?.isOwener">
                    <span class="add-icon" style="float: left;"><i class="fa fa-edit" data-target="#acctModel" data-toggle="modal"
                        (click)="selecetedItem(item,i)"></i></span> 
                    <span class="add-trash"><i class="fa fa-trash" (click)="deleteAccomplishment(item,i)"></i></span>    
                </div>              
                <!-- <div class="w-20"><i class="fas fa-check-square"></i></div>
                <div class="w-80 pl-3">
                    <div *ngIf="userInfo?.isOwener" class="deleteIcon">
                                
                    </div>
                </div>                -->
            </div>

             
          
            </div>
            <div class="mt-5 mb-5 col-12  "></div>
            
           
            
        </div>
        </div>
    <!-- <div class="row">
        <div class="col-11 col-xs-10">
            <h5>Accomplishment</h5>
        </div>
        <div class="col-1 col-xs-2">
            <i *ngIf="userInfo?.isOwener" class="fa fa-plus-circle fa-2x" aria-hidden="true" (click)="newOpen()"
                data-target="#acctModel" data-toggle="modal"></i>
        </div>
    </div> -->

    <!-- <div *ngFor="let item of accomplishmentList;let i = index" class="mt-3">
        <div class="dsplay-flex">
            <h6>
                {{item.name}}<br />{{item.fromDate | date: 'dd-MMM-yyyy'}} - {{item.tillDate | date: 'dd-MMM-yyyy'}}
            </h6>

            <div *ngIf="userInfo?.isOwener" class="deleteIcon">
                <span class="add-icon"><i class="fa fa-edit" data-target="#acctModel" data-toggle="modal"
                        (click)="selecetedItem(item,i)"></i></span> <br />
                <span class="add-trash"><i class="fa fa-trash" (click)="deleteSchool(item,i)"></i></span>

            </div>
        </div>
        <div>
            <label>{{item.certificationInstitute}}</label>
            <p>{{item.description}}</p>
        </div>
    </div> -->
</div>

<!-- ====== Education Model ===================== -->
<div class="acctModel">
<div class="modal" id="acctModel" role="dialog">
    <div class="modal-dialog">

        <div class="modal-content p-3">
            <div class="modal-Custheader">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h6 class="modal-title">Add Accomplishment</h6>
            </div>
            <div class="modal-body">
                <form [formGroup]="accomplishmentForm" (submit)="eduSubmit()">
                    <div class="row">
                        <div class="col col-md-12">
                            <label>Certification Name</label>
                            <input type="text" class="form-control" placeholder="Certification Name"
                                formControlName="name">
                            <small class="text-danger"
                                *ngIf="accomplishmentForm.get('name').invalid && accomplishmentForm.get('name').touched">Enter
                                Certification
                                Name</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-md-12">
                            <label>Institute Name</label>
                            <input type="text" class="form-control" placeholder="Institute Name"
                                formControlName="certificationInstitute">
                            <small class="text-danger"
                                *ngIf="accomplishmentForm.get('certificationInstitute').invalid && accomplishmentForm.get('certificationInstitute').touched">Enter
                                institute Name</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-md-12">
                            <label>Description</label>
                            <input type="text" placeholder="Description" class="form-control"
                                formControlName="description">
                            <small class="text-danger"
                                *ngIf="accomplishmentForm.get('description').invalid && accomplishmentForm.get('description').touched">Enter
                                Description</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-4">
                            <label>From</label>
                            <input type="date" placeholder="From date" class="form-control" formControlName="fromDate"
                                [max]="today" />
                        </div>
                        <div class="col col-4">
                            <label>To</label>
                            <input type="date" placeholder="To date" class="form-control" formControlName="tillDate"
                                [max]="today">
                        </div>
                    </div>
                    <div class="text-center">
                        <button type="submit" class="btn modelBtn">Save</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
</div>  