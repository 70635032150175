import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';

import { Component, OnInit, ViewChild } from '@angular/core';

import { HomeService } from './../service/home.service';
import { PublishContainerService } from 'src/app/shared/publish-container/publish-container.service';
import { postService } from './../../../shared/post-container/post-container.service';
import { WritePostComponent } from './write-post/write-post.component';
@Component({
  selector: 'post-Panel',
  templateUrl: './post-panel.component.html',
  styleUrls: ['./post-panel.component.scss']
})
export class PostPanelComponent implements OnInit {
  @ViewChild(WritePostComponent) writePostComponent;
  userInfo: any;
  postList: any = [];
  postPublisList: any =[]
  selectedTab: number = 1;
  currentPage: number = 0;
  throttle = 300;
  scrollDistance = 1;
  isStudent = false;
  publishList: any = [];
  companyInfo: any;
  publishItem:any;
  constructor(
    private apiService: HomeService,
    private postService: postService,
    private publishService: PublishContainerService
  ) {

  }
   

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('user'));
    this.getPostList(this.currentPage);    
    var companyList = JSON.parse(localStorage.getItem('company'));   
    
    if(companyList.length > 0){
      this.companyInfo = companyList[0];
    }    
    this.userInfo.roles.forEach(element => {
      if(element.roleName == 'Student'){
        this.isStudent = true;
      }
    });
    // if(this.userInfo.roles)
    //   this.apiService.event
    //     .subscribe(data => {
    //       if (this.selectedTab == 1) {
    //         this.getPostList(this.currentPage);
    //       } else {
    //         this.getPublishList()
    //       }

    //     });
    // this.postService.updateEvent.subscribe(data => {
    //   this.postList.map((post) => {
    //     if (data.id == post.id) {
    //       return [post.description = data.description, post.image = data.image]
    //     }
    //   })
    // })

    // this.postService.deleteEvent.subscribe(data => {
    //   this.postList.map((post, index) => {
    //     if (data == post.id) {
    //       this.postList.splice(index, 1)
    //     }
    //   })
    // })

    // this.publishService.deleteEvent.subscribe(data => {
    //   this.publishList.map((post, index) => {
    //     if (data == post.id) {
    //       this.postList.splice(index, 1)
    //     }
    //   })
    // })
    this.getPublishList();
    // this.publishService.updateEvent.subscribe(data => {
    //   this.publishList.map((post) => {
    //     if (data.id == post.id) {
    //       return [post.description = data.description, post.image = data.image]
    //     }
    //   })
    // })
    
  }
  communication(obj){
    switch(obj.action){
      case "POST_LIKE":
        this.ngOnInit() ;
        break;

    }
    console.log("parent call ");
  }
  parentFunction(){
    this.ngOnInit()     
  }
  publishItemUpdate(publish){
    this.writePostComponent.updatePublish(publish);      
  } 
  postItemUpdate(postItem){
    this.writePostComponent.updatePostItem(postItem);       
  } 
  postPanel(){
    alert();
    this.ngOnInit()     
  } 

  mergeList(){
    this.postPublisList = this.postList
    this.postPublisList = this.postPublisList.concat(this.publishList)
    this.postPublisList  = this.postPublisList.sort(function(a, b){return b.createdAt - a.createdAt});
  }

  getPostList(currentPage) {    
    this.apiService.getPostListByConnection(this.userInfo.id, currentPage).subscribe(res => {
      if (res.success) {
        if(res.data.content.length>0)
        //this.postList = this.postList.concat(res.data.content);   
        this.postList = res.data.content;   
        this.mergeList();      
      }
    })
  }

  getPublishList() {       
      this.apiService.getPublishListByCompanyIdAndUserId(0, this.userInfo.id).subscribe(res => {
        if (res.success) {
          if(res.data != null){          
            this.publishList = res.data;
            this.mergeList();
          }
        }
      })
     
  }

  getList(number) {
    this.selectedTab = number;
    switch (number) {
      case 1:
        this.getPostList(this.currentPage)
        break;
      case 2:        
        this.getPublishList()
        break;

    }
  }

  /* ======================  infinite Scroll  =========================== */
  onScrollDown() {    
    this.currentPage += 1
    if (this.selectedTab == 1) {
      this.getPostList(this.currentPage);
    } else {

    }

  }
 
}
