<div class="activeStats" *ngIf="presence$ | async as presence">
    <div class="tag is-large" 
      [ngClass]="{
          'is-success':  presence.status  === 'online',
          'is-warning': presence.status  === 'away',
          'is-danger':  presence.status  === 'offline'
      }"
    >
    </div>
    <div>{{ presence.status }}</div>
</div>
